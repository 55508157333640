import { FINISH_GET_STATISTICS_REPORTS, START_GET_STATISTICS_REPORTS } from 'src/store/actions/reportsActions.js';
import {DAILY_REPORT, NEW_REPORT, TOUCH_REPORT} from "@main/i18n/aliases";
import i18n from "@main/i18n";

const initialState = {
  isLoading: false,
  reports: null,
  nextDefaultName: 0,
};

const getNextDefaultName = (reports) => {
  let i = 1;
  while (true) {
    const possibleName = i18n.t(NEW_REPORT)+` ${i}`;
    if (reports.map((report) => report.name).includes(possibleName)) {
      i += 1;
    } else {
      return possibleName;
    }
  }
};

export default function ReportsReducer(state = initialState, { payload, type}) {

  const defaultReports = [
    {
      id: 'byDayReport',
      name: i18n.t(DAILY_REPORT),
      groups: ['year', 'month', 'day'],
      columns: ['users', 'active', 'active_percent', 'conversations', 'conversations_percent', 'ban', 'ban_percent', 'approved'],
      need_counters: true,
      counters: ['conversations', 'ban'],
    },
    {
      id: 'byTouchReport',
      name: i18n.t(TOUCH_REPORT),
      groups: ['year', 'month', 'day'],
      columns: ['users', 'approved', 'approved_percent', 'conversations', 'conversations_percent', 'ban', 'ban_percent', 'rejected', 'rejected_percent', 'combo', 'combo_percent', 'pending'],
      need_counters: true,
      counters: ['conversations', 'ban'],
    },
  ];
  switch (type) {
    case START_GET_STATISTICS_REPORTS:
      return {
        ...state,
        isLoading: true,
      };
    case FINISH_GET_STATISTICS_REPORTS:
      return {
        ...state,
        isLoading: false,
        reports: [...defaultReports, ...(payload.report || [])],
        nextDefaultName: getNextDefaultName(
          [...defaultReports, ...(payload.report || [])],
        ),
      };
    default:
      return state;
  }
}
