import {
  ACTION_TYPE,
  ACTIONS,
  ACTIONS_COUNT,
  ACTIVE,
  ACTIVE_USERS,
  ADD_CHANNEL,
  ADD_COLUMNS,
  ADD_FILTER,
  ADD_GROUPING,
  ADDITIONAL_PARAMETERS,
  ALL_CHANNELS,
  ALL_GOALS,
  AMOUNT,
  ANALYTICS,
  APPROVED_CONVERSIONS,
  AUDIENCE_CHURN,
  AUTHORIZATION,
  AVAILABLE_CHANNELS,
  AVAILABLE_FOR_WITHDRAWAL,
  BALANCE,
  BILL,
  BILLING_METHOD,
  BY_DAY,
  BY_INTERACTION,
  CAMPAIGN_NAME,
  CHANNEL,
  CHANNELS,
  CHANNELS_ADD_BUTTON_TOOLTIP,
  CHANNELS_ANALYTICS_TOOLTIP,
  CHANNELS_DIALOGS_TOOLTIP,
  CHANNELS_EXPANSION_LIST_TOOLTIP,
  CHANNELS_LINKS_TOOLTIP,
  CHURN,
  CHURN_COUNT,
  CITIES,
  CLICK_TO_COPY,
  CLOSED,
  COMMENT,
  CONNECT,
  CONNECTING_TELEGRAM_BOT,
  CONNECTING_VIBER_BOT,
  COPIED,
  COUNTRY,
  CR_CLICKED_LINKS,
  CR_GIVEN_LINKS,
  CREATED,
  CURRENT_MONTH,
  CUSTOM_DATES,
  CZECH_REPUBLIC,
  DATE,
  DAY,
  DESCRIPTION,
  DIALOG_COST,
  DIALOGS,
  DIALOGS_COUNT,
  DIRECT_LINK,
  DOWNLOAD,
  DOWNLOAD_REPORT,
  EARNINGS_PER_CONVERSION,
  EARNINGS_PER_DIALOG,
  ENTER_THE_AMOUNT_TO_WITHDRAW,
  ESTIMATE_DATE,
  EXTRA_FIELD,
  FINANCES,
  GEOGRAPHY,
  GERMANY,
  GOALS_AND_ACTIONS,
  GROUPING_BY_DAYS,
  GROUPING_BY_HOURS,
  GROUPING_BY_MONTHS,
  GROUPING_BY_YEARS,
  GROUPS_VK,
  HOLD,
  HOLD_CONVERSIONS,
  HOUR,
  HTML_CODE,
  IN_PROGRESS,
  INCOME,
  ITALY,
  KAZAKHSTAN,
  LAST_14_DAYS,
  LAST_30_DAYS,
  LAST_7_DAYS,
  LAST_MONTH,
  LEADS,
  LINKS,
  MAIN_PARAMETERS,
  MANAGE,
  MONTH,
  NAME,
  NEW_REQUEST,
  NEW_USERS,
  NEWEST_FIRST,
  NO_DATA,
  OFFER,
  OFFER_CONNECT_TOOLTIP,
  OFFERS,
  OLDEST_FIRST,
  ON_HOLD,
  ORDER_A_PAYMENT,
  PAGES_FACEBOOK,
  PAUSE,
  PAYMENTS_AMOUNT,
  PAYOUT_HISTORY,
  PLATFORM,
  POLAND,
  PREFERRED_CITIES,
  RATING_NOT_YET_AVAILABLE,
  REJECTED_CONVERSIONS,
  REMOVE,
  REPORTS,
  REQUEST,
  RESET,
  RUSSIA,
  SETTINGS,
  SIGN_IN,
  SOURCE,
  SPAIN,
  START,
  STATUS,
  SWITCH_PROFILE,
  TODAY,
  TOKEN,
  TOTAL,
  TOTAL_ON_ACCOUNT,
  TOOLS,
  TRACKING_LINK,
  TRAFFIC_TYPE,
  UKRAINE,
  UNWANTED_CITIES,
  USA,
  USERS,
  WHICH_CHANNEL_TO_CONNECT,
  YEAR,
  YOU_NEED_TO_LOG_INTO_YOUR_FACEBOOK_ACCOUNT_TO_PROCEED,
  YOU_NEED_TO_LOG_INTO_YOUR_VK_ACCOUNT_TO_PROCEED,
  APPEARANCE,
  THE_AMOUNT_OF_MONEY,
  ERROR_LOADING_PAYMENT_METHODS,
  MONEY_THAT_IS_CURRENTLY_AVAILABLE_FOR_WITHDRAWAL,
  PAYMENTS_YOU_ORDERED,
  WELCOME_TO_FINANCE,
  ON_THIS_PAGE_YOU_CAN_WITHDRAW_YOUR_FUNDS,
  MONEY_THAT_IS_CURRENTLY_NOT_AVAILABLE_FOR_WITHDRAWAL,
  ORDER_A_PAYOUT,
  YIELD_CHART,
  AVAILABLE,
  INCOME_PER_DAY,
  CONNECTED_CHANNELS,
  CONNECTED_MESSENGERS_TO_OFFERS,
  THE_AUDIENCE,
  THE_AUDIENCE_DESC,
  LTV,
  TOTAL_INCOME,
  LTV_DESC,
  INCOME_PER_DIALOG,
  INCOME_PER_DIALOG_DESCRIPTION,
  DELETE_ERROR,
  OFFER_INFO,
  COST_PER_ACTION,
  COST_PER_ACTION_DESC,
  REVENUE_SHARE_DESC,
  REVENUE_SHARE,
  PENDING,
  EXIT,
  ALL_OFFERS,
  MY_OFFERS,
  CHOOSE_THE_RIGHT_MESSENGER,
  CHOOSE_A_MONETIZATION_METHOD_FOR_THIS_CHANNEL,
  CONNECT_CHANNEL,
  YOUR_CHANNEL_HAS_BEEN_SUCCESSFULLY_CONNECTED,
  ON_THE_CHANNELS_PAGE_,
  GO_TO_CHANNELS_PAGE,
  CONNECT_ANOTHER_CHANNEL,
  CONNECTING_CHANNELS,
  CONVERSION,
  OFFER_STAT_CONVERSION_DESC,
  OFFER_STAT_INCOME_DESC,
  ALL,
  FAVORITES,
  LOGO,
  MORE,
  PLACE_DELETED,
  INCOME_DESC,
  ON_THIS_PAGE_YOU_CAN_SEE_THE_TERMS_,
  SEARCH,
  PLACE,
  PLACE_ACTIVE,
  PLACE_STOPPED,
  ACTIVATION_ERROR,
  STOPPED_ERROR,
  WANT_DELETE_PACE,
  CANCEL,
  APPROVE,
  ATTRITION,
  ERROR,
  BACK,
  PAYOUTS,
  CONNECT_OFFER,
  REGISTRATION,
  OFFER_DESC,
  MONETIZATION_OPTIONS,
  AVAILABLE_MESSENGERS,
  EVENTS,
  CLICK_PER_LINK_DESC,
  STATISTICS,
  DAILY_REPORT,
  TOUCH_REPORT,
  NEW_REPORT,
  SELECT_DATES,
  RESET_ALL,
  APPLY_FILTERS,
  FILTERS,
  CHOOSE_THE_FILTERS_,
  ERROR_DURING_FETCH_BALANCE,
  WAS_CREATED,
  IN_PROCESSING,
  COMPLETED,
  REJECTED,
  PAYMENT_HISTORY,
  REMOVING_A_HOLD,
  TERMS_AND_PROCEDURE_OF_PAYMENTS,
  ID,
  DATE_OF_CREATION,
  SUM,
  PAYOUT_METHOD,
  TERMS_AND_PROCEDURE_OF_PAYMENTS_DESC,
  PAYMENT_TERM,
  COMMISSION_AMOUNT,
  WORKING_DAY,
  WORKING_DAYS,
  WORKING_DAYS_2,
  ZALEYCASH,
  GETUNIQ,
  WEBMONEY_WMZ,
  YANDEX_MONEY,
  SOLAR_STAFF,
  CAPITALIST,
  GOOGLE_ADWORDS,
  MYTARGET,
  YANDEX_DIRECT,
  BANK_CARD,
  BANK_TRANSFER,
  BANK_TRANSFER_WITH_EDI,
  BANK_TRANSFER_SELF_EMPLOYED,
  SUBSCRIBERS,
  SUBSCRIBERS_DESC,
  BANNED,
  COMPLETED_ALL_ACTIONS,
  CONVERSIONS,
  REVENUE_PER_CONVERSION,
  REVENUE,
  NUMBER_OF_FIRST_EVENT_CONVERSIONS,
  FIRST_EVENT_CONVERSION_PERCENTAGE,
  BY_HOURS,
  BY_MONTH,
  BY_YEAR,
  REPORT_BY_DAY,
  BY_OFFERS,
  BY_CHANNELS,
  CLICKS,
  APPROVED,
  EXIT_DATES_FROM_HOLD,
  NUMBER_OF_CONVERSIONS,
  TOTAL_L,
  HOLD_REMOVAL_SCHEDULE,
  HOLD_REMOVAL_SCHEDULE_,
  CAME_OUT_OF_HOLD,
  ERROR_WHILE_GETTING_LIST_OF_OFFERS,
  SELECTED,
  SELECTED_1,
  SELECTED_2,
  SELECTED_3,
  BY_OFFER,
  OFFERS_1,
  OFFERS_L,
  OFFER_L,
  BY_CHANNEL,
  CHANNEL_L,
  CHANNEL_L_1,
  CHANNEL_L_2,
  BY_PLATFORM,
  PLATFORM_L,
  PLATFORMS_L,
  PLATFORM_L_1,
  BY_UTM_MARKS,
  MARK_L,
  MARK_L_1,
  MARKS_L,
  COPY_LINK,
  LINK_COPIED,
  UTM_MARKS,
  UTM_MARKS_CREATE_DESC,
  DOES_NOT_HAVE_CONNECTED_OFFERS,
  DOES_NOT_HAVE_CONNECTED_OFFERS_DESC,
  GO_TO_OFFER_PAGE,
  EMPTY,
  EMPTY_FAVORITE_OFFERS,
  DOES_NOT_HAVE_HOLD,
  DOES_NOT_HAVE_HOLD_DESC,
  YOU_HAVE_NOT_MADE_ANY_PAYMENTS,
  YOU_HAVE_NOT_MADE_ANY_PAYMENTS_DESC,
  ORDER_PAYMENT,
  NO_DATA_BY_FILTERS,
  NO_CONNECTED_CHANNELS,
  NO_CONNECTED_CHANNELS_DESC,
  HAVE_USER_WHIS_PHONE,
  HAVE_ACCOUNT_Q,
  ENTER,
  NAME_FIELD,
  NAME_PLACEHOLDER,
  PHONE_NUMBER,
  INVALID_PASSWORD_TRY_ANOTHER_ONE,
  INVALID_PASSWORD,
  GOOGLE_FIELDS,
  HELLO_S,
  VERIFICATION_CODE,
  GO_BACK,
  LAST_REGISTRATION_STEP,
  THINK_OF_A_PASSWORD,
  PASSWORD,
  CONFIRM_PASSWORD,
  ENTER_THE_CONFIRMATION_CODE,
  YOU_CAN_RESUBMIT_VIA,
  RESEND_CODE,
  SECONDS_S,
  RESEND_CODE_BUTTON,
  DONE,
  PLEASE_WAIT_ACCOUNT_VERIFICATION,
  COMPLETE_THE_SURVEY,
  REGISTRATION_REJECTED,
  DATA_NOT_FOUND,
  INCORRECT_REQUEST,
  USER_IS_NOT_REGISTERED,
  INTERNAL_ERROR,
  SERVER_UNAVAILABLE,
  REGISTRATION_REQUEST,
  HOW_DID_YOU_HEAR_ABOUT_LINKSTREAM,
  WHAT_TYPE_OF_TRAFFIC_DO_YOU_USE,
  HOW_MANY_SUBSCRIBERS,
  DO_YOU_HAVE_EXPERIENCE_THROUGH_CPA,
  ARE_YOU_REGISTERED_A_AN_INDIVIDUAL_ENTREPRENEUR,
  SEND_APPLICATION,
  APPLICATION_SUCCESSFULLY_CREATED,
  CONTINUE,
  REMEMBER_ME,
  INVALID_INPUT_FORMAT,
  EMAIL_OR_PHONE,
  FORGOT_PASSWORD, IF_YOU_HAVE_NOT_ACCOUNT, REGISTER,
} from '@main/i18n/aliases';

export default {
  [COPY_LINK]:'Copy link',
  [REMEMBER_ME]:'Remember me',
  [EMAIL_OR_PHONE]:'E-mail or phone',
  [FORGOT_PASSWORD]:'Forgot your password?',
  [IF_YOU_HAVE_NOT_ACCOUNT]:'If you don\'t have an account, ',
  [REGISTER]:'Register',
  [INVALID_INPUT_FORMAT]:'Invalid input format',
  [CONTINUE]:'Continue',
  [RESEND_CODE_BUTTON]:'Send again',
  [DONE]:'Done',
  [PLEASE_WAIT_ACCOUNT_VERIFICATION]:'You have completed the survey, please wait while your account is verified.',
  [COMPLETE_THE_SURVEY]:'Your account is currently under moderation. To pass moderation, please complete the survey',
  [REGISTRATION_REJECTED]:'Your registration has been rejected.',
  [DATA_NOT_FOUND]:'Data not found',
  [INCORRECT_REQUEST]:'The request was sent incorrectly',
  [USER_IS_NOT_REGISTERED]:'User is not registered',
  [INTERNAL_ERROR]:'Internal server error. Please contact support.',
  [SERVER_UNAVAILABLE]:'The server is temporarily unresponsive. Please try again later.',
  [REGISTRATION_REQUEST]:'Application for registration',
  [HOW_DID_YOU_HEAR_ABOUT_LINKSTREAM]:'How did you hear about Neometrix?',
  [WHAT_TYPE_OF_TRAFFIC_DO_YOU_USE]:'What type of traffic do you use? (provide a link to the site/VK/telegram page)',
  [HOW_MANY_SUBSCRIBERS]:'How many subscribers (audience) do you have?',
  [DO_YOU_HAVE_EXPERIENCE_THROUGH_CPA]:'Do you have experience providing services through CPA networks/platforms?',
  [ARE_YOU_REGISTERED_A_AN_INDIVIDUAL_ENTREPRENEUR]:'Are you registered as an individual entrepreneur/self-employed?',
  [SEND_APPLICATION]:'Send application',
  [APPLICATION_SUCCESSFULLY_CREATED]:'Your application has been successfully created.',
  [YOU_CAN_RESUBMIT_VIA]:'You can resubmit via',
  [RESEND_CODE]:'If the code was not delivered, we can resend it.',
  [SECONDS_S]:'s',
  [HAVE_USER_WHIS_PHONE]:'A user with this phone number is already registered',
  [HAVE_ACCOUNT_Q]:'Do you have an account?',
  [ENTER]:'Sign in',
  [NAME_FIELD]:'Name',
  [PHONE_NUMBER]:'Phone number',
  [INVALID_PASSWORD_TRY_ANOTHER_ONE]:'Incorrect password entered. Try another one',
  [INVALID_PASSWORD]:'Incorrect password',
  [GOOGLE_FIELDS]:'We used your Google account data. Fill in the blanks if any.',
  [HELLO_S]:'hello',
  [VERIFICATION_CODE]:'Verification code',
  [GO_BACK]:'Go back',
  [LAST_REGISTRATION_STEP]:'There is 1 step left to get started — create a password',
  [THINK_OF_A_PASSWORD]:'Think of a password',
  [PASSWORD]:'Password',
  [CONFIRM_PASSWORD]:'Confirm password',
  [ENTER_THE_CONFIRMATION_CODE]:'Enter the confirmation code sent to the number',
  [NAME_PLACEHOLDER]:'Alex',
  [DOES_NOT_HAVE_CONNECTED_OFFERS]:'Oops, you haven\'t connected any offers.',
  [DOES_NOT_HAVE_CONNECTED_OFFERS_DESC]:'To connect offers, go to the "All offers" page, where you can select the offer you are interested in.\nAfter connecting the offer, it will appear on this page, where you can manage it.',
  [GO_TO_OFFER_PAGE]:'Go to the offers page',
  [EMPTY]:'Empty',
  [EMPTY_FAVORITE_OFFERS]:'You can add offers to your favorites from the list by clicking\non the star in the upper right corner of the offer card.',
  [DOES_NOT_HAVE_HOLD]:'There is no hold',
  [DOES_NOT_HAVE_HOLD_DESC]:'when you start pouring traffic, you will be able to track when and what amounts will be on hold, exit it and be available for you to withdraw funds.',
  [YOU_HAVE_NOT_MADE_ANY_PAYMENTS]:'you have not made any payments',
  [YOU_HAVE_NOT_MADE_ANY_PAYMENTS_DESC]:'Once a payment is created, it goes to this page where you can track its status.',
  [ORDER_PAYMENT]:'Order payment',
  [LINK_COPIED]:'Link copied',
  [UTM_MARKS]:'Utm marks',
  [UTM_MARKS_CREATE_DESC]:'You can specify "Utm tags" and get the generated link here or specify tags directly in the address bar.',
  [SELECTED]:'Selected',
  [SELECTED_1]:'Selected',
  [SELECTED_2]:'Selected',
  [SELECTED_3]:'Selected',
  [BY_PLATFORM]:'By platform',
  [BY_UTM_MARKS]:'By utm marks',
  [MARK_L]:'mark',
  [MARK_L_1]:'mark',
  [MARKS_L]:'marks',
  [PLATFORM_L]:'platform',
  [PLATFORM_L_1]:'platform',
  [PLATFORMS_L]:'platforms',
  [BY_OFFER]:'By offer',
  [OFFERS_1]:'offers',
  [EXIT_DATES_FROM_HOLD]:'Exit dates from hold',
  [NUMBER_OF_CONVERSIONS]:'Number of conversions',
  [HOLD_REMOVAL_SCHEDULE]:'Hold Removal Schedule',
  [HOLD_REMOVAL_SCHEDULE_]:'This chart shows you what amount of money will be available for withdrawal, what will be on hold and what will come out of hold.',
  [CAME_OUT_OF_HOLD]:'Came out of hold',
  [ERROR_WHILE_GETTING_LIST_OF_OFFERS]:'Error while getting list of offers',
  [ID]:'ID',
  [COMPLETED_ALL_ACTIONS]:'Completed all actions',
  [CONVERSIONS]:'Conversions',
  [REVENUE_PER_CONVERSION]:'Revenue per conversion',
  [REVENUE]:'Revenue',
  [NUMBER_OF_FIRST_EVENT_CONVERSIONS]:'Number of first event conversions',
  [FIRST_EVENT_CONVERSION_PERCENTAGE]:'First event conversion percentage',
  [BY_HOURS]:'By hours',
  [BY_DAY]:'By day',
  [BY_MONTH]:'By month',
  [BY_YEAR]:'By year',
  [BY_OFFERS]:'By offers',
  [BY_CHANNELS]:'By channels',
  [BY_CHANNEL]:'By channel',
  [SUBSCRIBERS]:'Subscribers',
  [SUBSCRIBERS_DESC]:'Subscribers',
  [DATE_OF_CREATION]:'Date of creation',
  [SUM]:'Sum',
  [PAYOUT_METHOD]:'Payout method',
  [PAYMENT_TERM]:'Payment term',
  [WORKING_DAY]:'working day',
  [WORKING_DAYS]:'working days',
  [WORKING_DAYS_2]:'working days',
  [ZALEYCASH]:'ZaleyCash',
  [GETUNIQ]:'GetUniq',
  [WEBMONEY_WMZ]:'Webmoney (WMZ)',
  [YANDEX_MONEY]:'Yandex money',
  [SOLAR_STAFF]:'Solar-staff (bank card)',
  [CAPITALIST]:'Capitalist (RUB payment system)',
  [GOOGLE_ADWORDS]:'Google adwords',
  [MYTARGET]:'Mytarget',
  [YANDEX_DIRECT]:'YandexDirect',
  [BANK_CARD]:'Bank card',
  [BANK_TRANSFER]:'Bank transfer (Only for legal entities)',
  [BANK_TRANSFER_WITH_EDI]:'Bank transfer (With EDI connection)',
  [BANK_TRANSFER_SELF_EMPLOYED]:'Bank transfer (Self-employed)',
  [COMMISSION_AMOUNT]:'Commission amount',
  [IN_PROCESSING]:'In processing',
  [REJECTED]:'Rejected',
  [PAYMENT_HISTORY]:'Payment history',
  [REMOVING_A_HOLD]:'Removing a hold',
  [TERMS_AND_PROCEDURE_OF_PAYMENTS]:'Terms and procedure of payments',
  [TERMS_AND_PROCEDURE_OF_PAYMENTS_DESC]:'The deadline is approximate, subject to a quick exchange of documents. Payment is made within 3 working days from the moment the webmaster provides signed original documents.',
  [COMPLETED]:'Completed',
  [WAS_CREATED]:'Created',
  [ERROR_DURING_FETCH_BALANCE]:'Error during fetch balance',
  [NEW_REPORT]:'New report',
  [SELECT_DATES]:'Select dates',
  [RESET_ALL]:'Reset all',
  [APPLY_FILTERS]:'Apply filters',
  [FILTERS]:'Filters',
  [CHOOSE_THE_FILTERS_]:'Choose the filters you want appear in this area',
  [TOUCH_REPORT]:'Touch report',
  [DAILY_REPORT]:'Daily report',
  [CONNECT_OFFER]:'Connect offer',
  [OFFER_DESC]:'Offer description',
  [MONETIZATION_OPTIONS]:'Monetization options',
  [AVAILABLE_MESSENGERS]:'Available messengers',
  [EVENTS]:'Events',
  [CLICK_PER_LINK_DESC]:'Cliks per link',
  [REGISTRATION]:'Registration',
  [PAYOUTS]:'Payouts',
  [BACK]:'Back',
  [ERROR]:'Error',
  [ALL_OFFERS]:'All offers',
  [MY_OFFERS]:'My offers',
  [EXIT]:'Exit',
  [PENDING]:'Pending',
  [REVENUE_SHARE_DESC]:'An affiliate program model in which the webmaster receives a percentage of the profits generated. Short holds. This model will generate income for at least several more months. No limits on payments. The number of payments depends directly on your quality traffic.',
  [REVENUE_SHARE]:'Revenue Share',
  [COST_PER_ACTION_DESC]:'Payment for any action specified in the offer, bringing a person into dialogue. Quick money, but big holdings. There are limits on payments per day/week.\nAbility to reject conversions. Not always quick approvals with advertisers.',
  [COST_PER_ACTION]:'Cost per Action',
  [OFFER_INFO]:'Offer info',
  [DELETE_ERROR]:'Delete error',
  [INCOME_PER_DIALOG_DESCRIPTION]:'Average income from dialogue',
  [INCOME_PER_DIALOG]:'Income per dialog',
  [LTV_DESC]:'',
  [TOTAL_INCOME]:'Total income',
  [LTV]:'ltv',
  [THE_AUDIENCE]:'The audience',
  [THE_AUDIENCE_DESC]:'The volume of active subscribers that you have accumulated in the bot (excluding banned and inactive users)',
  [CONNECTED_MESSENGERS_TO_OFFERS]:'Connected messengers to offers',
  [CONNECTED_CHANNELS]:'Connected channels',
  [INCOME_PER_DAY]:'Income per day',
  [INCOME_DESC]:'Income',
  [AVAILABLE]:'Available',
  [YIELD_CHART]:'Yield chart',
  [ORDER_A_PAYOUT]:'Order a payout',
  [MONEY_THAT_IS_CURRENTLY_NOT_AVAILABLE_FOR_WITHDRAWAL]:'Money that is currently not available for withdrawal',
  [ON_THIS_PAGE_YOU_CAN_WITHDRAW_YOUR_FUNDS]:'On this page you can withdraw your funds, view your payout history, compare profitability, etc.',
  [WELCOME_TO_FINANCE]:', welcome to Finance',
  [PAYMENTS_YOU_ORDERED]:'Payments you ordered',
  [MONEY_THAT_IS_CURRENTLY_AVAILABLE_FOR_WITHDRAWAL]:'Money that is currently available for withdrawal.',
  [ERROR_LOADING_PAYMENT_METHODS]:'Error loading payment methods',
  [THE_AMOUNT_OF_MONEY]: 'The amount of money available for withdrawal and money in hold.',
  [BALANCE]: 'Total on account',
  [CHOOSE_THE_RIGHT_MESSENGER]: 'Choose the right messenger',
  [CHOOSE_A_MONETIZATION_METHOD_FOR_THIS_CHANNEL]: 'Choose a monetization method for this channel',
  [CONNECT_CHANNEL]: 'Connect channel',
  [YOUR_CHANNEL_HAS_BEEN_SUCCESSFULLY_CONNECTED]: 'Your channel has been successfully connected',
  [ON_THE_CHANNELS_PAGE_]: 'On the channels page, you can\nadjust group settings in more detail',
  [GO_TO_CHANNELS_PAGE]: 'Go to channels page',
  [CONNECT_ANOTHER_CHANNEL]: 'Connect another channel',
  [CONNECTING_CHANNELS]: 'Connecting channels',
  [CONVERSION]: 'Conversion',
  [OFFER_STAT_CONVERSION_DESC]: 'Conversion percent is the ratio of the number of site visitors who completed any targeted actions on it over the last 30 days.',
  [REPORTS]: 'Reports',
  [GOALS_AND_ACTIONS]: 'Goals & Actions',
  [DESCRIPTION]: 'Description',
  [AVAILABLE_CHANNELS]: 'Available channels',
  [HOLD]: 'Hold',
  [ON_HOLD]: 'On hold',
  [GEOGRAPHY]: 'Geography',
  [UNWANTED_CITIES]: 'Unwanted cities',
  [PREFERRED_CITIES]: 'Preferred cities',
  [BY_INTERACTION]: 'Report by actions',
  [REPORT_BY_DAY]: 'Report by days',
  [GROUPING_BY_HOURS]: 'Group by hour',
  [GROUPING_BY_DAYS]: 'Group by day',
  [GROUPING_BY_MONTHS]: 'Group by month',
  [GROUPING_BY_YEARS]: 'Group by year',
  [HOUR]: 'Hour',
  [DAY]: 'Day',
  [MONTH]: 'Month',
  [YEAR]: 'Year',
  [CUSTOM_DATES]: 'Custom dates',
  [TODAY]: 'Today',
  [NEW_USERS]: 'New users',
  [ACTIVE]: 'Active',
  [BANNED]: 'Banned',
  [ACTIVE_USERS]: 'Active users',
  [ACTIONS]: 'Actions',
  [ACTIONS_COUNT]: 'Actions in dialogs count',
  [CHURN]: 'Churn',
  [CHURN_COUNT]: 'Churn count',
  [LAST_7_DAYS]: 'Last 7 days',
  [LAST_14_DAYS]: 'Last 14 days',
  [LAST_30_DAYS]: 'Last 30 days',
  [CURRENT_MONTH]: 'Current month',
  [LAST_MONTH]: 'Last month',
  [ADD_FILTER]: 'Add filter',
  [ADD_GROUPING]: 'Add group',
  [ADD_COLUMNS]: 'Add column',
  [DATE]: 'Date',
  [USERS]: 'Users',
  [APPROVED]: 'Approved',
  [APPROVED_CONVERSIONS]: 'Approved conversions',
  [HOLD_CONVERSIONS]: 'Hold conversions',
  [REJECTED_CONVERSIONS]: 'Rejected conversions',
  [ALL_GOALS]: 'All actions',
  [ALL]: 'All',
  [FAVORITES]: 'Favorites',
  [LOGO]: 'Logo',
  [MORE]: 'More',
  [PLACE]: 'Place',
  [PLACE_ACTIVE]: 'The place is active',
  [PLACE_STOPPED]: 'The place has been stopped',
  [PLACE_DELETED]: 'The place has been deleted',
  [ACTIVATION_ERROR]: 'The place has been deleted',
  [STOPPED_ERROR]: 'The place has been deleted',
  [WANT_DELETE_PACE]: 'Are you sure you want to delete the place?',
  [CANCEL]: 'Cancel',
  [APPROVE]: 'Approve',
  [ATTRITION]: 'Attrition',
  [OFFER]: 'Offer',
  [OFFER_L]: 'offer',
  [CHANNEL]: 'Channel',
  [CHANNEL_L]: 'channel',
  [CHANNEL_L_1]: 'channel',
  [CHANNEL_L_2]: 'channel',
  [PLATFORM]: 'Page',
  [OFFERS]: 'Offers',
  [OFFERS_L]: 'offers',
  [OLDEST_FIRST]: 'Oldest first',
  [NEWEST_FIRST]: 'Newest first',
  [COUNTRY]: 'Country',
  [CZECH_REPUBLIC]: 'Czech Republic',
  [UKRAINE]: 'Ukraine',
  [SPAIN]: 'Spain',
  [POLAND]: 'Poland',
  [USA]: 'USA',
  [KAZAKHSTAN]: 'Kazakhstan',
  [RUSSIA]: 'Russia',
  [ITALY]: 'Italy',
  [GERMANY]: 'Germany',
  [RESET]: 'Reset',
  [DIALOGS_COUNT]: 'Dialogs count',
  [LEADS]: 'Leads',
  [CR_CLICKED_LINKS]: 'CR Clicked Links',
  [EARNINGS_PER_CONVERSION]: 'Earning per conversion',
  [CR_GIVEN_LINKS]: 'CR Given links',
  [STATISTICS]: 'Statistics',
  [AUDIENCE_CHURN]: 'Audience churn',
  [EARNINGS_PER_DIALOG]: 'Earning per dialog',
  [PAYMENTS_AMOUNT]: 'Payments amount',
  [RATING_NOT_YET_AVAILABLE]: 'Rating is not yet available',
  [DIALOG_COST]: 'Dialog cost',
  [CONNECT]: 'Connect',
  [CHANNELS]: 'Channels',
  [NAME]: 'Name',
  [SETTINGS]: 'Settings',
  [DIALOGS]: 'Dialogs',
  [LINKS]: 'Links',
  [ANALYTICS]: 'Analytics',
  [REMOVE]: 'Remove',
  [FINANCES]: 'Finance',
  [INCOME]: 'Income',
  [CLICKS]: 'Clicks',
  [OFFER_STAT_INCOME_DESC]: 'Amount paid to web masters over the last 30 days',
  [TOTAL]: 'Total',
  [TOTAL_L]: 'total',
  [CITIES]: 'Cities',
  [ACTION_TYPE]: 'Action type',
  [NO_DATA]: 'No data',
  [NO_DATA_BY_FILTERS]: 'We could not find data to report based on the selected filters.',
  [NO_CONNECTED_CHANNELS]: 'You have not connected any channels.',
  [NO_CONNECTED_CHANNELS_DESC]: 'To connect channels, go to the "All offers" page, where you can select the offer you are interested in.\nAfter connecting the channel to the selected offer, it will appear on this page, where you can manage it.',
  [ALL_CHANNELS]: 'All channels',
  [ADD_CHANNEL]: 'Add channel',
  [ON_THIS_PAGE_YOU_CAN_SEE_THE_TERMS_]: 'On this page you can see the terms, order, commissions for payments, and choose the best option for yourself.',
  [TRACKING_LINK]: 'Tracking link',
  [SEARCH]: 'Search',
  [TRAFFIC_TYPE]: 'Traffic type',
  [SOURCE]: 'Source',
  [CAMPAIGN_NAME]: 'Campaign name',
  [EXTRA_FIELD]: 'Extra field',
  [MAIN_PARAMETERS]: 'Main parameters',
  [ADDITIONAL_PARAMETERS]: 'Additional parameters',
  [DIRECT_LINK]: 'Direct link',
  [HTML_CODE]: 'HTML code',
  [CLICK_TO_COPY]: 'Click to copy',
  [COPIED]: 'Copied',
  [ORDER_A_PAYMENT]: 'Order a payment',
  [TOTAL_ON_ACCOUNT]: 'Total on account',
  [AVAILABLE_FOR_WITHDRAWAL]: 'Available for withdrawal',
  [PAYOUT_HISTORY]: 'Payout history',
  [DOWNLOAD_REPORT]: 'Download report',
  [AMOUNT]: 'Amount',
  [STATUS]: 'Status',
  [ESTIMATE_DATE]: 'Estimate date',
  [BILL]: 'Bill',
  [BILLING_METHOD]: 'Billing method',
  [COMMENT]: 'Comment',
  [CREATED]: 'Created',
  [IN_PROGRESS]: 'In progress',
  [CLOSED]: 'Closed',
  [NEW_REQUEST]: 'New request',
  [ENTER_THE_AMOUNT_TO_WITHDRAW]: 'Enter amount to withdraw',
  [DOWNLOAD]: 'Download',
  [REQUEST]: 'Request',
  [SWITCH_PROFILE]: 'Switch profile',
  [YOU_NEED_TO_LOG_INTO_YOUR_FACEBOOK_ACCOUNT_TO_PROCEED]: 'You need to log into your Facebook account to proceed',
  [YOU_NEED_TO_LOG_INTO_YOUR_VK_ACCOUNT_TO_PROCEED]: 'You need to log into your VK account to proceed',
  [GROUPS_VK]: 'VK groups',
  [PAGES_FACEBOOK]: 'Facebook Pages',
  [CONNECTING_TELEGRAM_BOT]: 'Connecting Telegram bot',
  [CONNECTING_VIBER_BOT]: 'Connecting Viber bot',
  [TOKEN]: 'Token',
  [AUTHORIZATION]: 'Authorization',
  [SIGN_IN]: 'Sign in',
  [WHICH_CHANNEL_TO_CONNECT]: 'Which channel to connect?',
  [PAUSE]: 'Pause',
  [START]: 'Start',
  [MANAGE]: 'Manage',
  [TOOLS]: 'Tools',
  [OFFER_CONNECT_TOOLTIP]: 'Choose channel to connect bot',
  [CHANNELS_ADD_BUTTON_TOOLTIP]: 'Choose offer to connect platform',
  [CHANNELS_EXPANSION_LIST_TOOLTIP]: 'Filter connected platforms by channel',
  [CHANNELS_DIALOGS_TOOLTIP]: 'Show dialogs history',
  [CHANNELS_LINKS_TOOLTIP]: 'Tracking settings',
  [CHANNELS_ANALYTICS_TOOLTIP]: 'Show detailed report by platform',
  [APPEARANCE]: 'Appearance',
};
