import fetch from '@main/utils/handledFetch';
import { backendURL } from '@main/toRemove';
import { showToast } from '@pages/oldOffers/utils';
import api from 'api';
import * as Sentry from '@sentry/react';

export const tryLoginViaServices = async (userType) => {
  try {
    const query = new URLSearchParams(window.location.search);
    const code = query.get('code');
    let state = null;
    if (query?.get?.('state')) {
      state = JSON.parse?.(atob?.(query?.get?.('state')));
    }

    let from;
    if (state?.from === 'google') {
      from = 'GOOGLE';
    } else {
      from = 'FACEBOOK';
    }
    if (code) {
      const response = await fetch(`${backendURL}/api/login/socials`, {
        method: 'POST',
        body: JSON.stringify({
          user_type: userType,
          code,
          social: from,
          ref: state?.ref,
          redirect_uri: `${window.location.origin}/login`,
        }),
        headers: {
          'Content-type': 'application/json',
        },
      });

      const json = await response.json();

      if (!response.ok || json.error) {
        if (json.error === 'NOT_FOUND') {
          showToast('Пользователь не был найден');
        } else if (json.error === 'INTERNAL') {
          showToast(
              'Не получилось получить данные о google аккаунте',
          );
        } else if (json.error === 'NOT_APPROVED') {
          showToast(
              'Вы прошли опрос, пожалуйста, подождите завершения проверки вашего аккаунта.',
          );
        } else if (json.error === 'REJECTED') {
          showToast(
              'Ваша регистрация отклонена.',
          );
        } else if (json.error === 'DOES_NOT_FORM') {
          showToast(
              'Ваш аккаунт находится на модерации. Для того чтобы пройти модерацию, пожалуйста, пройдите опрос',
          );
        }else {
          showToast('Неизвестная ошибка');
        }
        return;
      }
      return json;
    }
  } catch (e) {
    Sentry.captureException(e);
  }
};
