import React from 'react';
import EntranceWrapper from '@pages/entrance/components/EntranceWrapper';
import { useLocation } from 'react-router';
import SendEmailForm from '@pages/entrance/components/PasswordRestoration/Form';
import NewPasswordForm from '@pages/entrance/components/PasswordRestoration/NewPasswordForm';

const queryString = require('query-string');

function PasswordRestoration() {
  const location = useLocation();
  const { token } = queryString.parse(location.search);
  return (
    <EntranceWrapper removeTabs backButtonEnabled>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {token ? <NewPasswordForm /> : <SendEmailForm />}
      </div>
    </EntranceWrapper>
  );
}
export default PasswordRestoration;
