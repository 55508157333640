import withStyles from "@material-ui/core/styles/withStyles";
import React from 'react';
import classNames from 'classnames';

const Loader = ({classes, size}) => {
    return <svg viewBox="25 25 50 50" className={classNames(classes.root, size)}>
        <circle cx="50" cy="50" r="20" fill="none" className={classes.circle}/>
    </svg>
};

const style = {
    root: {
        height: '42px',
        width: '42px',
        '&.small': {
            height: '32px',
            width: '32px',
        },
        '&.large': {
            height: '52px',
            width: '52px',
        },
        animation: '$loading-rotate 2s linear infinite'
    },
    circle: {
        animation: '$loading-dash 1.5s ease-in-out infinite',
        strokeDasharray: '90,150',
        strokeDashoffset: 0,
        strokeWidth: 2,
        stroke: '#409eff',
        strokeLinecap: 'round'
    },
    "@keyframes loading-rotate": {
        "100%": {
            transform: 'rotate(1turn)'
        }
    },
    "@keyframes loading-dash": {
        "0%": {
            strokeDasharray: '1,200',
            strokeDashoffset: 0
        },
        "50%": {
            strokeDasharray: '90,150',
            strokeDashoffset: '-40px',
        },
        "100%": {
            strokeDasharray: '90,150',
            strokeDashoffset: '-120px',
        }
    }
}

export default withStyles(style)(Loader);