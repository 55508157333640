import React from 'react';
import { Notification } from '@aisales/react-uikit';
import { toast } from 'react-toastify';

export const formatCurrencyWithSymbol = (number) => `${formatCurrency(number)}₽`;

export const formatCurrency = (number) => {
  if (!number) {
    return '0';
  }

  return number.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ');
};

/**
 *
 * @param {any} money Value to be converted to 2 values after comma.
 * @param {int} precision Amount of numbers after comma
 */
export const formatNumber = (money, precision = 2) => {
  if (!money) {
    return '0';
  }
  const moneyInt = Number(money);
  const precisionInt = Number.parseInt(precision);

  if (Number.isNaN(moneyInt) || Number.isNaN(precisionInt)) {
    return '0';
  }

  return (
    Math.round(moneyInt * 10 ** precisionInt)
        / 10 ** precisionInt
  ).toFixed(precisionInt);
};

export const formatPercent = (number) => `${formatCurrency(
  formatNumber(
    number,
    1,
  ),
)}%`;

export const showToast = (str, ok) => {
  // toast(
  //     <Notification
  //         type={ok ? "info" : "report"}
  //         message={str}
  //         variant={"contained"}
  //         color={ok ? "success" : "error"}
  //     />,
  //     {
  //         position: toast.POSITION.TOP_RIGHT,
  //         progressClassName: "progress",
  //         className: "notification",
  //     }
  // );
};

export function phoneRe(value) {
  const length = value && value.match(/\d/g).length;
  return length && (length === 10 || length === 11);
}

export function codeRe(value) {
  const length = value && value.match(/\d/g).length;
  return length && length >= 1 && length < 9;
}
