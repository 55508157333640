import React from 'react';
import { greyColor } from '@main/lib';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core';

const style = () => ({
  root: {
    borderBottom: `solid 0.5px ${greyColor[10]}`,
  },
});
const GreyHr = ({ className, classes }) => <div className={clsx(classes.root, className)} />;
export default withStyles(style)(GreyHr);
