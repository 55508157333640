import { withStyles } from '@material-ui/styles';
import React from 'react';
import { blueColor } from '@main/lib';
import clsx from 'clsx';

const Steps = ({ classes, activeStep, className }) => {
  const Dot = ({ active }) => (
    <div
      className={clsx(classes.steps__dot, { [classes.steps__dot_active]: active })}
    />
  );
  return (
    <div className={clsx(classes.steps, className)}>
      {[...Array(3).keys()].map((index) => <Dot key={index} active={activeStep === index} />)}
    </div>
  );
};

const styles = () => ({
  steps: {
    display: 'flex',
    justifyContent: 'center',

  },
  steps__dot: {
    width: '10px',
    height: '10px',
    margin: '0px 5px',
    backgroundColor: blueColor[20],
    borderRadius: '50%',
  },
  steps__dot_active: {
    backgroundColor: blueColor[100],
  },
});
export default withStyles(styles)(Steps);
