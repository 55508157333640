import React from 'react';
import { Translation, useTranslation, withTranslation } from 'react-i18next';
import { CLOSED, CREATED, IN_PROGRESS } from '@main/i18n/aliases';

export const formatCurrency = (number) => {
  if (number === undefined) {
    return false;
  }

  return number.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ');
};

export const getWithdrawalRequestStatusText = (status) => {
  const Component = (color, status) => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div
        style={{
          width: '16px',
          height: '16px',

          background: color,
          border: '1px solid #FFFFFF',
          boxSizing: 'border-box',
          boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.25)',
          borderRadius: '60px',
          marginRight: '16px',
        }}
      />
      <Translation>{(t) => (status === 1 ? t(CREATED) : status === 2 ? t(IN_PROGRESS) : t(CLOSED))}</Translation>
    </div>
  );

  switch (Number(status)) {
    case 1:
      return Component('#059AC2', Number(status));
    case 2:
      return Component('#20204D', Number(status));
    case 3:
      return Component('#47E599', Number(status));
    default:
      return (
        'Неизвестно'
      );
  }
};

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}
