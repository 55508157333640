import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Loader } from '@aisales/react-uikit';
import { emptyPages } from '@pages/constants/emptyPages';

import { Typography } from '@main/lib';
import { fetchOffers, setFavorite } from '@store/actions';
import EmptyPage from '@components/EmptyPage';
import Offer from './components/Offer';

const OffersList = ({ favoritesOnly }) => {
  const emptyPage = emptyPages().offers.favoriteOffers;
  const classes = useStyles();
  const offers = useSelector((state) => state?.offers?.offers);
  const loading = useSelector((state) => state?.offers?.loading);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchOffers);
  }, []);

  const filterOffers = (offers) => (offers?.filter((offer) => !favoritesOnly || offer?.is_starred));

  const content = (
    <div>
      <div className="offersTour__filtersTour" />
      <>
        <div className={classes.list}>
          <div className={classes.emulatedFlexGap}>
            { filterOffers(offers?.filter((offer) => offer.id !== 25))?.map((offer, index) => (
              <Offer
                isDark={index % 2 !== 0}
                className={classes.offer}
                {...offer}
                key={offer.id + offer.name}
                isFavorite={offer.is_starred}
                onUpdateList={(isFavorite) => {
                  dispatch(setFavorite({ id: offer.id, isFavorite }));
                }}
              />
            ))}
            {/* {offers && filterOffers(offers.filter((offer) => offer.id !== 25)).size === 0 && ( */}
            {/* <Typography className={classes.noResults}> */}
            {/*  Офферов по выбраным фильтрам нет */}
            {/* </Typography> */}
            {/* )} */}

          </div>
          { !loading
          && (filterOffers(offers?.filter((offer) => offer.id !== 25)))?.length === 0
          && favoritesOnly && (
          <div className={classes.emptyPage}>
            <EmptyPage {...emptyPage} />
          </div>
          )}
        </div>

      </>

    </div>
  );
  return content;
};
const gap = '30px';
const useStyles = makeStyles((theme) => ({
  offer: {},

  noResults: {
    textAlign: 'center',
  },
  emptyPage: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  emulatedFlexGap: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    margin: `calc(-1 * ${gap}) 0 0 calc(-1 * ${gap})`,
    // width: `calc(100% + ${gap})`,
    '& > *': {
      margin: `${gap} 0 0 ${gap}`,
    },
  },
  list: {
    padding: '30px 28px 30px 30px',
  },
}));

export default (OffersList);
