import {
  CREATE_TICKET_FAIL,
  CREATE_TICKET_START,
  CREATE_TICKET_SUCCESS,
  TICKETS_FETCH_START,
  TICKETS_FETCH_SUCCESS,
  TICKETS_FRAME_LOADING_END,
  TICKETS_RESET,
  SET_CURRENT_TICKET,
  SET_FORM_OPEN,
  CLEAR_TICKETS,
} from '../actions';

const initialState = {
  tickets: null,
  current: null,
  loading: false,
  frameLoading: false,
  form: {
    open: false,
    loading: false,
    validate: false,
  },
};

const actionsMap = {
  [TICKETS_FETCH_START]: (state) => ({
    ...state,
    loading: true,
  }),
  [TICKETS_FETCH_SUCCESS]: (state, payload) => ({
    ...state,
    tickets: payload?.reverse() || [],
    loading: false,
  }),
  [TICKETS_FRAME_LOADING_END]: (state) => ({
    ...state,
    frameLoading: false,
  }),
  [TICKETS_RESET]: (state) => ({
    ...state,
    frameLoading: false,
  }),
  [CREATE_TICKET_SUCCESS]: (state, payload) => ({
    ...state,
    form: { open: false, loading: false, validate: false },
    tickets: [payload, ...state?.tickets],
  }),
  [CREATE_TICKET_START]: (state) => ({
    ...state,
    form: { ...state?.form, loading: true },
  }),
  [CREATE_TICKET_FAIL]: (state) => ({
    ...state,
    form: { ...state?.form, loading: false },
  }),
  [SET_FORM_OPEN]: (state, payload) => ({
    ...state,
    form: { ...state?.form, open: payload },
  }),
  [SET_CURRENT_TICKET]: (state, payload) => ({
    ...state,
    current: payload,
    frameLoading: true,
  }),
  [CLEAR_TICKETS]: (state) => ({
    ...state,
    tickets: null,
  }),
};

export default function tickets(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action.payload) : state;
}
