'use strict';

/* eslint:disable */
Object.defineProperty(exports, '__esModule', { value: true });
// This file has been generated by https://github.com/reverbnation/protoc-gen-twirp_ts.
// Do not edit.
const twirp_1 = require('./twirp');

const User_RolesEntry = /** @class */ (function () {
  function User_RolesEntry(m) {
    this._json = {};
    if (m) {
      this._json.key = m.key;
      this._json.value = m.value;
    }
  }
  Object.defineProperty(User_RolesEntry.prototype, 'key', {
    // key (key)
    get() {
      return this._json.key;
    },
    set(value) {
      this._json.key = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(User_RolesEntry.prototype, 'value', {
    // value (value)
    get() {
      return this._json.value;
    },
    set(value) {
      this._json.value = value;
    },
    enumerable: true,
    configurable: true,
  });
  User_RolesEntry.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new User_RolesEntry({
      key: m.key,
      value: m.value,
    });
  };
  User_RolesEntry.prototype.toJSON = function () {
    return this._json;
  };
  return User_RolesEntry;
}());
exports.User_RolesEntry = User_RolesEntry;
const User_PermissionsEntry = /** @class */ (function () {
  function User_PermissionsEntry(m) {
    this._json = {};
    if (m) {
      this._json.key = m.key;
      this._json.value = m.value;
    }
  }
  Object.defineProperty(User_PermissionsEntry.prototype, 'key', {
    // key (key)
    get() {
      return this._json.key;
    },
    set(value) {
      this._json.key = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(User_PermissionsEntry.prototype, 'value', {
    // value (value)
    get() {
      return this._json.value;
    },
    set(value) {
      this._json.value = value;
    },
    enumerable: true,
    configurable: true,
  });
  User_PermissionsEntry.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new User_PermissionsEntry({
      key: m.key,
      value: m.value,
    });
  };
  User_PermissionsEntry.prototype.toJSON = function () {
    return this._json;
  };
  return User_PermissionsEntry;
}());
exports.User_PermissionsEntry = User_PermissionsEntry;
const User = /** @class */ (function () {
  function User(m) {
    this._json = {};
    if (m) {
      this._json.id = m.id;
      this._json.mail = m.mail;
      this._json.name = m.name;
      this._json.roles = m.roles;
      this._json.permissions = m.permissions;
    }
  }
  Object.defineProperty(User.prototype, 'id', {
    // id (id)
    get() {
      return this._json.id;
    },
    set(value) {
      this._json.id = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(User.prototype, 'mail', {
    // mail (mail)
    get() {
      return this._json.mail;
    },
    set(value) {
      this._json.mail = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(User.prototype, 'name', {
    // name (name)
    get() {
      return this._json.name;
    },
    set(value) {
      this._json.name = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(User.prototype, 'roles', {
    // roles (roles)
    get() {
      return this._json.roles || [];
    },
    set(value) {
      this._json.roles = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(User.prototype, 'permissions', {
    // permissions (permissions)
    get() {
      return this._json.permissions || [];
    },
    set(value) {
      this._json.permissions = value;
    },
    enumerable: true,
    configurable: true,
  });
  User.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new User({
      id: m.id,
      mail: m.mail,
      name: m.name,
      roles: m.roles,
      permissions: m.permissions,
    });
  };
  User.prototype.toJSON = function () {
    return this._json;
  };
  return User;
}());
exports.User = User;
const GetMeResponse = /** @class */ (function () {
  function GetMeResponse(m) {
    this._json = {};
    if (m) {
      this._json.user_id = m.userId;
      this._json.user_mail = m.userMail;
      this._json.user_name = m.userName;
      this._json.current_project = m.currentProject;
      this._json.current_role = m.currentRole;
      this._json.current_permission = m.currentPermission;
      this._json.available_projects = m.availableProjects;
    }
  }
  Object.defineProperty(GetMeResponse.prototype, 'userId', {
    // userId (user_id)
    get() {
      return this._json.user_id;
    },
    set(value) {
      this._json.user_id = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(GetMeResponse.prototype, 'userMail', {
    // userMail (user_mail)
    get() {
      return this._json.user_mail;
    },
    set(value) {
      this._json.user_mail = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(GetMeResponse.prototype, 'userName', {
    // userName (user_name)
    get() {
      return this._json.user_name;
    },
    set(value) {
      this._json.user_name = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(GetMeResponse.prototype, 'currentProject', {
    // currentProject (current_project)
    get() {
      return this._json.current_project;
    },
    set(value) {
      this._json.current_project = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(GetMeResponse.prototype, 'currentRole', {
    // currentRole (current_role)
    get() {
      return this._json.current_role;
    },
    set(value) {
      this._json.current_role = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(GetMeResponse.prototype, 'currentPermission', {
    // currentPermission (current_permission)
    get() {
      return this._json.current_permission;
    },
    set(value) {
      this._json.current_permission = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(GetMeResponse.prototype, 'availableProjects', {
    // availableProjects (available_projects)
    get() {
      return this._json.available_projects || [];
    },
    set(value) {
      this._json.available_projects = value;
    },
    enumerable: true,
    configurable: true,
  });
  GetMeResponse.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new GetMeResponse({
      userId: m.user_id,
      userMail: m.user_mail,
      userName: m.user_name,
      currentProject: m.current_project,
      currentRole: m.current_role,
      currentPermission: m.current_permission,
      availableProjects: (m.available_projects || []).map((v) => String(v)),
    });
  };
  GetMeResponse.prototype.toJSON = function () {
    return this._json;
  };
  return GetMeResponse;
}());
exports.GetMeResponse = GetMeResponse;
const UserListEntity = /** @class */ (function () {
  function UserListEntity(m) {
    this._json = {};
    if (m) {
      this._json.id = m.id;
      this._json.mail = m.mail;
      this._json.name = m.name;
      this._json.role = m.role;
      this._json.permission = m.permission;
    }
  }
  Object.defineProperty(UserListEntity.prototype, 'id', {
    // id (id)
    get() {
      return this._json.id;
    },
    set(value) {
      this._json.id = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(UserListEntity.prototype, 'mail', {
    // mail (mail)
    get() {
      return this._json.mail;
    },
    set(value) {
      this._json.mail = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(UserListEntity.prototype, 'name', {
    // name (name)
    get() {
      return this._json.name;
    },
    set(value) {
      this._json.name = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(UserListEntity.prototype, 'role', {
    // role (role)
    get() {
      return this._json.role;
    },
    set(value) {
      this._json.role = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(UserListEntity.prototype, 'permission', {
    // permission (permission)
    get() {
      return this._json.permission;
    },
    set(value) {
      this._json.permission = value;
    },
    enumerable: true,
    configurable: true,
  });
  UserListEntity.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new UserListEntity({
      id: m.id,
      mail: m.mail,
      name: m.name,
      role: m.role,
      permission: m.permission,
    });
  };
  UserListEntity.prototype.toJSON = function () {
    return this._json;
  };
  return UserListEntity;
}());
exports.UserListEntity = UserListEntity;
const GetOneResponse = /** @class */ (function () {
  function GetOneResponse(m) {
    this._json = {};
    if (m) {
      this._json.user = m.user;
    }
  }
  Object.defineProperty(GetOneResponse.prototype, 'user', {
    // user (user)
    get() {
      return this._json.user;
    },
    set(value) {
      this._json.user = value;
    },
    enumerable: true,
    configurable: true,
  });
  GetOneResponse.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new GetOneResponse({
      user: User.fromJSON(m.user),
    });
  };
  GetOneResponse.prototype.toJSON = function () {
    return this._json;
  };
  return GetOneResponse;
}());
exports.GetOneResponse = GetOneResponse;
const GetManyResponse = /** @class */ (function () {
  function GetManyResponse(m) {
    this._json = {};
    if (m) {
      this._json.users = m.users;
    }
  }
  Object.defineProperty(GetManyResponse.prototype, 'users', {
    // users (users)
    get() {
      return this._json.users || [];
    },
    set(value) {
      this._json.users = value;
    },
    enumerable: true,
    configurable: true,
  });
  GetManyResponse.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new GetManyResponse({
      users: (m.users || []).map((v) => UserListEntity.fromJSON(v)),
    });
  };
  GetManyResponse.prototype.toJSON = function () {
    return this._json;
  };
  return GetManyResponse;
}());
exports.GetManyResponse = GetManyResponse;
const DeleteResponse = /** @class */ (function () {
  function DeleteResponse(m) {
    this._json = {};
    if (m) {
    }
  }
  DeleteResponse.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new DeleteResponse({});
  };
  DeleteResponse.prototype.toJSON = function () {
    return this._json;
  };
  return DeleteResponse;
}());
exports.DeleteResponse = DeleteResponse;
const CreateResponse = /** @class */ (function () {
  function CreateResponse(m) {
    this._json = {};
    if (m) {
      this._json.user = m.user;
    }
  }
  Object.defineProperty(CreateResponse.prototype, 'user', {
    // user (user)
    get() {
      return this._json.user;
    },
    set(value) {
      this._json.user = value;
    },
    enumerable: true,
    configurable: true,
  });
  CreateResponse.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new CreateResponse({
      user: UserListEntity.fromJSON(m.user),
    });
  };
  CreateResponse.prototype.toJSON = function () {
    return this._json;
  };
  return CreateResponse;
}());
exports.CreateResponse = CreateResponse;
const UpdateResponse = /** @class */ (function () {
  function UpdateResponse(m) {
    this._json = {};
    if (m) {
    }
  }
  UpdateResponse.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new UpdateResponse({});
  };
  UpdateResponse.prototype.toJSON = function () {
    return this._json;
  };
  return UpdateResponse;
}());
exports.UpdateResponse = UpdateResponse;
const GetMeRequest = /** @class */ (function () {
  function GetMeRequest(m) {
    this._json = {};
    if (m) {
    }
  }
  GetMeRequest.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new GetMeRequest({});
  };
  GetMeRequest.prototype.toJSON = function () {
    return this._json;
  };
  return GetMeRequest;
}());
exports.GetMeRequest = GetMeRequest;
const GetOneRequest = /** @class */ (function () {
  function GetOneRequest(m) {
    this._json = {};
    if (m) {
      this._json.id = m.id;
    }
  }
  Object.defineProperty(GetOneRequest.prototype, 'id', {
    // id (id)
    get() {
      return this._json.id;
    },
    set(value) {
      this._json.id = value;
    },
    enumerable: true,
    configurable: true,
  });
  GetOneRequest.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new GetOneRequest({
      id: m.id,
    });
  };
  GetOneRequest.prototype.toJSON = function () {
    return this._json;
  };
  return GetOneRequest;
}());
exports.GetOneRequest = GetOneRequest;
const GetManyRequest = /** @class */ (function () {
  function GetManyRequest(m) {
    this._json = {};
    if (m) {
      this._json.project = m.project;
    }
  }
  Object.defineProperty(GetManyRequest.prototype, 'project', {
    // project (project)
    get() {
      return this._json.project;
    },
    set(value) {
      this._json.project = value;
    },
    enumerable: true,
    configurable: true,
  });
  GetManyRequest.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new GetManyRequest({
      project: m.project,
    });
  };
  GetManyRequest.prototype.toJSON = function () {
    return this._json;
  };
  return GetManyRequest;
}());
exports.GetManyRequest = GetManyRequest;
const GetManyWithoutProjectRequest = /** @class */ (function () {
  function GetManyWithoutProjectRequest(m) {
    this._json = {};
    if (m) {
      this._json.project = m.project;
    }
  }
  Object.defineProperty(GetManyWithoutProjectRequest.prototype, 'project', {
    // project (project)
    get() {
      return this._json.project;
    },
    set(value) {
      this._json.project = value;
    },
    enumerable: true,
    configurable: true,
  });
  GetManyWithoutProjectRequest.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new GetManyWithoutProjectRequest({
      project: m.project,
    });
  };
  GetManyWithoutProjectRequest.prototype.toJSON = function () {
    return this._json;
  };
  return GetManyWithoutProjectRequest;
}());
exports.GetManyWithoutProjectRequest = GetManyWithoutProjectRequest;
const DeleteRequest = /** @class */ (function () {
  function DeleteRequest(m) {
    this._json = {};
    if (m) {
      this._json.id = m.id;
    }
  }
  Object.defineProperty(DeleteRequest.prototype, 'id', {
    // id (id)
    get() {
      return this._json.id;
    },
    set(value) {
      this._json.id = value;
    },
    enumerable: true,
    configurable: true,
  });
  DeleteRequest.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new DeleteRequest({
      id: m.id,
    });
  };
  DeleteRequest.prototype.toJSON = function () {
    return this._json;
  };
  return DeleteRequest;
}());
exports.DeleteRequest = DeleteRequest;
const CreateRequest = /** @class */ (function () {
  function CreateRequest(m) {
    this._json = {};
    if (m) {
      this._json.name = m.name;
      this._json.mail = m.mail;
      this._json.role = m.role;
    }
  }
  Object.defineProperty(CreateRequest.prototype, 'name', {
    // name (name)
    get() {
      return this._json.name;
    },
    set(value) {
      this._json.name = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(CreateRequest.prototype, 'mail', {
    // mail (mail)
    get() {
      return this._json.mail;
    },
    set(value) {
      this._json.mail = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(CreateRequest.prototype, 'role', {
    // role (role)
    get() {
      return this._json.role;
    },
    set(value) {
      this._json.role = value;
    },
    enumerable: true,
    configurable: true,
  });
  CreateRequest.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new CreateRequest({
      name: m.name,
      mail: m.mail,
      role: m.role,
    });
  };
  CreateRequest.prototype.toJSON = function () {
    return this._json;
  };
  return CreateRequest;
}());
exports.CreateRequest = CreateRequest;
const UpdateRequest_RolesEntry = /** @class */ (function () {
  function UpdateRequest_RolesEntry(m) {
    this._json = {};
    if (m) {
      this._json.key = m.key;
      this._json.value = m.value;
    }
  }
  Object.defineProperty(UpdateRequest_RolesEntry.prototype, 'key', {
    // key (key)
    get() {
      return this._json.key;
    },
    set(value) {
      this._json.key = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(UpdateRequest_RolesEntry.prototype, 'value', {
    // value (value)
    get() {
      return this._json.value;
    },
    set(value) {
      this._json.value = value;
    },
    enumerable: true,
    configurable: true,
  });
  UpdateRequest_RolesEntry.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new UpdateRequest_RolesEntry({
      key: m.key,
      value: m.value,
    });
  };
  UpdateRequest_RolesEntry.prototype.toJSON = function () {
    return this._json;
  };
  return UpdateRequest_RolesEntry;
}());
exports.UpdateRequest_RolesEntry = UpdateRequest_RolesEntry;
const UpdateRequest_PermissionsEntry = /** @class */ (function () {
  function UpdateRequest_PermissionsEntry(m) {
    this._json = {};
    if (m) {
      this._json.key = m.key;
      this._json.value = m.value;
    }
  }
  Object.defineProperty(UpdateRequest_PermissionsEntry.prototype, 'key', {
    // key (key)
    get() {
      return this._json.key;
    },
    set(value) {
      this._json.key = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(UpdateRequest_PermissionsEntry.prototype, 'value', {
    // value (value)
    get() {
      return this._json.value;
    },
    set(value) {
      this._json.value = value;
    },
    enumerable: true,
    configurable: true,
  });
  UpdateRequest_PermissionsEntry.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new UpdateRequest_PermissionsEntry({
      key: m.key,
      value: m.value,
    });
  };
  UpdateRequest_PermissionsEntry.prototype.toJSON = function () {
    return this._json;
  };
  return UpdateRequest_PermissionsEntry;
}());
exports.UpdateRequest_PermissionsEntry = UpdateRequest_PermissionsEntry;
const UpdateRequest = /** @class */ (function () {
  function UpdateRequest(m) {
    this._json = {};
    if (m) {
      this._json.id = m.id;
      this._json.name = m.name;
      this._json.mail = m.mail;
      this._json.roles = m.roles;
      this._json.permissions = m.permissions;
    }
  }
  Object.defineProperty(UpdateRequest.prototype, 'id', {
    // id (id)
    get() {
      return this._json.id;
    },
    set(value) {
      this._json.id = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(UpdateRequest.prototype, 'name', {
    // name (name)
    get() {
      return this._json.name;
    },
    set(value) {
      this._json.name = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(UpdateRequest.prototype, 'mail', {
    // mail (mail)
    get() {
      return this._json.mail;
    },
    set(value) {
      this._json.mail = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(UpdateRequest.prototype, 'roles', {
    // roles (roles)
    get() {
      return this._json.roles || [];
    },
    set(value) {
      this._json.roles = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(UpdateRequest.prototype, 'permissions', {
    // permissions (permissions)
    get() {
      return this._json.permissions || [];
    },
    set(value) {
      this._json.permissions = value;
    },
    enumerable: true,
    configurable: true,
  });
  UpdateRequest.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new UpdateRequest({
      id: m.id,
      name: m.name,
      mail: m.mail,
      roles: m.roles,
      permissions: m.permissions,
    });
  };
  UpdateRequest.prototype.toJSON = function () {
    return this._json;
  };
  return UpdateRequest;
}());
exports.UpdateRequest = UpdateRequest;
const UserService = /** @class */ (function () {
  function UserService(hostname, fetch) {
    this.path = '/twirp/admin.backend.UserService/';
    this.hostname = hostname;
    this.fetch = fetch;
  }
  UserService.prototype.url = function (name) {
    return this.hostname + this.path + name;
  };
  UserService.prototype.getOne = function (params, headers) {
    if (headers === void 0) { headers = {}; }
    return this.fetch(this.url('GetOne'), twirp_1.createTwirpRequest(params, headers)).then((res) => {
      if (!res.ok) {
        return twirp_1.throwTwirpError(res);
      }
      return res.json().then((m) => GetOneResponse.fromJSON(m));
    });
  };
  UserService.prototype.getMany = function (params, headers) {
    if (headers === void 0) { headers = {}; }
    return this.fetch(this.url('GetMany'), twirp_1.createTwirpRequest(params, headers)).then((res) => {
      if (!res.ok) {
        return twirp_1.throwTwirpError(res);
      }
      return res.json().then((m) => GetManyResponse.fromJSON(m));
    });
  };
  UserService.prototype.getManyWithoutProject = function (params, headers) {
    if (headers === void 0) { headers = {}; }
    return this.fetch(this.url('GetManyWithoutProject'), twirp_1.createTwirpRequest(params, headers)).then((res) => {
      if (!res.ok) {
        return twirp_1.throwTwirpError(res);
      }
      return res.json().then((m) => GetManyResponse.fromJSON(m));
    });
  };
  UserService.prototype.create = function (params, headers) {
    if (headers === void 0) { headers = {}; }
    return this.fetch(this.url('Create'), twirp_1.createTwirpRequest(params, headers)).then((res) => {
      if (!res.ok) {
        return twirp_1.throwTwirpError(res);
      }
      return res.json().then((m) => CreateResponse.fromJSON(m));
    });
  };
  UserService.prototype.update = function (params, headers) {
    if (headers === void 0) { headers = {}; }
    return this.fetch(this.url('Update'), twirp_1.createTwirpRequest(params, headers)).then((res) => {
      if (!res.ok) {
        return twirp_1.throwTwirpError(res);
      }
      return res.json().then((m) => UpdateResponse.fromJSON(m));
    });
  };
  UserService.prototype.delete = function (params, headers) {
    if (headers === void 0) { headers = {}; }
    return this.fetch(this.url('Delete'), twirp_1.createTwirpRequest(params, headers)).then((res) => {
      if (!res.ok) {
        return twirp_1.throwTwirpError(res);
      }
      return res.json().then((m) => DeleteResponse.fromJSON(m));
    });
  };
  UserService.prototype.getMe = function (params, headers) {
    if (headers === void 0) { headers = {}; }
    return this.fetch(this.url('GetMe'), twirp_1.createTwirpRequest(params, headers)).then((res) => {
      if (!res.ok) {
        return twirp_1.throwTwirpError(res);
      }
      return res.json().then((m) => GetMeResponse.fromJSON(m));
    });
  };
  return UserService;
}());
exports.UserService = UserService;
