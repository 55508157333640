export const validateName = (name) => {
  if (name === '') {
    // return 'Имя не должно быть пустым';
    return 'Name must not be empty';
  }
  return '';
};
export const validateEmail = (email) => {
  // eslint-disable-next-line no-control-regex
  const emailRe = /(?:[a-z0-9!#$%&' * +/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  if (emailRe.test(email)) {
    return '';
  }
  // return 'Невалидный email';
  return 'Invalid email';
};
const passwordRe = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

function validatePassword(pass) {
  if (!passwordRe.test(pass)) {
    // return 'Пароль должен состоять из минимум 8 латинских символов нижнего и верхнего регистров и цифр.';
    return 'The password must consist of at least 8 lowercase and uppercase Latin characters and numbers.';
  }

  return '';
}

function validatePasswordConfirmation(pass, passwordConfirmation) {
  if (pass !== passwordConfirmation) {
    // return 'Пароли не совпадают';
    return 'Passwords do not match';
  }
  return '';
}

export { validatePassword };
export { validatePasswordConfirmation };
