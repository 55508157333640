import { FINISH_GET_COUNTER_DATA, START_GET_COUNTER_DATA } from '@store/actions';

const initialState = {
  counterData: null,
  counterDataLoading: true,
};

export default function StatisticsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case START_GET_COUNTER_DATA:
      return { ...state, counterDataLoading: true };
    case FINISH_GET_COUNTER_DATA:
      return { ...state, counterDataLoading: false, counterData: payload };
    default:
      return state;
  }
}
