import { Button, Input } from '@main/lib';
import HelpText from '@pages/entrance/components/HelpText';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import i18n from "@main/i18n";
import {
  APPROVE,
  RESEND_CODE,
  RESEND_CODE_BUTTON,
  SECONDS_S,
  VERIFICATION_CODE,
  YOU_CAN_RESUBMIT_VIA
} from "@main/i18n/aliases";

const PhoneVerificationV2 = ({
  phoneConfirmationCode,
  setPhoneConfirmationCode,
  classes,
  submitPhone,
  submitCode,
}) => {
  const [timeTillNextCode, setTimeTillNextCode] = useState(30);
  const secondsTillNextCode = 30;
  useEffect(() => {
    startTimer(secondsTillNextCode);
  }, []);
  const startTimer = (numOfSeconds) => {
    let timeleft = numOfSeconds;
    const downloadTimer = setInterval(() => {
      timeleft -= 1;
      setTimeTillNextCode(timeleft);
      if (timeleft <= 0) clearInterval(downloadTimer);
    }, 1000);
  };
  return (
    <div>
      <Input
        label={i18n.t(VERIFICATION_CODE)}
        className={classes.codeInput}
        value={phoneConfirmationCode}
        onChange={(event) => { setPhoneConfirmationCode(event.target.value); }}
      />
      <HelpText className={classes.phoneVerificationV2__helpText_countdown} textBeforeLink={timeTillNextCode ? i18n.t(YOU_CAN_RESUBMIT_VIA)+` ${timeTillNextCode}`+i18n.t(SECONDS_S) : i18n.t(RESEND_CODE)} />
      <div className={clsx(classes.element__horizontallyAlignedButtons, classes.phoneVerificationV2__buttons)}>
        <Button
          className={clsx(classes.phoneVerificationV2__buttons__button, classes.phoneVerificationV2__buttons__buttonOneMoreTime)}
          color="secondary"
          disabled={timeTillNextCode !== 0}
          onClick={() => { submitPhone(); startTimer(secondsTillNextCode); }}
        >
          {i18n.t(RESEND_CODE_BUTTON)}
        </Button>
        <Button
          className={classes.phoneVerificationV2__buttons__button}
          disabled={phoneConfirmationCode.length !== 6}
          onClick={submitCode}
        >
          {i18n.t(APPROVE)}
        </Button>

      </div>
    </div>
  );
};

const styles = () => ({
  phoneVerificationV2__buttons__buttonOneMoreTime: {
    paddingLeft: '36px',
    paddingRight: '36px',
    marginRight: '20px',

  },
  codeInput: {
    marginTop: '20px',
    width: '100%',
    '& div:nth-of-type(2)': {
      width: '100%',
    },
    '& div:nth-of-type(1)': {
      textAlign: 'left',
    },
  },
  phoneVerificationV2__helpText_countdown: {
    marginTop: '10px',
    width: '100%',
    textAlign: 'left',
  },
  element__horizontallyAlignedButtons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  phoneVerificationV2__buttons: {
    marginTop: '30px',
  },
  phoneVerificationV2__buttons__button: {
    width: '190px',
  },
});

export default withStyles(styles)(PhoneVerificationV2);
