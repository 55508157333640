import { formatCurrency, formatCurrencyWithSymbol } from '@components/utils';
import i18n from "@main/i18n";
import {
  ACTIVE,
  BANNED,
  COMPLETED_ALL_ACTIONS,
  CONVERSIONS,
  DATE, EVENTS,
  FIRST_EVENT_CONVERSION_PERCENTAGE,
  HOLD,
  NUMBER_OF_FIRST_EVENT_CONVERSIONS,
  REJECTED,
  REVENUE,
  REVENUE_PER_CONVERSION,
  SUBSCRIBERS,
  SUBSCRIBERS_DESC
} from "@main/i18n/aliases";

export const defaultRenderer = formatCurrency;

export const fieldTitleMapping = {
  date: { title: i18n.t(DATE) },
  users: { title: i18n.t(SUBSCRIBERS), description: i18n.t(SUBSCRIBERS_DESC) },
  active: { title: i18n.t(ACTIVE), description: i18n.t(ACTIVE) },
  ban: {
    title: i18n.t(BANNED),
    ranges: {
      red: [45, 100],
      orange: [30, 44],
      green: [0, 29],
    },
  },
  combo: { title: i18n.t(COMPLETED_ALL_ACTIONS) },
  conversions: { title: i18n.t(CONVERSIONS) },
  income_per_conversation: { title: i18n.t(REVENUE_PER_CONVERSION), renderer: formatCurrencyWithSymbol },
  pending: { title: i18n.t(HOLD) },
  approved: { title: i18n.t(REVENUE), renderer: formatCurrencyWithSymbol },
  rejected: { title: i18n.t(REJECTED) },
  event_1: { title: i18n.t(NUMBER_OF_FIRST_EVENT_CONVERSIONS) },
  event_percent_1: { title: i18n.t(FIRST_EVENT_CONVERSION_PERCENTAGE) },
  conversations: {
    title: i18n.t(EVENTS),
    ranges: {
      red: [-100, 44],
      orange: [45, 59],
      green: [60, 100],
    },
  },
};

const reverseFieldTitleMapping = {};
for (const [key, value] of Object.entries(fieldTitleMapping)) {
  reverseFieldTitleMapping[value.title] = key;
}
export { reverseFieldTitleMapping };
