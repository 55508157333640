import { Menu, withStyles } from '@material-ui/core';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  Select, SelectItem, MailIcon, TextWithIcon, blueColor, EllipsisIcon, ReportIcon, ExclamationMarkIcon, AddIcon, DeleteIcon,
} from '@main/lib';

import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { backendURL, twirpFetchOptions } from '@main/toRemove';
import { showToast } from '@pages/oldOffers/utils';
import * as Sentry from '@sentry/react';

const style = () => ({
  root: {

  },
  dropdownItem: {
  },
  dropdown: {
    width: '180px',
  },
  menu: {
    marginTop: '28px',
  },
  square: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    color: blueColor[100],
    transition: 'background-color 0.2s, color 0.2s',

  },
  selectedSquare: {
    backgroundColor: blueColor[100],
    color: 'white',
    borderRadius: '3px',
  },
  icon: {
    cursor: 'pointer',
  },
  menuIcon: {
    color: 'black',
  },
  removeIcon: {
    '&&&&': {
      color: 'red',
    },
  },
});

const EllipsisDropdown = withStyles(style)(({
  className, classes, index, menuIsOpen, onClick, dropdownItems, onClose,
}) => {
  const ellipsisSquareRef = useCallback((node) => {
    setEllipsisSquare(node);
  }, []);
  const [ellipsisSquare, setEllipsisSquare] = useState();

  return (
    <div className={className}>
      <div ref={ellipsisSquareRef} onClick={() => { onClick(index); }} className={clsx(classes.square, { [classes.selectedSquare]: menuIsOpen })}><EllipsisIcon className={classes.icon} /></div>
      <Menu
        onClick={() => { onClick(-1); }}
        className={classes.menu}
        anchorEl={ellipsisSquare}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        getContentAnchorEl={null}
        open={menuIsOpen}
        onClose={onClose}
      >
        {dropdownItems.map((selectItem, index) => (
          <SelectItem onClick={selectItem.action} className={classes.dropdown}>
            <TextWithIcon className={selectItem.class} iconFirst label={selectItem.name} icon={React.cloneElement(selectItem.icon, { className: clsx(classes.menuIcon) })} />
          </SelectItem>
        ))}
      </Menu>
    </div>
  );
});

export default EllipsisDropdown;
