import {
  Chip, Link, Typography, greyColor, Input,
} from '@main/lib';
import React from 'react';
import { withStyles } from '@material-ui/core';
import i18n from "@main/i18n";
import {RESET} from "@main/i18n/aliases";

const DashboardFilterModalFormRightPartListItem = ({
  elements, onReset, disabled, classes, onDelete, title, inputs, onChangeInput, inputsValues, correspondingInputNames,
}) => (
  <div className={classes.dashboardFilterModalFormRightPartListItem}>
    <div className={classes.dashboardFilterModalFormRightPartListItem__upperPart}>
      <div className={classes.dashboardFilterModalFormRightPartListItem__upperPartLeft}>
        <Typography bold className={classes.dashboardFilterModalFormRightPartListItem__title}>
          { title }
        </Typography>
        <div className={classes.dashboardFilterModalFormRightPartListItem__number}>
          {elements.length}
        </div>
      </div>
      {onReset && <Link onClick={onReset}>{i18n.t(RESET)}</Link>}
    </div>
    <div className={classes.dashboardFilterModalFormRightPartListItem__lowerPart}>
      {elements.map((element, index) => (
        <Chip
          disabled={disabled}
          size="small"
          className={classes.dashboardFilterModalFormRightPartListItem__lowerPartChip}
          label={element}
          onDelete={onDelete ? () => onDelete(correspondingInputNames[index]) : undefined}
        />
      ))}
      {inputs && correspondingInputNames.map((inputName, index) => (
        <Input
          className={classes.dashboardFilterModalFormRightPartListItem__lowerPartInput}
          label={elements[index]}
          value={inputs[inputName]}
          onChange={(event) => { onChangeInput(inputName, event.target.value); console.log('first change'); }}
        />
      ))}
    </div>
  </div>
);

const styles = () => ({
  dashboardFilterModalFormRightPartListItem: {
    marginLeft: '30px',
    borderBottom: `1px solid ${greyColor[10]}`,
  },
  dashboardFilterModalFormRightPartListItem__title: {

  },
  dashboardFilterModalFormRightPartListItem__number: {
    background: greyColor.background,
    borderRadius: '3px',
    width: '25px',
    height: '25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '10px',
  },
  dashboardFilterModalFormRightPartListItem__upperPart: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
    marginTop: '30px',
  },
  dashboardFilterModalFormRightPartListItem__upperPartLeft: {
    display: 'flex',
    alignItems: 'center',

  },
  dashboardFilterModalFormRightPartListItem__lowerPart: {
    marginBottom: '30px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  dashboardFilterModalFormRightPartListItem__lowerPartInput: {
    width: '100%',
    marginTop: '15px',
  },
  dashboardFilterModalFormRightPartListItem__lowerPartChip: {
    marginRight: '10px',
    marginBottom: '10px',
  },

});

export default withStyles(styles)(DashboardFilterModalFormRightPartListItem);
