import React, { useEffect, useState } from 'react';
import { CircularProgress, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import {
  blueColor, Button, FilledOkIcon, greyColor, Input, Tooltip, Typography,
} from '@main/lib';
import * as Sentry from '@sentry/react';

import { backendURL, twirpFetchOptions } from '@main/toRemove';
import { codeRe, phoneRe, showToast } from '@components/utils';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserInfo } from '@store/actions';
import { AsYouType } from 'libphonenumber-js';
import clsx from 'clsx';
import { fetchSecretKey, fetchVerificationId } from '@pages/finance/pages/BalancePage/services/phoneConfirmation';

export default function PhoneVerification({
  className,
  disabled = false, // выключить редактирование всего
  editingDisabled = false,
  initialization = false,
  onChange, // выключить редактирование только поля (кнопка подтвердить остаётся активной)
  onSuccess,
  type,

  ...rest
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const phoneNumberStore = useSelector((state) => state.profile.formValues.phoneNumber);
  const [step, setStep] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [code, setCode] = useState('');

  const [verificationId, setVerificationId] = useState(null);
  const [timeLeft, setTimeLeft] = useState(45);
  const [refreshCode, setRefreshCode] = useState(false);
  const [isCodeError, setIsCodeError] = useState(false);

  useEffect(() => {
    dispatch(fetchUserInfo());
  }, []);
  useEffect(() => {
    setPhoneNumber(phoneNumberStore);
  }, [phoneNumberStore]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (refreshCode) {
        setTimeLeft(45);
        setRefreshCode(false);
      } else if (step === 1 && step > 0) {
        setTimeLeft((prev) => prev - 1);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [step, refreshCode]);

  const isValid = () => {
    switch (step) {
      case 0:
        if (
          !phoneRe(
                (phoneNumber)?.match(/\d/g)?.join(''),
          )
        ) {
          setError(
            'Номер телефона неверный. Пример: +7 (999) 777 66 55',
          );
          return false;
        }

        setError('');
        return true;
      case 1:
        if (!codeRe(code)) {
          setError('Код неверный');
          return false;
        }

        setError('');
        return true;
      default:
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isValid()) {
      return;
    }

    // eslint-disable-next-line default-case
    switch (step) {
      case 0:
        setLoading(true);
        fetchVerificationId(phoneNumber, type).then((json) => {
          setVerificationId(json.token);
          setStep(1);
        }).finally(() => { setLoading(false); });
        return;
      case 1:
        setLoading(true);
        fetchSecretKey(verificationId, code, type).then(() => {
        onSuccess?.(verificationId, (phoneNumber)?.match(/\d/g)?.join(''));
        setStep(2);
        }).catch((reason) => setIsCodeError(true)).finally(() => setLoading(false));
    }
  };

  const tooltipStyle = () => ({
    link: {
      color: blueColor[100],
      marginTop: '10px',
    },
  });
  const TooltipContent = withStyles(tooltipStyle)(({ classes }) => (
    <div>
      {/* <Typography variant="detailed">Для изменения номера телефона перейдите в личный кабинет.</Typography> */}
      <Typography variant="detailed">To change your phone number, go to your personal account.</Typography>
      <div
        className={classes.link}
        onClick={() => {
          history.push('/app/profile');
        }}
      >
        {/* Перейти в личный кабинет */}
        Go to personal account
      </div>
    </div>
  ));

  return (
    <div className={classNames(classes.root, className)} {...rest}>
      {loading && <CircularProgress />}
      {!loading && (
      <>

        <div className={classes.row}>
          {phoneNumber === null && !initialization ? (
            <Typography className={classes.phoneNumber}>
              {/* Добавьте номер в разделе профиль */}
              Add a number in the profile section
            </Typography>
          ) : (
            <Tooltip text={<TooltipContent />} opened={false}>
              <Input
                value={new AsYouType().input?.(`+${phoneNumber}`)}
                className={clsx(classes.input, classes.phoneInput)}
                label="Номер телефона"
                disabled={disabled || editingDisabled}
                onChange={(e) => {
                  onChange && onChange(e.target.value.substring(1));
                  setPhoneNumber(e.target.value.substring(1));
                }}
              />
            </Tooltip>

          )}
          {step === 0
              && (
              <div className={classes.buttonWrapper}>
                {/* <Button onClick={handleSubmit} disabled={disabled}>Получить код</Button> */}
                <Button onClick={handleSubmit} disabled={disabled}>Get the code</Button>
              </div>
              )}
        </div>

        {error && (
        <Typography variant="caption" style={{ color: 'red' }}>
          {error}
        </Typography>
        )}

        {/* {!error && ( */}

        {/*    <Typography variant={"caption"} className={classes.description}> */}

        {/*        На ваш номер будет отправлен код */}

        {/*    </Typography> */}

        {/* )} */}
      </>
      )}
      {!loading && step === 1 && (
      <>
        <div className={classes.row}>
          {!refreshCode ? (
            <Input
              className={classes.input}
              value={code}
              // errorText={isCodeError ? 'Вы ввели неверный код' : undefined}
              errorText={isCodeError ? 'You have entered an invalid code' : undefined}
              // label="Код подтверждения"
              label="Confirmation code"
              onChange={(e) => setCode(e.target.value)}
              // placeholder="Введите код подтверждения"
              placeholder="Enter confirmation code"
            />
          ) : (
            <CircularProgress />
          )}
          <div className={classes.twoButtonsWrapper}>
            <Button
              onClick={() => {
                setRefreshCode(true);
                setCode('');
                setIsCodeError(false);
                fetchVerificationId();
              }}
              color="secondary"
              className={classes.button}
            >
              {/* <Typography variant="caption">Выслать код ещё раз</Typography> */}
              <Typography variant="caption">Send code again</Typography>
            </Button>
            <Button
              variant="outlined"
              className={classes.button}
              onClick={handleSubmit}
            >
              {/* <Typography variant="caption">Подтвердить номер</Typography> */}
              <Typography variant="caption">Confirm number</Typography>
            </Button>
          </div>
        </div>

        {/* {timeLeft > 0 ? ( */}

        {/*    // <Typography variant={"caption"}>{`Получить новый код через ${timeLeft} секунд`}</Typography> */}

        {/* ) : ( */}

        {/*    <Typography */}

        {/*        variant={"caption"} */}

        {/*        onClick={() => { */}

        {/*            setRefreshCode(true); */}

        {/*            fetchVerificationId(); */}

        {/*        }} */}

        {/*        style={{ */}

        {/*            textDecoration: "underline", */}

        {/*            cursor: "pointer", */}

        {/*        }} */}

        {/*    > */}

        {/*        Получить новый код */}

        {/*    </Typography> */}

        {/* )} */}

        {error && (
        <Typography style={{ color: 'red' }}>
          {error}
        </Typography>
        )}

        {/* {!error && ( */}

        {/*    <div> */}

        {/*        <Typography variant={"caption"} className={classes.description}> */}

        {/*            Введите код из СМС */}

        {/*        </Typography> */}

        {/*    </div> */}

        {/* )} */}
      </>
      )}
      {!loading && step === 2 && (
      <div className={classes.iconWrapper}>
        <FilledOkIcon className={classes.icon} />
        {/* Ваш номер телефона */}
        {/* подтверждён */}
        Your phone number
        confirmed
      </div>
      // <div className={classes.row}>
      //     <Input
      //         value={phoneNumber}
      //         className={classes.phoneNumber}
      //         disabled
      //     />
      //     <Typography variant={"caption"} className={classes.success}>
      //         Подтвержден
      //     </Typography>
      // </div>
      )}
    </div>
  );
}

const useStyles = makeStyles(() => ({
  root: { width: '100%' },
  icon: {

    width: '16px',
    height: '16px',
    marginRight: '15px',
  },
  iconWrapper: {
    marginTop: '15px',
    display: 'flex',
    alignItems: 'center',

  },
  row: {
    marginTop: '20px',
  },
  buttonWrapper: {
    marginTop: '30px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& button': {
      width: '290px',
    },
  },
  input: {
    width: '100%',
  },
  phoneInput: {
    '& input': {
      color: greyColor[100],
    },
  },
  twoButtonsWrapper: {
    marginTop: '30px',
    display: 'flex',
    '&>button': {
      '&:first-of-type': {
        marginRight: '20px',
      },
      width: '100%',
    },

  },
}));
