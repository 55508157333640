'use strict';

/* eslint:disable */
const __importStar = (this && this.__importStar) || function (mod) {
  if (mod && mod.__esModule) return mod;
  const result = {};
  if (mod != null) for (const k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  result.default = mod;
  return result;
};
Object.defineProperty(exports, '__esModule', { value: true });
// This file has been generated by https://github.com/reverbnation/protoc-gen-twirp_ts.
// Do not edit.
const admin_clients = __importStar(require('./admin.clients.fields'));
const twirp_1 = require('./twirp');

const Task = /** @class */ (function () {
  function Task(m) {
    this._json = {};
    if (m) {
      this._json.id = m.id;
      this._json.name = m.name;
      this._json.project = m.project;
      this._json.description = m.description;
      this._json.exceptionOperators = m.exceptionOperators;
      this._json.fields = m.fields;
      this._json.timestamp = m.timestamp;
    }
  }
  Object.defineProperty(Task.prototype, 'id', {
    // id (id)
    get() {
      return this._json.id;
    },
    set(value) {
      this._json.id = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(Task.prototype, 'name', {
    // name (name)
    get() {
      return this._json.name;
    },
    set(value) {
      this._json.name = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(Task.prototype, 'project', {
    // project (project)
    get() {
      return this._json.project;
    },
    set(value) {
      this._json.project = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(Task.prototype, 'description', {
    // description (description)
    get() {
      return this._json.description;
    },
    set(value) {
      this._json.description = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(Task.prototype, 'exceptionOperators', {
    // exceptionOperators (exceptionOperators)
    get() {
      return this._json.exceptionOperators || [];
    },
    set(value) {
      this._json.exceptionOperators = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(Task.prototype, 'fields', {
    // fields (fields)
    get() {
      return this._json.fields || [];
    },
    set(value) {
      this._json.fields = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(Task.prototype, 'timestamp', {
    // timestamp (timestamp)
    get() {
      return this._json.timestamp;
    },
    set(value) {
      this._json.timestamp = value;
    },
    enumerable: true,
    configurable: true,
  });
  Task.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new Task({
      id: m.id,
      name: m.name,
      project: m.project,
      description: m.description,
      exceptionOperators: (m.exceptionOperators || []).map((v) => String(v)),
      fields: (m.fields || []).map((v) => admin_clients.Field.fromJSON(v)),
      timestamp: m.timestamp,
    });
  };
  Task.prototype.toJSON = function () {
    return this._json;
  };
  return Task;
}());
exports.Task = Task;
const GetOneResponse = /** @class */ (function () {
  function GetOneResponse(m) {
    this._json = {};
    if (m) {
      this._json.task = m.task;
    }
  }
  Object.defineProperty(GetOneResponse.prototype, 'task', {
    // task (task)
    get() {
      return this._json.task;
    },
    set(value) {
      this._json.task = value;
    },
    enumerable: true,
    configurable: true,
  });
  GetOneResponse.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new GetOneResponse({
      task: Task.fromJSON(m.task),
    });
  };
  GetOneResponse.prototype.toJSON = function () {
    return this._json;
  };
  return GetOneResponse;
}());
exports.GetOneResponse = GetOneResponse;
const GetManyResponse = /** @class */ (function () {
  function GetManyResponse(m) {
    this._json = {};
    if (m) {
      this._json.tasks = m.tasks;
    }
  }
  Object.defineProperty(GetManyResponse.prototype, 'tasks', {
    // tasks (tasks)
    get() {
      return this._json.tasks || [];
    },
    set(value) {
      this._json.tasks = value;
    },
    enumerable: true,
    configurable: true,
  });
  GetManyResponse.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new GetManyResponse({
      tasks: (m.tasks || []).map((v) => Task.fromJSON(v)),
    });
  };
  GetManyResponse.prototype.toJSON = function () {
    return this._json;
  };
  return GetManyResponse;
}());
exports.GetManyResponse = GetManyResponse;
const UpdateOneResponse = /** @class */ (function () {
  function UpdateOneResponse(m) {
    this._json = {};
    if (m) {
    }
  }
  UpdateOneResponse.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new UpdateOneResponse({});
  };
  UpdateOneResponse.prototype.toJSON = function () {
    return this._json;
  };
  return UpdateOneResponse;
}());
exports.UpdateOneResponse = UpdateOneResponse;
const CreateResponse = /** @class */ (function () {
  function CreateResponse(m) {
    this._json = {};
    if (m) {
      this._json.id = m.id;
    }
  }
  Object.defineProperty(CreateResponse.prototype, 'id', {
    // id (id)
    get() {
      return this._json.id;
    },
    set(value) {
      this._json.id = value;
    },
    enumerable: true,
    configurable: true,
  });
  CreateResponse.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new CreateResponse({
      id: m.id,
    });
  };
  CreateResponse.prototype.toJSON = function () {
    return this._json;
  };
  return CreateResponse;
}());
exports.CreateResponse = CreateResponse;
const DeleteResponse = /** @class */ (function () {
  function DeleteResponse(m) {
    this._json = {};
    if (m) {
    }
  }
  DeleteResponse.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new DeleteResponse({});
  };
  DeleteResponse.prototype.toJSON = function () {
    return this._json;
  };
  return DeleteResponse;
}());
exports.DeleteResponse = DeleteResponse;
const GetOneRequest = /** @class */ (function () {
  function GetOneRequest(m) {
    this._json = {};
    if (m) {
      this._json.id = m.id;
    }
  }
  Object.defineProperty(GetOneRequest.prototype, 'id', {
    // id (id)
    get() {
      return this._json.id;
    },
    set(value) {
      this._json.id = value;
    },
    enumerable: true,
    configurable: true,
  });
  GetOneRequest.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new GetOneRequest({
      id: m.id,
    });
  };
  GetOneRequest.prototype.toJSON = function () {
    return this._json;
  };
  return GetOneRequest;
}());
exports.GetOneRequest = GetOneRequest;
const GetManyRequest = /** @class */ (function () {
  function GetManyRequest(m) {
    this._json = {};
    if (m) {
      this._json.project = m.project;
    }
  }
  Object.defineProperty(GetManyRequest.prototype, 'project', {
    // project (project)
    get() {
      return this._json.project;
    },
    set(value) {
      this._json.project = value;
    },
    enumerable: true,
    configurable: true,
  });
  GetManyRequest.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new GetManyRequest({
      project: m.project,
    });
  };
  GetManyRequest.prototype.toJSON = function () {
    return this._json;
  };
  return GetManyRequest;
}());
exports.GetManyRequest = GetManyRequest;
const UpdateOneRequest = /** @class */ (function () {
  function UpdateOneRequest(m) {
    this._json = {};
    if (m) {
      this._json.id = m.id;
      this._json.name = m.name;
      this._json.description = m.description;
      this._json.exceptionOperators = m.exceptionOperators;
      this._json.fields = m.fields;
    }
  }
  Object.defineProperty(UpdateOneRequest.prototype, 'id', {
    // id (id)
    get() {
      return this._json.id;
    },
    set(value) {
      this._json.id = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(UpdateOneRequest.prototype, 'name', {
    // name (name)
    get() {
      return this._json.name;
    },
    set(value) {
      this._json.name = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(UpdateOneRequest.prototype, 'description', {
    // description (description)
    get() {
      return this._json.description;
    },
    set(value) {
      this._json.description = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(UpdateOneRequest.prototype, 'exceptionOperators', {
    // exceptionOperators (exceptionOperators)
    get() {
      return this._json.exceptionOperators || [];
    },
    set(value) {
      this._json.exceptionOperators = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(UpdateOneRequest.prototype, 'fields', {
    // fields (fields)
    get() {
      return this._json.fields || [];
    },
    set(value) {
      this._json.fields = value;
    },
    enumerable: true,
    configurable: true,
  });
  UpdateOneRequest.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new UpdateOneRequest({
      id: m.id,
      name: m.name,
      description: m.description,
      exceptionOperators: (m.exceptionOperators || []).map((v) => String(v)),
      fields: (m.fields || []).map((v) => admin_clients.Field.fromJSON(v)),
    });
  };
  UpdateOneRequest.prototype.toJSON = function () {
    return this._json;
  };
  return UpdateOneRequest;
}());
exports.UpdateOneRequest = UpdateOneRequest;
const CreateRequest = /** @class */ (function () {
  function CreateRequest(m) {
    this._json = {};
    if (m) {
      this._json.name = m.name;
      this._json.description = m.description;
      this._json.exceptionOperators = m.exceptionOperators;
      this._json.fields = m.fields;
    }
  }
  Object.defineProperty(CreateRequest.prototype, 'name', {
    // name (name)
    get() {
      return this._json.name;
    },
    set(value) {
      this._json.name = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(CreateRequest.prototype, 'description', {
    // description (description)
    get() {
      return this._json.description;
    },
    set(value) {
      this._json.description = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(CreateRequest.prototype, 'exceptionOperators', {
    // exceptionOperators (exceptionOperators)
    get() {
      return this._json.exceptionOperators || [];
    },
    set(value) {
      this._json.exceptionOperators = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(CreateRequest.prototype, 'fields', {
    // fields (fields)
    get() {
      return this._json.fields || [];
    },
    set(value) {
      this._json.fields = value;
    },
    enumerable: true,
    configurable: true,
  });
  CreateRequest.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new CreateRequest({
      name: m.name,
      description: m.description,
      exceptionOperators: (m.exceptionOperators || []).map((v) => String(v)),
      fields: (m.fields || []).map((v) => admin_clients.Field.fromJSON(v)),
    });
  };
  CreateRequest.prototype.toJSON = function () {
    return this._json;
  };
  return CreateRequest;
}());
exports.CreateRequest = CreateRequest;
const DeleteRequest = /** @class */ (function () {
  function DeleteRequest(m) {
    this._json = {};
    if (m) {
      this._json.id = m.id;
    }
  }
  Object.defineProperty(DeleteRequest.prototype, 'id', {
    // id (id)
    get() {
      return this._json.id;
    },
    set(value) {
      this._json.id = value;
    },
    enumerable: true,
    configurable: true,
  });
  DeleteRequest.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new DeleteRequest({
      id: m.id,
    });
  };
  DeleteRequest.prototype.toJSON = function () {
    return this._json;
  };
  return DeleteRequest;
}());
exports.DeleteRequest = DeleteRequest;
const TaskService = /** @class */ (function () {
  function TaskService(hostname, fetch) {
    this.path = '/twirp/admin.backend.TaskService/';
    this.hostname = hostname;
    this.fetch = fetch;
  }
  TaskService.prototype.url = function (name) {
    return this.hostname + this.path + name;
  };
  TaskService.prototype.getOne = function (params, headers) {
    if (headers === void 0) { headers = {}; }
    return this.fetch(this.url('GetOne'), twirp_1.createTwirpRequest(params, headers)).then((res) => {
      if (!res.ok) {
        return twirp_1.throwTwirpError(res);
      }
      return res.json().then((m) => GetOneResponse.fromJSON(m));
    });
  };
  TaskService.prototype.getMany = function (params, headers) {
    if (headers === void 0) { headers = {}; }
    return this.fetch(this.url('GetMany'), twirp_1.createTwirpRequest(params, headers)).then((res) => {
      if (!res.ok) {
        return twirp_1.throwTwirpError(res);
      }
      return res.json().then((m) => GetManyResponse.fromJSON(m));
    });
  };
  TaskService.prototype.create = function (params, headers) {
    if (headers === void 0) { headers = {}; }
    return this.fetch(this.url('Create'), twirp_1.createTwirpRequest(params, headers)).then((res) => {
      if (!res.ok) {
        return twirp_1.throwTwirpError(res);
      }
      return res.json().then((m) => CreateResponse.fromJSON(m));
    });
  };
  TaskService.prototype.update = function (params, headers) {
    if (headers === void 0) { headers = {}; }
    return this.fetch(this.url('Update'), twirp_1.createTwirpRequest(params, headers)).then((res) => {
      if (!res.ok) {
        return twirp_1.throwTwirpError(res);
      }
      return res.json().then((m) => UpdateOneResponse.fromJSON(m));
    });
  };
  TaskService.prototype.delete = function (params, headers) {
    if (headers === void 0) { headers = {}; }
    return this.fetch(this.url('Delete'), twirp_1.createTwirpRequest(params, headers)).then((res) => {
      if (!res.ok) {
        return twirp_1.throwTwirpError(res);
      }
      return res.json().then((m) => DeleteResponse.fromJSON(m));
    });
  };
  return TaskService;
}());
exports.TaskService = TaskService;
