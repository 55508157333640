import {
  CREATE_SUCCESS,
  DELETE_SUCCESS,
  FETCH_START,
  FETCH_SUCCESS,
  SET_FAVORITE,
  TOGGLESTAR_FAIL,
  TOGGLESTAR_START,
  TOGGLESTAR_SUCCESS,
} from '../actions';

const initialState = {
  offers: null,
  loading: false,
  idToIndex: {},
};

const actionsMap = {
  [FETCH_START]: (state) => ({
    ...state,
    loading: true,
  }),
  [FETCH_SUCCESS]: (state, payload) => {
    const idToIndex = {};
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < payload.length; i++) {
      idToIndex[payload?.[i]?.id] = i;
    }
    return {
      ...state,
      offers: payload,
      idToIndex,
      loading: false,
    };
  },
  [CREATE_SUCCESS]: (state, payload) => ({
    ...state,
    offers: [payload, ...state?.offers],
  }),
  [DELETE_SUCCESS]: (state, id) => ({
    ...state,
    offers: state?.offers?.filter((value) => value.id !== id) || [],
  }),
  [SET_FAVORITE]: (state, payload) => {
    const newOffers = state?.offers.map((offer) => {
      if (offer.id === payload.id) {
        // eslint-disable-next-line no-param-reassign
        offer.is_starred = payload.isFavorite;
        return offer;
      }
      return offer;
    });
    return {
      ...state,
      offers: newOffers,
    };
  },
  [TOGGLESTAR_START]: (state, id) => {
    const offers = state?.offers || [];
    offers[state?.idToIndex?.[id]].isStarLoading = true;
    return {
      ...state,
      offers,
    };
  },
  [TOGGLESTAR_SUCCESS]: (state, id) => {
    const offers = state?.offers || [];
    offers[state?.idToIndex?.[id]].isStarLoading = false;
    offers[state?.idToIndex?.[id]].is_starred = !offers?.[
            state?.idToIndex?.[id]
            ]?.isStarred;
    return {
      ...state,
      offers,
    };
  },
  [TOGGLESTAR_FAIL]: (state, id) => {
    const offers = state?.offers || [];
    offers[state?.idToIndex?.[id]].isStarLoading = false;
    return {
      ...state,
      offers,
    };
  },
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action.payload) : state;
}
