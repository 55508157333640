import React from 'react';
import Form from '@pages/profile/components/Blocks/Postback/Form';
import { withStyles } from '@material-ui/styles';

function AddPostbackForm({ classes, createPostback, possibleGoals }) {
  return (
    <div>
      <p className={classes.title}>Добавить постбеки</p>
      <Form createPostback={createPostback} possibleGoals={possibleGoals} addPostback />
    </div>
  );
}

const styles = {
  title: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    color: '#767676',
    paddingTop: '15px',
    paddingBottom: '15px',
    paddingLeft: '30px',
  },
};
export default withStyles(styles)(AddPostbackForm);
