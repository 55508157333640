'use strict';

/* eslint:disable */
Object.defineProperty(exports, '__esModule', { value: true });
// This file has been generated by https://github.com/reverbnation/protoc-gen-twirp_ts.
// Do not edit.
const twirp_1 = require('./twirp');

const Permission_ScopesEntry = /** @class */ (function () {
  function Permission_ScopesEntry(m) {
    this._json = {};
    if (m) {
      this._json.key = m.key;
      this._json.value = m.value;
    }
  }
  Object.defineProperty(Permission_ScopesEntry.prototype, 'key', {
    // key (key)
    get() {
      return this._json.key;
    },
    set(value) {
      this._json.key = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(Permission_ScopesEntry.prototype, 'value', {
    // value (value)
    get() {
      return this._json.value;
    },
    set(value) {
      this._json.value = value;
    },
    enumerable: true,
    configurable: true,
  });
  Permission_ScopesEntry.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new Permission_ScopesEntry({
      key: m.key,
      value: NameSpaceScope.fromJSON(m.value),
    });
  };
  Permission_ScopesEntry.prototype.toJSON = function () {
    return this._json;
  };
  return Permission_ScopesEntry;
}());
exports.Permission_ScopesEntry = Permission_ScopesEntry;
const Permission = /** @class */ (function () {
  function Permission(m) {
    this._json = {};
    if (m) {
      this._json.id = m.id;
      this._json.scopes = m.scopes;
    }
  }
  Object.defineProperty(Permission.prototype, 'id', {
    // id (id)
    get() {
      return this._json.id;
    },
    set(value) {
      this._json.id = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(Permission.prototype, 'scopes', {
    // scopes (scopes)
    get() {
      return this._json.scopes || [];
    },
    set(value) {
      this._json.scopes = value;
    },
    enumerable: true,
    configurable: true,
  });
  Permission.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new Permission({
      id: m.id,
      scopes: m.scopes,
    });
  };
  Permission.prototype.toJSON = function () {
    return this._json;
  };
  return Permission;
}());
exports.Permission = Permission;
const NameSpaceScope_ScopeEntry = /** @class */ (function () {
  function NameSpaceScope_ScopeEntry(m) {
    this._json = {};
    if (m) {
      this._json.key = m.key;
      this._json.value = m.value;
    }
  }
  Object.defineProperty(NameSpaceScope_ScopeEntry.prototype, 'key', {
    // key (key)
    get() {
      return this._json.key;
    },
    set(value) {
      this._json.key = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(NameSpaceScope_ScopeEntry.prototype, 'value', {
    // value (value)
    get() {
      return this._json.value;
    },
    set(value) {
      this._json.value = value;
    },
    enumerable: true,
    configurable: true,
  });
  NameSpaceScope_ScopeEntry.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new NameSpaceScope_ScopeEntry({
      key: m.key,
      value: m.value,
    });
  };
  NameSpaceScope_ScopeEntry.prototype.toJSON = function () {
    return this._json;
  };
  return NameSpaceScope_ScopeEntry;
}());
exports.NameSpaceScope_ScopeEntry = NameSpaceScope_ScopeEntry;
var NameSpaceScope = /** @class */ (function () {
  function NameSpaceScope(m) {
    this._json = {};
    if (m) {
      this._json.Scope = m.Scope;
    }
  }
  Object.defineProperty(NameSpaceScope.prototype, 'Scope', {
    // Scope (Scope)
    get() {
      return this._json.Scope || [];
    },
    set(value) {
      this._json.Scope = value;
    },
    enumerable: true,
    configurable: true,
  });
  NameSpaceScope.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new NameSpaceScope({
      Scope: m.Scope,
    });
  };
  NameSpaceScope.prototype.toJSON = function () {
    return this._json;
  };
  return NameSpaceScope;
}());
exports.NameSpaceScope = NameSpaceScope;
const GetOneResponse = /** @class */ (function () {
  function GetOneResponse(m) {
    this._json = {};
    if (m) {
      this._json.permission = m.permission;
    }
  }
  Object.defineProperty(GetOneResponse.prototype, 'permission', {
    // permission (permission)
    get() {
      return this._json.permission;
    },
    set(value) {
      this._json.permission = value;
    },
    enumerable: true,
    configurable: true,
  });
  GetOneResponse.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new GetOneResponse({
      permission: Permission.fromJSON(m.permission),
    });
  };
  GetOneResponse.prototype.toJSON = function () {
    return this._json;
  };
  return GetOneResponse;
}());
exports.GetOneResponse = GetOneResponse;
const UpdateResponse = /** @class */ (function () {
  function UpdateResponse(m) {
    this._json = {};
    if (m) {
    }
  }
  UpdateResponse.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new UpdateResponse({});
  };
  UpdateResponse.prototype.toJSON = function () {
    return this._json;
  };
  return UpdateResponse;
}());
exports.UpdateResponse = UpdateResponse;
const GetOneRequest = /** @class */ (function () {
  function GetOneRequest(m) {
    this._json = {};
    if (m) {
      this._json.id = m.id;
    }
  }
  Object.defineProperty(GetOneRequest.prototype, 'id', {
    // id (id)
    get() {
      return this._json.id;
    },
    set(value) {
      this._json.id = value;
    },
    enumerable: true,
    configurable: true,
  });
  GetOneRequest.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new GetOneRequest({
      id: m.id,
    });
  };
  GetOneRequest.prototype.toJSON = function () {
    return this._json;
  };
  return GetOneRequest;
}());
exports.GetOneRequest = GetOneRequest;
const UpdateRequest_ScopesEntry = /** @class */ (function () {
  function UpdateRequest_ScopesEntry(m) {
    this._json = {};
    if (m) {
      this._json.key = m.key;
      this._json.value = m.value;
    }
  }
  Object.defineProperty(UpdateRequest_ScopesEntry.prototype, 'key', {
    // key (key)
    get() {
      return this._json.key;
    },
    set(value) {
      this._json.key = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(UpdateRequest_ScopesEntry.prototype, 'value', {
    // value (value)
    get() {
      return this._json.value;
    },
    set(value) {
      this._json.value = value;
    },
    enumerable: true,
    configurable: true,
  });
  UpdateRequest_ScopesEntry.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new UpdateRequest_ScopesEntry({
      key: m.key,
      value: NameSpaceScope.fromJSON(m.value),
    });
  };
  UpdateRequest_ScopesEntry.prototype.toJSON = function () {
    return this._json;
  };
  return UpdateRequest_ScopesEntry;
}());
exports.UpdateRequest_ScopesEntry = UpdateRequest_ScopesEntry;
const UpdateRequest = /** @class */ (function () {
  function UpdateRequest(m) {
    this._json = {};
    if (m) {
      this._json.id = m.id;
      this._json.scopes = m.scopes;
    }
  }
  Object.defineProperty(UpdateRequest.prototype, 'id', {
    // id (id)
    get() {
      return this._json.id;
    },
    set(value) {
      this._json.id = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(UpdateRequest.prototype, 'scopes', {
    // scopes (scopes)
    get() {
      return this._json.scopes || [];
    },
    set(value) {
      this._json.scopes = value;
    },
    enumerable: true,
    configurable: true,
  });
  UpdateRequest.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new UpdateRequest({
      id: m.id,
      scopes: m.scopes,
    });
  };
  UpdateRequest.prototype.toJSON = function () {
    return this._json;
  };
  return UpdateRequest;
}());
exports.UpdateRequest = UpdateRequest;
const PermissionsService = /** @class */ (function () {
  function PermissionsService(hostname, fetch) {
    this.path = '/twirp/admin.backend.PermissionsService/';
    this.hostname = hostname;
    this.fetch = fetch;
  }
  PermissionsService.prototype.url = function (name) {
    return this.hostname + this.path + name;
  };
  PermissionsService.prototype.getOne = function (params, headers) {
    if (headers === void 0) { headers = {}; }
    return this.fetch(this.url('GetOne'), twirp_1.createTwirpRequest(params, headers)).then((res) => {
      if (!res.ok) {
        return twirp_1.throwTwirpError(res);
      }
      return res.json().then((m) => GetOneResponse.fromJSON(m));
    });
  };
  PermissionsService.prototype.update = function (params, headers) {
    if (headers === void 0) { headers = {}; }
    return this.fetch(this.url('Update'), twirp_1.createTwirpRequest(params, headers)).then((res) => {
      if (!res.ok) {
        return twirp_1.throwTwirpError(res);
      }
      return res.json().then((m) => UpdateResponse.fromJSON(m));
    });
  };
  return PermissionsService;
}());
exports.PermissionsService = PermissionsService;
