import { fieldTitleMapping } from '@pages/dashboard/constants/fieldTitleMapping';
import i18n from "@main/i18n";
import {
  ACTIVE, APPROVED, BANNED,
  BY_CHANNELS,
  BY_DAY,
  BY_HOURS,
  BY_MONTH,
  BY_OFFERS,
  BY_YEAR, CHANNEL,
  CLICKS, DATE,
  INCOME, OFFER, PENDING, REJECTED,
  SUBSCRIBERS
} from "@main/i18n/aliases";

export const dateGropingOptions = [
  { label: i18n.t(BY_HOURS), value: ['year', 'month', 'day', 'hour'] },
  { label: i18n.t(BY_DAY), value: ['year', 'month', 'day'] },
  { label: i18n.t(BY_MONTH), value: ['year', 'month'] },
  { label: i18n.t(BY_YEAR), value: ['year'] },
];

export const offerGroupingOptions = [
  { label: i18n.t(BY_OFFERS), value: ['offer'] },
];

export const channelGroupingOptions = [
  { label: i18n.t(BY_CHANNELS), value: ['channel'] },
];

export const cellsWithDataOptions = [
  i18n.t(INCOME),
  i18n.t(CLICKS),
  i18n.t(SUBSCRIBERS),
  i18n.t(ACTIVE),
  i18n.t(BANNED),
  i18n.t(APPROVED),
  i18n.t(PENDING),
  i18n.t(REJECTED),
];

export const groupingSelectOptions = [
  {
    title: i18n.t(DATE),
    options: dateGropingOptions,
  },
  {
    title: i18n.t(OFFER),
    options: offerGroupingOptions,
  },
  {
    title: i18n.t(CHANNEL),
    options: channelGroupingOptions,
  },
];


export const counterOptions = [
  { label: fieldTitleMapping.conversations.title, value: 'conversations' },
  { label: fieldTitleMapping.ban.title, value: 'ban' },
];
