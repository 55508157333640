import React, { useEffect, useState } from 'react';
import DashboardReportCreationForm from '@pages/dashboard/components/ReportCreationForm';
import PageTemplate from '@components/PageTemplate';
import DashboardTable from '@pages/dashboard/components/Table';
import { withStyles } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router';
import { bodyRequestDefault } from '@pages/dashboard/constants/defaultRequestParams';
import { fieldTitleMapping } from '@pages/dashboard/constants/fieldTitleMapping';
import {
  eliminatePercentFields, emptyPage, getPercentFieldName, precise,
} from '@pages/dashboard/utils';
import { getStatistics } from '@pages/dashboard/services';
import { getPercentColorMaker } from '@pages/dashboard/utils/getPercentColorMaker';
import { getTooltipTextMaker } from '@pages/dashboard/utils/getTooltipTextMaker';
import CounterBlock from '@pages/dashboard/components/CounterBlock';
import { useDispatch, useSelector } from 'react-redux';
import EmptyPage from '@components/EmptyPage';
import FilterModal from '@pages/dashboard/components/FilterModal';
import RightUpperCorner from '@pages/dashboard/components/RightUpperCorner';
import { fetchOffers, getCounterData, getStatisticsReports } from '@store/actions';
import { fetchGroupedOffers } from '@pages/services/fetchGroupedOffers';
import { makeFilterValues } from '@pages/dashboard/components/FilterModal/utils/makeOptions';
import defaultDropdownMapping from '@pages/dashboard/components/FilterModal/constants/defaultDropdownMapping';
import defaultInputMapping from '@pages/dashboard/components/FilterModal/constants/defaultInputMapping';
import { dashboardPaths } from '@pages/dashboard/constants/dashboardPaths';
import { getQueryStringFromPeriod, getUnixDate, getUnixPeriodWithinNLastDays } from '@pages/dashboard/utils/dateGetters';
import DashboardReportUpdateForm from '@pages/dashboard/components/ReportUpdateForm';
import { getDashboardTabs } from '@pages/dashboard/utils/tabsGetters';
import ConfirmationModal from '@pages/dashboard/components/ConfirmationModal/ConfirmationModal';
import downloadTable from '@pages/dashboard/services/downloadTable';
import getAllPlatforms from '@pages/dashboard/utils/getAllPlatforms';
import getRequestFiltersPayload from '@pages/dashboard/utils/getRequestFiltersPayload';
import Loader from '@components/Loader';
import {useTranslation} from "react-i18next";
import i18n from "@main/i18n";
import {REPORTS} from "@main/i18n/aliases";

export const mergeSearch = (location, newSearchValues) => {
  const search = location.split('?')[1];
  const currentSearchObject = Object.fromEntries([...new URLSearchParams(search).entries()].map(([key, value]) => [key, JSON.parse(value)]));
  const temp = new URLSearchParams(search).entries();
  const mergedSearchObject = { ...currentSearchObject, ...newSearchValues };
  let newSearch = '?';
  const mergedSearchObjectEntries = Object.entries(mergedSearchObject);
  for (const [index, entry] of mergedSearchObjectEntries.entries()) {
    newSearch = `${newSearch + entry[0]}=${JSON.stringify(entry[1])}${index !== mergedSearchObjectEntries.length - 1 ? '&' : ''}`;
  }
  return newSearch;
};

const Dashboard = ({ classes }) => {
  const [reportCreationModalOpen, setReportCreationModalOpen] = useState(false);
  const [reportUpdateModalOpen, setReportUpdateModalOpen] = useState(false);
  const [tableData, setTableData] = useState(null);
  const history = useHistory();
  const [filterSelectValues, setSelectFilterValues] = useState(makeFilterValues(Object.keys(defaultDropdownMapping)));
  const [filterInputsValues, setFilterInputsValues] = useState(defaultInputMapping);
  const [reportIdClickedWhileNewReportMode, setReportIdClickedWhileNewReportMode] = useState(null);
  const counterData = useSelector((state) => state?.statistics.counterData);
  const [footerData, setTotalRow] = useState(null);
  const [count, setCount] = useState(null);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [requestBody, setRequestBody] = useState(null);
  const [currentReport, setCurrentReport] = useState('');
  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [formattedOffers, setFormattedOffers] = useState(null);
  const [filterModalFormOpen, setFilterModalFormOpen] = useState(false);
  const [newReportMode, setNewReportMode] = useState(false);
  const [calendarIsOpened, setCalendarIsOpened] = useState(false);
  const [newReportCounters, setNewReportCounters] = useState([]);
  const [customDate, setCustomDate] = useState(null);
  const [redirectIdWhenFormIsClosed, setRedirectIdWhenFormIsClosed] = useState(null);
  const nextDefaultName = useSelector((state) => state?.reports.nextDefaultName);
  const location = useLocation();
  const [headers, setHeaders] = useState([fieldTitleMapping.date,
    ...eliminatePercentFields(bodyRequestDefault.fields).map((field) => fieldTitleMapping[field])]);
  const offers = useSelector((state) => state?.offers?.offers);
  const statisticsReports = useSelector((state) => state?.reports.reports);
  const statisticsReportsLoading = useSelector((state) => state?.reports.isLoading);
  const [userOffers, setUserOffers] = useState(null);
  const dispatch = useDispatch();
  const [platforms, setPlatforms] = useState(null);
  const {t} = useTranslation()

  useEffect(() => {
    const newSearch = mergeSearch(window.location.href,{ filter: filterSelectValues });
    history.push({
      pathname: window.location.pathname,
      search: newSearch,
    });
  }, [filterSelectValues]);

  useEffect(() => {
    // if something is changed in request body, make new requests, which depend on it
    if (requestBody) {
      setDataIsLoading(true);
      dispatch(fetchOffers);
      getStatistics(requestBody).then((res) => {
        setTableData(res?.rows);
        setCount(res?.count);
        setTotalRow(res?.total.data);
        setDataIsLoading(false);
      });
      if (currentReport.counters) {
        dispatch(getCounterData(requestBody));
      }
      setHeaders([fieldTitleMapping.date,
        ...eliminatePercentFields(requestBody.fields).map((field) => fieldTitleMapping[field])]);
    }
  }, [requestBody]);

  useEffect(() => {
    // when new report mode is enabled, save the counters ( we can not look them up anywhere)
    // if there is no date filter, set default (7 days)
    if (newReportMode) {
      setNewReportCounters(currentReport?.counters || []);
      let date = getCurrentDate();
      if (!date) {
        date = getUnixPeriodWithinNLastDays(7);
      }
      history.push({
        pathname: dashboardPaths.customReportPath,
        search: getQueryStringFromPeriod(date),
      });
    }
  }, [newReportMode]);
  //
  useEffect(() => {
    // requests which are not dependent on eny other variables are called here
    if (!statisticsReports && !statisticsReportsLoading) {
      dispatch(getStatisticsReports);
    }
    fetchGroupedOffers().then((res) => setUserOffers(res));
    // const platform = getCurrentPlatform();
    const filter = getCurrentFilter();
    if (filter) {
      setSelectFilterValues({ ...filterSelectValues, ...filter });
    }
  }, []);
  useEffect(() => {
    // when we got offers and user offers, we can fetch platforms for the filter options
    if (offers && userOffers) {
      const userOffersIds = userOffers.map((userOffer) => userOffer.offer_id);
      const temp = offers.filter((offer) => userOffersIds.includes(offer.id));
      getAllPlatforms(userOffersIds).then((res) => { setPlatforms(res); });
      setFormattedOffers(temp);
    }
  }, [offers, userOffers]);

  useEffect(() => {
    // not allow user to visit page with custom report (we can not know which parameters of creation were there)
    if (window.location.pathname.includes('custom')) {
      history.push(dashboardPaths.indexPath);
    }
  }, []);

  useEffect(() => {
    // do not allow user to visit page without reportId or date in location
    // if reportId and date are ok, change requestBody according to them
    // if there is no date or report id in location,
    // set the defaults and change request body according to them

    if (statisticsReports !== null) {
      const date = getCurrentDate();
      const currentReportSync = getCurrentReport();
      setCurrentReport(currentReportSync);
      if (!newReportMode) {
        if (date && currentReportSync) {
          if (requestBody) {
            setRequestBody({
              ...requestBody,
              date,
              groups: currentReportSync.groups,
              fields: currentReportSync.columns,
              page: currentPage - 1,
              offset: rowPerPage,
            });
          } else {
            setRequestBody({
              ...bodyRequestDefault,
              date,
              groups: currentReportSync.groups,
              fields: currentReportSync.columns,
              page: currentPage - 1,
              offset: rowPerPage,
            });
          }
          setCurrentPage(1);
        } else {
          let date = getCurrentDate();
          if (!date) {
            date = getUnixPeriodWithinNLastDays(7);
          }
          let currentReportSync = getCurrentReport();
          if (!currentReportSync) {
            currentReportSync = statisticsReports[0];
          }
          setCurrentReport(currentReportSync);
          const newSearch = mergeSearch(window.location.href,
            { date });
          history.push({
            pathname: `${dashboardPaths.indexPath}/${currentReportSync.id}`,
            search: newSearch,
          });
        }
      }
    }
  }, [location, statisticsReports]);

  useEffect(() => {
    // if there is another tab clicked while newReportMode show the confirmation modal
    if (reportIdClickedWhileNewReportMode && newReportMode) {
      setConfirmationModalOpen(true);
    }
  }, [reportIdClickedWhileNewReportMode]);

  useEffect(() => {
    // change request body when page is changes
    if (requestBody && requestBody.page !== currentPage - 1) {
      setRequestBody({ ...requestBody, page: currentPage - 1 });
    }
  }, [currentPage]);

  useEffect(() => {
    // change request body when row per page is changed
    if (requestBody && requestBody.offset !== rowPerPage) {
      setRequestBody({ ...requestBody, offset: rowPerPage });
      setCurrentPage(1);
    }
  }, [rowPerPage]);

  useEffect(() => {
  }, [reportUpdateModalOpen]);

  const onClickDownload = () => {
    downloadTable(requestBody);
  };

  const onClickRefresh = () => {};

  const onChangeCustomDate = (event) => {
    if (event.target.value.start && event.target.value.end) {
      setCustomDate(event.target.value);
      const date = { from: getUnixDate(event.target.value.start), to: getUnixDate(event.target.value.end) };
      const newSearch = mergeSearch(window.location.href, { date, customDate: true });
      history.push({
        pathname: `${dashboardPaths.indexPath}/${currentReport.id}`,
        search: newSearch,
      });
    }
  };

  const onCloseCalendarMenu = () => {
    setCalendarIsOpened(false);
  };

  const onClickCustomDateTab = () => {
    setCalendarIsOpened(true);
  };

  const onClickCorrectIcon = (event) => {
    event.stopPropagation();
    setReportUpdateModalOpen(true);
  };

  const getCurrentDate = () => JSON.parse(new URLSearchParams(location.search).get('date'));
  const getCurrentPlatform = () => JSON.parse(new URLSearchParams(location.search).get('filter'))?.platform || [];
  const getCurrentFilter = () => JSON.parse(new URLSearchParams(location.search).get('filter'));

  const getCurrentReport = () => {
    const temp = location.pathname.split('/');
    const id = temp[temp.length - 1];
    if (id === 'dashboard') {
      return null;
    } if (id === 'custom') {
      return 'custom';
    }
    return statisticsReports.find((report) => report.id === id);
  };

  const getNumOfSelectedFilters = () => {
    let number = 0;
    for (const selectValues of Object.values(filterSelectValues)) {
      number += selectValues.length;
    }
    return number;
  };

  const onApplyFilters = (newFilterSelectValues, newFilterInputValues) => {
    setSelectFilterValues(newFilterSelectValues);
    setFilterInputsValues(newFilterInputValues);
    const filterPayload = getRequestFiltersPayload(newFilterSelectValues, newFilterInputValues);
    setRequestBody({ ...requestBody, filters: filterPayload });
    setFilterModalFormOpen(false);
  };

  const onClickFilters = () => {
    setFilterModalFormOpen(true);
  };

  const getTabsWithClickablePlus = () => {
    // const dashboardTabs = statisticsReports
    const currentDashboardTabs = getDashboardTabs(
        t,
        newReportMode,
        (reportId) => { setConfirmationModalOpen(true); setReportIdClickedWhileNewReportMode(reportId); },
        statisticsReports,
        currentReport.id,
        onClickCorrectIcon,
        onChangeCustomDate,
        onCloseCalendarMenu,
        onClickCustomDateTab,
        calendarIsOpened,
        nextDefaultName,
        history);
    const lastIndex = currentDashboardTabs.length - 1;
    return [...currentDashboardTabs.slice(0, lastIndex),
      {
        ...currentDashboardTabs[lastIndex],
        onClick: () => { setReportCreationModalOpen(true); },
      }];
  };

  const getInfoBlock = (field) => {

  const info = fieldTitleMapping[field];
  const { title } = info;
  const data = counterData.total.data[field];
  const percent = precise(counterData.total.data[getPercentFieldName(field)]
      - counterData.previous.data[getPercentFieldName(field)]);
  const percentStr = (counterData.total.data[getPercentFieldName(field)]
      - counterData.previous.data[getPercentFieldName(field)]).toFixed(2);
  const getPercentColor = getPercentColorMaker(info.ranges);
  const getTooltipText = getTooltipTextMaker(info.ranges);
  return (
    <CounterBlock
        tooltipText={getTooltipText(percent)}
        upperTitle={title}
        data={data}
        percent={counterData.total.data[getPercentFieldName(field)]
            && (
            <div style={{ color: getPercentColor(percent) }}>
              {`${percentStr}%`}
            </div>
            )}
      />
    );
  };

  return (
    (statisticsReports !== null && currentReport) ? (
      <PageTemplate
        outerScroll
        title={i18n.t(REPORTS)}
        tabs={getTabsWithClickablePlus()}
        rightTopCorner={((
          <RightUpperCorner
            number={getNumOfSelectedFilters()}
            onClickDownload={onClickDownload}
            onClickRefresh={onClickRefresh}
            onClickFilters={onClickFilters}
          />
              )
  )}
      >
        {!dataIsLoading ? (
          <div className={classes.pageContents}>
            <DashboardReportCreationForm
              open={reportCreationModalOpen}
              onFinish={async (id) => {
                dispatch(getCounterData(requestBody));
                dispatch(getStatisticsReports).then(() => {
                  history.push(`${dashboardPaths.indexPath}/${id}`);
                  window.location.reload();
                });
                setReportCreationModalOpen(false);
              }}
              onClose={() => { setReportCreationModalOpen(false); }}
            />
            <DashboardReportUpdateForm
              open={reportUpdateModalOpen}
              onFinish={(id) => {
                setReportUpdateModalOpen(false);
                dispatch(getCounterData(requestBody));
                if (reportIdClickedWhileNewReportMode) {
                  history.push(`${dashboardPaths.indexPath}/${reportIdClickedWhileNewReportMode}`); setReportIdClickedWhileNewReportMode(null);
                  setReportIdClickedWhileNewReportMode(null);
                  setNewReportMode(false);
                } else {
                  setRedirectIdWhenFormIsClosed(id);
                }
              }}
              onClose={() => {
                if (redirectIdWhenFormIsClosed) {
                  setNewReportMode(false);
                  history.push(`${dashboardPaths.indexPath}/${redirectIdWhenFormIsClosed}`);
                }
                setReportUpdateModalOpen(false);
              }}
              newReportMode={newReportMode}
              currentReport={newReportMode ? {
                name: nextDefaultName,
                columns: eliminatePercentFields(requestBody.fields),
                groups: requestBody.groups,
                need_counters: Boolean(newReportCounters.length),
                counters: newReportCounters,
              } : currentReport}
            />
            <ConfirmationModal
              open={confirmationModalOpen}
              onClickYes={() => {
                setReportUpdateModalOpen(true);
                setConfirmationModalOpen(false);
              }}
              onClickNo={() => {
                setNewReportMode(false);
                setConfirmationModalOpen(false);
                history.push(`${dashboardPaths.indexPath}/${reportIdClickedWhileNewReportMode}`);
                setReportIdClickedWhileNewReportMode(null);
              }}
              onClose={() => setConfirmationModalOpen(false)}
            />
            {(!tableData && !dataIsLoading)
              ? (
                <div>
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <EmptyPage {...emptyPage} />
                </div>
              )
              : (
                <div>

                  { ((counterData && counterData?.total && currentReport.counters)
                        || (counterData && newReportMode && newReportCounters)) && (
                        <div className={classes.dashboard__infoBlocks}>
                          {eliminatePercentFields(
                            newReportMode
                              ? newReportCounters
                              : currentReport.counters,
                          )
                            .map((field) => (
                              getInfoBlock(field)
                            ))}
                        </div>
                  )}

                  <DashboardTable
                    setNonExistingReportMode={setNewReportMode}
                    tableData={tableData}
                    requestBody={requestBody}
                    counterData={counterData}
                    dataIsLoading={dataIsLoading}
                    headers={headers}
                    footerData={footerData}
                    rowPerPage={rowPerPage}
                    currentPage={currentPage}
                    count={count}
                    setCurrentPage={setCurrentPage}
                    setRowPerPage={setRowPerPage}
                    setRequestBody={setRequestBody}
                  />
                </div>
              )}
            <FilterModal
              filterSelectValues={filterSelectValues}
              filterInputValues={filterInputsValues}
              offers={formattedOffers}
              open={filterModalFormOpen}
              platforms={platforms}
              onApplyFilters={onApplyFilters}
              onClose={() => { setFilterModalFormOpen(false); }}
            />
          </div>
        ) : (
          <div className={classes.loaderWrapper}>
            <Loader size="large" />
          </div>
        ) }

      </PageTemplate>
    ) : <div />
  );
};

const styles = () => ({
  pageContents: {
    padding: '30px',
  },
  loaderWrapper: {
    display: 'flex',
    paddingTop: '100px',
    paddingBottom: '100px',
    justifyContent: 'center',
    justifyItems: 'center',
  },

  dashboard__infoBlocks: {
    marginBottom: '30px',
    display: 'flex',
    '&>div': {
      marginRight: '20px',
    },
  },

});

export default withStyles(styles)(Dashboard);
