import { makeChannelsOptions } from '@pages/dashboard/components/FilterModal/utils/makeOptions';
import filterFields from '@pages/dashboard/components/FilterModal/constants/filterFields';
import {
  BY_CHANNEL,
  BY_OFFER, BY_PLATFORM, BY_UTM_MARKS, CHANNEL_L, CHANNEL_L_1, CHANNEL_L_2, MARK_L, MARK_L_1, MARKS_L,
  OFFER_L,
  OFFERS_1, OFFERS_L, PLATFORM_L, PLATFORM_L_1, PLATFORMS_L,
  SELECTED,
  SELECTED_1,
  SELECTED_2,
  SELECTED_3
} from "@main/i18n/aliases";
import i18n from "@main/i18n";

const maleVersion = [i18n.t(SELECTED), i18n.t(SELECTED_1), i18n.t(SELECTED_2)];
const femaleVersion = [i18n.t(SELECTED_3), i18n.t(SELECTED_1), i18n.t(SELECTED_2)];

const channelsOptions = makeChannelsOptions();
export default {
  [filterFields.offer]: {
    label: i18n.t(BY_OFFER),
    options: null,
    wordForms: [i18n.t(OFFER_L), i18n.t(OFFERS_L), i18n.t(OFFERS_1)],
    participleForms: maleVersion,
  },
  [filterFields.channel]: {
    label: i18n.t(BY_CHANNEL),
    options: channelsOptions,
    wordForms: [i18n.t(CHANNEL_L), i18n.t(CHANNEL_L_1), i18n.t(CHANNEL_L_2)],
    participleForms: maleVersion,
  },
  [filterFields.platform]: {
    label: i18n.t(BY_PLATFORM),
    options: null,
    wordForms: [i18n.t(PLATFORM_L), i18n.t(PLATFORMS_L), i18n.t(PLATFORM_L_1)],
    participleForms: femaleVersion,
  },
  [filterFields.utm]: {
    label: i18n.t(BY_UTM_MARKS),
    options: {
      utm1: 'utm1', utm2: 'utm2', utm3: 'utm3', utm4: 'utm4', utm5: 'utm5',
    },
    wordForms: [i18n.t(MARK_L), i18n.t(MARKS_L), i18n.t(MARK_L_1)],
    participleForms: femaleVersion,
    inputs: true,
  },
};
