import React from 'react';
import { ReactComponent as MyOffers } from '../assets/emptyPagesImages/myOffers.svg';
import { ReactComponent as HoldWithdrawal } from '../assets/emptyPagesImages/holdWithdrawal.svg';
import { ReactComponent as PaymentHistory } from '../assets/emptyPagesImages/paymentHistory.svg';
import { ReactComponent as FavoriteOffers } from '../assets/emptyPagesImages/favoriteOffers.svg';
import { ReactComponent as Dashboard } from '../assets/emptyPagesImages/dashboard.svg';
import {
  DOES_NOT_HAVE_CONNECTED_OFFERS,
  GO_TO_OFFER_PAGE,
  DOES_NOT_HAVE_CONNECTED_OFFERS_DESC,
  DOES_NOT_HAVE_HOLD,
  DOES_NOT_HAVE_HOLD_DESC,
  EMPTY,
  EMPTY_FAVORITE_OFFERS,
  YOU_HAVE_NOT_MADE_ANY_PAYMENTS,
  YOU_HAVE_NOT_MADE_ANY_PAYMENTS_DESC,
  ORDER_PAYMENT,
  NO_DATA,
  NO_DATA_BY_FILTERS,
  NO_CONNECTED_CHANNELS, NO_CONNECTED_CHANNELS_DESC
} from "@main/i18n/aliases";
import i18n from "@main/i18n";

export const emptyPages = (name) => ({
  offers: {
    myOffers: {
      title: i18n.t(DOES_NOT_HAVE_CONNECTED_OFFERS),
      text: i18n.t(DOES_NOT_HAVE_CONNECTED_OFFERS_DESC),
      image: <MyOffers />,
      buttonText: i18n.t(GO_TO_OFFER_PAGE),
      link: '/app/offers/all-offers',
    },
    favoriteOffers: {
      title: i18n.t(EMPTY),
      text: i18n.t(EMPTY_FAVORITE_OFFERS),
      image: <FavoriteOffers />,
      buttonText: '',
    },
  },
  finance: {
    holdWithdrawal: {
      title: i18n.t(DOES_NOT_HAVE_HOLD),
      text: `${name}, `+i18n.t(DOES_NOT_HAVE_HOLD_DESC),
      image: <HoldWithdrawal />,
      buttonText: i18n.t(GO_TO_OFFER_PAGE),
      link: '/app/offers/all-offers',
    },
    paymentHistory: {
      title: `${name}, `+i18n.t(YOU_HAVE_NOT_MADE_ANY_PAYMENTS),
      text: i18n.t(YOU_HAVE_NOT_MADE_ANY_PAYMENTS_DESC),
      image: <PaymentHistory />,
      buttonText: i18n.t(ORDER_PAYMENT),
      link: '/app/finance/balance',
    },
  },
  dashboard: {
    title: i18n.t(NO_DATA),
    text: i18n.t(NO_DATA_BY_FILTERS),
    image: <Dashboard />,
    buttonText: '',
  },
  channels: {
    title: i18n.t(NO_CONNECTED_CHANNELS),
    text: i18n.t(NO_CONNECTED_CHANNELS_DESC),
    image: <MyOffers />,
    buttonText: i18n.t(GO_TO_OFFER_PAGE),
    link: '/app/offers/all-offers',
  },
});
