import { ClosedEyeIcon, EyeIcon, Input } from '@main/lib';
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';

const passwordInputStyle = ({
  icon: {
    cursor: 'pointer',
  },
});
const PasswordInput = ({
  className, classes, errorText, label, setError, setPassword,
}) => {
  const [passwordIsShown, setPasswordIsShown] = useState(false);
  return (
    <Input
      className={className}
      errorText={errorText}
      type={passwordIsShown ? undefined : 'password'}
      label={label}
      icon={!passwordIsShown ? (
        <ClosedEyeIcon
          className={classes.icon}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setPasswordIsShown(true);
          }}
        />
      ) : (
        <EyeIcon
          className={classes.icon}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setPasswordIsShown(false);
          }}
        />
      )}
      onChange={(event) => {
        setPassword(event.target.value);
        setError('');
      }}
    />
  );
};
export default withStyles(passwordInputStyle)(PasswordInput);
