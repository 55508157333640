import React, { useEffect, useState } from 'react';
import { Field, Formik } from 'formik';
import { makeStyles } from '@material-ui/styles';
import { Loader } from '@aisales/react-uikit';
import * as Sentry from '@sentry/react';
import { useDispatch, useSelector } from 'react-redux';
import { backendURL, twirpFetchOptions } from '@main/toRemove';
import { showToast } from '@pages/oldOffers/utils';
import PhoneVerification from '@components/PhoneVerification';
import { fetchUserInfo, updateProfileFormInfo } from '@store/actions';
import InputField from './InputField';

export default function ProfileForm({ disabled, ...rest }) {
  const classes = useStyles();
  const isLoading = useSelector((state) => state.profile.isLoading);
  const [count, setCount] = useState(0);
  const [phoneNumberExists, setPhoneNumberExists] = useState(false);
  const [phoneVerificated, setPhoneVerificated] = useState(false); // TODO: naming
  const formValues = useSelector((state) => state.profile.formValues);
  const [currentFormValues, setCurrentFormValues] = useState(null);
  const isFormLoading = useSelector((state) => state.profile.isLoading);
  const dispatch = useDispatch();

  const isEditing = count % 2 === 0; // TODO: what in the kentucky fried fuck
  let resetFormikForm;

  useEffect(() => {
    if (!isLoading) {
      const isInitialPhoneNumberOk = Boolean(formValues.phoneNumber);
      setPhoneNumberExists(isInitialPhoneNumberOk);
      setPhoneVerificated(isInitialPhoneNumberOk);
    }
    // this is only for the initial call
  }, [isLoading]);

  // TODO: refactor the phone number block into another hook
  // TODO: show actual errors, and don't do shit like showToast; return undefined
  function checkPhoneNumber(phoneNumber) {
    const formattedPhoneNumber = phoneNumber?.match(/\d/g)?.join('') || '';

    if (
      !phoneNumberExists
            && formattedPhoneNumber?.length !== 11
            && formattedPhoneNumber?.length !== 0
    ) {
      showToast('Неверный формат номера телефона');
      return undefined;
    }

    if (!phoneVerificated) {
      showToast('Не подтвержденный номер телефона');
      return undefined;
    }

    return formattedPhoneNumber;
  }

  useEffect(() => {
    const updateUserInfoImpl = async () => {
      try {
        // const formattedPhoneNumber = checkPhoneNumber(currentFormValues.phoneNumber)
        // if (!formattedPhoneNumber) {
        //     if (resetFormikForm) {
        //         resetFormikForm()
        //     }
        //     return
        // }

        const response = await fetch(
          `${backendURL
          }/twirp/cpa.backend.UserService/UpdateContactInfo`,
          {
            ...twirpFetchOptions,
            body: JSON.stringify({
              info: {
                ...currentFormValues,
                // phone_number: formattedPhoneNumber,
              },
            }),
          },
        );

        if (!response.ok) {
          showToast('Ошибка сервера');
        } else {
          showToast('Данные профиля успешно обновлены', true);
          dispatch(updateProfileFormInfo({ form: currentFormValues }));
        }
      } catch (e) {
        Sentry.captureException(e);
      }
    };

    if (count > 1 && count % 2 === 0 && disabled) {
      updateUserInfoImpl();
    }

    setCount((prev) => prev + 1); // TODO: гений блять
  }, [disabled]);

  useEffect(() => {
    try {
      dispatch(fetchUserInfo()).then(() => {
      });
    } catch (err) {
      Sentry.captureException(err);
    }
  }, []);

  return (
    <>
      {!isLoading && (
      // TODO: move the submit button inside the fucking form
      <Formik initialValues={{ ...formValues }} {...rest}>
        {({
          handleReset, handleSubmit, resetForm, setFieldValue, ...props
        }) => {
          resetFormikForm = resetForm;
          setCurrentFormValues(props.values);
          return (
            <form className={classes.form} {...props}>
              <div className={classes.leftInputs}>
                <InputField
                  name="nickname"
                  label="Nickname"
                  disabled={disabled}
                />
                <InputField
                  name="name"
                  label="Имя"
                  disabled={disabled}
                />
                <InputField
                  name="surname"
                  label="Фамилия"
                  disabled={disabled}
                />
                <InputField
                  name="patronymic"
                  label="Отчество"
                  disabled={disabled}
                />
              </div>
              <div className={classes.centerInputs}>
                <InputField
                  name="telegram"
                  label="Телеграм"
                  disabled={disabled}
                />
                <InputField
                  name="skype"
                  label="Skype"
                  disabled={disabled}
                />
                {!isFormLoading && isEditing
                                  && renderEditingPhoneNumber({
                                    classes, disabled, setPhoneNumberExists, setFieldValue, setPhoneVerificated,
                                  })}
                {!isFormLoading && !isEditing && renderReadOnlyPhoneNumber()}
              </div>
              <div className={classes.rightInputs} />
            </form>
          );
        }}
      </Formik>
      )}
      {isLoading && <Loader />}
    </>
  );
}

function renderEditingPhoneNumber({
  classes, disabled, setFieldValue, setPhoneNumberExists, setPhoneVerificated,
}) {
  return (
    <Field name="phoneNumber">
      {({ field }) => (
        <>
          <PhoneVerification
            type="NUMBER_VERIFICATION"
            field={{ field }}
            disabled
            initialization
            mask={[
              '+',
              /\d/,
              ' ',
              '(',
              /\d/,
              /\d/,
              /\d/,
              ')',
              ' ',
              /\d/,
              /\d/,
              /\d/,
              ' ',
              /\d/,
              /\d/,
              ' ',
              /\d/,
              /\d/,
            ]}
            className={
                        classes.phoneVerification
                    }
            onChange={(e, value) => {
              setPhoneVerificated(false);
            }}
            onSuccess={(e, number) => {
              // ne nado tak
              // <pic with a sad dog>

              // dispatch(updateProfileFormInfo({
              //     ...formValues,
              //     phoneNumber: number
              // }))
              setFieldValue('phoneNumber', number);
              setPhoneNumberExists(true);
              setPhoneVerificated(true);
            }}
          />
        </>
      )}
    </Field>
  );
}

function renderReadOnlyPhoneNumber() {
  return (
    <InputField
      name="phoneNumber"
      label="Номер телефона"
      disabled
      mask={[
        '+',
        /\d/,
        ' ',
        '(',
        /\d/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
        ' ',
        /\d/,
        /\d/,
      ]}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '56px',
  },
  leftInputs: {
    flex: '1 0 31%',
    '& > div': {
      marginRight: '40px',
      paddingBottom: '13px',
    },
  },
  centerInputs: {
    flex: '1 0 37%',
    '& > div': {
      padding: '0 45px 13px 45px',
      borderRight: '1px solid #E6E6E6',
      borderLeft: '1px solid #E6E6E6',
    },
  },
  rightInputs: {
    flex: '1 0 31%',
    '& > div': {
      marginLeft: '40px',
      paddingBottom: '13px',
    },
  },
  phoneVerification: {
    width: '100%',
  },
}));
