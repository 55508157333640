import { backendURL, twirpFetchOptions } from '@main/toRemove';
import { entries } from '@amcharts/amcharts4/.internal/core/utils/Object';
import { fieldTitleMapping } from '@pages/dashboard/constants/fieldTitleMapping';

function downloadFile(filename, url) {
  const link = document.createElement('a');
  link.href = url;
  document.body.appendChild(link);
  link.click();
  link.remove();
}

async function downloadTable(requestBody) {
  const times = [
    {
      key: 'year',
      value: 'год',
    },
    {
      key: 'month',
      value: 'месяц',
    },
    {
      key: 'day',
      value: 'день',
    },
    {
      key: 'hour',
      value: 'час',
    },

  ];
  const body = {
    ...requestBody,
    columns: [...Object.entries(fieldTitleMapping).map(([key, value]) => ({ key, value: value.title })), ...times, ...Object.entries(fieldTitleMapping).map(([key, value]) => ({ key: `${key}_percent`, value: `%${value.title}` }))],

  };
  const response = await fetch(
    `${backendURL}/twirp/statistics.cpa.CpaService/GetStatisticsExcel`,
    {
      ...twirpFetchOptions,
      body: JSON.stringify(body),
    },
  );
  const { url } = await response.json();

  // downloadFile('p1d0r.txt', url);
}

export default downloadTable;
