import React from 'react';
import { withStyles } from '@material-ui/core';

const AnotherPageTitle = ({ classes, style, ...props }) => (
  <div className={classes.pageTitleContainer} style={style}>
    <span className={classes.typo} style={{ opacity: (props['no-alpha'] ? 1 : 0.67) }}>
      {props.title}
      {props.children}
    </span>
    {props.button && (
      <div>
        {props.button}
      </div>
    )}
  </div>
);

const styles = (theme) => ({
  pageTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',

    background: '#F6F7FD',
    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.07)',
    height: '85px',
    alignItems: 'center',
    padding: '10px',
    borderBottom: '1px solid #8080802b',
  },
  typo: {
    color: '#000000',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '22px',
    opacity: '0.67',
  },
  button: {

    textTransform: 'none',
    '&:active': {

    },
  },
});

export default withStyles(styles)(AnotherPageTitle);
