import { withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import {
  Tooltip,
  ExclamationMarkIcon,
  FilledExclamationMarkIcon, greenColor, greyColor, orangeColor, redColor,
  TableCell,
  TableRow, DownloadIcon,

} from '@main/lib';
import clsx from 'clsx';

const rowWithStatusStyle = () => ({
  rowOrange: {
    '&&&&&&>td, &&&&&&&>th': {
      backgroundColor: orangeColor[10],
    },
    color: 'red',
  },
  rowRed: {
    '&&&&&&>th, &&&&&&&>td': {
      background: redColor[10],
    },

  },
  green: {
    color: greenColor[100],
  },
  grey: {
    color: greyColor[50],
  },
  orange: {
    color: orangeColor[100],
  },
  red: {
    color: redColor[100],
  },
  rightIcon: {
    width: '16px',
    height: '16px',
  },
  statusWrapper: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&>div': {
      height: 'min-content',
      '&>div': {
        height: '16px',
      },
    },
  },
  cell: {
    position: 'relative',
  },
  statusIcon: {
    cursor: 'pointer',
  },
  lastCell: {
  },
});

const RowWithStatusTooltip = ({
  classes,
  tableCellsChildren, // [{element: JSX.Element, align?: "center" || "left" || "right|}]
  color = 'grey',
  tooltipText, key,
  alternativeIcon,
}) => {
  const getRowClass = () => {
    if (tooltipIsOpened) {
      if (color === 'orange') {
        return classes.rowOrange;
      }
      if (color === 'red') {
        return classes.rowRed;
      }
    }
    return undefined;
  };
  const [tooltipIsOpened, setTooltipIsOpened] = useState(false);
  const StatusIcon = (props) => (props.tooltipIsOpened ? <ExclamationMarkIcon {...props} />
    : <FilledExclamationMarkIcon {...props} />);

  return (
    <TableRow className={getRowClass()}>
      {[...tableCellsChildren.map((child, index) => (
        <TableCell
          align={child?.align && (index === tableCellsChildren.length - 1 ? 'left' : 'center')}
          className={clsx({ [classes.lastCell]: index === tableCellsChildren.length - 1 })}
        >
          <div
            className={clsx(classes.cell)}
          >
            {child.element}
          </div>

        </TableCell>
      )), (
        <TableCell>
          <div
            className={clsx(classes[color], classes.statusWrapper)}
          >
            { alternativeIcon || (
              <Tooltip
                  placement={'top-end'}
                text={tooltipText}
                opened={tooltipIsOpened}
                classes={{ exclamationMark: classes[color] }}
                onClose={() => {
                  setTooltipIsOpened(false);
                }}
              >
                <div>
                  <StatusIcon
                    className={clsx(classes.statusIcon, classes.rightIcon)}
                    onClick={() => {
                      setTooltipIsOpened(true);
                    }}
                    tooltipIsOpened={tooltipIsOpened}
                  />
                </div>
              </Tooltip>
            )}
          </div>
        </TableCell>
      )]}
    </TableRow>
  );
};
export default withStyles(rowWithStatusStyle)(RowWithStatusTooltip);
