import fetch from '@main/utils/handledFetch';
import { backendURL, twirpFetchOptions } from '@main/toRemove';

export const START_FETCH_BANNERS = 'banners/START_FETCH_BANNERS';
export const FINISH_FETCH_BANNERS = 'banners/FINISH_FETCH_BANNERS';

export const startFetchBanners = () => ({
  type: START_FETCH_BANNERS,
});

export const finishFetchBanners = (payload) => ({
  type: FINISH_FETCH_BANNERS,
  payload,
});

export const fetchBanners = () => async (dispatch) => {
  dispatch(startFetchBanners());
  const response = await fetch(
    `${backendURL
    }/twirp/cpa.backend.BannerStateService/GetOne`,
    {
      ...twirpFetchOptions,
      body: '{}',
    },
  );
  const json = await response.json();
  dispatch(finishFetchBanners(json.state.body));
};

export const setBanner = ({ key, value }) => async (dispatch) => {
  dispatch(startFetchBanners());
  const response = await fetch(
    `${backendURL}/twirp/cpa.backend.BannerStateService/Set`,
    {
      ...twirpFetchOptions,
      body: JSON.stringify({ key, value }),
    },
  );
  const json = await response.json();
  dispatch(finishFetchBanners(json.state.body));
};
