import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';
import {
  CheckboxSelect, EllipsisIcon, blueColor, greyColor,
} from '@main/lib';

const ellipsisSquareStyle = (props) => ({
  ellipsisSquare: {
    width: '55px',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: greyColor[80],
    backgroundColor: blueColor[25],
  },
  ellipsisSquare_menuOpened: {
    backgroundColor: blueColor[30],
    color: blueColor[100],
    border: `1px solid ${greyColor[25]}`,
  },
});
const EllipsisSquare = withStyles(ellipsisSquareStyle)(
  React.forwardRef((props, ref) => {
    const { classes, menuOpened } = props;
    return (
      <div ref={ref} className={clsx(classes.ellipsisSquare, { [classes.ellipsisSquare_menuOpened]: menuOpened })} {...props}>
        <EllipsisIcon />
      </div>
    );
  }),
);

const DashboardTableEllipsisCheckboxSelect = ({
  ellipsisClasses, title, checkboxesItems, onChange, value, className,
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  return (
    <CheckboxSelect
      className={className}
      onClick={(event) => event.stopPropagation()}
      title={title}
      value={value}
      onChange={onChange}
      menuIsOpen={menuIsOpen}
      onClose={() => { setMenuIsOpen(false); }}
      anchorElement={<EllipsisSquare classes={ellipsisClasses} menuOpened={menuIsOpen} onClick={() => { setMenuIsOpen(true); }} />}
      checkboxesItems={checkboxesItems}
    />
  );
};
export default DashboardTableEllipsisCheckboxSelect;
