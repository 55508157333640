import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import en from './en';
import ru from './ru';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    ru: {
      translation: ru,
    },
  },
  lng: localStorage.getItem('lang') || 'en',
  fallbackLng: 'ru',
  interpolation: {
    escapeValue: false,
  },
}).then((r) => console.log(r)).catch((err) => console.error(err));

try {
  require(`moment/locale/${new URLSearchParams(window.location.search).get('lang')}`);
} catch (e) {
  console.warn('provided lang is not supported in moment.js . using english...');
}
moment.locale((localStorage.getItem('lang')) || 'en');
export default i18n;
