import Block from '@components/Block/Block';
import Table from '@pages/profile/components/Blocks/MacrosList/MacrosTable';
import React from 'react';
import { withStyles } from '@material-ui/styles';

function MacrosList({ classes, style }) {
  return (
    <Block style={style} title="Список макросов">
      <div className={classes.header}>
        <p>
          Укажите URL сайта трекера, который будет получать данные (его обычно можно взять в документации трекера) и
          пропишите нужные макросы, используя таблицу ниже. Проверьте наличие наличие символов /? и & в вашем Postback и
          сохраните его.
        </p>
        <div className={classes.example}>
          <p>Пример</p>
          <a>{'http://mysite.ru/statistics.php?ConvStatus={status}&SubID={aff_sub1}'}</a>
        </div>
        <p style={{ fontWeight: '600', fontSize: '14px', color: '#767676' }}>Список макросов:</p>
      </div>
      <Table />
    </Block>
  );
}

const styles = {
  header: {
    marginTop: '15px',
    '& >p': {
      fontSize: '14px',
      color: '#818181',
      marginLeft: '30px',
      marginBottom: '18px',
    },
  },
  example: {
    background: 'linear-gradient(90deg, rgba(248, 248, 248, 0.9) 0%, rgba(248, 248, 248, 0) 100%)',
    border: '1px solid #E5E5E5',
    boxSizing: 'borderBox',
    borderRadius: '5px',
    marginLeft: '30px',
    margin: '16px',
    padding: '14px',
    '& > p': {
      fontWeight: 'bold',
      fontSize: '14px',
      color: '#818181',
    },
    '& > a': {
      textDecoration: 'underline',
      color: '#55C7E1',
    },
  },
};
export default withStyles(styles)(MacrosList);
