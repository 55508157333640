"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var permission = __importStar(require("./admin.backend.permission"));
var project = __importStar(require("./admin.backend.project"));
var role = __importStar(require("./admin.backend.role"));
var task = __importStar(require("./admin.backend.task"));
var user = __importStar(require("./admin.backend.user"));
var clients = __importStar(require("./admin.clients.clients"));
var fields = __importStar(require("./admin.clients.fields"));
var process = __importStar(require("./admin.flowBuilder.process"));
var segment = __importStar(require("./admin.flowBuilder.segment"));
var conversations = __importStar(require("./admin.messenger.conversations"));
var reports = __importStar(require("./admin.statistics.reports"));
var landbots = __importStar(require("./admin.tools.landbots.protofiles"));
var tools = __importStar(require("./admin.tools.protofiles"));
exports.default = {
    permission: permission,
    project: project,
    role: role,
    task: task,
    user: user,
    clients: clients,
    fields: fields,
    process: process,
    segment: segment,
    conversations: conversations,
    reports: reports,
    landbots: landbots,
    tools: tools,
};
