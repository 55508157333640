import between from '@pages/dashboard/utils/index';

const getWarningTextRed = () => 'У вас плохие показатели, обратите внимание, иначе мы будем вынуждены остановить выплату';

const getWarningTextYellow = () => 'У вас плохие показатели';

const getNormalText = () => 'У вас нормальные показатели';

export const getTooltipTextMaker = ({ red, orange, green }) => {
  const getTooltip = (percent) => {
    if (between(percent, red)) {
      return getWarningTextRed();
    } if (between(percent, orange)) {
      return getWarningTextYellow();
    } if (between(percent, green)) {
      return getNormalText();
    }
    return getNormalText();
  };
  return getTooltip;
};
