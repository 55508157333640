import SocialNetworkStepper from '@pages/offers/components/OfferConnect/components/SocialNetworkStepper';
import VKConnector
  from '@pages/offers/components/OfferConnect/components/SocialNetworkStepper/VKStepper/components/VKConnector';
import React from 'react';

const VKStepper = (props) => (
  <VKConnector offerId={props.offer.id}>
    <SocialNetworkStepper {...props} />
  </VKConnector>
);

export default VKStepper;
