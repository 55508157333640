import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  greyColor, Tab, TabPanel, Tabs, Typography,
} from '@main/lib';
import { withStyles } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router';

const PageTemplate = ({
  children,
  tabs,
  rightTopCorner, outerScroll,
  classes,
  title,
}) => {
  useEffect(() => {
  }, []);
  function getScrollbarWidth() {
    // Creating invisible container
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // forcing scrollbar to appear
    outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement('div');
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

    // Removing temporary elements from the DOM
    outer.parentNode.removeChild(outer);

    return scrollbarWidth;
  }

  const isSelected = (location, tab) => {
    if (location) {
      // console.log(new RegExp(subItem.regex))
      const oneOfSubmenusLocation = tab?.subtabs?.find(
              (subItem) => (subItem.regex ? new RegExp(subItem.regex)
                .test(location.pathname + decodeURIComponent(location.search))
                : subItem.link === (location.pathname + decodeURIComponent(location.search))));
      const thisLinkLocation = (tab.regex ? new RegExp(tab.regex)
        .test(location.pathname + decodeURIComponent(location.search))
        : (tab.link) === (location.pathname + decodeURIComponent(location.search)));

      const temp = Boolean(Boolean(oneOfSubmenusLocation) | thisLinkLocation);
      return temp;
    }
  };
  const history = useHistory();
  const [internalTabValue, setInternalTabValue] = useState(0);
  const location = useLocation();
  const [externalTabValue, setExternalTabValue] = useState(0);
  useEffect(() => {
  }, [internalTabValue]);
  useEffect(() => {
    if (location.pathname && !!tabs) {
      const temp = tabs.findIndex((tab) => isSelected(location, tab));
      setExternalTabValue(temp);

      setInternalTabValue(tabs[temp]?.subtabs?.findIndex((tab) => isSelected(location, tab)));
    }
  }, [location]);

  history.listen((location, action) => {
    if (location.pathname && !!tabs) {
      setExternalTabValue(tabs.findIndex((tab) => isSelected(location, tab)));
    }
  });
  return (
    <>
      <div className={classes.root}>
        <div className={classNames(classes.content, { outer: outerScroll })}>
          <div className={classNames(classes.upperPart, { outer: outerScroll })}>
            <Typography variant="title" className={classNames(classes.title, { outer: outerScroll })}>{title}</Typography>
            {!!tabs && (
              <Tabs
                className={classNames(classes.tabs, { outer: outerScroll })}
                styleVariant="common"
                value={externalTabValue}
                variant="scrollable"
                scrollButtons="off"
              >
                {tabs.map((tab) => (
                  <Tab
                    floating={tab.floating}
                    label={tab.text}
                    onClick={tab.onClick ? () => {
                      tab.onClick();
                    } : () => {
                      if (tab.floating) {
                        tab.onClick();
                      } else {
                        history.push(tab.link);
                      }
                    }}
                  />
                ))}
              </Tabs>
            )}
            {(!!tabs && tabs[externalTabValue]?.subtabs)
                        && (
                        <TabPanel value={externalTabValue}>
                          <div className={classes.subtabsWrapper}>
                            <Tabs className={classes.subtabs} value={internalTabValue}>
                              {tabs[externalTabValue]?.subtabs.map((subtab) => (
                                <Tab
                                  label={subtab.text}
                                  icon={subtab.icon}
                                  onClick={subtab.onClick ? subtab.onClick : () => {
                                    history.push(subtab.link);
                                  }}
                                />
                              ))}
                            </Tabs>
                            <span className={classes.pagePanel__rightTopCorner}>
                              {rightTopCorner}
                            </span>
                          </div>
                        </TabPanel>
                        )}
          </div>
          <div style={{ paddingRight: `calc(30px - ${!outerScroll ? getScrollbarWidth() : '0'}px)` }} className={classNames(classes.lowerPart, { outer: outerScroll })}>
            <TabPanel value={externalTabValue} className={classes.tabpanel}>
              {children}
            </TabPanel>
          </div>
        </div>
      </div>
    </>
  );
};

const style = (theme) => ({
  root: {
    backgroundColor: greyColor.background,
    height: '100%',
  },
  content: {
    height: '100%',
    paddingTop: '40px',
    '&.outer': {
      overflowY: 'auto',
      display: 'block',
    },
    display: 'flex',
    flexFlow: 'column',
  },
  tabs: {
    // marginTop: '15px',
  },
  subtabsWrapper: {
    borderBottom: `1px solid ${greyColor[25]}`,
    display: 'flex',
    position: 'relative',
  },
  title: {
    marginBottom: '15px',
    display: 'block',
  },
  internalContent: {
    paddingRight: '30px',
    paddingLeft: '30px',
    paddingTop: '30px',
  },
  footer: {
    height: '30px',
    width: '100%',
  },
  subtabs: {
    paddingTop: '30px',
    marginLeft: '30px',
    width: 'fit-content',
  },
  upperPart: {
    paddingLeft: '30px',
    paddingRight: '30px',
    '&.outer': {
      display: 'initial',
      '& > span.outer': {
        marginLeft: '30px',
        marginRight: '30px',
      },
      '& > div.outer': {
        margin: '0 30px',
      },
      '& > div:nth-child(3)': {
        position: 'sticky',
        top: '-40px',
        margin: '0 30px',
        zIndex: 100,
      },
    },
  },
  lowerPart: {
    overflowY: 'scroll',
    flex: '1',
    '&.outer': {
      overflowY: 'hidden',
      marginTop: '-20px',
    },
    paddingLeft: '30px',
    paddingBottom: '30px',
  },
  tabpanel: {
    height: 'fit-content',
    width: '100%',
    minHeight: '100%',
  },
  pagePanel__rightTopCorner: {
    position: 'absolute',
    right: '30px',
    top: '30px',
  },
});

export default withStyles(style)(PageTemplate);
