import React, { useEffect } from 'react';
import PageTemplate from '@components/PageTemplate';
import { financePaths } from '@pages/finance/constants/financePaths';
import { financeTabs } from '@pages/finance/constants/financeTabs';
import { withStyles } from '@material-ui/core';
import { Route, Switch } from 'react-router-dom';
import HoldWithdrawalPage from '@pages/finance/pages/HoldWithdrawalPage';
import BalancePage from '@pages/finance/pages/BalancePage';
import TermsAndProcedurePaymentsPage from '@pages/finance/pages/TermsAndProcedurePaymentsPage';
import PaymentHistoryPage from '@pages/finance/pages/PaymentHistoryPage';
import {useTranslation} from "react-i18next";
import {FINANCES} from "@main/i18n/aliases";
import i18n from "@main/i18n";

const Finance = (props) => {
  const { classes } = props;
  const {t} = useTranslation()
  useEffect(() => {

  }, []);
  return (
    <PageTemplate tabs={financeTabs(t)} title={i18n.t(FINANCES)}>
      <div className={classes.content}>
        <Switch>
          <Route path={financePaths.balancePagePath} render={() => <BalancePage />} />
          <Route path={financePaths.pathPrefixHistory} render={() => <PaymentHistoryPage />} />
          <Route
            path={financePaths.termsAndProcedurePaymentsPagePath}
            render={() => <TermsAndProcedurePaymentsPage />}
          />
          <Route
            path={financePaths.holdWithdrawalPagePath}
            render={() => <HoldWithdrawalPage />}
          />
        </Switch>
      </div>
    </PageTemplate>
  );
};

const styles = (theme) => ({
  content: {
    padding: '30px',
  },

});

export default withStyles(styles)(Finance);
