import OfferStepper from '@pages/offers/components/OfferConnect/components/Stepper';
import React from 'react';
import Authentication
  from '@pages/offers/components/OfferConnect/components/SocialNetworkStepper/components/Authentication';
import Groups
  from '@pages/offers/components/OfferConnect/components/SocialNetworkStepper/components/Groups';

const SocialNetworkStepper = ({ ...otherProps }) => {
  function specialSteps({
    currentStep,
    setStep,
  }) {
    return [{
      component: React.cloneElement(<Authentication />,
        {
          setStep,
          currentStep,
          ...otherProps,
        }),
    },
    {
      component: React.cloneElement(<Groups />, {
        setStep,
        currentStep,
        ...otherProps,
      }),
    },
    ];
  }

  return <OfferStepper specialSteps={specialSteps} monetizationStepIdx={4} {...otherProps} />;
};
export default SocialNetworkStepper;
