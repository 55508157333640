const from = new Date();
const to = new Date();
from.setDate(to.getDate() - 7);

const skip = 0;
const pageSize = 10;
export const bodyRequestDefault = {
  date: null,
  currency: '1',

  fields: [
  ],
  filter: {},
  groups: ['year', 'month', 'day'],
  page: Math.floor(skip / pageSize),
  offset: pageSize,
  sorts: [
    {
      field: 'year',
      order: -1,
    },
    {
      field: 'month',
      order: -1,
    },
    {
      field: 'day',
      order: -1,
    },
  ],
  time_zone: new Date().getTimezoneOffset() / -60,
};
