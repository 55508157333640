import { ReactComponent as ZaleycashIcon } from '@pages/finance/assets/payments/zaleycash.svg';
import uniqIcon from '@pages/finance/assets/payments/getuniq.png';
import webmoneyIcon from '@pages/finance/assets/payments/webmoney.png';
import yandexMoneyIcon from '@pages/finance/assets/payments/yandex money.png';
import solarStaff from '@pages/finance/assets/payments/solar-staff.png';
import capitalist from '@pages/finance/assets/payments/capitalist.png';
import googleAdwords from '@pages/finance/assets/payments/google adwords.png';
import myTarget from '@pages/finance/assets/payments/mytarget.png';
import yandexDirect from '@pages/finance/assets/payments/yandex money.png';
import { ReactComponent as VkIcon } from '@pages/finance/assets/payments/vk.svg';
import { ReactComponent as BankIcon } from '@pages/finance/assets/payments/bank card.svg';
import { ReactComponent as BankTransferIcon } from '@pages/finance/assets/payments/bank transfer.svg';
import React from 'react';
import i18n from "@main/i18n";
import {
  BANK_CARD, BANK_TRANSFER, BANK_TRANSFER_SELF_EMPLOYED, BANK_TRANSFER_WITH_EDI,
  CAPITALIST,
  GETUNIQ,
  GOOGLE_ADWORDS, MYTARGET,
  SOLAR_STAFF,
  WEBMONEY_WMZ, YANDEX_DIRECT,
  YANDEX_MONEY,
  ZALEYCASH
} from "@main/i18n/aliases";

export const paymentsMethods = [
  {
    name: i18n.t(ZALEYCASH),
    icon: <ZaleycashIcon />,
    numOfDays: 3,
    commission: 0,
  },
  {
    name: i18n.t(GETUNIQ),
    icon: <img src={uniqIcon} />,
    numOfDays: 7,
    commission: 0,
  },
  {
    name: i18n.t(WEBMONEY_WMZ),
    icon: <img src={webmoneyIcon} />,
    numOfDays: 7,
    commission: 3.9,
  },

  {
    name: i18n.t(YANDEX_MONEY),
    icon: <img src={yandexMoneyIcon} />,
    numOfDays: 7,
    commission: 3.9,
  },
  {
    name: i18n.t(SOLAR_STAFF),
    icon: <img src={solarStaff} />,
    numOfDays: 7,
    commission: 4.5,
  },
  {
    name: i18n.t(CAPITALIST),
    icon: <img src={capitalist} />,
    numOfDays: 7,
    commission: 2.9,
  },
  {
    name: i18n.t(GOOGLE_ADWORDS),
    icon: <img src={googleAdwords} />,
    numOfDays: 7,
    commission: 0,
  },
  {
    name: i18n.t(MYTARGET),
    icon: <img src={myTarget} />,
    numOfDays: 3,
    commission: 0,
  },
  {
    name: i18n.t(YANDEX_DIRECT),
    icon: <img src={yandexDirect} />,
    numOfDays: 3,
    commission: 0,
  },
  {
    name: i18n.t(BANK_CARD),
    icon: <BankIcon />,
    numOfDays: 3,
    commission: 4.5,
  },
  {
    name: i18n.t(BANK_TRANSFER),
    icon: <BankTransferIcon />,
    numOfDays: 7,
    commission: 0,
  },
  {
    name: i18n.t(BANK_TRANSFER_WITH_EDI),
    icon: <BankTransferIcon />,
    numOfDays: 7,
    commission: 0,
  },
  {
    name: i18n.t(BANK_TRANSFER_SELF_EMPLOYED),
    icon: <BankTransferIcon />,
    numOfDays: 7,
    commission: 0,
  },
];
