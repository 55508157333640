import api from 'api';
import { backendURL, twirpFetchOptions } from '@main/toRemove';
import { showToast } from '@pages/oldOffers/utils';
import moment from 'moment';
import fetch from '@main/utils/handledFetch';

export const FETCH_START = 'FETCH_START';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_FAIL = 'FETCH_FAIL';
export const CREATE_START = 'CREATE_START';
export const CREATE_SUCCESS = 'CREATE_SUCCESS';
export const CREATE_FAIL = 'CREATE_FAIL';
export const DELETE_START = 'DELETE_START';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';
export const DELETE_FAIL = 'DELETE_FAIL';
export const TOGGLESTAR_START = 'TOGGLESTAR_START';
export const TOGGLESTAR_SUCCESS = 'TOGGLESTAR_SUCCESS';
export const TOGGLESTAR_FAIL = 'TOGGLESTAR_FAIL';
export const SET_FAVORITE = 'SET_FAVORITE';

const offers = new api.tools.ToolsService(backendURL, window.fetch.bind(window));

export const mock_offers = [
  {
    id: 122,
    name: '[Credits] [CPA] Быстрый займ Моментальные выплаты без проблем Aidafinance',
    icon: 'https://obs.ru-moscow1.huaweicloud.ru/images.l406102/offers/1871/logo/',
    status: 1,
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed rutrum diam. Pellentesque pellentesque, turpis at ultricies viverra, massa tortor tempor mauris, non tincidunt massa urna et augue. Cras non neque ut ante commodo efficitur a id magna. Donec et metus vehicula, tristique enim vel, laoreet odio. Sed vehicula ex id massa euismod scelerisque. Sed eget semper dolor, eu finibus ex. Cras mollis lobortis fringilla. Suspendisse dolor eros, porta vitae enim et, sagittis sollicitudin tellus. Praesent egestas eros at ligula gravida aliquet.',
    date: '24.11.2019',
    isSubscribed: true,
    isStarred: true,
  },
  {
    id: 124,
    name: '[Credits] [CPA] Моментальный займ быстрые выплаты без проволочек Aidafinance',
    icon: 'https://obs.ru-moscow1.huaweicloud.ru/images.l406102/offers/1871/logo/',
    status: 0,
    description: '',
    date: '22.11.2019',
    isSubscribed: false,
    isStarred: false,
  },
];

export const fetchOffers = (dispatch) => {
  fetch(`${backendURL}/twirp/cpa.offers.OffersManagementService/GetOffersList`, { ...twirpFetchOptions, body: '{}' }).then((res) => {
    if (res.ok) {
      return res.json();
    }
    throw new Error(res.statusText);
  }).then((res) => {
    dispatch({
      type: FETCH_SUCCESS,
      payload: res.offers.map((offer) => ({ ...offer, dateParsed: moment(new Date(offer.date * 1000)).format('DD.MM.YYYY') })),
    });
  }).catch((err) => {
    showToast('Ошибка при получении списка офферов', false);
    dispatch({
      type: FETCH_FAIL,
      payload: err,
    });
  });
  return dispatch({
    type: FETCH_START,
  });
};

export const setFavorite = ({ id, isFavorite }) => (dispatch) => {
  dispatch({
    type: SET_FAVORITE,
    payload: { id, isFavorite },
  });
};

export const remove = (id) => (dispatch) => {
  offers.delete(api.tools.DeleteRequest.fromJSON({ id })).then((res) => {
    showToast('Инструмент успешно удалён', true);
    dispatch({
      type: DELETE_SUCCESS,
      payload: id,
    });
  }).catch((err) => {
    showToast('Ошибка при удалени', false);
    dispatch({
      type: DELETE_FAIL,
      payload: err,
    });
  });
  return dispatch({
    type: DELETE_START,
  });
};

export const create = (data, onSuccess) => (dispatch) => {
  offers.create(api.tools.CreateRequest.fromJSON({ ...data })).then((res) => {
    dispatch({
      type: CREATE_SUCCESS,
      payload: res.tool,
    });
    onSuccess(res.tool.id);
  }).catch((err) => {
    showToast('Ошибка при создании', false);
    dispatch({
      type: CREATE_FAIL,
      payload: err,
    });
  });
  return dispatch({
    type: CREATE_START,
  });
};
