import fetch from '@main/utils/handledFetch';
import { backendURL } from '@main/toRemove';

const defaultRegister = async (userType, password, phoneVerificationToken, refCode, name, phoneNumber) => {
  const response = await fetch(`${backendURL}/api/register`, {
    method: 'POST',
    body: JSON.stringify({
      user_type: userType,
      name,
      number: phoneNumber,
      password,
      number_token: phoneVerificationToken,
      ref: refCode,
    }),
    headers: {
      'Content-type': 'application/json',
    },
  });
  return response;
};
export default defaultRegister;
