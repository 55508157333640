import api from 'api';
import { backendURL } from '@main/toRemove';
import { fetchOffers as fetchTools, mock_offers } from './offersActions';

export const FETCH_START = 'SPLITVIEW_FETCH_START';
export const FETCH_SUCCESS = 'SPLITVIEW_FETCH_SUCCESS';
export const FETCH_FAIL = 'SPLITVIEW_FETCH_FAIL';
export const FRAME_LOADING_END = 'SPLITVIEW_FRAME_LOADING_END';
export const RESET = 'SPLITVIEW_RESET';
export const SET_LOGGED_VK = 'SPLITVIEW_SET_LOGGED_VK';
export const SET_LOGGED_FACEBOOK = 'SPLITVIEW_SET_LOGGED_FACEBOOK';
export const SET_CHANNEL = 'SPLITVIEW_SET_CHANNEL';
export const SET_DATA = 'SPLITVIEW_SET_DATA';
export const SAVE_START = 'SPLITVIEW_SAVE_START';
export const SAVE_SUCCESS = 'SPLITVIEW_SAVE_SUCCESS';

const tools = new api.tools.ToolsService(backendURL, window.fetch.bind(window));
const landbots = new api.landbots.LandbotsServiceSecured(backendURL, window.fetch.bind(window));

const parseRPC = (landbot) => {
  const res = landbot.toJSON();
  res.layout = res.layout.toJSON();
  res.layout.bot_message = res.layout.bot_message.toJSON();
  res.layout.bot_message.colors = res.layout.bot_message.colors.toJSON();
  res.layout.user_message = res.layout.user_message.toJSON();
  res.layout.user_message.colors = res.layout.user_message.colors.toJSON();
  return res;
};

export const fetch = (id) => (dispatch) => {
  try {
    setTimeout(() => {
      for (let i = 0; i < mock_offers.length; i++) {
        if (mock_offers[i].id === Number(id)) {
          dispatch({
            type: FETCH_SUCCESS,
            payload: mock_offers[i],
          });
          break;
        }
      }
    }, 500);
  } catch (err) {
    dispatch({
      type: FETCH_FAIL,
      payload: err,
    });
  }
  return dispatch({
    type: FETCH_START,
  });
};

export const update = (data) => (dispatch) => {
  tools.update(api.tools.UpdateRequest.fromJSON({
    id: data.tool_id,
    name: data.name,
  })).then(() => {
  });
  landbots.updateLayout(api.landbots.UpdateLayoutRequest.fromJSON({
    ...data,
  })).then((res) => {
    dispatch({
      type: SAVE_SUCCESS,
      payload: {
        ...parseRPC(res.landbot),
        name: data.name,
        tool_id: data.tool_id,
      },
    });
    dispatch(fetchTools);
  });
  return dispatch({ type: SAVE_START });
};

export const frameLoaded = (dispatch) => dispatch({ type: FRAME_LOADING_END });
export const reset = (dispatch) => dispatch({ type: RESET });
export const setLoggedVK = (isLogged) => (dispatch) => dispatch({ type: SET_LOGGED_VK, payload: isLogged });
export const setLoggedFacebook = (isLogged) => (dispatch) => dispatch({ type: SET_LOGGED_FACEBOOK, payload: isLogged });
export const setChannel = (chan) => (dispatch) => dispatch({ type: SET_CHANNEL, payload: chan });
export const setData = (data) => (dispatch) => dispatch({ type: SET_DATA, payload: data });
