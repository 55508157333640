import { backendURL, twirpFetchOptions } from '@main/toRemove';
import * as Sentry from '@sentry/react';
import fetch from '@main/utils/handledFetch';
import {useTranslation} from "react-i18next";
import {ERROR_DURING_FETCH_BALANCE} from "@main/i18n/aliases";

export const START_GET_BALANCE = 'Balance/START_GET_BALANCE';
export const GET_BALANCE_SUCCESS = 'Balance/GET_BALANCE_SUCCESS';
export const GET_BALANCE_FAILURE = 'Balance/GET_BALANCE_FAILURE';

export const getBalanceStart = () => ({
  type: START_GET_BALANCE,
});

export const getBalanceSuccess = (payload) => ({
  type: GET_BALANCE_SUCCESS,
  payload,
});

export const getBalanceFailure = () => ({
  type: GET_BALANCE_FAILURE,
});

export const getBalance = (accountID) => async (dispatch) => {
  try {
    // setBalanceLoading(true);
    dispatch(getBalanceStart());
    const response = await fetch(
      `${backendURL
      }/twirp/cpa.transactions.TransactionsServiceSecured/GetBalance`,
      {
        ...twirpFetchOptions,
        body: JSON.stringify({ account_id: accountID }),
      },
    );
    if (!response.ok) {
      const {t} = useTranslation()
      throw Error(t(ERROR_DURING_FETCH_BALANCE));
    }

    const json = await response.json();

    dispatch(
      getBalanceSuccess({
        balance: json.balance,
      }),
    );
  } catch (e) {
    Sentry.captureException(e);
    dispatch(getBalanceFailure());
  } finally {
    // setBalanceLoading(false);
  }
};
