import { Input, SearchIcon, Typography, } from '@main/lib';
import ChangeButton
  from '@pages/offers/components/OfferConnect/components/SocialNetworkStepper/components/ChangeButton';
import React, { useState } from 'react';
import SocialNetworksTable
  from '@pages/offers/components/OfferConnect/components/SocialNetworkStepper/components/Groups/Table';
import { escapeRegex } from '@pages/oldOffers/utils';
import withStyles from '@material-ui/core/styles/withStyles';

const style = ({
  selectedGroup: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      height: '16px',
      marginRight: '16px',
    },
    '& p': {},
  },
  title: {
    marginBottom: '20px',
    justifyContent: 'space-between',
    width: '100%',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
  },
  changeButton: {
    // marginRight: '53px'
  },
});

const Groups = ({
  classes,
  groupToAdd,
  groups,
  isLogged,
  platform,
  setGroupToAdd,
  chosenChannel,
}) => {
  const [filterRegex, setFilterRegex] = useState(new RegExp('()', 'gi'));
  const [searchQuery, setSearchQuery] = useState(null);
  const handleSearchChange = (e) => {
    setFilterRegex(new RegExp(`(${escapeRegex(e.target.value)})`, 'gi'));
    setSearchQuery(e.target.value);
  };

  return (
    <div style={{
      position: 'relative',
      width: '100%',
    }}
    >
      <div>
        <Typography className={classes.title} variant="subtitle">
          {/* Выбор
          группы */}
          Choice
          groups
          {' '}
          {isLogged && (groupToAdd
            ? (
              <ChangeButton
                className={classes.changeButton}
                onClick={() => {
                  setGroupToAdd(null);
                }}
              />
            ) : (
              <Input
                value={searchQuery}
                // placeholder="Название группы или ID"
                placeholder="Group name or ID"
                icon={<SearchIcon />}
                onChange={handleSearchChange}
              />
            ))}
        </Typography>
        {' '}
        {(groups || !!platform)
        && isLogged && (
          <>
            {/* <VKAuthTour haveGroups /> */}
            {groupToAdd
              ? (
                <>
                  <div className={classes.selectedGroup}>
                    <img
                      alt="group logo"
                      src={groupToAdd.image}
                    />
                    {' '}
                    {groupToAdd.name}
                  </div>
                </>
              )
              : (
                <SocialNetworksTable
                  onChangeGroup={(group) => {
                    let tmp = group;
                    if (chosenChannel === 'instagram') {
                      tmp = { ...group, connect_instagram: true };
                    }
                    setGroupToAdd(tmp);
                  }}
                  searchQuery={searchQuery}
                  filterRegex={filterRegex}
                  classes={classes}
                  groupToAdd={groupToAdd}
                  groups={groups}
                />
              )}
          </>
        )}
      </div>
    </div>
  );
};

export default withStyles(style)(Groups);
