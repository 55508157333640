import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    fetchFinanceTickets, fetchOffers, fetchPaymentInfo, fetchUserInfo, getBalance,
} from '@store/actions';
import {backendURL, twirpFetchOptions} from '@main/toRemove';
import {
    blueColor, Table, TableBody, TableCell, TableHead, TableRow,
    DropdownIcon,
} from '@main/lib';
import {getCookie} from '@pages/finance/utils';
import {withStyles} from '@material-ui/core';
import clsx from 'clsx';
import fetch from '@main/utils/handledFetch';
import {showToast} from '@pages/oldOffers/utils';
import EmptyPage from '@components/EmptyPage';
import {emptyPages} from '@pages/constants/emptyPages';
import Chart from '../../components/Chart';
import Loader from "@components/Loader";
import i18n from "@main/i18n";
import {
    AVAILABLE_FOR_WITHDRAWAL, CAME_OUT_OF_HOLD, ERROR_WHILE_GETTING_LIST_OF_OFFERS,
    EXIT_DATES_FROM_HOLD, HOLD,
    HOLD_REMOVAL_SCHEDULE, HOLD_REMOVAL_SCHEDULE_,
    NUMBER_OF_CONVERSIONS,
    OFFER,
    SUM,
    TOTAL_L
} from "@main/i18n/aliases";

function renameKeys(obj, newKeys) {
    const keyValues = Object.keys(obj).map((key) => {
        const newKey = newKeys[key] || key;
        return {[newKey]: obj[key]};
    });
    return Object.assign({}, ...keyValues);
}

export const getHoldReleaseByDate = async (accountID) => {
    try {
        const res = await fetch(`${backendURL}/twirp/cpa.transactions.TransactionsServiceSecured/GetHoldReleaseByDate`, {
            ...twirpFetchOptions,
            body: JSON.stringify({account_id: accountID}),
        });
        let res_json;
        if (res.ok) {
            res_json = await res.json();
        } else {
            throw new Error(res.statusText);
        }
        return res_json.result;
    } catch (e) {
        showToast(i18n.t(ERROR_WHILE_GETTING_LIST_OF_OFFERS), false);
    }
};
const username = JSON.parse(localStorage.getItem('user'))?.user?.username;

const emptyPage = emptyPages(username).finance.holdWithdrawal;
const HoldWithdrawalPage = ({classes}) => {
        const [chartDataLoading, setChartDataLoading] = useState(true);
        const getHoldGrouped = async (accountId) => {
            try {
                setChartDataLoading(true);

                const res = await fetch(
                    `${backendURL
                    }/twirp/cpa.billing.BillingService/GetHoldGrouped`,
                    {
                        ...twirpFetchOptions,
                        body: JSON.stringify({
                            account_id: accountId,
                        }),
                    },
                );
                let res_json;
                if (res.ok) {
                    res_json = await res.json();
                } else {
                    throw new Error(res.statusText);
                }

                return res_json.offers;
            } catch (e) {
                return [];
            } finally {
                setChartDataLoading(false);
            }
        };
        const start = new Date();
        const end = new Date();
        start.setDate(end.getDate() - 29);
        const offers = useSelector((state) => state?.offers?.offers);
        const [selectedRowIndex, setSelectedRowIndex] = useState(null);
        const dispatch = useDispatch();
        const [tableData, setTableData] = useState(null);
        const [tableDataLoading, setTableDataLoading] = useState(true);
        const [selectValue, setSelectValue] = useState(7);
        const [chartData, setChartData] = useState([]);
        const accountID = getCookie('account_id');
        const balance = useSelector((state) => state.balance.balance);

        useEffect(() => {
            dispatch(fetchOffers);
        }, []);

        useEffect(() => {
            dispatch(getBalance(accountID));
        }, []);

        useEffect(() => {
            dispatch(getBalance(accountID));
            dispatch(fetchUserInfo());
            dispatch(fetchPaymentInfo());
            dispatch(fetchFinanceTickets(accountID));
            getHoldReleaseByDate(accountID).then((result) => {
                setChartData(formatChartData(result));
            });
            getHoldGrouped(accountID).then((res) => {
                setTableData(res);
            }).finally(setTableDataLoading(false));
        }, []);

        useEffect(() => {
            setChartData(onBalanceChanged());
        }, [chartDataLoading, balance]);

        const formatChartData = (chartData) => {
            const newKeys = {
                amount: 'total',
            };
            return chartData.map((o) => renameKeys(o, newKeys)).map((o) => ({...o, date: o.date * 1000}));
        };

        const getOfferInfo = (offerId) => {
            const offer = offers.filter((offer) => offer.id === offerId)[0];
            return {
                name: offer.name,
                conversions: offer.statistics.leads,
                sum: offer.statistics.income,
            };
        };

        const onBalanceChanged = () => {
            let lastBalance = balance?.available;
            let lastHold = balance?.total - balance?.available;
            return chartData.map((o) => {
                lastBalance += o.total;
                lastHold -= o.total;
                return {
                    ...o,
                    futureAvailable: lastBalance,
                    futureHold: lastHold
                }
            });
        }

        const Row = ({
                         dataPiece, rowIsOpen, onChangeOpened, offerId, sub,
                     }) => {
            let offerInfo = sub ? dataPiece : null;
            if (offerId) {
                offerInfo = getOfferInfo(offerId);
            }

            const getDetailedRows = () => {
                if (dataPiece.data && rowIsOpen) {
                    return dataPiece.data.map((detailedPiece) => (
                        <Row
                            sub
                            dataPiece={{
                                date: detailedPiece.date,
                                conversions: detailedPiece.count,
                                sum: detailedPiece.amount,
                            }}
                        />
                    ));
                }
                return [];
            };
            return ([
                    <TableRow className={clsx({[classes.activeRow]: rowIsOpen})}>
                        <TableCell>
                            {offerInfo.name}
                        </TableCell>
                        <TableCell>
                            {
                                sub ? dataPiece.date : (
                                    <div>
                                        (
                                        {`${dataPiece.data[0].date} `}
                                        —
                                        {` ${dataPiece.data[dataPiece.data.length - 1].date}`}
                                        )

                                    </div>
                                )
                            }
                        </TableCell>
                        <TableCell>
                            {offerInfo.conversions}
                            {!sub && ' ('+i18n.t(TOTAL_L)+')'}
                        </TableCell>
                        <TableCell
                            align="center"
                        >
                            <div className={classes.cellWithDropdown}>
                                {offerInfo.sum}
                                {!sub && ' ('+i18n.t(TOTAL_L)+')'}
                                {dataPiece.data && (
                                    <div className={classes.dropdownIconWrapperWrapper}>
                                        <div
                                            onClick={() => {
                                                onChangeOpened(!rowIsOpen);
                                            }}
                                            className={clsx(classes.dropdownIconWrapper, {[classes.activeIconWrapper]: rowIsOpen})}
                                        >
                                            <DropdownIcon/>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </TableCell>
                    </TableRow>,
                    [...getDetailedRows()],
                ]
            );
        };
        return <>
            {(!tableDataLoading && !chartDataLoading && tableData?.length !== 0 &&
                <div>
                    {chartData.length !== 0
                    && (
                        <Chart
                            chartData={chartData}
                            chartDataLoading={chartDataLoading}
                            setSelectValue={setSelectValue}
                            selectValue={selectValue}
                            chartTitle={i18n.t(HOLD_REMOVAL_SCHEDULE)}
                            description={(
                                <div>
                                    {i18n.t(HOLD_REMOVAL_SCHEDULE_)}
                                </div>
                            )}
                            tooltipData={[
                                [i18n.t(AVAILABLE_FOR_WITHDRAWAL), 'futureAvailable', 'blue'],
                                [i18n.t(HOLD), 'futureHold', 'yellow'],
                            ]}
                            bottomData={[
                                i18n.t(CAME_OUT_OF_HOLD), 'total',
                            ]}
                        />
                    )}
                    <Table>
                        <TableHead>
                            <TableCell>
                                {i18n.t(OFFER)}
                            </TableCell>
                            <TableCell>
                                {i18n.t(EXIT_DATES_FROM_HOLD)}
                            </TableCell>
                            <TableCell>
                                {i18n.t(NUMBER_OF_CONVERSIONS)}
                            </TableCell>
                            <TableCell>
                                {i18n.t(SUM)}
                            </TableCell>
                        </TableHead>
                        <TableBody>
                            {tableData && tableData?.map((dataPiece, index) => (
                                <Row
                                    offerId={dataPiece.id}
                                    dataPiece={dataPiece}
                                    rowIsOpen={selectedRowIndex === index}
                                    onChangeOpened={(opened) => {
                                        if (opened) {
                                            setSelectedRowIndex(index);
                                        } else {
                                            setSelectedRowIndex(null);
                                        }
                                    }}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </div>)}
            {!tableDataLoading && !chartDataLoading && tableData?.length === 0 && <EmptyPage {...emptyPage} />}
            {(tableDataLoading || chartDataLoading) && <div className={classes.loaderWrapper}>
                <Loader size={'large'}/>
            </div>}
        </>;
    }
;

const style = {
    loaderWrapper: {
        marginTop: '100px',
        textAlign: 'center'
    },
    activeRow: {
        '&&& tr, &&& td': {
            backgroundColor: blueColor[10],
        },
    },
    dropdownIconWrapperWrapper: {
        display: 'flex',
        height: '100%',
        position: 'absolute',
        width: '100%',
        top: '0',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    dropdownIconWrapper: {
        cursor: 'pointer',
        top: '0',
        right: '0',
        color: blueColor[100],
        width: '30px',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight:'10px',
        borderRadius:'5px',
    }
    ,
    activeIconWrapper: {
        transform: 'rotate(180deg)',
        color: 'white',
        backgroundColor: blueColor[100],
    },
    cellWithDropdown: {
        position: 'relative',
    }
};

export default withStyles(style)(HoldWithdrawalPage);
