import {
  getCurrentMonthPeriodQueryString,
  getLastMonthPeriodQueryString,
  getNDaysPeriodQueryString,
} from '@pages/dashboard/utils/dateGetters';

const dashboardPathPrefix = '/app/dashboard';
const reportPath = `${dashboardPathPrefix}/report`;

export const dashboardPaths = {
  indexPath: dashboardPathPrefix,
  reportPath,
  newReportPath: `${reportPath}/new/`,
  customReportPath: `${dashboardPathPrefix}/custom`,
  sevenDaysPostfix: getNDaysPeriodQueryString(7),
  lastMonthPostfix: getLastMonthPeriodQueryString(),
  fourteenDaysPostfix: getNDaysPeriodQueryString(14),
  currentMonthPostfix: getCurrentMonthPeriodQueryString(),
  byDayReportPrefix: `${dashboardPathPrefix}/byDayReport`,
  byTouchReportPrefix: `${dashboardPathPrefix}/byTouchReport`,
  newReportPrefix: `${dashboardPathPrefix}/newReport`,
};
