import clsx from 'clsx';
import {
  Button, CodeIcon, Input, Typography,
} from '@main/lib';
import HelpText from '@pages/entrance/components/HelpText';
import React from 'react';
import { withStyles } from '@material-ui/core';
import IMask from 'imask';
import HeaderWithCircleIcon from '@pages/entrance/components/Registration/components/HeaderWithCircleIcon';
import { Code } from '@material-ui/icons';
import PhoneVerificationV2 from '@components/PhoneVerificationV2';
import {ENTER_THE_CONFIRMATION_CODE, GO_BACK, VERIFICATION_CODE} from "@main/i18n/aliases";
import i18n from "@main/i18n";

const masked = IMask.createMask({
  mask: '+0 000 000 00 00',
  // ...and other options
});

const formatValue = (value) => masked.resolve(value);

const EntranceRegistrationSecondStep = ({
  classes, phoneConfirmationCode, setPhoneConfirmationCode, phoneNumber, timeTillNextCode, submitPhone, submitCode, setStep,
}) => (
  <div>
    <div className={clsx(classes.center, classes.hint)}>
      <HeaderWithCircleIcon
        icon={<CodeIcon />}
        text={(
          <div>
            {i18n.t(ENTER_THE_CONFIRMATION_CODE)}
            <br />
            {' '}
            {formatValue(phoneNumber)}
          </div>
)}
        title={i18n.t(VERIFICATION_CODE)}
      />
      <PhoneVerificationV2
        phoneConfirmationCode={phoneConfirmationCode}
        setPhoneConfirmationCode={setPhoneConfirmationCode}
        timeTillNextCode={timeTillNextCode}
        submitPhone={submitPhone}
        submitCode={submitCode}
      />
      <HelpText linkText={i18n.t(GO_BACK)} className={classes.secondStep__helpText__returnBack} onClick={() => { setStep(0); }} />

    </div>
  </div>
);

const styles = () => ({
  secondStep__step2Buttons__buttonOneMoreTime: {
    paddingLeft: '36px',
    paddingRight: '36px',
    marginRight: '20px',

  },
  element_marginBottom20: {
    marginBottom: '20px',
    width: '100%',
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  hint: {
    marginBottom: '40px',
  },
  secondStep__helpText_code: {
    marginTop: '5px',
  },
  codeInput: {
    marginTop: '20px',
    width: '100%',
    '& div:nth-of-type(2)': {
      width: '100%',
    },
    '& div:nth-of-type(1)': {
      textAlign: 'left',
    },
  },
  secondStep__helpText_countdown: {
    marginTop: '10px',
    width: '100%',
    textAlign: 'left',
  },
  element__horizontallyAlignedButtons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  secondStep__step2Buttons: {
    marginTop: '30px',
  },
  secondStep__step2Buttons__button: {
    width: '190px',
  },
  secondStep__helpText__returnBack: {
    marginTop: '40px',
  },
});

export default withStyles(styles)(EntranceRegistrationSecondStep);
