import React from 'react';
import Message from '@pages/finance/pages/BalancePage/components/Message';
import { withStyles } from '@material-ui/core';
import {
  Table, TableBody, TableCell, TableHead, TableRow,
} from '@main/lib';
import { paymentsMethods } from '@pages/finance/constants/paymentMethods';
import declOfNum from '@pages/finance/pages/utils/declOfNum';
import { ReactComponent as Illustration } from './assets/Illustration.svg';
import {
  COMMISSION_AMOUNT,
  PAYMENT_TERM,
  PAYOUT_METHOD,
  TERMS_AND_PROCEDURE_OF_PAYMENTS,
  TERMS_AND_PROCEDURE_OF_PAYMENTS_DESC, WORKING_DAY, WORKING_DAYS, WORKING_DAYS_2
} from "@main/i18n/aliases";
import i18n from "@main/i18n";

const styles = () => ({
  message: {
    height: '135px',
    marginBottom: '30px',
    marginTop: '0px',
  },
  iconNameCell: {
    '& > div': {
      display: 'flex',
      alignItems: 'center',
    }
  },
  icon: {
    marginRight: '10px',
  },
});

const TermsAndProcedurePaymentsPage = ({ classes }) => (
  <div>
    <Message
      upperText={i18n.t(TERMS_AND_PROCEDURE_OF_PAYMENTS)}
      classes={{ root: classes.message }}
      illustration={<Illustration />}
      withoutCloseIcon
      bottomText={i18n.t(TERMS_AND_PROCEDURE_OF_PAYMENTS_DESC)}
    />
    <Table>
      <TableHead>
        <TableCell align="left">
          {i18n.t(PAYOUT_METHOD)}
        </TableCell>
        <TableCell>
          {i18n.t(PAYMENT_TERM)}
        </TableCell>
        <TableCell>
          {i18n.t(COMMISSION_AMOUNT)}
        </TableCell>
      </TableHead>
      <TableBody>
        {paymentsMethods.map((payment) => (
          <TableRow>
            <TableCell className={classes.iconNameCell} align="left">
              {React.cloneElement(payment.icon, { className: classes.icon })}
              {payment.name}
            </TableCell>
            <TableCell>
              {payment.numOfDays}
              {' '}
              {declOfNum(payment.numOfDays, [i18n.t(WORKING_DAY), i18n.t(WORKING_DAYS), i18n.t(WORKING_DAYS_2)])}
            </TableCell>
            <TableCell>
              {payment.commission}
              {' '}
              %
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </div>
);

export default withStyles(styles)(TermsAndProcedurePaymentsPage);
