import defaultDropdownMapping from '@pages/dashboard/components/FilterModal/constants/defaultDropdownMapping';

const getRequestFiltersPayload = (filterSelectValues, filterInputsValues) => {
  const result = {};
  for (const [key, values] of Object.entries(filterSelectValues)) {
    if (values.length) {
      if (!defaultDropdownMapping[key].inputs) {
        result[key] = { data: values };
      } else {
        for (const value of values) {
          result[value] = { data: [filterInputsValues[value]] };
        }
      }
    }
  }
  return result;
};
export default getRequestFiltersPayload;
