import {
  Button,
  FilledOkIcon,
  greyColor,
  Input,
  Modal,
  redColor,
  Typography,
  typographyDefaultTheme,
} from '@main/lib';
import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { fetchUserInfo } from '@store/actions';
import fetch from '@main/utils/handledFetch';
import clsx from 'clsx';
import { backendURL } from '@main/toRemove';
import { useHistory } from 'react-router';
import {
  APPLICATION_SUCCESSFULLY_CREATED,
  ARE_YOU_REGISTERED_A_AN_INDIVIDUAL_ENTREPRENEUR,
  DO_YOU_HAVE_EXPERIENCE_THROUGH_CPA,
  HOW_DID_YOU_HEAR_ABOUT_LINKSTREAM, HOW_MANY_SUBSCRIBERS,
  REGISTRATION_REQUEST, SEND_APPLICATION,
  WHAT_TYPE_OF_TRAFFIC_DO_YOU_USE
} from "@main/i18n/aliases";
import i18n from "@main/i18n";

const styles = () => ({
  labelStar: {
    '&&': {
      color: redColor[100],
    },
  },
  label: {
    '& span': {
      ...typographyDefaultTheme.typography.root,
      ...typographyDefaultTheme.typography.subtitle,
      ...typographyDefaultTheme.typography.normal,
    },
    marginTop: '20px',
  },
  inputWrapper: {
    marginTop: '40px',
  },
  input: {
    width: '100%',
  },
  description: {
    marginTop: '-10px',
    marginBottom: '20px',
  },
  buttonWrapper: {
    marginTop: '30px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& button': {
      width: '290px',
    },
  },
  textArea: {
    marginBottom: '20px',
    height: 'auto',
  },
  icon: {
    marginTop: '10px',
    marginBottom: '15px',

  },
  successContent: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonChangedPadding: {
    '&>button': {
      paddingLeft: '15px',
      paddingRight: '15px',
    },

  },
  button: {
    width: '290px',
  },
  successText: {
    marginTop: '3px',
    width: '280px',
    textAlign: 'center',
  },
  modalContent: {
    width: '600px',
  },
  modalContentWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  lastSelectItem: {
    '&&>div': {
      width: '100%',
      marginRight: '15px',
      borderTop: '1px solid',
      borderColor: greyColor[10],
    },
    // paddingLeft: '0px',
    // paddingRight: '0px',
    // marginLeft: '15px',
    // marginRight: '15px',
    height: 'fit-content',

    '&>div': {
      '&>div': {
        paddingTop: '24px',
        marginBottom: '14px',

      },
    },
  },
  beforeLastSelectItem: {
    paddingBottom: '16px',
    paddingTop: '14px',
  },
  twoButtonsWrapper: {
    width: '100%',
    marginTop: '27px',
    justifyContent: 'center',
    display: 'flex',
    '&>button': {
      '&:first-of-type': {
        marginRight: '20px',
      },
      '&:last-of-type': {
        padding: '0px',
      },
      width: '290px',
    },

  },

});

const NewUserForm = ({ classes, modalOpen }) => {
  const [finished, setFinished] = useState(false);
  const [from, setFrom] = useState(null);
  const [traffic, setTraffic] = useState(null);
  const [auditory, setAuditory] = useState(null);
  const [experience, setExperience] = useState(null);
  const [selfWork, setSelfWork] = useState(null);
  const history = useHistory();
  const [formIsValid, setFormIsValid] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUserInfo());
  }, []);

  const checkFormIsValid = () =>
      from !== null &&
      traffic !== null &&
      auditory !== null &&
      experience !== null &&
      selfWork !== null;

  const form = {
    from,
    traffic,
    auditory,
    experience,
    selfWork,
  };

  const sendForm = () => {
    const user = localStorage.getItem('user_id');

    fetch(`${backendURL}/api/form?user_id=${user}`, {
      method: 'POST',
      body: JSON.stringify({
        'Откуда Вы узнали о Neometrix?': form.from,
        'Какой вид трафика Вы используете? (предоставьте ссылку на сайт/страницу VK/telegram)':form.traffic,
        'Cколько у вас подписчиков (аудитория)?':form.auditory,
        'Есть ли опыт оказания услуг через CPA сети/платформы?':form.experience,
        'Оформлены ли Вы как индивидуальный предприниматель/самозанятый?':form.selfWork,
      }),
      headers: {
        'Content-type': 'application/json',
      },
    }).then(r => setFinished(true));
  };

  useEffect(() => {
    setFormIsValid(checkFormIsValid());
  }, [from, traffic, auditory, experience,selfWork]);

  const Label = ({ label, withoutStar }) => (
    <div className={classes.label}>
      <span>
        {label}
        {!withoutStar && (
        <span
          className={classes.labelStar}
        >
          {' '}
          *
        </span>
        )}
      </span>
    </div>
  );
  return (
    <Modal
      title={i18n.t(REGISTRATION_REQUEST)}
      open={modalOpen}
      onClose={() => {
        history.push('login');
      }}
    >
      <div className={classes.modalContentWrapper}>
        <div className={classes.modalContent}>
          {!finished
            ? (
              <div>
                <div className={classes.inputWrapper}>
                  <Label label={i18n.t(HOW_DID_YOU_HEAR_ABOUT_LINKSTREAM)} />
                  <Input
                    className={clsx(classes.input, classes.textArea)}
                    multiline
                    value={from}
                    onChange={(event) => {
                      setFrom(event.target.value);
                    }}
                  />
                </div>
                <div className={classes.inputWrapper}>
                  <Label label={i18n.t(WHAT_TYPE_OF_TRAFFIC_DO_YOU_USE)} />
                  <Input
                    className={clsx(classes.input, classes.textArea)}
                    multiline
                    value={traffic}
                    onChange={(event) => {
                      setTraffic(event.target.value);
                    }}
                  />
                </div>
                <div className={classes.inputWrapper}>
                  <Label label={i18n.t(HOW_MANY_SUBSCRIBERS)} />
                  <Input
                    className={clsx(classes.input, classes.textArea)}
                    multiline
                    value={auditory}
                    onChange={(event) => {
                      setAuditory(event.target.value);
                    }}
                  />
                </div>
                <div className={classes.inputWrapper}>
                  <Label label={i18n.t(DO_YOU_HAVE_EXPERIENCE_THROUGH_CPA)} />
                  <Input
                    className={clsx(classes.input, classes.textArea)}
                    multiline
                    value={experience}
                    onChange={(event) => {
                      setExperience(event.target.value);
                    }}
                  />
                </div>
                <div className={classes.inputWrapper}>
                  <Label label= {i18n.t(ARE_YOU_REGISTERED_A_AN_INDIVIDUAL_ENTREPRENEUR)} />
                  <Input
                    className={clsx(classes.input, classes.textArea)}
                    multiline
                    value={selfWork}
                    onChange={(event) => {
                      setSelfWork(event.target.value);
                    }}
                  />
                </div>
                <div className={classes.buttonWrapper}>
                  <Button
                    disabled={!formIsValid}
                    onClick={() => {
                      sendForm();
                    }}
                  >
                    {i18n.t(SEND_APPLICATION)}
                  </Button>
                </div>
              </div>
            ) : (
              <div className={classes.successContent}>
                <FilledOkIcon className={classes.icon} />
                <Typography bold>{i18n.t(APPLICATION_SUCCESSFULLY_CREATED)}</Typography>
              </div>
            )}
        </div>
      </div>
    </Modal>
  );
};

export default withStyles(styles)(NewUserForm);
