"use strict";
/* eslint:disable */
Object.defineProperty(exports, "__esModule", { value: true });
// This file has been generated by https://github.com/reverbnation/protoc-gen-twirp_ts.
// Do not edit.
var twirp_1 = require("./twirp");
var ClientsCountByFiltersRequest = /** @class */ (function () {
    function ClientsCountByFiltersRequest(m) {
        this._json = {};
        if (m) {
            this._json['project'] = m['project'];
            this._json['filters'] = m['filters'];
            this._json['relation'] = m['relation'];
        }
    }
    Object.defineProperty(ClientsCountByFiltersRequest.prototype, "project", {
        // project (project)
        get: function () {
            return this._json.project;
        },
        set: function (value) {
            this._json.project = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ClientsCountByFiltersRequest.prototype, "filters", {
        // filters (filters)
        get: function () {
            return this._json.filters || [];
        },
        set: function (value) {
            this._json.filters = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ClientsCountByFiltersRequest.prototype, "relation", {
        // relation (relation)
        get: function () {
            return this._json.relation;
        },
        set: function (value) {
            this._json.relation = value;
        },
        enumerable: true,
        configurable: true
    });
    ClientsCountByFiltersRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new ClientsCountByFiltersRequest({
            project: m['project'],
            filters: (m['filters'] || []).map(function (v) { return Filter.fromJSON(v); }),
            relation: m['relation']
        });
    };
    ClientsCountByFiltersRequest.prototype.toJSON = function () {
        return this._json;
    };
    return ClientsCountByFiltersRequest;
}());
exports.ClientsCountByFiltersRequest = ClientsCountByFiltersRequest;
var CountResponse = /** @class */ (function () {
    function CountResponse(m) {
        this._json = {};
        if (m) {
            this._json['count'] = m['count'];
        }
    }
    Object.defineProperty(CountResponse.prototype, "count", {
        // count (count)
        get: function () {
            return this._json.count;
        },
        set: function (value) {
            this._json.count = value;
        },
        enumerable: true,
        configurable: true
    });
    CountResponse.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new CountResponse({
            count: m['count']
        });
    };
    CountResponse.prototype.toJSON = function () {
        return this._json;
    };
    return CountResponse;
}());
exports.CountResponse = CountResponse;
var ClientRequest = /** @class */ (function () {
    function ClientRequest(m) {
        this._json = {};
        if (m) {
            this._json['project'] = m['project'];
            this._json['channel'] = m['channel'];
            this._json['id'] = m['id'];
            this._json['platform'] = m['platform'];
            this._json['token'] = m['token'];
        }
    }
    Object.defineProperty(ClientRequest.prototype, "project", {
        // project (project)
        get: function () {
            return this._json.project;
        },
        set: function (value) {
            this._json.project = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ClientRequest.prototype, "channel", {
        // channel (channel)
        get: function () {
            return this._json.channel;
        },
        set: function (value) {
            this._json.channel = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ClientRequest.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ClientRequest.prototype, "platform", {
        // platform (platform)
        get: function () {
            return this._json.platform;
        },
        set: function (value) {
            this._json.platform = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ClientRequest.prototype, "token", {
        // token (token)
        get: function () {
            return this._json.token;
        },
        set: function (value) {
            this._json.token = value;
        },
        enumerable: true,
        configurable: true
    });
    ClientRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new ClientRequest({
            project: m['project'],
            channel: m['channel'],
            id: m['id'],
            platform: m['platform'],
            token: m['token']
        });
    };
    ClientRequest.prototype.toJSON = function () {
        return this._json;
    };
    return ClientRequest;
}());
exports.ClientRequest = ClientRequest;
var UpdateDataRequest_DataEntry = /** @class */ (function () {
    function UpdateDataRequest_DataEntry(m) {
        this._json = {};
        if (m) {
            this._json['key'] = m['key'];
            this._json['value'] = m['value'];
        }
    }
    Object.defineProperty(UpdateDataRequest_DataEntry.prototype, "key", {
        // key (key)
        get: function () {
            return this._json.key;
        },
        set: function (value) {
            this._json.key = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UpdateDataRequest_DataEntry.prototype, "value", {
        // value (value)
        get: function () {
            return this._json.value;
        },
        set: function (value) {
            this._json.value = value;
        },
        enumerable: true,
        configurable: true
    });
    UpdateDataRequest_DataEntry.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new UpdateDataRequest_DataEntry({
            key: m['key'],
            value: m['value']
        });
    };
    UpdateDataRequest_DataEntry.prototype.toJSON = function () {
        return this._json;
    };
    return UpdateDataRequest_DataEntry;
}());
exports.UpdateDataRequest_DataEntry = UpdateDataRequest_DataEntry;
var UpdateDataRequest = /** @class */ (function () {
    function UpdateDataRequest(m) {
        this._json = {};
        if (m) {
            this._json['project'] = m['project'];
            this._json['channel'] = m['channel'];
            this._json['platform'] = m['platform'];
            this._json['id'] = m['id'];
            this._json['data'] = m['data'];
            this._json['del'] = m['del'];
        }
    }
    Object.defineProperty(UpdateDataRequest.prototype, "project", {
        // project (project)
        get: function () {
            return this._json.project;
        },
        set: function (value) {
            this._json.project = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UpdateDataRequest.prototype, "channel", {
        // channel (channel)
        get: function () {
            return this._json.channel;
        },
        set: function (value) {
            this._json.channel = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UpdateDataRequest.prototype, "platform", {
        // platform (platform)
        get: function () {
            return this._json.platform;
        },
        set: function (value) {
            this._json.platform = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UpdateDataRequest.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UpdateDataRequest.prototype, "data", {
        // data (data)
        get: function () {
            return this._json.data || [];
        },
        set: function (value) {
            this._json.data = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UpdateDataRequest.prototype, "del", {
        // del (del)
        get: function () {
            return this._json.del;
        },
        set: function (value) {
            this._json.del = value;
        },
        enumerable: true,
        configurable: true
    });
    UpdateDataRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new UpdateDataRequest({
            project: m['project'],
            channel: m['channel'],
            platform: m['platform'],
            id: m['id'],
            data: (m['data'] || []).map(function (v) { return UpdateDataRequest_DataEntry.fromJSON(v); }),
            del: m['del']
        });
    };
    UpdateDataRequest.prototype.toJSON = function () {
        return this._json;
    };
    return UpdateDataRequest;
}());
exports.UpdateDataRequest = UpdateDataRequest;
var UpdateDataResponse = /** @class */ (function () {
    function UpdateDataResponse(m) {
        this._json = {};
        if (m) {
        }
    }
    UpdateDataResponse.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new UpdateDataResponse({});
    };
    UpdateDataResponse.prototype.toJSON = function () {
        return this._json;
    };
    return UpdateDataResponse;
}());
exports.UpdateDataResponse = UpdateDataResponse;
var CheckClientRequest = /** @class */ (function () {
    function CheckClientRequest(m) {
        this._json = {};
        if (m) {
            this._json['project'] = m['project'];
            this._json['channel'] = m['channel'];
            this._json['id'] = m['id'];
            this._json['conditions'] = m['conditions'];
        }
    }
    Object.defineProperty(CheckClientRequest.prototype, "project", {
        // project (project)
        get: function () {
            return this._json.project;
        },
        set: function (value) {
            this._json.project = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CheckClientRequest.prototype, "channel", {
        // channel (channel)
        get: function () {
            return this._json.channel;
        },
        set: function (value) {
            this._json.channel = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CheckClientRequest.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CheckClientRequest.prototype, "conditions", {
        // conditions (conditions)
        get: function () {
            return this._json.conditions || [];
        },
        set: function (value) {
            this._json.conditions = value;
        },
        enumerable: true,
        configurable: true
    });
    CheckClientRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new CheckClientRequest({
            project: m['project'],
            channel: m['channel'],
            id: m['id'],
            conditions: (m['conditions'] || []).map(function (v) { return Condition.fromJSON(v); })
        });
    };
    CheckClientRequest.prototype.toJSON = function () {
        return this._json;
    };
    return CheckClientRequest;
}());
exports.CheckClientRequest = CheckClientRequest;
var ClientCheckResponse = /** @class */ (function () {
    function ClientCheckResponse(m) {
        this._json = {};
        if (m) {
            this._json['result'] = m['result'];
        }
    }
    Object.defineProperty(ClientCheckResponse.prototype, "result", {
        // result (result)
        get: function () {
            return this._json.result || [];
        },
        set: function (value) {
            this._json.result = value;
        },
        enumerable: true,
        configurable: true
    });
    ClientCheckResponse.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new ClientCheckResponse({
            result: (m['result'] || []).map(function (v) { return Boolean(v); })
        });
    };
    ClientCheckResponse.prototype.toJSON = function () {
        return this._json;
    };
    return ClientCheckResponse;
}());
exports.ClientCheckResponse = ClientCheckResponse;
var ClientsBySegmentRequest = /** @class */ (function () {
    function ClientsBySegmentRequest(m) {
        this._json = {};
        if (m) {
            this._json['segment'] = m['segment'];
            this._json['page'] = m['page'];
            this._json['count'] = m['count'];
        }
    }
    Object.defineProperty(ClientsBySegmentRequest.prototype, "segment", {
        // segment (segment)
        get: function () {
            return this._json.segment;
        },
        set: function (value) {
            this._json.segment = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ClientsBySegmentRequest.prototype, "page", {
        // page (page)
        get: function () {
            return this._json.page;
        },
        set: function (value) {
            this._json.page = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ClientsBySegmentRequest.prototype, "count", {
        // count (count)
        get: function () {
            return this._json.count;
        },
        set: function (value) {
            this._json.count = value;
        },
        enumerable: true,
        configurable: true
    });
    ClientsBySegmentRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new ClientsBySegmentRequest({
            segment: m['segment'],
            page: m['page'],
            count: m['count']
        });
    };
    ClientsBySegmentRequest.prototype.toJSON = function () {
        return this._json;
    };
    return ClientsBySegmentRequest;
}());
exports.ClientsBySegmentRequest = ClientsBySegmentRequest;
var ClientsByFiltersRequest = /** @class */ (function () {
    function ClientsByFiltersRequest(m) {
        this._json = {};
        if (m) {
            this._json['project'] = m['project'];
            this._json['filters'] = m['filters'];
            this._json['relation'] = m['relation'];
            this._json['page'] = m['page'];
            this._json['count'] = m['count'];
        }
    }
    Object.defineProperty(ClientsByFiltersRequest.prototype, "project", {
        // project (project)
        get: function () {
            return this._json.project;
        },
        set: function (value) {
            this._json.project = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ClientsByFiltersRequest.prototype, "filters", {
        // filters (filters)
        get: function () {
            return this._json.filters || [];
        },
        set: function (value) {
            this._json.filters = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ClientsByFiltersRequest.prototype, "relation", {
        // relation (relation)
        get: function () {
            return this._json.relation;
        },
        set: function (value) {
            this._json.relation = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ClientsByFiltersRequest.prototype, "page", {
        // page (page)
        get: function () {
            return this._json.page;
        },
        set: function (value) {
            this._json.page = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ClientsByFiltersRequest.prototype, "count", {
        // count (count)
        get: function () {
            return this._json.count;
        },
        set: function (value) {
            this._json.count = value;
        },
        enumerable: true,
        configurable: true
    });
    ClientsByFiltersRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new ClientsByFiltersRequest({
            project: m['project'],
            filters: (m['filters'] || []).map(function (v) { return Filter.fromJSON(v); }),
            relation: m['relation'],
            page: m['page'],
            count: m['count']
        });
    };
    ClientsByFiltersRequest.prototype.toJSON = function () {
        return this._json;
    };
    return ClientsByFiltersRequest;
}());
exports.ClientsByFiltersRequest = ClientsByFiltersRequest;
var ShortClientsResponse = /** @class */ (function () {
    function ShortClientsResponse(m) {
        this._json = {};
        if (m) {
            this._json['clients'] = m['clients'];
            this._json['count'] = m['count'];
        }
    }
    Object.defineProperty(ShortClientsResponse.prototype, "clients", {
        // clients (clients)
        get: function () {
            return this._json.clients || [];
        },
        set: function (value) {
            this._json.clients = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShortClientsResponse.prototype, "count", {
        // count (count)
        get: function () {
            return this._json.count;
        },
        set: function (value) {
            this._json.count = value;
        },
        enumerable: true,
        configurable: true
    });
    ShortClientsResponse.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new ShortClientsResponse({
            clients: (m['clients'] || []).map(function (v) { return short_client.fromJSON(v); }),
            count: m['count']
        });
    };
    ShortClientsResponse.prototype.toJSON = function () {
        return this._json;
    };
    return ShortClientsResponse;
}());
exports.ShortClientsResponse = ShortClientsResponse;
var Client_DataEntry = /** @class */ (function () {
    function Client_DataEntry(m) {
        this._json = {};
        if (m) {
            this._json['key'] = m['key'];
            this._json['value'] = m['value'];
        }
    }
    Object.defineProperty(Client_DataEntry.prototype, "key", {
        // key (key)
        get: function () {
            return this._json.key;
        },
        set: function (value) {
            this._json.key = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Client_DataEntry.prototype, "value", {
        // value (value)
        get: function () {
            return this._json.value;
        },
        set: function (value) {
            this._json.value = value;
        },
        enumerable: true,
        configurable: true
    });
    Client_DataEntry.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new Client_DataEntry({
            key: m['key'],
            value: ClientField.fromJSON(m['value'])
        });
    };
    Client_DataEntry.prototype.toJSON = function () {
        return this._json;
    };
    return Client_DataEntry;
}());
exports.Client_DataEntry = Client_DataEntry;
var Client = /** @class */ (function () {
    function Client(m) {
        this._json = {};
        if (m) {
            this._json['created'] = m['created'];
            this._json['project'] = m['project'];
            this._json['channel'] = m['channel'];
            this._json['platforms'] = m['platforms'];
            this._json['id'] = m['id'];
            this._json['segment'] = m['segment'];
            this._json['first_name'] = m['firstName'];
            this._json['last_name'] = m['lastName'];
            this._json['maiden_name'] = m['maidenName'];
            this._json['nick_name'] = m['nickName'];
            this._json['sex'] = m['sex'];
            this._json['last_seen'] = m['lastSeen'];
            this._json['bdate'] = m['bdate'];
            this._json['is_closed'] = m['isClosed'];
            this._json['country'] = m['country'];
            this._json['city'] = m['city'];
            this._json['home_town'] = m['homeTown'];
            this._json['photo'] = m['photo'];
            this._json['relation'] = m['relation'];
            this._json['contacts'] = m['contacts'];
            this._json['data'] = m['data'];
        }
    }
    Object.defineProperty(Client.prototype, "created", {
        // created (created)
        get: function () {
            return this._json.created;
        },
        set: function (value) {
            this._json.created = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Client.prototype, "project", {
        // project (project)
        get: function () {
            return this._json.project;
        },
        set: function (value) {
            this._json.project = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Client.prototype, "channel", {
        // channel (channel)
        get: function () {
            return this._json.channel;
        },
        set: function (value) {
            this._json.channel = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Client.prototype, "platforms", {
        // platforms (platforms)
        get: function () {
            return this._json.platforms || [];
        },
        set: function (value) {
            this._json.platforms = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Client.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Client.prototype, "segment", {
        // segment (segment)
        get: function () {
            return this._json.segment;
        },
        set: function (value) {
            this._json.segment = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Client.prototype, "firstName", {
        // firstName (first_name)
        get: function () {
            return this._json.first_name;
        },
        set: function (value) {
            this._json.first_name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Client.prototype, "lastName", {
        // lastName (last_name)
        get: function () {
            return this._json.last_name;
        },
        set: function (value) {
            this._json.last_name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Client.prototype, "maidenName", {
        // maidenName (maiden_name)
        get: function () {
            return this._json.maiden_name;
        },
        set: function (value) {
            this._json.maiden_name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Client.prototype, "nickName", {
        // nickName (nick_name)
        get: function () {
            return this._json.nick_name;
        },
        set: function (value) {
            this._json.nick_name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Client.prototype, "sex", {
        // sex (sex)
        get: function () {
            return this._json.sex;
        },
        set: function (value) {
            this._json.sex = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Client.prototype, "lastSeen", {
        // lastSeen (last_seen)
        get: function () {
            return this._json.last_seen;
        },
        set: function (value) {
            this._json.last_seen = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Client.prototype, "bdate", {
        // bdate (bdate)
        get: function () {
            return this._json.bdate;
        },
        set: function (value) {
            this._json.bdate = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Client.prototype, "isClosed", {
        // isClosed (is_closed)
        get: function () {
            return this._json.is_closed;
        },
        set: function (value) {
            this._json.is_closed = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Client.prototype, "country", {
        // country (country)
        get: function () {
            return this._json.country;
        },
        set: function (value) {
            this._json.country = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Client.prototype, "city", {
        // city (city)
        get: function () {
            return this._json.city;
        },
        set: function (value) {
            this._json.city = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Client.prototype, "homeTown", {
        // homeTown (home_town)
        get: function () {
            return this._json.home_town;
        },
        set: function (value) {
            this._json.home_town = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Client.prototype, "photo", {
        // photo (photo)
        get: function () {
            return this._json.photo;
        },
        set: function (value) {
            this._json.photo = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Client.prototype, "relation", {
        // relation (relation)
        get: function () {
            return this._json.relation;
        },
        set: function (value) {
            this._json.relation = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Client.prototype, "contacts", {
        // contacts (contacts)
        get: function () {
            return this._json.contacts;
        },
        set: function (value) {
            this._json.contacts = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Client.prototype, "data", {
        // data (data)
        get: function () {
            return this._json.data || [];
        },
        set: function (value) {
            this._json.data = value;
        },
        enumerable: true,
        configurable: true
    });
    Client.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new Client({
            created: m['created'],
            project: m['project'],
            channel: m['channel'],
            platforms: (m['platforms'] || []).map(function (v) { return String(v); }),
            id: m['id'],
            segment: m['segment'],
            firstName: m['first_name'],
            lastName: m['last_name'],
            maidenName: m['maiden_name'],
            nickName: m['nick_name'],
            sex: m['sex'],
            lastSeen: LastSeen.fromJSON(m['last_seen']),
            bdate: m['bdate'],
            isClosed: m['is_closed'],
            country: Country.fromJSON(m['country']),
            city: City.fromJSON(m['city']),
            homeTown: City.fromJSON(m['home_town']),
            photo: Photo.fromJSON(m['photo']),
            relation: m['relation'],
            contacts: Contacts.fromJSON(m['contacts']),
            data: (m['data'] || []).map(function (v) { return Client_DataEntry.fromJSON(v); })
        });
    };
    Client.prototype.toJSON = function () {
        return this._json;
    };
    return Client;
}());
exports.Client = Client;
var short_client = /** @class */ (function () {
    function short_client(m) {
        this._json = {};
        if (m) {
            this._json['created'] = m['created'];
            this._json['channel'] = m['channel'];
            this._json['id'] = m['id'];
            this._json['first_name'] = m['firstName'];
            this._json['last_name'] = m['lastName'];
            this._json['avatar'] = m['avatar'];
            this._json['sex'] = m['sex'];
        }
    }
    Object.defineProperty(short_client.prototype, "created", {
        // created (created)
        get: function () {
            return this._json.created;
        },
        set: function (value) {
            this._json.created = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(short_client.prototype, "channel", {
        // channel (channel)
        get: function () {
            return this._json.channel;
        },
        set: function (value) {
            this._json.channel = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(short_client.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(short_client.prototype, "firstName", {
        // firstName (first_name)
        get: function () {
            return this._json.first_name;
        },
        set: function (value) {
            this._json.first_name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(short_client.prototype, "lastName", {
        // lastName (last_name)
        get: function () {
            return this._json.last_name;
        },
        set: function (value) {
            this._json.last_name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(short_client.prototype, "avatar", {
        // avatar (avatar)
        get: function () {
            return this._json.avatar;
        },
        set: function (value) {
            this._json.avatar = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(short_client.prototype, "sex", {
        // sex (sex)
        get: function () {
            return this._json.sex;
        },
        set: function (value) {
            this._json.sex = value;
        },
        enumerable: true,
        configurable: true
    });
    short_client.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new short_client({
            created: m['created'],
            channel: m['channel'],
            id: m['id'],
            firstName: m['first_name'],
            lastName: m['last_name'],
            avatar: m['avatar'],
            sex: m['sex']
        });
    };
    short_client.prototype.toJSON = function () {
        return this._json;
    };
    return short_client;
}());
exports.short_client = short_client;
var ClientField = /** @class */ (function () {
    function ClientField(m) {
        this._json = {};
        if (m) {
            this._json['platform'] = m['platform'];
            this._json['value'] = m['value'];
        }
    }
    Object.defineProperty(ClientField.prototype, "platform", {
        // platform (platform)
        get: function () {
            return this._json.platform;
        },
        set: function (value) {
            this._json.platform = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ClientField.prototype, "value", {
        // value (value)
        get: function () {
            return this._json.value;
        },
        set: function (value) {
            this._json.value = value;
        },
        enumerable: true,
        configurable: true
    });
    ClientField.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new ClientField({
            platform: m['platform'],
            value: m['value']
        });
    };
    ClientField.prototype.toJSON = function () {
        return this._json;
    };
    return ClientField;
}());
exports.ClientField = ClientField;
var LastSeen = /** @class */ (function () {
    function LastSeen(m) {
        this._json = {};
        if (m) {
            this._json['time'] = m['time'];
            this._json['platform'] = m['platform'];
        }
    }
    Object.defineProperty(LastSeen.prototype, "time", {
        // time (time)
        get: function () {
            return this._json.time;
        },
        set: function (value) {
            this._json.time = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LastSeen.prototype, "platform", {
        // platform (platform)
        get: function () {
            return this._json.platform;
        },
        set: function (value) {
            this._json.platform = value;
        },
        enumerable: true,
        configurable: true
    });
    LastSeen.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new LastSeen({
            time: m['time'],
            platform: m['platform']
        });
    };
    LastSeen.prototype.toJSON = function () {
        return this._json;
    };
    return LastSeen;
}());
exports.LastSeen = LastSeen;
var Country = /** @class */ (function () {
    function Country(m) {
        this._json = {};
        if (m) {
            this._json['id'] = m['id'];
            this._json['title'] = m['title'];
        }
    }
    Object.defineProperty(Country.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Country.prototype, "title", {
        // title (title)
        get: function () {
            return this._json.title;
        },
        set: function (value) {
            this._json.title = value;
        },
        enumerable: true,
        configurable: true
    });
    Country.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new Country({
            id: m['id'],
            title: m['title']
        });
    };
    Country.prototype.toJSON = function () {
        return this._json;
    };
    return Country;
}());
exports.Country = Country;
var City = /** @class */ (function () {
    function City(m) {
        this._json = {};
        if (m) {
            this._json['id'] = m['id'];
            this._json['title'] = m['title'];
        }
    }
    Object.defineProperty(City.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(City.prototype, "title", {
        // title (title)
        get: function () {
            return this._json.title;
        },
        set: function (value) {
            this._json.title = value;
        },
        enumerable: true,
        configurable: true
    });
    City.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new City({
            id: m['id'],
            title: m['title']
        });
    };
    City.prototype.toJSON = function () {
        return this._json;
    };
    return City;
}());
exports.City = City;
var Photo = /** @class */ (function () {
    function Photo(m) {
        this._json = {};
        if (m) {
            this._json['origin'] = m['origin'];
            this._json['_50'] = m['50'];
            this._json['_100'] = m['100'];
            this._json['_200'] = m['200'];
            this._json['_400'] = m['400'];
        }
    }
    Object.defineProperty(Photo.prototype, "origin", {
        // origin (origin)
        get: function () {
            return this._json.origin;
        },
        set: function (value) {
            this._json.origin = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Photo.prototype, 50, {
        // 50 (_50)
        get: function () {
            return this._json._50;
        },
        set: function (value) {
            this._json._50 = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Photo.prototype, 100, {
        // 100 (_100)
        get: function () {
            return this._json._100;
        },
        set: function (value) {
            this._json._100 = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Photo.prototype, 200, {
        // 200 (_200)
        get: function () {
            return this._json._200;
        },
        set: function (value) {
            this._json._200 = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Photo.prototype, 400, {
        // 400 (_400)
        get: function () {
            return this._json._400;
        },
        set: function (value) {
            this._json._400 = value;
        },
        enumerable: true,
        configurable: true
    });
    Photo.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new Photo({
            origin: m['origin'],
            50: m['_50'],
            100: m['_100'],
            200: m['_200'],
            400: m['_400']
        });
    };
    Photo.prototype.toJSON = function () {
        return this._json;
    };
    return Photo;
}());
exports.Photo = Photo;
var Contacts = /** @class */ (function () {
    function Contacts(m) {
        this._json = {};
        if (m) {
            this._json['phone'] = m['phone'];
            this._json['email'] = m['email'];
            this._json['site'] = m['site'];
            this._json['skype'] = m['skype'];
            this._json['facebook'] = m['facebook'];
            this._json['twitter'] = m['twitter'];
            this._json['instagram'] = m['instagram'];
            this._json['vk'] = m['vk'];
        }
    }
    Object.defineProperty(Contacts.prototype, "phone", {
        // phone (phone)
        get: function () {
            return this._json.phone;
        },
        set: function (value) {
            this._json.phone = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Contacts.prototype, "email", {
        // email (email)
        get: function () {
            return this._json.email;
        },
        set: function (value) {
            this._json.email = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Contacts.prototype, "site", {
        // site (site)
        get: function () {
            return this._json.site;
        },
        set: function (value) {
            this._json.site = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Contacts.prototype, "skype", {
        // skype (skype)
        get: function () {
            return this._json.skype;
        },
        set: function (value) {
            this._json.skype = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Contacts.prototype, "facebook", {
        // facebook (facebook)
        get: function () {
            return this._json.facebook;
        },
        set: function (value) {
            this._json.facebook = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Contacts.prototype, "twitter", {
        // twitter (twitter)
        get: function () {
            return this._json.twitter;
        },
        set: function (value) {
            this._json.twitter = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Contacts.prototype, "instagram", {
        // instagram (instagram)
        get: function () {
            return this._json.instagram;
        },
        set: function (value) {
            this._json.instagram = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Contacts.prototype, "vk", {
        // vk (vk)
        get: function () {
            return this._json.vk;
        },
        set: function (value) {
            this._json.vk = value;
        },
        enumerable: true,
        configurable: true
    });
    Contacts.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new Contacts({
            phone: m['phone'],
            email: m['email'],
            site: m['site'],
            skype: m['skype'],
            facebook: m['facebook'],
            twitter: m['twitter'],
            instagram: m['instagram'],
            vk: m['vk']
        });
    };
    Contacts.prototype.toJSON = function () {
        return this._json;
    };
    return Contacts;
}());
exports.Contacts = Contacts;
var Condition = /** @class */ (function () {
    function Condition(m) {
        this._json = {};
        if (m) {
            this._json['filters'] = m['filters'];
        }
    }
    Object.defineProperty(Condition.prototype, "filters", {
        // filters (filters)
        get: function () {
            return this._json.filters || [];
        },
        set: function (value) {
            this._json.filters = value;
        },
        enumerable: true,
        configurable: true
    });
    Condition.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new Condition({
            filters: (m['filters'] || []).map(function (v) { return Filter.fromJSON(v); })
        });
    };
    Condition.prototype.toJSON = function () {
        return this._json;
    };
    return Condition;
}());
exports.Condition = Condition;
var Filter = /** @class */ (function () {
    function Filter(m) {
        this._json = {};
        if (m) {
            this._json['field'] = m['field'];
            this._json['operator'] = m['operator'];
            this._json['value'] = m['value'];
        }
    }
    Object.defineProperty(Filter.prototype, "field", {
        // field (field)
        get: function () {
            return this._json.field;
        },
        set: function (value) {
            this._json.field = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Filter.prototype, "operator", {
        // operator (operator)
        get: function () {
            return this._json.operator;
        },
        set: function (value) {
            this._json.operator = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Filter.prototype, "value", {
        // value (value)
        get: function () {
            return this._json.value;
        },
        set: function (value) {
            this._json.value = value;
        },
        enumerable: true,
        configurable: true
    });
    Filter.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new Filter({
            field: m['field'],
            operator: m['operator'],
            value: m['value']
        });
    };
    Filter.prototype.toJSON = function () {
        return this._json;
    };
    return Filter;
}());
exports.Filter = Filter;
var Date = /** @class */ (function () {
    function Date(m) {
        this._json = {};
        if (m) {
            this._json['year'] = m['year'];
            this._json['month'] = m['month'];
            this._json['day'] = m['day'];
        }
    }
    Object.defineProperty(Date.prototype, "year", {
        // year (year)
        get: function () {
            return this._json.year;
        },
        set: function (value) {
            this._json.year = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Date.prototype, "month", {
        // month (month)
        get: function () {
            return this._json.month;
        },
        set: function (value) {
            this._json.month = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Date.prototype, "day", {
        // day (day)
        get: function () {
            return this._json.day;
        },
        set: function (value) {
            this._json.day = value;
        },
        enumerable: true,
        configurable: true
    });
    Date.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new Date({
            year: m['year'],
            month: m['month'],
            day: m['day']
        });
    };
    Date.prototype.toJSON = function () {
        return this._json;
    };
    return Date;
}());
exports.Date = Date;
var ClientsService = /** @class */ (function () {
    function ClientsService(hostname, fetch) {
        this.path = '/twirp/admin.clients.ClientsService/';
        this.hostname = hostname;
        this.fetch = fetch;
    }
    ClientsService.prototype.url = function (name) {
        return this.hostname + this.path + name;
    };
    ClientsService.prototype.getClient = function (params, headers) {
        console.log("get Client start")
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('GetClient'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) {
                console.log(m)
                return Client.fromJSON(m); });
        });
    };
    ClientsService.prototype.updateData = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('UpdateData'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return UpdateDataResponse.fromJSON(m); });
        });
    };
    ClientsService.prototype.checkClient = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('CheckClient'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return ClientCheckResponse.fromJSON(m); });
        });
    };
    ClientsService.prototype.getClientsCountByFilters = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('GetClientsCountByFilters'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return CountResponse.fromJSON(m); });
        });
    };
    ClientsService.prototype.getClientsByFilters = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('GetClientsByFilters'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return ShortClientsResponse.fromJSON(m); });
        });
    };
    ClientsService.prototype.getClientsBySegment = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('GetClientsBySegment'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return ShortClientsResponse.fromJSON(m); });
        });
    };
    return ClientsService;
}());
exports.ClientsService = ClientsService;
