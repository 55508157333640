import React from 'react';
// Material UI components
// Own components
// Package components
import { Typography } from '@material-ui/core';

class Agreement extends React.Component {
  render() {
    return (
      <>
        <h2>Пользовательское соглашение</h2>

        <Typography>
          Настоящее Пользовательское Соглашение (Далее Соглашение) регулирует отношения между ООО "ТРАФИКПАРТНЕРС"
          (далее Affiliate.Neometrix или Администрация) с одной стороны и пользователем сайта с другой.
          Сайт Affiliate.Neometrix не является средством массовой информации.

          Используя сайт, Вы соглашаетесь с условиями данного соглашения.
          Если Вы не согласны с условиями данного соглашения, не используйте сайт Affiliate.Neometrix!
          <Typography variant="h4" style={{ marginTop: '20px', marginBottom: '20px' }}>Права и обязанности сторон</Typography>
          <b>Пользователь имеет право:</b>
          <Typography>
            - осуществлять поиск информации на сайте
            - получать информацию на сайте
            - создавать информацию для сайта
            - требовать от администрации скрытия любой информации о пользователе
            - требовать от администрации скрытия любой информации переданной пользователем сайту
            - использовать информацию сайта в личных некоммерческих целях
          </Typography>

          <b>Администрация имеет право:</b>
          <Typography>
            - по своему усмотрению и необходимости создавать, изменять, отменять правила
            - ограничивать доступ к любой информации на сайте
            - создавать, изменять, удалять информацию
            - удалять учетные записи
            - отказывать в регистрации без объяснения причин
          </Typography>
          <b>Пользователь обязуется:</b>
          <Typography>
            - не распространять информацию, которая направлена на пропаганду войны, разжигание национальной,
            расовой или религиозной ненависти и вражды, а также иной информации, за распространение которой
            предусмотрена уголовная или административная ответственность
            - не нарушать работоспособность сайта
            - не передавать в пользование свою учетную запись и/или логин и пароль своей учетной записи
            третьим
            лицам
          </Typography>
          <b>Администрация обязуется:</b>
          <Typography>
            - поддерживать работоспособность сайта за исключением случаев, когда это невозможно по
            независящим
            от Администрации причинам.
            - осуществлять разностороннюю защиту учетной записи Пользователя
          </Typography>
          <b>Ответственность сторон</b>
          <Typography>
            - администрация не несет никакой ответственности за услуги, предоставляемые третьими лицами
            - в случае возникновения форс-мажорной ситуации (боевые действия, чрезвычайное положение,
            стихийное
            бедствие и т. д.) Администрация не гарантирует сохранность информации, размещённой
            Пользователем, а
            также бесперебойную работу информационного ресурса
          </Typography>
          <Typography variant="h4" style={{ marginTop: '20px', marginBottom: '20px' }}>Условия действия Соглашения</Typography>
          <Typography>
            Данное Соглашение вступает в силу при регистрации на сайте.
            Соглашение перестает действовать при появлении его новой версии.
            Администрация оставляет за собой право в одностороннем порядке изменять данное соглашение по
            своему
            усмотрению.
            Администрация не оповещает пользователей об изменении в Соглашении.
          </Typography>
        </Typography>
      </>
    );
  }
}

export default Agreement;
