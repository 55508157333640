import clsx from 'clsx';
import React from 'react';
import { greyColor } from '@main/lib';
import { withStyles } from '@material-ui/core';

const IconWrapper = ({ children, classes, className }) => (
  <div className={clsx(classes.iconWrapper, classes.element_centralizedHorizontally, classes.element_centralizedVertically, className)}>
    {children}
  </div>
);

const styles = () => ({
  iconWrapper: {
    backgroundColor: greyColor.background,
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    '&>svg': {
      width: '24px',
      height: '24px',
    },
  },
  element_centralizedVertically: {
    display: 'flex',
    alignItems: 'center',
  },
  element_centralizedHorizontally: {
    display: 'flex',
    justifyContent: 'center',
  },
});

export default withStyles(styles)(IconWrapper);
