import React, { useState } from 'react';
import 'react-phone-number-input/style.css';
import images from '@pages/entrance/components/PhoneInput/constants/flags';
import { Input, PhoneIcon } from '@main/lib';
import { AsYouType } from 'libphonenumber-js';
import { InputAdornment, withStyles } from '@material-ui/core';
import IMask from 'imask';

const masked = IMask.createMask({
  mask: '+0 (000) 000 00 00',
  // ...and other options
});

const getCountryByPhone = (value) => {
  const asYouType = new AsYouType();

  asYouType.input(value);
  return asYouType.getNumber()?.country;
};

const formatValue = (value) => masked.resolve(value);
function PhoneInput({
  classes, onChange, value, ...otherProps
}) {
  const [country, setCountry] = useState(getCountryByPhone(`+${value}`) || 'NOCOUNTRY');
  return (
    <div>
      <Input
        placeholder="+7 (495) 000 00 00"
        className={classes.phoneInput}
        icon={<PhoneIcon />}
        startAdornment={<InputAdornment position="start"><div className={classes.flagWrapper}>{images[country]?.() || images.NOCOUNTRY()}</div></InputAdornment>}
        onChange={(event) => {
          const temp = formatValue(event.target.value)
            .replace('+', '')
            .replace('(', '')
            .replace(')', '')
            .replace(/ /g, '');
          const country = getCountryByPhone(event.target.value);
          onChange?.(temp);
          setCountry(country);
        }}
        value={formatValue(value)}
        {...otherProps}
      />
    </div>
  );
}

const style = () => ({
  phoneInput: {
  },
  flagWrapper: {
    borderRight: '1px solid #CACACA',
    paddingRight: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      height: '16px',
      width: '16px',
    },
  },
});

export default withStyles(style)(PhoneInput);
