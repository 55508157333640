import { Formik } from 'formik';
import InputField from '@pages/profile/components/InputField';
import React, { useState } from 'react';
import { withStyles } from '@material-ui/styles';
import SelectInput from '@pages/profile/components/Blocks/SelectInput';
import Button from '@pages/profile/components/Blocks/Button';
import RequestsModal from '@pages/profile/components/Blocks/RequestsModal';
import { backendURL, twirpFetchOptions } from '@main/toRemove';
import * as Sentry from '@sentry/react';
import classnames from 'classnames';
import { showToast } from '@pages/oldOffers/utils';

function Form({
  classes, createPostback, possibleGoals, postback, removePostback, ...props
}) {
  function getLogs(id) {
    return new Promise((resolve, reject) => {
      fetch(
        `${backendURL
        }/twirp/observer.postbacks.PostbacksService/GetLogs `,
        {
          ...twirpFetchOptions,
          body: JSON.stringify({ postback_id: id }),
        },
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          reject(res.statusText);
        })
        .then((res) => {
          res = Object.keys(res).length === 0 ? { logs: [] } : res;
          setPostbackLogs(res.logs);
          resolve();
        })
        .catch((err) => {
          Sentry.captureException(err);
          // showToast('Ошибка при загрузке логов', false);
          showToast('Loading log error', false);
          // setOffers([]);
          reject(err);
        })
        .finally(() => {
        });
    });
  }

  const probableStatus = [
    // { id: 'ALL', name: 'Любые' },
    // { id: 'NEW_CONVERSION', name: 'Создание конверсии' },
    // { id: 'UPDATE_STATUS', name: 'Изменение статуса конверсии' },
    { id: 'ALL', name: 'All' },
    { id: 'NEW_CONVERSION', name: 'Create conversion' },
    { id: 'UPDATE_STATUS', name: 'Change conversion status' },
  ];
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [postbackLogs, setPostbackLogs] = useState([]);

  return (
    <div className={classnames(classes.form, { background: props.background })} key={props.key}>
      <Formik
        initialValues={{
          goal: postback ? postback.handle_events : undefined,
          status: postback ? postback.handle_type : ['ALL'],
          url: postback ? postback.url : '',
        }}
        validate={(values) => ({})}
        onSubmit={(values, { resetForm }) => {
          if (!postback) {
            createPostback(values).then(resetForm());
          }
        }}
      >
        {({
          handleChange, handleSubmit, setFieldValue, values,
        }) => (
          <form onSubmit={handleSubmit}>
            <div style={{ display: 'flex' }}>
              <SelectInput
                disabled={postback && true}
                value={values.goal}
                options={possibleGoals}
                onChange={(value) => {
                  setFieldValue('goal', value);
                }}
                // placeholder="Выбрать цели"
                placeholder="Choose goal"
                className={classes.inputField}
                style={{ width: '500px' }}
                name="goal"
                label="Цель (Goal)"
              />
              <SelectInput
                disabled={postback && true}
                value={values.status}
                options={probableStatus}
                onChange={(value) => {
                  setFieldValue('status', value);
                }}
                className={classes.inputField}
                style={{ width: '269px' }}
                name="status"
                singleSelect
                // label="События"
                label="Events"
              />
              {postback && (
              <Button
                onClick={() => {
                  removePostback(postback.id);
                }}
                className={classes.button}
                style={{ marginTop: '40px' }}
                variant="outlined"
              >
                {/* Удалить */}
                Delete
              </Button>
              )}
            </div>
            <div style={{ paddingRight: '30px' }}>
              <span style={{ display: 'flex', alignItems: 'flex-end' }} className={classes.inputField}>
                <InputField
                  variant="postback"
                  disabled={postback && true}
                  style={{ width: '100%' }}
                  name="url"
                  label="URL"
                />
                {postback && (
                <Button
                  onClick={() => {
                    setModalIsOpen(true);
                    getLogs(postback.id);
                  }}
                  style={{ marginLeft: '30px', width: '170px' }}
                  variant="outlined"
                >
                  {/* Список запросов */}
                  List of requests
                </Button>
                )}
              </span>
            </div>
            {!postback && (
              <Button
                onClick={handleSubmit}
                className={classes.button}
                variant="contained"
              >
                {/* Добавить */}
                Add
              </Button>
            )}
          </form>
        )}
      </Formik>
      <RequestsModal
        postbackLogs={postbackLogs}
        open={modalIsOpen}
        handleClickedToClose={() => {
          setModalIsOpen(false);
        }}
      />
    </div>
  );
}

const styles = {
  inputField: {
    marginLeft: '30px',
    marginTop: '18px',
  },
  inputFields: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    marginLeft: '30px',
    marginTop: '15px',
  },
  form: {
    paddingBottom: '30px',
    '&.background': {
      background: 'linear-gradient(90deg, rgba(248, 248, 248, 0.9) 0%, rgba(248, 248, 248, 0) 100%);',
      border: '1px solid #E6E6E6',
    },
  },
};
export default withStyles(styles)(Form);
