import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useField, useFormikContext } from 'formik';
import MaskedInput from 'react-text-mask';
import { Input } from '@aisales/react-uikit';

export default function InputField({
  className,
  disabled,
  label,
  mask,
  style,
  type, variant,
  ...rest
}) {
  const { submitCount } = useFormikContext();
  const [field, meta, helpers] = useField({ ...rest });
  const classes = useStyles({
    disabled,
    error: submitCount !== 0 && meta.error,
  });

  return (
    <div className={className} style={style}>
      <Typography className={classes.label}>{label}</Typography>
      {mask && (
        <MaskedInput
          showMask
          mask={mask}
          {...field}
          className={classes.input}
          disabled={disabled}
        />
      )}

      {!mask && (
        <Input
          style={variant === 'postback' ? {
            border: disabled ? '1px solid rgb(204, 204, 204) !important' : '1px solid #cccccc',
            fontColor: disabled ? 'rgb(204, 204, 204) !important' : 'rgb(74, 74, 74)',
            fontWeight: 'normal',
          } : {}}
          {...field}
          autoComplete="off"
          className={classes.input}
          disabled={disabled}
          type={type || undefined}
        />
      )}
      {meta.error && submitCount !== 0 && (
        <div style={{ color: 'red' }}>{meta.error}</div>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {},
  label: (props) => ({
    fontSize: '12px',
    lineHeight: '15px',
    fontWeight: 'normal',
    color: props.error ? 'red' : 'rgba(0, 0, 0, 0.67)',
    marginLeft: '2px',
    marginBottom: '6px',
  }),
  input: (props) => ({
    flex: '1 0 100%',

    width: '100%',
    height: '40px',

    background: !props.disabled ? '#FFFFFF' : 'none',
    border: props.error ? '1px solid #ff9900' : '1px solid #E6E6E6',
    boxSizing: 'border-box',
    borderRadius: '5px',
    padding: '0 10px',
    fontWeight: props.disabled ? 'bold' : 'normal',
    outlineColor: 'rgb(180, 180, 180) !important',
  }),
}));
