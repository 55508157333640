import { TickIcon } from '@main/lib';
import React from 'react';

const SuccessMessage = (props) => {
  const { classes } = props;
  return (
    <div className={classes.successMessage}>
      <div className={classes.tickIconWrapperSuccess}>
        <TickIcon className={classes.tickIconSuccess} />
      </div>
    </div>
  );
};

export default SuccessMessage;
