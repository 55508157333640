import fetch from '@main/utils/handledFetch';
import { backendURL, twirpFetchOptions } from '@main/toRemove';
import { showToast } from '@pages/oldOffers/utils';

export const fetchGroupedOffers = async () => {
  try {
    const res = await fetch(`${backendURL}/twirp/cpa.offers.OfferSubcriptionsService/GetManyGrouped`, {
      ...twirpFetchOptions,
      body: '{}',
    });
    let res_json;
    if (res.ok) {
      res_json = await res.json();
    } else {
      throw new Error(res.statusText);
    }
    return res_json.subs;
  } catch (e) {
    showToast('Ошибка при получении списка офферов', false);
  }
};
