import {
  Button, LinkIcon, blueColor, AlertContents,
} from '@main/lib';
import HelpText from '@pages/entrance/components/HelpText';
import WordWithLine from '@pages/entrance/components/Login/components/WordWithLine';
import alternativeEnterInfo from '@pages/entrance/components/Login/constants/alternativeEnterInfo';
import AlternativeEnterButton from '@pages/entrance/components/Login/components/AlternativeEnterIcon';
import FacebookInit from '@pages/entrance/components/Login/components/FacebookInit';
import React from 'react';
import HeaderWithCircleIcon from '@pages/entrance/components/Registration/components/HeaderWithCircleIcon';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';
import checkIfPhoneNumberExists from '@pages/entrance/components/Registration/services/checkIfPhoneNumberExists';
import { toast } from 'react-toastify';
import {CONTINUE, ENTER, GOOGLE_FIELDS, HAVE_ACCOUNT_Q, HAVE_USER_WHIS_PHONE, HELLO_S} from "@main/i18n/aliases";
import i18n from "@main/i18n";

const FirstStep = ({
  isRegisterViaSocialNetwork,
  email,
  name,
  nameEmailPhoneInputs,
  moveToSecondStep,
  refCode,
  classes,
  phoneNumber,
  userType,
}) => (
  <div>
    {(isRegisterViaSocialNetwork && email)
            && (
            <HeaderWithCircleIcon
              icon={<LinkIcon className={classes.firstStep__LinkIcon} />}
              text={i18n.t(GOOGLE_FIELDS)}
              title={`${name}, `+i18n.t(HELLO_S)+`!`}
            />
            )}
    {nameEmailPhoneInputs}
    <div className={classes.center}>
      <Button
        onClick={async () => {
          const status = await checkIfPhoneNumberExists(phoneNumber, userType);
          if (status === 409) {
            toast(<AlertContents severity="error" title={i18n.t(HAVE_USER_WHIS_PHONE)} />);
          } else {
            moveToSecondStep();
          }
        }}
        className={classes.button}
      >
        {i18n.t(CONTINUE)}
      </Button>
      {/*<HelpText*/}
      {/*  detailed*/}
      {/*  className={clsx(classes.firstStep__helpText_privacy, classes.firstStep__helpText)}*/}
      {/*  textBeforeLink="Входя в аккаунт или создавая новый, вы соглашаетесь на "*/}
      {/*  linkText="обработку персональных данных"*/}
      {/*  link="/policy"*/}
      {/*/>*/}
      {/*<WordWithLine word="Или" className={classes.firstStep__wordWithLine} />
      <div className={classes.verticallyAlignedButtons}>
        {Object.values(alternativeEnterInfo).map((info, index) => (
          <AlternativeEnterButton
            mode="Регистрация"
            key={index}
            className={classes.firstStep__alternativeEnterButton}
            icon={info.icon}
            href={info.getHref(refCode, 'register')}
            platformName={info.name}
          />
        ))}
        <FacebookInit />
      </div>*/}
      <HelpText
        className={classes.firstStep__helpText_login}
        textBeforeLink={i18n.t(HAVE_ACCOUNT_Q)+" "}
        linkText={i18n.t(ENTER)}
        link="/login"
      />
    </div>
  </div>
);

const style = () => ({
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  button: {
    width: '100%',
    marginTop: '10px',
  },
  firstStep__helpText_privacy: {
    marginTop: '15px',
    marginBottom: '40px',
  },
  firstStep__LinkIcon: {
    color: blueColor[100],
  },
  firstStep__wordWithLine: {
    marginBottom: '10px',
  },
  firstStep__alternativeEnterButton: {
    marginBottom: '20px',
  },
  firstStep__helpText_login: {
    marginTop: '20px',
  },
  verticallyAlignedButtons: {
    marginTop: '10px',
    width: '100%',
  },
  firstStep__helpText: {

  },
});

export default withStyles(style)(FirstStep);
