import { backendURL, twirpFetchOptions } from '@main/toRemove';
import * as Sentry from '@sentry/react';
import { showToast } from '@pages/oldOffers/utils';
import fetch from '@main/utils/handledFetch';
import moment from 'moment';

export const START_FETCH_PAYMENT_INFO = 'Finance/START_FETCH_PAYMENT_INFO';
export const FETCH_PAYMENT_INFO_SUCCESS = 'Finance/FETCH_PAYMENT_INFO_SUCCESS';
export const FETCH_PAYMENT_INFO_FAILURE = 'Finance/FETCH_PAYMENT_INFO_FAILURE';

export const START_FETCH_TICKETS = 'Finance/START_FETCH_TICKETS';
export const FETCH_TICKETS_SUCCESS = 'Finance/FETCH_TICKETS_SUCCESS';
export const FETCH_TICKETS_FAILURE = 'Finance/FETCH_TICKETS_FAILURE';

export const startFetchPaymentInfo = () => ({
  type: START_FETCH_PAYMENT_INFO,
});

export const fetchPaymentInfoSuccess = (payload) => ({
  type: FETCH_PAYMENT_INFO_SUCCESS,
  payload,
});

export const fetchPaymentInfoFailure = (payload) => ({
  type: FETCH_PAYMENT_INFO_FAILURE,
  payload,
});

export const startFetchTickets = () => ({
  type: START_FETCH_TICKETS,
});

export const fetchTicketsSuccess = (payload) => ({
  type: FETCH_TICKETS_SUCCESS,
  payload,
});

export const fetchTicketsFailure = (payload) => ({
  type: FETCH_TICKETS_FAILURE,
  payload,
});

export const fetchPaymentInfo = () => async (dispatch) => {
  try {
    dispatch(startFetchPaymentInfo());
    const response = await fetch(
      `${backendURL
      }/twirp/cpa.users.BillingInfoService/GetByUser`,
      {
        ...twirpFetchOptions,
        body: '{}',
      },
    );

    if (!response.ok) {
      dispatch(fetchPaymentInfoFailure());
      // throw new Error("Ошибка загрузки способов выплат");
    }

    const json = await response.json();
    const index = json?.response?.findIndex(
            (payment) => payment.is_default
        );
    const payload = {
      selectedPayment: index === -1 ? 0 : index,
      paymentInfo: json.response ? json.response : [],
    };
    dispatch(fetchPaymentInfoSuccess(payload));
  } catch (e) {
    // Sentry.captureException(e);
    // showToast(e.message);
  }
};

export const fetchFinanceTickets = (accountID) => async (dispatch, getState) => {
  let canMakeRequest = true;
  try {
    dispatch(startFetchTickets());
    const resp = await fetch(
      `${backendURL
      }/twirp/cpa.billing.BillingService/GetManyTickets`,
      {
        ...twirpFetchOptions,
        body: JSON.stringify({ account_id: accountID }),
      },
    );

    if (!resp.ok) {
      // throw Error(resp.message);
      dispatch(fetchTicketsFailure());
    }

    const json = await resp.json();
    json.tickets = (json.tickets || []).sort((a, b) => (a.id < b.id ? 1 : a.id === b.id ? 0 : -1));
    for (let i = 0; i < json.tickets.length; i++) {
      json.tickets[i].created_time = moment(
        new Date(json.tickets[i].created_time * 1000),
      ).format('DD.MM.YYYY');
      json.tickets[i].estimate_date = moment(
        new Date(json.tickets[i].estimate_time * 1000),
      ).format('DD.MM.YYYY');
      if (json.tickets[i].status !== 3 && getState().finance.canMakeRequest) {
        canMakeRequest = false;
      }
    }
    const payload = {
      canMakeRequest,
      tickets: json.tickets,
    };

    dispatch(fetchTicketsSuccess(payload));
  } catch (err) {
    Sentry.captureException(err);
    showToast('Ошибка при получении заявок');
  }
};
