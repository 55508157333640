"use strict";
/* eslint:disable */
Object.defineProperty(exports, "__esModule", { value: true });
// This file has been generated by https://github.com/reverbnation/protoc-gen-twirp_ts.
// Do not edit.
var twirp_1 = require("./twirp");
var GetMessagesRequest = /** @class */ (function () {
    function GetMessagesRequest(m) {
        this._json = {};
        if (m) {
            this._json['conv_id'] = m['convId'];
            this._json['timestamp'] = m['timestamp'];
        }
    }
    Object.defineProperty(GetMessagesRequest.prototype, "convId", {
        // convId (conv_id)
        get: function () {
            return this._json.conv_id;
        },
        set: function (value) {
            this._json.conv_id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetMessagesRequest.prototype, "timestamp", {
        // timestamp (timestamp)
        get: function () {
            return this._json.timestamp;
        },
        set: function (value) {
            this._json.timestamp = value;
        },
        enumerable: true,
        configurable: true
    });
    GetMessagesRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new GetMessagesRequest({
            convId: m['conv_id'],
            timestamp: m['timestamp']
        });
    };
    GetMessagesRequest.prototype.toJSON = function () {
        return this._json;
    };
    return GetMessagesRequest;
}());
exports.GetMessagesRequest = GetMessagesRequest;
var GetManyRequest = /** @class */ (function () {
    function GetManyRequest(m) {
        this._json = {};
        if (m) {
            this._json['query'] = m['query'];
            this._json['project'] = m['project'];
            this._json['timestamp'] = m['timestamp'];
            this._json['channel'] = m['channel'];
            this._json['unread'] = m['unread'];
            this._json['task'] = m['task'];
        }
    }
    Object.defineProperty(GetManyRequest.prototype, "query", {
        // query (query)
        get: function () {
            return this._json.query;
        },
        set: function (value) {
            this._json.query = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetManyRequest.prototype, "project", {
        // project (project)
        get: function () {
            return this._json.project;
        },
        set: function (value) {
            this._json.project = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetManyRequest.prototype, "timestamp", {
        // timestamp (timestamp)
        get: function () {
            return this._json.timestamp;
        },
        set: function (value) {
            this._json.timestamp = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetManyRequest.prototype, "channel", {
        // channel (channel)
        get: function () {
            return this._json.channel;
        },
        set: function (value) {
            this._json.channel = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetManyRequest.prototype, "unread", {
        // unread (unread)
        get: function () {
            return this._json.unread;
        },
        set: function (value) {
            this._json.unread = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetManyRequest.prototype, "task", {
        // task (task)
        get: function () {
            return this._json.task;
        },
        set: function (value) {
            this._json.task = value;
        },
        enumerable: true,
        configurable: true
    });
    GetManyRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new GetManyRequest({
            query: m['query'],
            project: m['project'],
            timestamp: m['timestamp'],
            channel: m['channel'],
            unread: m['unread'],
            task: m['task']
        });
    };
    GetManyRequest.prototype.toJSON = function () {
        return this._json;
    };
    return GetManyRequest;
}());
exports.GetManyRequest = GetManyRequest;
var GetManyResponse = /** @class */ (function () {
    function GetManyResponse(m) {
        this._json = {};
        if (m) {
            this._json['conversations'] = m['conversations'];
        }
    }
    Object.defineProperty(GetManyResponse.prototype, "conversations", {
        // conversations (conversations)
        get: function () {
            return this._json.conversations || [];
        },
        set: function (value) {
            this._json.conversations = value;
        },
        enumerable: true,
        configurable: true
    });
    GetManyResponse.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new GetManyResponse({
            conversations: (m['conversations'] || []).map(function (v) { return GetConversationResponse.fromJSON(v); })
        });
    };
    GetManyResponse.prototype.toJSON = function () {
        return this._json;
    };
    return GetManyResponse;
}());
exports.GetManyResponse = GetManyResponse;
var Empty = /** @class */ (function () {
    function Empty(m) {
        this._json = {};
        if (m) {
        }
    }
    Empty.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new Empty({});
    };
    Empty.prototype.toJSON = function () {
        return this._json;
    };
    return Empty;
}());
exports.Empty = Empty;
var SendRequest = /** @class */ (function () {
    function SendRequest(m) {
        this._json = {};
        if (m) {
            this._json['conv_id'] = m['convId'];
            this._json['sender'] = m['sender'];
            this._json['event'] = m['event'];
            this._json['message'] = m['message'];
            this._json['task'] = m['task'];
            this._json['single'] = m['single'];
            this._json['message_buttons'] = m['messageButtons'];
        }
    }
    Object.defineProperty(SendRequest.prototype, "convId", {
        // convId (conv_id)
        get: function () {
            return this._json.conv_id;
        },
        set: function (value) {
            this._json.conv_id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SendRequest.prototype, "sender", {
        // sender (sender)
        get: function () {
            return this._json.sender;
        },
        set: function (value) {
            this._json.sender = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SendRequest.prototype, "event", {
        // event (event)
        get: function () {
            return this._json.event;
        },
        set: function (value) {
            this._json.event = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SendRequest.prototype, "message", {
        // message (message)
        get: function () {
            return this._json.message;
        },
        set: function (value) {
            this._json.message = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SendRequest.prototype, "task", {
        // task (task)
        get: function () {
            return this._json.task;
        },
        set: function (value) {
            this._json.task = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SendRequest.prototype, "single", {
        // single (single)
        get: function () {
            return this._json.single;
        },
        set: function (value) {
            this._json.single = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SendRequest.prototype, "messageButtons", {
        // messageButtons (message_buttons)
        get: function () {
            return this._json.message_buttons;
        },
        set: function (value) {
            this._json.message_buttons = value;
        },
        enumerable: true,
        configurable: true
    });
    SendRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new SendRequest({
            convId: m['conv_id'],
            sender: m['sender'],
            event: m['event'],
            message: MessageEvent.fromJSON(m['message']),
            task: TaskEvent.fromJSON(m['task']),
            single: SingleEvent.fromJSON(m['single']),
            messageButtons: MessageButtonsEvent.fromJSON(m['message_buttons'])
        });
    };
    SendRequest.prototype.toJSON = function () {
        return this._json;
    };
    return SendRequest;
}());
exports.SendRequest = SendRequest;
var SingleEvent = /** @class */ (function () {
    function SingleEvent(m) {
        this._json = {};
        if (m) {
            this._json['url'] = m['url'];
        }
    }
    Object.defineProperty(SingleEvent.prototype, "url", {
        // url (url)
        get: function () {
            return this._json.url;
        },
        set: function (value) {
            this._json.url = value;
        },
        enumerable: true,
        configurable: true
    });
    SingleEvent.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new SingleEvent({
            url: m['url']
        });
    };
    SingleEvent.prototype.toJSON = function () {
        return this._json;
    };
    return SingleEvent;
}());
exports.SingleEvent = SingleEvent;
var SetTaskRequest = /** @class */ (function () {
    function SetTaskRequest(m) {
        this._json = {};
        if (m) {
            this._json['conv_id'] = m['convId'];
            this._json['task'] = m['task'];
        }
    }
    Object.defineProperty(SetTaskRequest.prototype, "convId", {
        // convId (conv_id)
        get: function () {
            return this._json.conv_id;
        },
        set: function (value) {
            this._json.conv_id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SetTaskRequest.prototype, "task", {
        // task (task)
        get: function () {
            return this._json.task;
        },
        set: function (value) {
            this._json.task = value;
        },
        enumerable: true,
        configurable: true
    });
    SetTaskRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new SetTaskRequest({
            convId: m['conv_id'],
            task: m['task']
        });
    };
    SetTaskRequest.prototype.toJSON = function () {
        return this._json;
    };
    return SetTaskRequest;
}());
exports.SetTaskRequest = SetTaskRequest;
var GetConversationRequest = /** @class */ (function () {
    function GetConversationRequest(m) {
        this._json = {};
        if (m) {
            this._json['conv_id'] = m['convId'];
        }
    }
    Object.defineProperty(GetConversationRequest.prototype, "convId", {
        // convId (conv_id)
        get: function () {
            return this._json.conv_id;
        },
        set: function (value) {
            this._json.conv_id = value;
        },
        enumerable: true,
        configurable: true
    });
    GetConversationRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new GetConversationRequest({
            convId: m['conv_id']
        });
    };
    GetConversationRequest.prototype.toJSON = function () {
        return this._json;
    };
    return GetConversationRequest;
}());
exports.GetConversationRequest = GetConversationRequest;
var GetConversationResponse = /** @class */ (function () {
    function GetConversationResponse(m) {
        this._json = {};
        if (m) {
            this._json['id'] = m['id'];
            this._json['user_id'] = m['userId'];
            this._json['group_id'] = m['groupId'];
            this._json['channel'] = m['channel'];
            this._json['project'] = m['project'];
            this._json['unread'] = m['unread'];
            this._json['last_message'] = m['lastMessage'];
            this._json['data'] = m['data'];
            this._json['timestamp'] = m['timestamp'];
            this._json['task'] = m['task'];
        }
    }
    Object.defineProperty(GetConversationResponse.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetConversationResponse.prototype, "userId", {
        // userId (user_id)
        get: function () {
            return this._json.user_id;
        },
        set: function (value) {
            this._json.user_id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetConversationResponse.prototype, "groupId", {
        // groupId (group_id)
        get: function () {
            return this._json.group_id;
        },
        set: function (value) {
            this._json.group_id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetConversationResponse.prototype, "channel", {
        // channel (channel)
        get: function () {
            return this._json.channel;
        },
        set: function (value) {
            this._json.channel = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetConversationResponse.prototype, "project", {
        // project (project)
        get: function () {
            return this._json.project;
        },
        set: function (value) {
            this._json.project = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetConversationResponse.prototype, "unread", {
        // unread (unread)
        get: function () {
            return this._json.unread;
        },
        set: function (value) {
            this._json.unread = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetConversationResponse.prototype, "lastMessage", {
        // lastMessage (last_message)
        get: function () {
            return this._json.last_message;
        },
        set: function (value) {
            this._json.last_message = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetConversationResponse.prototype, "data", {
        // data (data)
        get: function () {
            return this._json.data;
        },
        set: function (value) {
            this._json.data = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetConversationResponse.prototype, "timestamp", {
        // timestamp (timestamp)
        get: function () {
            return this._json.timestamp;
        },
        set: function (value) {
            this._json.timestamp = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GetConversationResponse.prototype, "task", {
        // task (task)
        get: function () {
            return this._json.task;
        },
        set: function (value) {
            this._json.task = value;
        },
        enumerable: true,
        configurable: true
    });
    GetConversationResponse.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new GetConversationResponse({
            id: m['id'],
            userId: m['user_id'],
            groupId: m['group_id'],
            channel: m['channel'],
            project: m['project'],
            unread: m['unread'],
            lastMessage: LM.fromJSON(m['last_message']),
            data: Data.fromJSON(m['data']),
            timestamp: m['timestamp'],
            task: m['task']
        });
    };
    GetConversationResponse.prototype.toJSON = function () {
        return this._json;
    };
    return GetConversationResponse;
}());
exports.GetConversationResponse = GetConversationResponse;
var LM = /** @class */ (function () {
    function LM(m) {
        this._json = {};
        if (m) {
            this._json['event'] = m['event'];
            this._json['from'] = m['from'];
            this._json['message'] = m['message'];
            this._json['task'] = m['task'];
            this._json['single'] = m['single'];
            this._json['message_buttons'] = m['messageButtons'];
        }
    }
    Object.defineProperty(LM.prototype, "event", {
        // event (event)
        get: function () {
            return this._json.event;
        },
        set: function (value) {
            this._json.event = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LM.prototype, "from", {
        // from (from)
        get: function () {
            return this._json.from;
        },
        set: function (value) {
            this._json.from = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LM.prototype, "message", {
        // message (message)
        get: function () {
            return this._json.message;
        },
        set: function (value) {
            this._json.message = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LM.prototype, "task", {
        // task (task)
        get: function () {
            return this._json.task;
        },
        set: function (value) {
            this._json.task = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LM.prototype, "single", {
        // single (single)
        get: function () {
            return this._json.single;
        },
        set: function (value) {
            this._json.single = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LM.prototype, "messageButtons", {
        // messageButtons (message_buttons)
        get: function () {
            return this._json.message_buttons;
        },
        set: function (value) {
            this._json.message_buttons = value;
        },
        enumerable: true,
        configurable: true
    });
    LM.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new LM({
            event: m['event'],
            from: m['from'],
            message: MessageEvent.fromJSON(m['message']),
            task: TaskEvent.fromJSON(m['task']),
            single: SingleEvent.fromJSON(m['single']),
            messageButtons: MessageButtonsEvent.fromJSON(m['message_buttons'])
        });
    };
    LM.prototype.toJSON = function () {
        return this._json;
    };
    return LM;
}());
exports.LM = LM;
var Dialog = /** @class */ (function () {
    function Dialog(m) {
        this._json = {};
        if (m) {
            this._json['data'] = m['data'];
            this._json['messages'] = m['messages'];
        }
    }
    Object.defineProperty(Dialog.prototype, "data", {
        // data (data)
        get: function () {
            return this._json.data;
        },
        set: function (value) {
            this._json.data = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Dialog.prototype, "messages", {
        // messages (messages)
        get: function () {
            return this._json.messages || [];
        },
        set: function (value) {
            this._json.messages = value;
        },
        enumerable: true,
        configurable: true
    });
    Dialog.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new Dialog({
            data: GetConversationResponse.fromJSON(m['data']),
            messages: (m['messages'] || []).map(function (v) { return Message.fromJSON(v); })
        });
    };
    Dialog.prototype.toJSON = function () {
        return this._json;
    };
    return Dialog;
}());
exports.Dialog = Dialog;
var Attachments = /** @class */ (function () {
    function Attachments(m) {
        this._json = {};
        if (m) {
            this._json['images'] = m['images'];
            this._json['sounds'] = m['sounds'];
            this._json['files'] = m['files'];
            this._json['location'] = m['location'];
        }
    }
    Object.defineProperty(Attachments.prototype, "images", {
        // images (images)
        get: function () {
            return this._json.images || [];
        },
        set: function (value) {
            this._json.images = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Attachments.prototype, "sounds", {
        // sounds (sounds)
        get: function () {
            return this._json.sounds || [];
        },
        set: function (value) {
            this._json.sounds = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Attachments.prototype, "files", {
        // files (files)
        get: function () {
            return this._json.files || [];
        },
        set: function (value) {
            this._json.files = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Attachments.prototype, "location", {
        // location (location)
        get: function () {
            return this._json.location;
        },
        set: function (value) {
            this._json.location = value;
        },
        enumerable: true,
        configurable: true
    });
    Attachments.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new Attachments({
            images: (m['images'] || []).map(function (v) { return String(v); }),
            sounds: (m['sounds'] || []).map(function (v) { return String(v); }),
            files: (m['files'] || []).map(function (v) { return String(v); }),
            location: Location.fromJSON(m['location'])
        });
    };
    Attachments.prototype.toJSON = function () {
        return this._json;
    };
    return Attachments;
}());
exports.Attachments = Attachments;
var Location = /** @class */ (function () {
    function Location(m) {
        this._json = {};
        if (m) {
            this._json['latitude'] = m['latitude'];
            this._json['longitude'] = m['longitude'];
        }
    }
    Object.defineProperty(Location.prototype, "latitude", {
        // latitude (latitude)
        get: function () {
            return this._json.latitude;
        },
        set: function (value) {
            this._json.latitude = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Location.prototype, "longitude", {
        // longitude (longitude)
        get: function () {
            return this._json.longitude;
        },
        set: function (value) {
            this._json.longitude = value;
        },
        enumerable: true,
        configurable: true
    });
    Location.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new Location({
            latitude: m['latitude'],
            longitude: m['longitude']
        });
    };
    Location.prototype.toJSON = function () {
        return this._json;
    };
    return Location;
}());
exports.Location = Location;
var Message = /** @class */ (function () {
    function Message(m) {
        this._json = {};
        if (m) {
            this._json['id'] = m['id'];
            this._json['event'] = m['event'];
            this._json['message'] = m['message'];
            this._json['task'] = m['task'];
            this._json['single'] = m['single'];
            this._json['message_buttons'] = m['messageButtons'];
            this._json['from'] = m['from'];
            this._json['conv_id'] = m['convId'];
            this._json['timestamp'] = m['timestamp'];
        }
    }
    Object.defineProperty(Message.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Message.prototype, "event", {
        // event (event)
        get: function () {
            return this._json.event;
        },
        set: function (value) {
            this._json.event = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Message.prototype, "message", {
        // message (message)
        get: function () {
            return this._json.message;
        },
        set: function (value) {
            this._json.message = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Message.prototype, "task", {
        // task (task)
        get: function () {
            return this._json.task;
        },
        set: function (value) {
            this._json.task = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Message.prototype, "single", {
        // single (single)
        get: function () {
            return this._json.single;
        },
        set: function (value) {
            this._json.single = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Message.prototype, "messageButtons", {
        // messageButtons (message_buttons)
        get: function () {
            return this._json.message_buttons;
        },
        set: function (value) {
            this._json.message_buttons = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Message.prototype, "from", {
        // from (from)
        get: function () {
            return this._json.from;
        },
        set: function (value) {
            this._json.from = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Message.prototype, "convId", {
        // convId (conv_id)
        get: function () {
            return this._json.conv_id;
        },
        set: function (value) {
            this._json.conv_id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Message.prototype, "timestamp", {
        // timestamp (timestamp)
        get: function () {
            return this._json.timestamp;
        },
        set: function (value) {
            this._json.timestamp = value;
        },
        enumerable: true,
        configurable: true
    });
    Message.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new Message({
            id: m['id'],
            event: m['event'],
            message: MessageEvent.fromJSON(m['message']),
            task: TaskEvent.fromJSON(m['task']),
            single: SingleEvent.fromJSON(m['single']),
            messageButtons: MessageButtonsEvent.fromJSON(m['message_buttons']),
            from: m['from'],
            convId: m['conv_id'],
            timestamp: m['timestamp']
        });
    };
    Message.prototype.toJSON = function () {
        return this._json;
    };
    return Message;
}());
exports.Message = Message;
var MessageButtonsEvent = /** @class */ (function () {
    function MessageButtonsEvent(m) {
        this._json = {};
        if (m) {
            this._json['text'] = m['text'];
            this._json['parse_mode'] = m['parseMode'];
            this._json['url'] = m['url'];
            this._json['message_id'] = m['messageId'];
            this._json['attachments'] = m['attachments'];
            this._json['buttons'] = m['buttons'];
        }
    }
    Object.defineProperty(MessageButtonsEvent.prototype, "text", {
        // text (text)
        get: function () {
            return this._json.text;
        },
        set: function (value) {
            this._json.text = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MessageButtonsEvent.prototype, "parseMode", {
        // parseMode (parse_mode)
        get: function () {
            return this._json.parse_mode;
        },
        set: function (value) {
            this._json.parse_mode = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MessageButtonsEvent.prototype, "url", {
        // url (url)
        get: function () {
            return this._json.url;
        },
        set: function (value) {
            this._json.url = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MessageButtonsEvent.prototype, "messageId", {
        // messageId (message_id)
        get: function () {
            return this._json.message_id;
        },
        set: function (value) {
            this._json.message_id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MessageButtonsEvent.prototype, "attachments", {
        // attachments (attachments)
        get: function () {
            return this._json.attachments;
        },
        set: function (value) {
            this._json.attachments = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MessageButtonsEvent.prototype, "buttons", {
        // buttons (buttons)
        get: function () {
            return this._json.buttons || [];
        },
        set: function (value) {
            this._json.buttons = value;
        },
        enumerable: true,
        configurable: true
    });
    MessageButtonsEvent.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new MessageButtonsEvent({
            text: m['text'],
            parseMode: m['parse_mode'],
            url: m['url'],
            messageId: m['message_id'],
            attachments: Attachments.fromJSON(m['attachments']),
            buttons: (m['buttons'] || []).map(function (v) { return ButtonsRow.fromJSON(v); })
        });
    };
    MessageButtonsEvent.prototype.toJSON = function () {
        return this._json;
    };
    return MessageButtonsEvent;
}());
exports.MessageButtonsEvent = MessageButtonsEvent;
var ButtonsRow = /** @class */ (function () {
    function ButtonsRow(m) {
        this._json = {};
        if (m) {
            this._json['row'] = m['row'];
        }
    }
    Object.defineProperty(ButtonsRow.prototype, "row", {
        // row (row)
        get: function () {
            return this._json.row || [];
        },
        set: function (value) {
            this._json.row = value;
        },
        enumerable: true,
        configurable: true
    });
    ButtonsRow.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new ButtonsRow({
            row: (m['row'] || []).map(function (v) { return Button.fromJSON(v); })
        });
    };
    ButtonsRow.prototype.toJSON = function () {
        return this._json;
    };
    return ButtonsRow;
}());
exports.ButtonsRow = ButtonsRow;
var Button = /** @class */ (function () {
    function Button(m) {
        this._json = {};
        if (m) {
            this._json['text'] = m['text'];
            this._json['color'] = m['color'];
            this._json['payload'] = m['payload'];
            this._json['request_contact'] = m['requestContact'];
            this._json['request_location'] = m['requestLocation'];
            this._json['url'] = m['url'];
            this._json['call'] = m['call'];
        }
    }
    Object.defineProperty(Button.prototype, "text", {
        // text (text)
        get: function () {
            return this._json.text;
        },
        set: function (value) {
            this._json.text = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Button.prototype, "color", {
        // color (color)
        get: function () {
            return this._json.color;
        },
        set: function (value) {
            this._json.color = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Button.prototype, "payload", {
        // payload (payload)
        get: function () {
            return this._json.payload;
        },
        set: function (value) {
            this._json.payload = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Button.prototype, "requestContact", {
        // requestContact (request_contact)
        get: function () {
            return this._json.request_contact;
        },
        set: function (value) {
            this._json.request_contact = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Button.prototype, "requestLocation", {
        // requestLocation (request_location)
        get: function () {
            return this._json.request_location;
        },
        set: function (value) {
            this._json.request_location = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Button.prototype, "url", {
        // url (url)
        get: function () {
            return this._json.url;
        },
        set: function (value) {
            this._json.url = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Button.prototype, "call", {
        // call (call)
        get: function () {
            return this._json.call;
        },
        set: function (value) {
            this._json.call = value;
        },
        enumerable: true,
        configurable: true
    });
    Button.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new Button({
            text: m['text'],
            color: m['color'],
            payload: m['payload'],
            requestContact: m['request_contact'],
            requestLocation: m['request_location'],
            url: m['url'],
            call: m['call']
        });
    };
    Button.prototype.toJSON = function () {
        return this._json;
    };
    return Button;
}());
exports.Button = Button;
var MessageEvent = /** @class */ (function () {
    function MessageEvent(m) {
        this._json = {};
        if (m) {
            this._json['text'] = m['text'];
            this._json['parse_mode'] = m['parseMode'];
            this._json['url'] = m['url'];
            this._json['message_id'] = m['messageId'];
            this._json['attachments'] = m['attachments'];
        }
    }
    Object.defineProperty(MessageEvent.prototype, "text", {
        // text (text)
        get: function () {
            return this._json.text;
        },
        set: function (value) {
            this._json.text = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MessageEvent.prototype, "parseMode", {
        // parseMode (parse_mode)
        get: function () {
            return this._json.parse_mode;
        },
        set: function (value) {
            this._json.parse_mode = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MessageEvent.prototype, "url", {
        // url (url)
        get: function () {
            return this._json.url;
        },
        set: function (value) {
            this._json.url = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MessageEvent.prototype, "messageId", {
        // messageId (message_id)
        get: function () {
            return this._json.message_id;
        },
        set: function (value) {
            this._json.message_id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MessageEvent.prototype, "attachments", {
        // attachments (attachments)
        get: function () {
            return this._json.attachments;
        },
        set: function (value) {
            this._json.attachments = value;
        },
        enumerable: true,
        configurable: true
    });
    MessageEvent.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new MessageEvent({
            text: m['text'],
            parseMode: m['parse_mode'],
            url: m['url'],
            messageId: m['message_id'],
            attachments: Attachments.fromJSON(m['attachments'])
        });
    };
    MessageEvent.prototype.toJSON = function () {
        return this._json;
    };
    return MessageEvent;
}());
exports.MessageEvent = MessageEvent;
var TaskEvent = /** @class */ (function () {
    function TaskEvent(m) {
        this._json = {};
        if (m) {
            this._json['id'] = m['id'];
            this._json['description'] = m['description'];
            this._json['name'] = m['name'];
        }
    }
    Object.defineProperty(TaskEvent.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaskEvent.prototype, "description", {
        // description (description)
        get: function () {
            return this._json.description;
        },
        set: function (value) {
            this._json.description = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaskEvent.prototype, "name", {
        // name (name)
        get: function () {
            return this._json.name;
        },
        set: function (value) {
            this._json.name = value;
        },
        enumerable: true,
        configurable: true
    });
    TaskEvent.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new TaskEvent({
            id: m['id'],
            description: m['description'],
            name: m['name']
        });
    };
    TaskEvent.prototype.toJSON = function () {
        return this._json;
    };
    return TaskEvent;
}());
exports.TaskEvent = TaskEvent;
var GetMessagesResponse = /** @class */ (function () {
    function GetMessagesResponse(m) {
        this._json = {};
        if (m) {
            this._json['messages'] = m['messages'];
        }
    }
    Object.defineProperty(GetMessagesResponse.prototype, "messages", {
        // messages (messages)
        get: function () {
            return this._json.messages || [];
        },
        set: function (value) {
            this._json.messages = value;
        },
        enumerable: true,
        configurable: true
    });
    GetMessagesResponse.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new GetMessagesResponse({
            messages: (m['messages'] || []).map(function (v) { return Message.fromJSON(v); })
        });
    };
    GetMessagesResponse.prototype.toJSON = function () {
        return this._json;
    };
    return GetMessagesResponse;
}());
exports.GetMessagesResponse = GetMessagesResponse;
var Data = /** @class */ (function () {
    function Data(m) {
        this._json = {};
        if (m) {
            this._json['name'] = m['name'];
            this._json['photo'] = m['photo'];
        }
    }
    Object.defineProperty(Data.prototype, "name", {
        // name (name)
        get: function () {
            return this._json.name;
        },
        set: function (value) {
            this._json.name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Data.prototype, "photo", {
        // photo (photo)
        get: function () {
            return this._json.photo;
        },
        set: function (value) {
            this._json.photo = value;
        },
        enumerable: true,
        configurable: true
    });
    Data.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new Data({
            name: m['name'],
            photo: m['photo']
        });
    };
    Data.prototype.toJSON = function () {
        return this._json;
    };
    return Data;
}());
exports.Data = Data;
var ConversationsService = /** @class */ (function () {
    function ConversationsService(hostname, fetch) {
        this.path = '/twirp/admin.messenger.ConversationsService/';
        this.hostname = hostname;
        this.fetch = fetch;
    }
    ConversationsService.prototype.url = function (name) {
        return this.hostname + this.path + name;
    };
    ConversationsService.prototype.getConversation = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('GetConversation'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return GetConversationResponse.fromJSON(m); });
        });
    };
    ConversationsService.prototype.getMany = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('GetMany'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return GetManyResponse.fromJSON(m); });
        });
    };
    ConversationsService.prototype.getDialog = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('GetDialog'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return Dialog.fromJSON(m); });
        });
    };
    ConversationsService.prototype.setTask = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('SetTask'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return Empty.fromJSON(m); });
        });
    };
    ConversationsService.prototype.resetTask = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('ResetTask'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return Empty.fromJSON(m); });
        });
    };
    ConversationsService.prototype.send = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('Send'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return Empty.fromJSON(m); });
        });
    };
    ConversationsService.prototype.getMessages = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('GetMessages'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return GetMessagesResponse.fromJSON(m); });
        });
    };
    return ConversationsService;
}());
exports.ConversationsService = ConversationsService;
