import { backendURL, twirpFetchOptions } from '@main/toRemove';

const checkIfPhoneNumberExists = async (phoneNumber, userType) => {
  const response = await fetch(
    `${backendURL}/api/register/check`,
    {
      ...twirpFetchOptions,
      body: JSON.stringify({
        number: phoneNumber,
        user_type: userType,
      }),
    },
  );
  return response.status;
};
export default checkIfPhoneNumberExists;
