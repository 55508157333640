export const getUnixPeriodWithinNLastDays = (days) => {
  const from = new Date();
  const to = new Date();
  to.setHours(23, 59, 59, 999);
  from.setDate(to.getDate() - days + 1);
  from.setHours(0, 0, 0, 0);
  return {
    from: getUnixDate(from),
    to: getUnixDate(to),
  };
};

export const getLastMonthPeriod = () => {
  const days = getDaysNumInLastMonth();
  const period = getUnixPeriodWithinNLastDays(days);
  return period;
};

export const getCurrentMonthPeriod = () => {
  const days = getDaysFromCurrentMonthStart();
  const period = getUnixPeriodWithinNLastDays(days);
  return period;
};

export const getUnixDate = (date) => Math.floor(date?.getTime() / 1000);

export const getDaysNumInLastMonth = () => {
  const dt = new Date();
  let month = dt.getMonth() - 1;
  if (month - 1 < 0) {
    month = 11;
  }
  const year = dt.getFullYear();
  return new Date(year, month, 0).getDate();
};

export const getDaysFromCurrentMonthStart = () => new Date().getDate();

export const getQueryStringFromPeriod = ({ from, to }) => `date={"from":${from},"to":${to}}`;

export const getLastMonthPeriodQueryString = () => {
  const days = getDaysNumInLastMonth();
  const period = getUnixPeriodWithinNLastDays(days);
  return getQueryStringFromPeriod(period);
};

export const getCurrentMonthPeriodQueryString = () => {
  const days = getDaysFromCurrentMonthStart();
  const period = getUnixPeriodWithinNLastDays(days);
  return getQueryStringFromPeriod(period);
};

export const getNDaysPeriodQueryString = (n) => {
  const period = getUnixPeriodWithinNLastDays(n);
  return getQueryStringFromPeriod(period);
};
