import React from 'react';
import { withStyles } from '@material-ui/styles';
import {
  Radio, Table, TableBody, TableCell, TableHead, TableRow,
} from '@main/lib';

import { NAME } from '@main/i18n/aliases';
import { useTranslation } from 'react-i18next';

const getId = () => {
  let id = window.location.pathname.match(/\d+/);
  id = id && id[0];
  return id;
};

const styles = () => ({
  root: {
    maxHeight: '333px',
    overflowY: 'scroll',
  },
  number: {
    width: '50px',
  },
  logo: {
    width: '70px',
  },
  name: {
    width: '250px',
  },
  id: {
    width: '120px',
  },
  add: {
    width: '100px',
  },
});
const SocialNetworksTable = ({
  classes, filterRegex, groupToAdd, groups, onChangeGroup,
}) => {
  const { t } = useTranslation();
  const list = groups;

  return (
    <div>
      <Table className={classes.root}>
        <TableHead>
          <TableCell classes={{ root: classes.number }}>
            №
          </TableCell>
          <TableCell className={classes.logo}>
            {/* Лого */}
            Logo
          </TableCell>
          <TableCell align="left" className={classes.name}>
            {t(NAME)}
          </TableCell>
          <TableCell align="left" className={classes.id}>
            ID
          </TableCell>
          <TableCell className={classes.add}>
            {/* Добавить */}
            Add
          </TableCell>
        </TableHead>
        <TableBody>
          {list
                        && list.items
                            ?.filter((group) => (group?.name + group?.id).match(
                              filterRegex,
                            )
                            )
                            .map((val, i) => (
                              <TableRow key={`group${i + 1}`}>
                                <TableCell>
                                  {i + 1}
                                </TableCell>
                                <TableCell>
                                  <img
                                    src={val?.photo_50}
                                    width={28}
                                  />
                                </TableCell>
                                <TableCell align="left">
                                  <div>

                                    <span
                                      className={
                                                    classes.name
                                                }
                                      dangerouslySetInnerHTML={{
                                        __html:
                                                        filterRegex
                                                            ?.source
                                                        !== '()'
                                                          ? val?.name?.replace(
                                                            filterRegex,
                                                            `<span class="${classes.searchEntry}">$1</span>`
                                                            )
                                                          : val.name,
                                      }}
                                    />
                                  </div>
                                </TableCell>
                                <TableCell align="left">
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html:
                                                        filterRegex
                                                            ?.source !== '()'
                                                          ? String(
                                                            val?.id,
                                                          ).replace(
                                                            filterRegex,
                                                            `<span class="${classes.searchEntry}">$1</span>`,
                                                          )
                                                          : val?.id,
                                    }}
                                  />
                                </TableCell>
                                <TableCell light center>
                                  <Radio
                                    checked={groupToAdd?.selectedGroup?.id === val.id}
                                    onClick={() => {
                                      onChangeGroup({
                                        selectedGroup: val,
                                        uri: val?.screen_name,
                                        groupId: val?.id,
                                        lang: 0,
                                        country: 0,
                                        image: val?.image,
                                        name: val?.name,
                                        access_token: val?.access_token,
                                      });
                                      // this.getGroupToken(val)
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
        </TableBody>
      </Table>

    </div>
  );
};

export default withStyles(styles)(SocialNetworksTable);
