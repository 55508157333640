import { withStyles } from '@material-ui/core';
import IconWrapper from '@pages/entrance/components/Registration/components/IconWrapper';
import { Typography } from '@main/lib';
import React from 'react';

const HeaderWithCircleIcon = (({
  icon, text, title, classes,
}) => (
  <div className={classes.headerWithCircleIcon}>
    <IconWrapper className={classes.element_marginBottom20}>
      {icon}
    </IconWrapper>
    <Typography bold>
      {title}
    </Typography>
    <div className={classes.headerWithCircleIcon__text}>
      {text}
    </div>
  </div>
));

const headerWithCircleIconStyle = () => ({
  headerWithCircleIcon: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headerWithCircleIcon__text: {
    textAlign: 'center',
    marginTop: '5px',
  },
  element_marginBottom20: {
    marginBottom: '20px',
  },
});

export default withStyles(headerWithCircleIconStyle)(HeaderWithCircleIcon);
