import { FINISH_FETCH_BANNERS, START_FETCH_BANNERS } from '@store/actions';

const initialState = {
  banners: {},
  bannersAreLoading: false,
};
export default function BannersReducer(state = initialState, { type, payload }) {
  switch (type) {
    case (START_FETCH_BANNERS):
      return {
        ...state,
        bannersAreLoading: true,
      };
    case (FINISH_FETCH_BANNERS):
      return {
        ...state,
        bannersAreLoading: false,
        banners: payload,
      };
    default:
      return state;
  }
}
