import { PaymentBankCardIcon } from '@main/lib';
import webmoneyIcon from '@pages/finance/assets/payments/webmoney.png';
import React from 'react';

export const paymentMapping = {
  CARD: {
    name: 'Банковская карта',
    icon: <PaymentBankCardIcon />,
  },
  WEBMONEY: {
    name: 'Webmoney',
    icon: <img src={webmoneyIcon} style={{ height: '15px' }} />,
  }
};
