import React from 'react';

function importAll(r) {
  return r.keys().map(r);
}
const changeName = (name) => {
  const temp = name.split('/');
  const temp1 = temp[temp.length - 1].split('.');
  return temp1[0].toUpperCase();
};

const renameObjectKeys = (imagesObject) => {
  const newImagesObject = {};
  for (const [key, value] of Object.entries(imagesObject)) {
    newImagesObject[changeName(imagesObject[key].default)] = (props) => <img src={value.default} alt={key} />;

    if (changeName(imagesObject[key].default) === 'NOCOUNTRY') {

    }
  }
  return newImagesObject;
};

const images = renameObjectKeys(importAll(require.context('src/pages/entrance/components/PhoneInput/assets/flags', false, /\.(png|jpe?g|svg)$/)));

export default images;
