import React, { useEffect } from 'react';
import { useField } from 'formik';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

import { ExpansionList, ListElem } from '@aisales/react-uikit';

/**
 *
 * @param {Array<Object>} options. Array of objects of options. Each object consists of 2 params: "value" and "label"
 */
export default function SelectField({
  label, name, options, ...rest
}) {
  const [field, meta, helpers] = useField({ name, ...rest });
  const classes = useStyles();

  useEffect(() => {
    helpers.setValue(options.length !== 0 ? options[0].value : '');
  }, []);

  const currentOption = options && options.find((el) => el.value === field.value);
  const textValue = currentOption && currentOption.label;

  return (
    <div {...rest}>
      <Typography className={classes.label}>{label}</Typography>
      <ExpansionList
        closeOnClick
        maxHeight="300px"
        textValue={textValue}
        style={{ width: '100%' }}
      >
        {options
                    && options.map(({ label, value }) => (
                      <ListElem onClick={() => helpers.setValue(value)}>
                        {label}
                      </ListElem>
                    ))}
      </ExpansionList>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '12px',
    lineHeight: '15px',
    fontWeight: 'normal',
    color: 'rgba(0, 0, 0, 0.67)',
    marginLeft: '2px',
    marginBottom: '6px',
  },
}));
