import {
  blueColor,
  Modal,
  Button,
  greenColor,
  greyColor,
  RadioGroup,
  Select,
  SelectItem,
  Stepper,
  TextWithIcon,
  Typography,
} from '@main/lib';
import { withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import MonetizationItem from '@pages/offers/components/RadioItem';
import SuccessMessage from '@pages/offers/components/OfferConnect/components/Stepper/components/SuccessMessage';
import { channels } from '@pages/channels/constants';
import {useTranslation} from "react-i18next";
import {
  CHOOSE_A_MONETIZATION_METHOD_FOR_THIS_CHANNEL,
  CHOOSE_THE_RIGHT_MESSENGER, CONNECT_ANOTHER_CHANNEL, CONNECT_CHANNEL, CONNECTING_CHANNELS,
  COST_PER_ACTION, GO_TO_CHANNELS_PAGE, ON_THE_CHANNELS_PAGE_,
  REVENUE_SHARE,
  REVENUE_SHARE_DESC, YOUR_CHANNEL_HAS_BEEN_SUCCESSFULLY_CONNECTED
} from "@main/i18n/aliases";

const style = () => ({
  root: {
    '&&&&&:focus': {
      outline: 'none',
    },
    marginTop: '30px',
    width: '800px',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '30px',
    boxSizing: 'border-box',
    position: 'relative',
    boxShadow: '0px 2px 20px rgba(0, 36, 70, 0.07)',
    borderRadius: '5px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '30px',
  },
  buttonAddChat: {
    marginTop: '30px',
    width: '290px',
  },
  input: {
    '&&&&': {
      width: '600px',
    },
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },

  tickIconWrapperSuccess: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: greenColor[100],
  },
  tickIconSuccess: {
    width: '14px',
    height: '10px',
    color: 'white',
  },
  successMessage: {
    marginBottom: '15px',
  },
  bottom: {
    marginTop: '40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  bottomButton: {
    '&:nth-child(1)': {
      marginRight: '20px',
      backgroundColor: blueColor[30],
      '& span': {
        color: greyColor[100],
      },

    },
  },
  successBottomSign: {
    width: '333px',
    textAlign: 'center',
    marginBottom: '30px',
  },
  stepper: {
    marginRight: '53px',
    width: '670px',
  },
  closeIcon: {
    cursor: 'pointer',
    position: 'absolute',
    height: '13.13px',
    width: '13.13px',
    top: '33.75px',
    right: '33.12px',
  },
  icon: {
    width: '16px',
    height: '16px',
  },
  modal: {
    overflow: 'scroll',
  },
  messengerChoice: {},
});

/**
 * TODO: available_channels
 */


const OfferStepper = (props) => {
  const history = useHistory();
  const [openMonetizationDescription, setOpenMonetizationDescription] = useState(-1);
  const [activeStep, setActiveStep] = useState(1);
  const [chosenMonetization, setChosenMonetization] = useState(null);
  const [finished, setFinished] = useState(false);
  const {t} = useTranslation()

  const radioValues = [{
    id: 'cpa',
    name: t(COST_PER_ACTION),
    description: t(COST_PER_ACTION),
  }, {
    id: 'revshare',
    name: t(REVENUE_SHARE),
    description: t(REVENUE_SHARE_DESC),
  }];

  const {
    chosenChannel,
    classes,
    error,
    groupToAdd,
    monetizationStepIdx = 3,
    offer,
    onClose,
    onFinish,
    open,
    setChosenChannel,
    specialSteps,
  } = props;

  useEffect(() => {
    if (chosenChannel) {
      setActiveStep(2);
    }
  },
  []);


  const steps = [{
    title: t(CHOOSE_THE_RIGHT_MESSENGER),
    component:
  <Select
    className={classes.messengerChoice}
    renderValue={() => (chosenChannel ? channels[chosenChannel]?.name : t(CHOOSE_THE_RIGHT_MESSENGER))}
    onChange={(event) => {
      setChosenChannel(event.target.value);
      setActiveStep(2);
    }}
    classes={{ inputRoot: classes.input }}
    value={!chosenChannel ? 'muiselectsucks' : chosenChannel}
  >
    {offer.available_channels.map((name) => (
      <SelectItem value={name}>
        <TextWithIcon
          iconFirst
          icon={React.cloneElement(channels[name]?.icon, { className: classes.icon })}
          label={channels[name]?.name}
        />
      </SelectItem>

    ))}
  </Select>,
  },
  ...specialSteps({ currentStep: activeStep, setStep: setActiveStep, finished }),
  {
    title: t(CHOOSE_A_MONETIZATION_METHOD_FOR_THIS_CHANNEL),
    component:
  <div>
    {' '}
    {activeStep === monetizationStepIdx
                && (
                <div>
                  <RadioGroup onChange={(event) => { setChosenMonetization(event.target.value); }} aria-label="gender" name="gender1">
                    {radioValues.map((radioValue, index) => (
                      <div>
                        <MonetizationItem
                          onChangeOpen={(open) => {
                            if (open) {
                              setOpenMonetizationDescription(index);
                            } else {
                              setOpenMonetizationDescription(-1);
                            }
                          }}
                          opened={openMonetizationDescription === index}
                          radioValue={radioValue}
                          withoutOverflow
                        />
                      </div>
                    ))}
                  </RadioGroup>

                  <div className={classes.buttonWrapper}>
                    <Button
                      disabled={!chosenMonetization || error}
                      onClick={() => {
                        onFinish({ group: groupToAdd, monetization: chosenMonetization }).then((success) => {
                          if (success) {
                            setFinished(true);
                          }
                        });
                      }}
                      classes={{ root: classes.buttonAddChat }}
                    >
                      {t(CONNECT_CHANNEL)}
                    </Button>
                  </div>
                </div>
                )}
  </div>,
  },
  ];

  const bottom = (
    <div className={classes.bottom}>
      <SuccessMessage classes={classes} />
      <Typography bold>{t(YOUR_CHANNEL_HAS_BEEN_SUCCESSFULLY_CONNECTED)}</Typography>
      <Typography variant="common" className={classes.successBottomSign}>
        {' '}
        {t(ON_THE_CHANNELS_PAGE_)}
      </Typography>
      <div>
        <Button
          onClick={() => {
            history.push('/app/channels');
          }}
          className={classes.bottomButton}
        >
          {t(GO_TO_CHANNELS_PAGE)}
        </Button>
        <Button
          className={classes.bottomButton}
          onClick={() => {
            setChosenChannel('');
          }}
        >
          {t(CONNECT_ANOTHER_CHANNEL)}
        </Button>
      </div>
    </div>
  );
  return (
    <Modal
      className={classes.modal}
      open={open}
      title={t(CONNECTING_CHANNELS)}
      onClose={() => { onClose(); setActiveStep(0); setChosenChannel(''); }}
    >
      {!finished ? (
        <Stepper
          className={classes.stepper}
          steps={steps}
          activeStep={activeStep}
        />
      ) : bottom}
    </Modal>
  );
};
export default withStyles(style)(OfferStepper);
