import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Loader } from '@aisales/react-uikit';
import { useHistory } from 'react-router-dom';
import { showToast } from '@components/utils';
import { backendURL, twirpFetchOptions } from '@main/toRemove';
import { fetchOffers } from '@store/actions';
import {
  BackIcon,
  blueColor,
  Button,
  FilledHelpIcon,
  Tooltip,
  Typography,
} from '@main/lib';
import OfferHeader from '@pages/offers/components/Offer/components/Header';
import OfferStepper from '@pages/offers/components/Offer/components/Stepper';
import ChannelsLine from '@pages/offers/components/ChannelsLine';
import OfferConnect from '@pages/offers/components/OfferConnect';
import {
  AVAILABLE_MESSENGERS,
  BACK, CONNECT_OFFER,
  COST_PER_ACTION,
  COST_PER_ACTION_DESC,
  ERROR, MONETIZATION_OPTIONS, OFFER_DESC, PAYOUTS, REGISTRATION,
  REVENUE_SHARE,
  REVENUE_SHARE_DESC
} from "@main/i18n/aliases";

function Offer({
  getList,
  loading
}) {
  const temp = window?.location?.pathname?.replace('/app/offers/', '').split('/');
  const pathId = Number(temp[temp.findIndex((value) => value === 'all-offers' || value === 'favorites') + 1]);
  const history = useHistory();
  const classes = useStyles();
  const [offer, setOffer] = useState(null);
  const [offerInfo, setOfferInfo] = useState(null);
  const id = pathId;
  const isConnectPage = window?.location?.pathname.includes('connect');
  const dispatch = useDispatch();
  const offers = useSelector((state) => state?.offers?.offers);
  const { t } = useTranslation();

  useEffect(() => {
    getList();
    dispatch(fetchOffers);
  }, []);

  useEffect(() => {
    if (!id) {
      return;
    }

    async function fetchOfferInfo() {
      try {
        // setLoading(true);
        const response = await fetch(`${backendURL}/twirp/cpa.offers.OffersManagementService/GetOffer`,
          {
            ...twirpFetchOptions,
            body: JSON.stringify({
              id,
            }),
          },
        );

        if (!response.ok) {
          throw new Error(response);
        }

        const json = await response.json();
        const formattedArray = [];
        json.offer.events.forEach((event) => {
          const foundIndex = formattedArray.findIndex((formattedElement) => formattedElement.name === event.name);
          if (foundIndex !== -1) {
            formattedArray[foundIndex].channels.push(event.channel);
            formattedArray[foundIndex].amounts.push(event.amount);
          } else {
            formattedArray.push({
              name: event.name,
              channels: [event.channel],
              amounts: [event.amount],
              currency: event.currency,
              description: event.description,
              holdDays: event.hold_days,
            });
          }
        });
        json.offer.events = formattedArray;
        setOfferInfo(json.offer);
      } catch (e) {
        Sentry.captureException(e.message);
        showToast(t(ERROR));
      } finally {
        // setLoading(false);
      }
    }

    fetchOfferInfo();
  }, []);

  const isAuthenticated = useSelector((state) => state.login.isAuthenticated);

  useEffect(() => {

  }, [offerInfo]);

  const monetizationOptions = [{
    name: t(COST_PER_ACTION),
    description: t(COST_PER_ACTION_DESC),
  }, {
    name: t(REVENUE_SHARE),
    description: t(REVENUE_SHARE_DESC)
  }];
  if (offerInfo) {
    return (
      <div className={classes.offer}>
        <OfferConnect
          offer={offerInfo}
          open={isConnectPage}
          onClose={() => {
            history.push(window?.location?.pathname?.replace('/connect', ''));
          }}
        />
        <div
          className={classes.backButton}
          onClick={() => {
            const temp = window.location.pathname.split('/');
            const temp1 = window.location.pathname.split('/')
              .slice(0, temp.length - 1)
              .join('/');
            history.push(temp1);
          }}
        >
          <BackIcon/>
          {' '}
          <p>{t(BACK)}</p>
        </div>
        <OfferHeader offerInfo={offerInfo} icon={offerInfo.icon}/>
        <div className={classes.content}>
          <div className={classes.leftPart}>
            <Typography variant="subtitle">{t(PAYOUTS)}</Typography>
            <OfferStepper className={classes.offerStepper} offerInfo={offerInfo}/>
            <div className={classes.connectButtonWrapper}>

              {
                isAuthenticated ? (
                  <Button
                    color="primary"
                    onClick={() => history.push(`${window.location.pathname}/connect`)}
                  >
                    {t(CONNECT_OFFER)}
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    onClick={() => history.push('/register')}
                  >
                    {t(REGISTRATION)}
                  </Button>
                )
              }

            </div>
          </div>
          <div>
            <div>
              <div>
                <Typography variant="subtitle">{t(OFFER_DESC)}</Typography>
              </div>
              <div className={classes.description} dangerouslySetInnerHTML={{ __html: offerInfo.description }} />
            </div>
            <div>
              <div className={classes.subtitle}>
                <Typography variant="subtitle">{t(MONETIZATION_OPTIONS)}</Typography>
              </div>
              {monetizationOptions.map(option => <div className={classes.monetizationOption}>
                <span>{option.name}</span>
                <Tooltip text={option.description}>
                  <div>
                    <FilledHelpIcon
                      className={classes.tooltipIcon}
                      ellipseColor="#CACACA"
                      questionMarkColor="white"
                    />
                  </div>
                </Tooltip>
              </div>)}
            </div>
            <div>
              <div className={classes.title}>
                <Typography variant="subtitle">{t(AVAILABLE_MESSENGERS)}</Typography>
              </div>
              <ChannelsLine isBig channels={offerInfo.available_channels}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <div>{loading && <Loader/>}</div>;
}

const useStyles = makeStyles((theme) => ({
  monetizationOption: {
    display: 'flex',
    marginBottom: '10px',
    '&:last-child': {
      marginBottom: '20px'
    }
  },
  tooltipIcon: {
    verticalAlign: 'middle',
    marginLeft: '5px',
    cursor: 'pointer',
    height: '14px',
    width: '14px',
    '& > ellipse': {
      transition: 'fill .2s'
    },
    '&:hover > ellipse': {
      fill: '#0486FE'
    }
  },
  subtitle: {
    marginBottom: '10px',
    '& > span': {
      fontSize: '14px'
    }
  },
  offer: {
    padding: '30px',
  },
  scrollablePart: {
    paddingTop: '20px',

    paddingLeft: '30px',
    overflowY: 'auto',
    flex: '1',
    paddingRight: '30px',
  },
  title: {
    marginBottom: '15px',
  },
  content: {
    marginTop: '30px',
    display: 'grid',
  },

  [theme.breakpoints.down('md')]: {
    content: {
      gridTemplateColumns: '7fr 5fr',
    },
  },
  [theme.breakpoints.up('lg')]: {
    content: {
      gridTemplateColumns: '6fr 6fr',
    },
  },
  leftPart: {
    width: '100%',
    paddingRight: '30px',
  },
  radioItem: {
    // position: 'absolute',
    '&&&&&&': {
      width: '100%',
    },
    '&:first-of-type': {
      marginRight: '10px',
    },

  },
  radioItems: {
    display: 'flex',
  },
  radioItemWrapper: {
    position: 'absolute',
  },
  radioItemWrapperWrapper: {
    position: 'relative',
  },
  description: {
    marginTop: '15px',
    overflow: 'scroll',
    whiteSpace: 'pre-line',
    marginBottom: '18px'
  },
  backButton: {
    color: blueColor[100],
    marginBottom: '20px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& p': {
      marginLeft: '10px',
    },
  },
  offerStepper: {
    marginTop: '15px',
  },

  connectButtonWrapper: {
    paddingTop: '30px',
    paddingBottom: '30px',
  },
}));

const mapStateToProps = (state) => ({
  offers: state?.offers?.offers || [],
  loading: state?.offers?.loading || false,
});

const mapDispatchToProps = (dispatch) => ({
  getList: () => dispatch(fetchOffers),
});

export default connect(mapStateToProps, mapDispatchToProps)(Offer);
