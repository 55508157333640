import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button as ButtonWrapper } from '@material-ui/core';
import classNames from 'classnames';

export default function Button({
  children, className, color, disabled, onClick, style, variant, ...rest
}) {
  const classes = useStyles({ variant, color, style });

  return (
    <ButtonWrapper
      disabled={disabled}
      onClick={onClick}
      style={style}
      className={classNames(classes.root, className)}
      variant={variant}
      {...rest}
    >
      {' '}
      {children}
    </ButtonWrapper>
  );
}
const useStyles = makeStyles(() => ({
  root: (props) => ({
    '&.MuiButton-root': {
      background: props.variant === 'contained' ? props.color !== 'secondary' ? '#55C7E1' : '#45CC15' : 'white',
      textTransform: props?.style?.textTransform ? props?.style?.textTransform : 'capitalize',
      width: props?.style?.width ? props?.style?.width : '165px',
      height: props?.style?.height ? props?.style?.height : '40px',
      borderRadius: '5px',
      border: props?.variant === 'contained' ? 'none' : '#55C7E1 1px solid',
      color: props?.variant === 'contained' ? 'white' : '#55C7E1',
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: props?.style?.fontSize ? props?.style?.fontSize : '12px',
      filter: 'drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))',
      '&:hover': {
        backgroundColor: props.variant === 'contained' ? props.color === 'secondary' ? '#276f0e' : '#489eac' : '#e2eeee',
      },
      ...props.style,
    },
  }),
}));
