import clsx from 'clsx';
import HeaderWithCircleIcon from '@pages/entrance/components/Registration/components/HeaderWithCircleIcon';
import { Button, LockIcon } from '@main/lib';
import PasswordInput from '@pages/entrance/components/PasswordInput';
import React from 'react';
import { withStyles } from '@material-ui/core';
import {CONFIRM_PASSWORD, DONE, LAST_REGISTRATION_STEP, PASSWORD, THINK_OF_A_PASSWORD} from "@main/i18n/aliases";
import i18n from "@main/i18n";

const ThirdStep = ({
  nameEmailPhoneInputs, setPasswordError, passwordError, setPassword, passwordConfirmationError, setPasswordConfirmation, handleUserDataSubmit, classes,
}) => (
  <div>
    {nameEmailPhoneInputs}
    <div className={clsx(classes.thirdStep__passwordForm, classes.element_centralizedVertically)}>
      <HeaderWithCircleIcon icon={<LockIcon />} text={i18n.t(LAST_REGISTRATION_STEP)} title={i18n.t(THINK_OF_A_PASSWORD)} />
      <div className={classes.element_marginBottom20}>
        <PasswordInput
          setError={setPasswordError}
          errorText={passwordError}
          label={i18n.t(PASSWORD)}
          setPassword={setPassword}
          className={classes.thirdStep__input}
        />
      </div>
      <div className={classes.element_marginBottom20}>
        <PasswordInput
          setError={setPasswordError}
          errorText={passwordConfirmationError}
          label={i18n.t(CONFIRM_PASSWORD)}
          setPassword={setPasswordConfirmation}
          className={classes.thirdStep__input}
        />
      </div>

      <div className={classes.thirdStep__buttons}>
        <Button onClick={() => {
          handleUserDataSubmit();
        }}
        >
          {i18n.t(DONE)}
        </Button>
      </div>
    </div>

  </div>
);

const styles = () => ({
  thirdStep__passwordForm: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  element_centralizedVertically: {
    display: 'flex',
    alignItems: 'center',
  },
  thirdStep__input: {
    width: '100%',
    '&>div:nth-of-type(2)': {
      width: '100%',
    },
  },
  element_marginBottom20: {
    marginBottom: '20px',
    width: '100%',
  },
  thirdStep__buttons: {
    marginTop: '10px',
    width: '100%',
  },
});

export default withStyles(styles)(ThirdStep);
