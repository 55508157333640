import { Table, TableBody, TableHead } from '@main/lib';
import SortableTooltipHeadCell from '@pages/dashboard/components/Table/components/SortableTooltipHeadCell';
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import ChannelsPageTableRow from '@pages/channels/components/Table/components/Row';
import clsx from 'clsx';
import ChannelsTrackingLinkModal from '@pages/channels/components/TrackingLinkModal';
import {useTranslation} from "react-i18next";
import {CHANNEL, DIALOGS, EVENTS, INCOME, OFFER, PLACE} from "@main/i18n/aliases";

const sortAlphabetic = (array, getValue) => [...array].sort((a, b) => {
  if (getValue(a) > getValue(b)) {
    return 1;
  } if (getValue(a) < getValue(b)) {
    return -1;
  }
  return 0;
});

const sortNumeral = (array, getValue) => [...array].sort((a, b) => getValue(a) - getValue(b));
const getName = (channel) => channel.name;
const getOfferName = (channel) => channel.offerName;
const getDialogs = (channel) => channel.stats.conversations;
const getActions = (channel) => channel.stats.conversions;
const getIncome = (channel) => channel.stats.income;


const sortChannels = (sorting, sortedColumnIdx, channels) => {
  if (!sorting || !sortedColumnIdx) {
    return channels;
  }
  const results = [...channels];
  const { sortFunc } = headers[sortedColumnIdx];
  const { getValue } = headers[sortedColumnIdx];
  if (sorting === 'ascending') {
    return sortFunc(results, getValue);
  } if (sorting === 'descending') {
    return sortFunc(results, getValue).reverse();
  }
};

const filterChannels = (filterPlatformInput, filterOfferInput, filterSearchInput, channels) => {
  let results = [...channels];
  if (filterPlatformInput.length) {
    results = results.filter((result) => filterPlatformInput.includes(result.name));
  }
  if (filterOfferInput.length) {
    results = results.filter((result) => filterOfferInput.includes(result.offerName));
  }
  if (filterSearchInput) {
    results = results.filter((result) => result.name.toLowerCase().includes(filterSearchInput.toLowerCase()) || result.group_id.includes(filterSearchInput));
  }
  return results;
};

const ChannelsPageTable = ({
  classes, channels, filterPlatformInput, filterOfferInput, filterSearchInput,
}) => {
  const [sortedColumnIdx, setSortedColumnIdx] = useState();
  const [sorting, setSorting] = useState();
  const [currentModalChannel, setCurrentModalChannel] = useState(null);
  const {t} = useTranslation()

  const headers = [
    { title: t(PLACE), sortFunc: sortAlphabetic, getValue: getName },
    { title: t(OFFER), sortFunc: sortAlphabetic, getValue: getOfferName },
    { title: t(CHANNEL) },
    { title: t(DIALOGS), sortFunc: sortNumeral, getValue: getDialogs },
    { title: t(EVENTS), sortFunc: sortNumeral, getValue: getActions },
    { title: t(INCOME), sortFunc: sortNumeral, getValue: getIncome },
  ];

  return (
    <Table className={classes.channels__table}>
      <ChannelsTrackingLinkModal
        channel={currentModalChannel}
        open={Boolean(currentModalChannel)}
        onClose={() => setCurrentModalChannel(null)}
      />
      <TableHead
        sortable
        sortedColumnIdx={sortedColumnIdx}
        sorting={sorting}
        onChangeSorting={(sorting, index) => {
          setSorting(sorting);
          setSortedColumnIdx(index);
        }}
      >
        { headers.map((header, index) => (
          <SortableTooltipHeadCell
            className={clsx({ [classes.channels__sortableTooltipHeadCell_first]: index === 0 })}
            align={(index === headers.length - 1) || (index === 0) ? 'left' : undefined}
            sortable={header.sortFunc}
            header={(
              <div className={classes.channels__headerTitle}>
                {header.title}
              </div>
                    )}
            description={header.description || undefined}
          />
        ))}
      </TableHead>
      <TableBody>
        { sortChannels(
          sorting,
          sortedColumnIdx,
          filterChannels(
            filterPlatformInput,
            filterOfferInput,
            filterSearchInput,
            channels,
          ),
        ).map((channel) => <ChannelsPageTableRow channel={channel} onOpenModal={() => { setCurrentModalChannel(channel); }} />)}
      </TableBody>
    </Table>
  );
};

const styles = () => ({
  channels__headerTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  channels__table: {
  },
  channels__sortableTooltipHeadCell_first: {
    paddingLeft: '12px',
  },
});

export default withStyles(styles)(ChannelsPageTable);
