import React, { useEffect, useRef, useState } from 'react';
import {
  Button, CircularProgress, InputBase, Modal, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { connect } from 'react-redux';
import * as Sentry from '@sentry/react';
import { CircleIcon } from '@aisales/react-uikit';
import classNames from 'classnames';

import {
  addTicket,
  clearTickets,
  fetchTickets,
  frameLoaded,
  reset,
  setCurrentTicket,
  setFormOpen,
} from '@store/actions';

import { showToast } from '@components/utils';
import addSrc from '@assets/add.svg';
import Ticket from './components/Ticket';
import Folder from './components/Folder';

// import Messenger from "./components/Messenger";

const folders = [
  {
    name: 'Финансы',
    type: 'FINANCE',
  },
  {
    name: 'Буккипинг',
    type: 'BOOKKEEPING',
  },
  {
    name: 'Другие',
    type: 'OTHER',
  },
];

const Chat = (props) => {
  const {
    addTicket,
    clearTickets,
    currentTicket,
    formLoading,
    formOpen,
    formValidate,
    frameLoaded,
    frameLoading,
    getTickets,
    loading,
    onUnmount,
    selectTicket,
    setFormOpen,
    tickets,
  } = props;

  const [form, setForm] = useState({});
  const [selectedFolder, setSelectedFolder] = useState(
    Number(new URLSearchParams(window.location.search).get('folder')) || 0,
  );
  const isFirstRun = useRef(true);
  const classes = useStyles({ currentTicket, selectedFolder, loading });

  const updateURLSearch = () => {
    if (window.history.pushState) {
      const newURL = new URL(window.location.href);
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('folder', selectedFolder || 0);
      searchParams.set('ticket', JSON.stringify(currentTicket));
      newURL.search = searchParams.toString();
      window.history.pushState({ path: newURL.href }, '', newURL.href);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    let ticket;

    try {
      ticket = JSON.parse(searchParams.get('ticket')) || null;
    } catch (e) {
      Sentry.captureException(e);
      showToast('Произошла ошибка');
    }

    selectTicket(ticket);
    return () => {
      onUnmount();
    };
  }, []);

  useEffect(() => {
    if (!selectedFolder && selectedFolder !== 0) {
      return;
    }

    clearTickets();

    if (!loading || !tickets) {
      getTickets(folders?.[selectedFolder]?.type);
    }

    if (isFirstRun.current) {
      isFirstRun.current = false;
    } else {
      selectTicket(null);
    }
  }, [selectedFolder]);

  useEffect(() => {
    updateURLSearch();
  }, [selectedFolder, currentTicket]);

  const handleFrameRef = (ref) => {
    if (ref == null) {
      return;
    }
    const iframe = ref;
    if (iframe.attachEvent) {
      iframe.attachEvent('onload', () => {
        frameLoaded();
      });
    } else {
      iframe.onload = function () {
        frameLoaded();
      };
    }
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.foldersWrapper}>
          {folders.map((folder, index) => (
            <Folder
              {...folder}
              onClick={() => {
                setSelectedFolder(index);
              }}
              active={index === selectedFolder}
            />
          ))}
        </div>
        <div className={classes.ticketsWrapper}>
          {tickets?.map((ticket, index) => (
            <Ticket
              {...ticket}
              onClick={() => selectTicket(ticket)}
              active={currentTicket?.id === ticket?.id}
            />
          ))}
          {tickets && !loading && (
          <div
            style={{
              position: 'absolute',
              bottom: 53,
              left: 'calc(50% - 20px)',
            }}
          >
            <CircleIcon
              src={addSrc}
              alt="Add"
              className={classes.addIcon}
              size="40px"
              iconSize="80px"
              onClick={() => setFormOpen(true)}
            />
          </div>
          )}

          {loading && <CircularProgress />}
        </div>
        <div className={classes.messenger}>
          {currentTicket && (
          <iframe
            ref={handleFrameRef}
            src={`https://cpa_support.aisales.space/?lang=${localStorage.getItem('lang') || 'ru'}&uid=${currentTicket?.chat_id}`}
            seamless
            className={classes.frame}
          />
          )}
          {!currentTicket && (
          <Typography>
            Откройте тикет или создайте новый
          </Typography>
          )}
        </div>
      </div>
      <Modal open={formOpen} onBackdropClick={() => setFormOpen(false)}>
        <div className={classes.formWrapper}>
          <Typography variant="h4" style={{ color: '#0091b3' }}>
            Новая заявка
          </Typography>
          <div>
            <div style={{ marginTop: '20px' }}>
              <Typography variant="caption">Тема</Typography>
              <div
                className={classNames(classes.inputWrapper, {
                  invalid:
                                        formValidate
                                        && form?.topic?.trim().length !== 0,
                })}
              >
                <InputBase
                  autoComplete="off"
                  placeholder="Тема"
                  disabled={formLoading}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      topic: e.target.value,
                    });
                  }}
                  value={form.topic}
                  style={{
                    width: '100%',
                    marginLeft: '10px',
                    paddingTop: '2px',
                  }}
                />
              </div>
            </div>
            <div style={{ marginTop: '10px' }}>
              <Typography variant="caption">
                Описание
              </Typography>
              <div
                style={{ height: 'auto' }}
                className={classNames(classes.inputWrapper, {
                  invalid:
                                        formValidate
                                        && form?.text?.trim().length <= 10,
                })}
              >
                <InputBase
                  disabled={formLoading}
                  autoComplete="off"
                  // placeholder="Подробное описание проблемы"
                  placeholder="Detailed description of the problem"
                  value={form.text}
                  onChange={(e) => setForm({
                    ...form,
                    text: e.target.value,
                  })}
                  multiline
                  rows={10}
                  rowsMax={10}
                  style={{
                    width: '100%',
                    marginLeft: '10px',
                    paddingTop: '2px',
                  }}
                />
              </div>
            </div>
            <div
              style={{
                marginTop: '20px',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                disabled={formLoading}
                onClick={() => addTicket(form.topic, form.text)}
                variant="contained"
                color="primary"
                style={{ textTransform: 'none' }}
              >
                Отправить
              </Button>
            </div>
          </div>
        </div>
      </Modal>

    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    height: 'calc(100% - 110px)',
    display: 'flex',
    width: '100%',

    background: '#FFFFFF',
    boxShadow: '0px 0px 12px rgba(1, 171, 211, 0.39)',
    borderRadius: 5,
  },
  foldersWrapper: {
    overflow: 'auto',
    flex: '0 0 10%',
    flexWrap: 'wrap',
    height: '100%',
  },
  rightPart: {
    display: 'flex',
    flexWrap: 'wrap',
    height: '100%',
    flex: '0 0 90%',
  },
  messenger: (props) => ({
    flex: '0 0 74%',
    height: '100%',
    width: '100%',

    background: '#F0F1F3',
    boxShadow: '0px 0px 12px rgba(1, 171, 211, 0.39)',
    borderRadius: 5,
    zIndex: '1',

    display: props.currentTicket ? 'block' : 'flex',
    alignItems: !props.currentTicket && 'center',
    justifyContent: !props.currentTicket && 'center',
    textAlign: props.currentTicket && 'center',
  }),
  ticketsWrapper: (props) => {
    let onSelectedFolder = {};
    if (
      (!props.selectedFolder && props.selectedFolder !== 0)
            || props.loading
    ) {
      onSelectedFolder = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      };
    }
    return {
      position: 'relative',
      flex: '1 0 15%',
      height: '100%',
      overflow: 'auto',

      background: '#FFFFFF',
      boxShadow: '0px 0px 12px rgba(1, 171, 211, 0.39)',
      borderRadius: 5,
      ...onSelectedFolder,
    };
  },
  frame: {
    border: 'none',
    width: '100%',
    height: '100%',
    background: '#f6f7fd',
  },
  addIcon: {
    // margin: "0 auto",
    position: 'fixed',
    boxShadow: '0px 0px 4px 1px #00000057',
    transition: 'all .2s',
    cursor: 'pointer',

    '&:hover': {
      boxShadow: '0px 0px 9px 3px #00000038',
      background: '#f8f8ff',
    },
  },
  inputWrapper: {
    display: 'flex',
    width: '100%',
    background: 'rgb(255, 255, 255)',
    paddingRight: '10px',
    border: '1px solid rgb(230, 230, 230)',
    boxSizing: 'border-box',
    borderRadius: '5px',
    height: '40px',
    transition: 'border .2s',
    '&.invalid': {
      border: '1px solid rgb(234, 132, 132)',
    },
  },
  label: {
    paddingLeft: '5px',
    marginTop: '5px',
    fontWeight: '600 !important',
  },
  '@global': {
    '*::-webkit-scrollbar': {
      width: '4px',
    },
    '*:hover::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
      position: 'absolute',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: '#a1a2a8',
      outline: '1px solid slategrey',
      borderRadius: '5px',
    },
  },
  formWrapper: {
    width: '500px',
    height: '435px',
    background: 'white',
    margin: 'auto',
    marginTop: 'calc(50vh - 250px)',
    padding: '20px',
    borderRadius: '5px',
    outline: 'none',
  },
}));

export default connect(
  (state) => ({
    tickets: state?.tickets?.tickets,
    loading: state?.tickets?.loading,
    formOpen: state?.tickets?.form?.open,
    formLoading: state?.tickets?.form?.loading,
    formValidate: state?.tickets?.form?.validate,
    frameLoading: state?.tickets?.frameLoading,
    currentTicket: state?.tickets?.current,
  }),
  (dispatch) => ({
    getTickets: (folder) => {
      dispatch(fetchTickets(folder));
    },
    addTicket: (topic, description) => {
      dispatch(addTicket(topic, description));
    },
    selectTicket: (ticket) => {
      dispatch(setCurrentTicket(ticket));
    },
    setFormOpen: (isOpen) => {
      dispatch(setFormOpen(isOpen));
    },
    clearTickets: () => {
      dispatch(clearTickets);
    },
    frameLoaded: () => dispatch(frameLoaded),
    onUnmount: () => dispatch(reset),
  }),
)(Chat);
