import fetch from '@main/utils/handledFetch';
import { backendURL, twirpFetchOptions } from '@main/toRemove';
import { showToast } from '@pages/oldOffers/utils';
import * as Sentry from '@sentry/react';

export const START_FETCH_USER_INFO = 'profile/START_FETCH_USER_INFO';
export const FETCH_USER_INFO_SUCCESS = 'profile/FETCH_USER_INFO_SUCCESS';
export const FINISH_LOADING = 'profile/FINISH_LOADING';
export const UPDATE_PROFILE_FORM_INFO = 'profile/UPDATE_PROFILE_FORM_INFO';
export const startFetchUserInfo = () => ({
  type: START_FETCH_USER_INFO,
});
export const finishLoading = () => ({
  type: FINISH_LOADING,
});
export const fetchUserInfoSuccess = (payload) => {
  const tmp = {
    type: FETCH_USER_INFO_SUCCESS,
    payload,
  };
  return tmp;
};

export const updateProfileFormInfo = (payload) => ({
  type: UPDATE_PROFILE_FORM_INFO,
  payload,
});

const fetchUserInfoImpl = async (dispatch) => {
  const response = await fetch(
    `${backendURL}/twirp/cpa.backend.UserService/GetMe`,
    {
      ...twirpFetchOptions,
      body: '{}',
    },
  );

  if (!response.ok) {
    showToast('Ошибка сервера');
  } else {
    const json = await response.json();

    const {
      phone_number: phoneNumber,
      ...rest
    } = json.contact_info;
    dispatch(fetchUserInfoSuccess({
      form: {
        phoneNumber: phoneNumber || '',
        ...rest,
      },
    }));
  }
  // reducer
  dispatch(finishLoading());
};

export const fetchUserInfo = () => async (dispatch) => {
  try {
    dispatch(startFetchUserInfo());
    await fetchUserInfoImpl(dispatch);
  } catch (err) {
    Sentry.captureException(err);
  }
};
