import { List, Map } from 'immutable';
import { CREATE_SUCCESS, DELETE_SUCCESS } from '@pages/tools/actions/landbot';
import {
  FETCH_START,
  FETCH_SUCCESS,
  FRAME_LOADING_END, GET_MANY_START, GET_MANY_SUCCESS,
  RESET,
  SAVE_SUCCESS,
  SET_DATA,
  SET_LANGUAGE,
  TRACKER_TOGGLE,
} from '../actions/landbot';

const initialState = Map({
  data: {},
  changed: false,
  loading: false,
  landbots: null,
  frameLoading: true,
  trackerLink: false,
  lang: 'en',
});

const actionsMap = {
  [FETCH_START]: (state) => state.merge({
    loading: true,
  }),
  [FETCH_SUCCESS]: (state, payload) => state.merge({
    data: payload,
    serverData: JSON.stringify(payload),
    loading: false,
    frameLoading: true,
  }),
  [FRAME_LOADING_END]: (state) => state.merge({
    frameLoading: false,
  }),
  [RESET]: (state) => state.merge({
    frameLoading: true,
  }),
  [TRACKER_TOGGLE]: (state) => state.merge({
    trackerLink: !state.get('trackerLink'),
  }),
  [SET_LANGUAGE]: (state, payload) => state.merge({
    frameLoading: true,
    lang: payload,
  }),
  [SET_DATA]: (state, payload) => state.merge({
    changed: JSON.stringify(payload) !== state.get('serverData'),
    data: payload,
  }),
  [SAVE_SUCCESS]: (state, payload) => state.merge({
    data: payload,
    serverData: JSON.stringify(payload),
    changed: false,
    frameLoading: true,
  }),
  [GET_MANY_START]: (state) => state.merge({
    loading: true,
  }),
  [GET_MANY_SUCCESS]: (state, payload) => state.merge({
    landbots: List(payload),
    loading: false,
  }),
  [CREATE_SUCCESS]: (state, payload) => state.merge({
    landbots: state.get('landbots').insert(0, payload),
  }),
  [DELETE_SUCCESS]: (state, id) => state.merge({
    landbots: state.get('landbots').filter((tool) => tool.id !== id),
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action.payload) : state;
}
