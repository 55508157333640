import {
  FilledHelpIcon, greyColor, Tooltip, Typography,
} from '@main/lib';
import clsx from 'clsx';
import React from 'react';
import { withStyles } from '@material-ui/core';

const CounterBlock = ({
  classes, upperTitle, data, percent, tooltipText,
}) => (
  <div className={classes.counterBlock}>
    <div className={clsx(classes.counterBlock__upperPart, classes.block_centralizedVertically)}>
      <div className={classes.counterBlock__upperPartTitle}>
        {upperTitle}
      </div>
      <Tooltip text={tooltipText}>
        <div>

          <FilledHelpIcon ellipseColor={greyColor[25]} questionMarkColor="white" />
        </div>
      </Tooltip>
    </div>
    <div className={clsx(classes.counterBlock__bottomPart, classes.block_centralizedVertically)}>
      <div className={classes.counterBlock__bottomPartData}>
        <Typography variant="subtitle">{data}</Typography>
      </div>
      <div className={classes.counterBlock__bottomPartPercent}>
        <Typography variant="detailed" bold>{percent}</Typography>
      </div>
    </div>
  </div>
);

const styles = () => ({
  counterBlock: {
    padding: '16px 20px 0px 20px',
    width: '201px',
    height: '77px',
    background: '#FFFFFF',
    /* Menu shadows */
    boxShadow: '0px 2px 20px rgba(0, 36, 70, 0.07)',
    borderRadius: '5px',
  },
  counterBlock__upperPart: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  counterBlock__upperPartTitle: {
    color: greyColor[50],
  },
  block_centralizedVertically: {
    display: 'flex',
    alignItems: 'center',
  },
  counterBlock__bottomPart: {
    marginTop: '2px',
  },
  counterBlock__bottomPartData: {
    marginRight: '5px',
  },
  counterBlock__bottomPartPercent: {

  },
  data: {},
  percent: {},
});
export default withStyles(styles)(CounterBlock);
