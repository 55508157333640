import fetch from '@main/utils/handledFetch';
import { backendURL, twirpFetchOptions } from '@main/toRemove';
import React from 'react';

const MessengerConnector = ({
  children, chosenChannel, offer, token,
}) => {
  const countries = offer?.countries;
  const languages = offer?.languages;
  const addPlatform = ({ monetization }) => {
    const body = {
      token: token?.trim(),
      country: countries,
      language: languages,
      channel: chosenChannel,
    };
    return fetch(
      `${backendURL
      }/twirp/cpa.offers.OffersManagementService/ConnectOffer`,
      {
        ...twirpFetchOptions,
        body: JSON.stringify({
          offer_id: offer.id,
          channel: chosenChannel,
          platform_input: body,
          billing_mode: monetization,
        }),
      },
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('bad response code');
      })
      .then((res) => res);
  };

  return React.cloneElement(children, {
    onFinish: addPlatform,
    ...children.props,
  });
};

export default MessengerConnector;
