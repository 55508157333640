import { greyColor, OfferBackgroundSemiTransparentPartsV2, Typography } from '@main/lib';
import { withStyles } from '@material-ui/styles';
import { isRecent } from '@pages/offers/utils';
import TagNew from '@pages/offers/components/TagNew';
import React from 'react';
import ChannelsLine from '@pages/offers/components/ChannelsLine';
import { useTranslation } from 'react-i18next';
import {
  AUDIENCE_CHURN, CLICK_PER_LINK_DESC, CR_GIVEN_LINKS, DIALOGS_COUNT, EARNINGS_PER_CONVERSION, LEADS,
} from '@main/i18n/aliases';
import { formatCurrency, formatNumber } from '@components/utils';
import OfferIcon from '@pages/offers/components/OfferIcon';
import TagNewAndStar from '@pages/offers/components/TagNewAndStar';

const offerHeaderStyle = () => ({
  root: {
    backgroundColor: greyColor.background2,
    width: '100%',
    borderRadius: '5px',
    position: 'relative',

  },
  squareRobotIcon: {
    width: '60px',
    height: '60px',
    marginLeft: '30px',
    position: 'absolute',
  },
  topLine: {
    paddingTop: '37px',
    display: 'flex',
  },
  content: {
    marginLeft: '120px',
    paddingBottom: '30px',
  },
  statisticsLine: {
    display: 'flex',
    marginTop: '21px',

  },
  statisticsPiece: {
    width: '93px',
    marginRight: '69px',
  },
  semiTransparentParts: {
    position: 'absolute',
    zIndex: '100',
    right: '0',
  },
  channels: {
    marginLeft: '30px',
  },

});

// TODO: check date, dont like it
const OfferHeader = withStyles(offerHeaderStyle)(({
  classes, date, icon, offerInfo,
}) => {
  const { t } = useTranslation();
  const getStatistics = () => ([{
    value: formatCurrency(offerInfo.statistics.conversations) || 0,
    description: t(DIALOGS_COUNT),
  },
  {
    value: `${formatCurrency(
      formatNumber(offerInfo.statistics.conversations_percent, 1),
    )}₽`,
    description: t(EARNINGS_PER_CONVERSION),
  },
  { value: formatCurrency(offerInfo.statistics.leads) || 0, description: t(LEADS) },
  { value: `${formatNumber(offerInfo.statistics.link_percent, 1)}%`, description: t(CR_GIVEN_LINKS) },
  { value: `${formatNumber(offerInfo.statistics.ban_percent, 1)}%`, description: t(AUDIENCE_CHURN) },
  { value: `${formatNumber(offerInfo.statistics.click_per_link, 1)}%`, description: t(CLICK_PER_LINK_DESC) },
  ]);

  return (
    <div className={classes.root}>
      {isRecent(date) && <TagNew className={classes.tagNew} />}
      <OfferBackgroundSemiTransparentPartsV2 className={classes.semiTransparentParts} />
      <TagNewAndStar date={date} isFavorite={offerInfo.is_starred} id={offerInfo.id} />
      <OfferIcon icon={icon} />
      {offerInfo
        && (
        <div className={classes.content}>
          <div className={classes.topLine}>
            <Typography style="white" variant="title">{offerInfo.name}</Typography>
            <ChannelsLine className={classes.channels} channels={offerInfo.available_channels} />
          </div>
          <span className={classes.statisticsLine}>
            {getStatistics().map((statisticsPiece) => (
              <div className={classes.statisticsPiece}>
                <Typography variant="title" style="white">{statisticsPiece.value}</Typography>
                <div>
                  <Typography style="white">{statisticsPiece.description}</Typography>
                </div>
              </div>
            ))}
          </span>
        </div>
        )}
    </div>
  );
});

export default OfferHeader;
