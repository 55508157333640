import { Button, FilledOkIcon, Typography } from '@main/lib';
import React from 'react';
import { withStyles } from '@material-ui/core';

const DashboardReportCreationUpdateFormBaseFinishedState = ({
  classes, onClickButton, topText, buttonShown,
}) => (
  <div className={classes.finishedState}>
    <FilledOkIcon />
    <Typography bold className={classes.finishedState__topText}>{topText}</Typography>
    <div>Управлять отчётом Вы можете на странице созданого отчёта</div>
    {buttonShown && (
    <Button color="secondary" className={classes.finishedState__button} onClick={onClickButton}>
      Создать ещё один отчёт
    </Button>
    )}
  </div>
);

const style = () => ({
  finishedState: {
    width: '290px',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  finishedState__button: {
    width: '100%',
    marginTop: '30px',
  },
  finishedState__topText: {
    display: 'block',
    marginBottom: '5px',
    marginTop: '15px',
  },
});

export default withStyles(style)(DashboardReportCreationUpdateFormBaseFinishedState);
