/* global fuckAdBlock, a */
/* eslint no-undef: "error" */
import React, { useEffect, useState } from 'react';
import AISalesIcon from '@components/Navbar/assets/AISalesIcon';
import withStyles from '@material-ui/core/styles/withStyles';
import ErrorIcon from '@material-ui/icons/Error';

const adBlockProblemText = 'Please turn off the AdBlock';
const cookiesProblemText = 'Please turn on cookies';
const localStorageProblemText = 'Please turn on the local storage';
const mobileDeviceProblemText = 'Mobile devices are not supported';

const style = {
  centered: {
    color: 'gray',
    fontWeight: 'normal',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '5em',
    '& > svg': {
      fontSize: '8em',
      width: '1em',
      height: '1em',
    },
  },

  errorElement: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      fontSize: '1.5em',
      width: '1em',
      height: '1em',
      color: 'red',
      marginRight: '0.5em',
    },
  },
  icon: {
    color: '#01ABD3',
  },
};

let ErrorElement = ({ classes, errorText }) => (
  <div className={classes.errorElement}>
    <ErrorIcon />
    {errorText}
  </div>
);

ErrorElement = withStyles(style)(ErrorElement);

function ChangeSettingsPage({
  adBlockIsEnabled, classes, cookiesAreAvailable, isMobile, localStorageIsAvailable,
}) {
  return (
    <>
      <div className={classes.centered}>
        <AISalesIcon className={classes.icon} />
        <h3 style={{ textTransform: 'uppercase', marginTop: '3em', letterSpacing: '2px' }}>AISales Network</h3>
        <div style={{ marginTop: '3em' }}>
          {adBlockIsEnabled && <ErrorElement errorText={adBlockProblemText} />}
        </div>
        {!cookiesAreAvailable && <ErrorElement errorText={cookiesProblemText} />}
        {!localStorageIsAvailable && <ErrorElement errorText={localStorageProblemText} />}
        {isMobile && <ErrorElement errorText={mobileDeviceProblemText} />}
      </div>
      ' '
    </>
  );
}

const StyledChangeSettingsPage = withStyles(style)(ChangeSettingsPage);

function SettingsCheck({ children }) {
  const [adBlockIsEnabled, setAdBlockIsEnabled] = useState(false);
  const [needToChangeSettings, setNeedToChangeSettings] = useState(false);
  const checkAdBlock = () => {
    // Function called if AdBlock is not detected
    function adBlockNotDetected() {
      //setAdBlockIsEnabled(false);
    }

    // Function called if AdBlock is detected
    function adBlockDetected() {
      setAdBlockIsEnabled(true);
    }

    // We look at whether FuckAdBlock already exists.
    if (typeof fuckAdBlock !== 'undefined' || typeof FuckAdBlock !== 'undefined') {
      // If this is the case, it means that something tries to usurp are identity
      // So, considering that it is a detection
      adBlockDetected();
    } else {
      // Otherwise, you import the script FuckAdBlock
      const importFAB = document.createElement('script');
      importFAB.onload = function () {
        // If all goes well, we configure FuckAdBlock
        fuckAdBlock.setOption({ debug: true });
        fuckAdBlock.setOption({ loopCheckTime: 1000, loopMaxNumber: 1, checkOnLoad: true });
        fuckAdBlock.onDetected(adBlockDetected);
        fuckAdBlock.onNotDetected(adBlockNotDetected);
      };
      importFAB.onerror = function () {
        // If the script does not load (blocked, integrity error, ...)
        // Then a detection is triggered
        adBlockDetected();
      };
      importFAB.integrity = 'sha256-xjwKUY/NgkPjZZBOtOxRYtK20GaqTwUCf7WYCJ1z69w=';
      importFAB.crossOrigin = 'anonymous';
      importFAB.src = 'https://cdnjs.cloudflare.com/ajax/libs/fuckadblock/3.2.1/fuckadblock.min.js';
      document.head.appendChild(importFAB);
    }
  };
  useEffect(() => {
    //checkAdBlock();
  }, []);
  const cookiesAreAvailable = () => {
    // Quick test if browser has cookieEnabled host property
    if (navigator.cookieEnabled) return true;
    // Create cookie
    document.cookie = 'cookietest=1';
    const ret = document.cookie.indexOf('cookietest=') !== -1;
    // Delete cookie
    document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';
    return ret;
  };
  const localStorageIsAvailable = () => !!window.localStorage;

  function isMobile() {
    let check = false;
    (function (a) {
      if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
    }(navigator.userAgent || navigator.vendor || window.opera));
    return check;
  }

  useEffect(() => {
    setNeedToChangeSettings(adBlockIsEnabled /*|| isMobile()*/ || !cookiesAreAvailable() || !localStorageIsAvailable());
  }, [adBlockIsEnabled]);

  return (
    <>
      {needToChangeSettings ? (
        <StyledChangeSettingsPage
          adBlockIsEnabled={adBlockIsEnabled}
          cookiesAreAvailable={cookiesAreAvailable()}
          localStorageIsAvailable={localStorageIsAvailable()}
          isMobile={isMobile()}
        />
      ) : children}
    </>
  );
}

export default SettingsCheck;
