import { Map } from 'immutable';
import {
  FETCH_START,
  FETCH_SUCCESS,
  FRAME_LOADING_END,
  RESET,
  SAVE_SUCCESS,
  SET_CHANNEL,
  SET_DATA,
  SET_LOGGED_FACEBOOK,
  SET_LOGGED_VK,
} from '../actions';

const initialState = Map({
  data: {},
  changed: false,
  loading: false,
  frameLoading: true,
  isAuthorized: false,
  selectedChannel: null,
  isLoggedVK: Boolean(localStorage.getItem('vk_access_token')),
  isLoggedFacebook: Boolean(localStorage.getItem('fb_access_token')),
});

const actionsMap = {
  [FETCH_START]: (state) => state.merge({
    loading: true,
  }),
  [FETCH_SUCCESS]: (state, payload) => state.merge({
    data: payload,
    serverData: JSON.stringify(payload),
    loading: false,
    frameLoading: true,
  }),
  [FRAME_LOADING_END]: (state) => state.merge({
    frameLoading: false,
  }),
  [RESET]: (state) => state.merge({
    frameLoading: true,
  }),
  [SET_LOGGED_VK]: (state, isLogged) => state.merge({
    isLoggedVK: isLogged,
  }),
  [SET_LOGGED_FACEBOOK]: (state, isLogged) => {
    if (isLogged === false) {
      localStorage.removeItem('fb_access_token');
    }
    return state.merge({
      isLoggedFacebook: isLogged,
    });
  },
  [SET_CHANNEL]: (state, payload) => state.merge({
    selectedChannel: payload,
  }),
  [SET_DATA]: (state, payload) => state.merge({
    changed: JSON.stringify(payload) !== state.get('serverData'),
    data: payload,
  }),
  [SAVE_SUCCESS]: (state, payload) => state.merge({
    data: payload,
    serverData: JSON.stringify(payload),
    changed: false,
    frameLoading: true,
  }),
};

export default function reducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action.payload) : state;
}
