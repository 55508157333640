import { channels } from '@pages/channels/constants';

export const makeFilterValues = (keys) => {
  const result = {};
  for (const key of keys) {
    result[key] = [];
  }
  return result;
};

export const makeByOfferOptions = (offers) => {
  const result = {};
  for (const offer of offers) {
    result[offer.id] = offer.name;
  }
  return result;
};

export const makeByPlatformsOptions = (platforms) => {
  const result = {};

  for (const platform of platforms) {
    result[platform.id] = platform.name;
  }
  return result;
};

export const makeChannelsOptions = () => {
  const result = {};
  // eslint-disable-next-line no-unused-vars
  for (const channel in channels) {
    result[channel] = channels[channel].name;
  }
  return result;
};
