import React from 'react';

const FullWhiteLogoIcon = (props) => (
  <svg {...props} width="241" height="55" viewBox="0 0 241 55" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M47.5492 19.8722H25.8852C22.5515 19.8722 19.8491 22.5657 19.8491 25.8882C19.8491 29.2108 22.5515 31.9043 25.8852 31.9043H25.9679V38.4972H25.8852C18.8982 38.4972 13.2341 32.852 13.2341 25.8882C13.2341 18.9245 18.8982 13.2793 25.8852 13.2793H47.5492C54.5362 13.2793 60.2003 18.9245 60.2003 25.8882C60.2003 33.2229 57.0582 36.5541 47.5492 39.1565C27.3736 44.678 25.9679 45.0901 25.9679 45.0901V38.4972C25.9679 38.4972 26.6294 38.3324 47.7972 32.0691C51.7627 30.8958 53.5853 29.2108 53.5853 25.8882C53.5853 22.5657 50.8828 19.8722 47.5492 19.8722Z" fill="white" />
  <path d="M89.3989 13.8828V38.1916C89.3989 39.0575 88.9766 39.4905 88.1319 39.4905H84.9272C84.5546 39.4905 84.2564 39.4039 84.0329 39.2307C83.8093 39.0575 83.6229 38.7978 83.4739 38.4514L74.7913 20.3033C74.5677 19.8085 74.3068 19.2518 74.0087 18.6332C73.7106 18.0147 73.487 17.4456 73.338 16.9261H72.7417C72.7914 17.4704 72.8287 18.0271 72.8535 18.5961C72.8784 19.1652 72.8908 19.7342 72.8908 20.3033V39.4905H69.6115V15.1818C69.6115 14.3158 70.0463 13.8828 70.9158 13.8828H74.0087C74.3814 13.8828 74.6795 13.9694 74.9031 14.1426C75.1267 14.3158 75.313 14.5756 75.462 14.922L83.8838 32.6247C84.1571 33.1443 84.4428 33.7381 84.7409 34.4061C85.0638 35.0494 85.362 35.6927 85.6352 36.336H86.2687C86.2439 35.6679 86.219 35.0123 86.1942 34.369C86.1693 33.7257 86.1569 33.0824 86.1569 32.4391V13.8828H89.3989Z" fill="white" />
  <path d="M100.768 20.9713C103.5 20.9713 105.475 21.4662 106.693 22.4558C107.935 23.4455 108.556 24.9176 108.556 26.8722C108.581 28.4805 108.245 29.7423 107.55 30.6577C106.854 31.5484 105.749 31.9938 104.233 31.9938H93.65V29.5443H103.413C104.233 29.5443 104.767 29.2722 105.016 28.7279C105.264 28.1588 105.388 27.5403 105.388 26.8722C105.363 25.7094 105.016 24.8805 104.345 24.3857C103.674 23.8909 102.531 23.6434 100.917 23.6434C99.5999 23.6434 98.5565 23.8414 97.7863 24.2372C97.0411 24.6084 96.5069 25.2764 96.184 26.2413C95.8859 27.1815 95.7368 28.5052 95.7368 30.2124C95.7368 32.1422 95.9231 33.6144 96.2958 34.6288C96.6933 35.6432 97.3143 36.336 98.159 36.7071C99.0037 37.0782 100.122 37.2638 101.513 37.2638C102.482 37.2638 103.537 37.2267 104.68 37.1524C105.848 37.0535 106.891 36.9421 107.81 36.8184L108.146 38.9338C107.574 39.1565 106.866 39.3421 106.022 39.4905C105.202 39.639 104.345 39.7503 103.451 39.8245C102.581 39.8988 101.798 39.9359 101.103 39.9359C99.0161 39.9359 97.3392 39.6266 96.0722 39.0081C94.8052 38.3648 93.8736 37.3504 93.2774 35.9648C92.706 34.5546 92.4203 32.6989 92.4203 30.3979C92.4203 28.0227 92.706 26.1547 93.2774 24.7939C93.8736 23.4084 94.7803 22.4311 95.9977 21.862C97.2398 21.2682 98.8298 20.9713 100.768 20.9713Z" fill="white" />
  <path d="M118.77 20.9713C120.857 20.9713 122.522 21.2806 123.764 21.8991C125.031 22.5177 125.938 23.5321 126.484 24.9424C127.031 26.3279 127.304 28.1712 127.304 30.4722C127.304 32.7731 127.031 34.6288 126.484 36.0391C125.938 37.4246 125.031 38.4266 123.764 39.0452C122.522 39.6637 120.857 39.973 118.77 39.973C116.708 39.973 115.044 39.6637 113.777 39.0452C112.535 38.4266 111.628 37.4246 111.057 36.0391C110.51 34.6288 110.237 32.7731 110.237 30.4722C110.237 28.1712 110.51 26.3279 111.057 24.9424C111.628 23.5321 112.535 22.5177 113.777 21.8991C115.044 21.2806 116.708 20.9713 118.77 20.9713ZM118.77 23.6434C117.454 23.6434 116.41 23.8537 115.64 24.2744C114.895 24.6702 114.361 25.363 114.038 26.3527C113.715 27.3423 113.553 28.7155 113.553 30.4722C113.553 32.2288 113.715 33.602 114.038 34.5917C114.361 35.5813 114.895 36.2865 115.64 36.7071C116.41 37.103 117.454 37.3009 118.77 37.3009C120.087 37.3009 121.13 37.103 121.901 36.7071C122.671 36.2865 123.217 35.5813 123.54 34.5917C123.863 33.602 124.025 32.2288 124.025 30.4722C124.025 28.7155 123.863 27.3423 123.54 26.3527C123.217 25.363 122.671 24.6702 121.901 24.2744C121.13 23.8537 120.087 23.6434 118.77 23.6434Z" fill="white" />
  <path d="M152.135 20.9713C153.7 20.9713 154.893 21.3919 155.713 22.2332C156.557 23.0744 156.98 24.3115 156.98 25.9444V39.4905H153.85V26.8351C153.825 25.7217 153.601 24.9176 153.179 24.4228C152.756 23.9032 152.036 23.6434 151.017 23.6434C150.372 23.6434 149.75 23.7548 149.154 23.9775C148.583 24.1754 147.949 24.497 147.254 24.9424C146.558 25.3877 145.713 25.9692 144.72 26.6867L144.533 24.4599C145.85 23.2971 147.13 22.4311 148.372 21.862C149.614 21.2682 150.868 20.9713 152.135 20.9713ZM132.646 21.4167L132.87 24.8682L133.205 25.4248V39.4905H130.038V21.4167H132.646ZM140.248 20.9713C141.788 20.9713 142.968 21.3919 143.788 22.2332C144.608 23.0496 145.03 24.2867 145.055 25.9444V39.4905H141.999V26.8351C141.999 25.6723 141.763 24.8558 141.291 24.3857C140.819 23.8909 140.111 23.6434 139.167 23.6434C138.546 23.6434 137.938 23.7424 137.341 23.9403C136.77 24.1383 136.124 24.4599 135.404 24.9053C134.708 25.3506 133.851 25.9444 132.832 26.6867L132.609 24.4599C133.925 23.2971 135.205 22.4311 136.447 21.862C137.689 21.2682 138.956 20.9713 140.248 20.9713Z" fill="white" />
  <path d="M167.897 20.9713C170.63 20.9713 172.605 21.4662 173.822 22.4558C175.065 23.4455 175.686 24.9176 175.686 26.8722C175.71 28.4805 175.375 29.7423 174.68 30.6577C173.984 31.5484 172.878 31.9938 171.363 31.9938H160.78V29.5443H170.543C171.363 29.5443 171.897 29.2722 172.146 28.7279C172.394 28.1588 172.518 27.5403 172.518 26.8722C172.493 25.7094 172.146 24.8805 171.475 24.3857C170.804 23.8909 169.661 23.6434 168.046 23.6434C166.73 23.6434 165.686 23.8414 164.916 24.2372C164.171 24.6084 163.637 25.2764 163.314 26.2413C163.016 27.1815 162.867 28.5052 162.867 30.2124C162.867 32.1422 163.053 33.6144 163.426 34.6288C163.823 35.6432 164.444 36.336 165.289 36.7071C166.134 37.0782 167.251 37.2638 168.643 37.2638C169.612 37.2638 170.667 37.2267 171.81 37.1524C172.978 37.0535 174.021 36.9421 174.94 36.8184L175.276 38.9338C174.704 39.1565 173.996 39.3421 173.152 39.4905C172.332 39.639 171.475 39.7503 170.58 39.8245C169.711 39.8988 168.928 39.9359 168.233 39.9359C166.146 39.9359 164.469 39.6266 163.202 39.0081C161.935 38.3648 161.003 37.3504 160.407 35.9648C159.836 34.5546 159.55 32.6989 159.55 30.3979C159.55 28.0227 159.836 26.1547 160.407 24.7939C161.003 23.4084 161.91 22.4311 163.128 21.862C164.37 21.2682 165.96 20.9713 167.897 20.9713Z" fill="white" />
  <path d="M182.77 16.0725V34.5174C182.77 35.3834 182.956 36.0019 183.329 36.3731C183.702 36.7442 184.335 36.9298 185.229 36.9298H187.987L188.36 39.3421C187.962 39.441 187.503 39.5276 186.981 39.6019C186.459 39.6761 185.95 39.7256 185.453 39.7503C184.956 39.7998 184.559 39.8245 184.261 39.8245C182.745 39.8245 181.59 39.4039 180.795 38.5627C180 37.7215 179.602 36.5091 179.602 34.9257V16.0725H182.77ZM188.546 21.4167V23.9032H176.323V21.6022L179.901 21.4167H188.546Z" fill="white" />
  <path d="M192.64 21.4167L193.05 24.8682L193.348 25.4248V39.4905H190.18V21.4167H192.64ZM201.546 20.9713L201.173 23.9403H200.13C199.062 23.9403 197.993 24.163 196.925 24.6084C195.857 25.029 194.553 25.6352 193.012 26.4269L192.752 24.4599C194.093 23.3713 195.435 22.5177 196.776 21.8991C198.143 21.2806 199.459 20.9713 200.726 20.9713H201.546Z" fill="white" />
  <path d="M206.18 21.4167V39.4905H203.05V21.4167H206.18ZM205.361 13.7344C206.007 13.7344 206.33 14.056 206.33 14.6993V16.8147C206.33 17.458 206.007 17.7796 205.361 17.7796H203.87C203.224 17.7796 202.901 17.458 202.901 16.8147V14.6993C202.901 14.056 203.224 13.7344 203.87 13.7344H205.361Z" fill="white" />
  <path d="M225.373 21.4167L219.747 30.1753L225.821 39.4905H222.392L216.467 30.1753L221.945 21.4167H225.373ZM211.735 21.4167L217.213 30.1753L211.325 39.4905H207.859L213.933 30.1753L208.306 21.4167H211.735ZM218.815 28.8763V31.3258H214.902V28.8763H218.815Z" fill="white" />
  </svg>
);
export default FullWhiteLogoIcon;
