import { toast } from 'react-toastify';
import React from 'react';
import { AlertContents } from '@main/lib';
import {
  COMPLETE_THE_SURVEY,
  DATA_NOT_FOUND, INCORRECT_REQUEST, INTERNAL_ERROR,
  PLEASE_WAIT_ACCOUNT_VERIFICATION,
  REGISTRATION_REJECTED, SERVER_UNAVAILABLE, USER_IS_NOT_REGISTERED
} from "@main/i18n/aliases";
import i18n from "@main/i18n";

function getTextByCode(code) {
  switch (code) {
    case 405:
      return i18n.t(PLEASE_WAIT_ACCOUNT_VERIFICATION)
    case 406:
      return i18n.t(COMPLETE_THE_SURVEY)
    case 403:
      return i18n.t(REGISTRATION_REJECTED)
    case 404:
      return i18n.t(DATA_NOT_FOUND);
    case 400:
      return i18n.t(INCORRECT_REQUEST);
    case 409:
      return i18n.t(USER_IS_NOT_REGISTERED);
    case 500:
      return i18n.t(INTERNAL_ERROR);
    case 502:
    case 503:
      return i18n.t(SERVER_UNAVAILABLE);
    default:
      return null;
  }
}

const showAlert = (() => {
  const visibleMessages = new Set();
  return (msg) => {
    if (visibleMessages.has(msg)) {
      return;
    }
    visibleMessages.add(msg);
    toast(<AlertContents severity="error" title={msg} />);
    setTimeout(() => visibleMessages.delete(msg), 3000);
  };
})();

async function handledFetch(url, options) {
  const {
    alert = true,
    errHandler = null,
  } = options;
  const response = await fetch(url, options);
  if (response.status === 401) {
    localStorage.removeItem('user');
  } else if ((response.status < 200 || response.status >= 300) && alert) {
    if (typeof errHandler !== 'function') {
      showAlert(getTextByCode(response.status) || response.statusText);
    } else {
      // noinspection JSValidateTypes
      errHandler(response);
    }
  }
  return response;
}

export default handledFetch;
