import React from 'react';
import { CssBaseline } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Channels from '@main/pages/channels';
import Dashboard from '@main/pages/dashboard';
import Offers from '@pages/offers';
import Finance from '@pages/finance';
import Support from '@pages/support';
import Messenger from '@pages/messenger';
import Profile from '@pages/profile';
import { toggleSidebar } from '@store/actions';
import Tools from '@pages/tools';
import Navigation from '@components/Navigation';

const Layout = () => {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <CssBaseline />
      <div>
        <div>
          <Navigation>
            <Switch>
              <Route
                path="/app/offers/:offerID/chat"
                render={() => <Messenger />}
              />
              <Route
                path="/app/channels/landbot"
                render={() => <Tools />}
              />
              <Route
                path="/app/channels"
                render={() => <Channels />}
              />
              <Route
                path="/app/dashboard"
                render={() => <Dashboard />}
              />
              <Route
                path="/app/offers"
                render={() => <Offers />}
              />
              <Route
                path="/app/finance"
                render={() => <Finance />}
              />
              <Route
                path="/app/support"
                render={() => <Support />}
              />
              <Route
                path="/app/profile"
                render={() => <Profile />}
              />
            </Switch>
          </Navigation>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    maxWidth: '100vw',
    height: '100vh',
    overflow: 'hidden',
    backgroundColor: '#ffffff',
    '& > div': {
      width: '100%',
    },
  },
  gridLayoutNotExpanded: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '60px auto',
  },
  gridLayoutExpanded: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '180px auto',
  },
  content: (props) => ({
    flexGrow: 1,
    zIndex: 10,
    width: '100%',
    minHeight: '100vh',
  })
}));

const mapStateToProps = (state) => ({
  isSidebarOpened: state.layout.isSidebarOpened,
  isAuthenticated: state.login.isAuthenticated,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  toggleSidebar,
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
