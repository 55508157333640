import {
  FETCH_USER_INFO_SUCCESS, START_FETCH_USER_INFO, FINISH_LOADING, UPDATE_PROFILE_FORM_INFO,
} from '@store/actions';

const initialState = (
  {
    isLoading: true,
    phoneNumberExists: false,
    formValues: {
      nickname: '',
      name: '',
      surname: '',
      patronymic: '',
      email: '',
      telegram: '',
      skype: '',
      phoneNumber: '',
      currency: '',
    },
  });

export default function ProfileReducer(state = initialState, { payload, type }) {
  // debugger;
  switch (type) {
    case START_FETCH_USER_INFO:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_USER_INFO_SUCCESS:
      return {
        ...state,
        formValues: payload.form,
      };
    case FINISH_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_PROFILE_FORM_INFO:
      return {
        ...state,
        formValues: payload.form,
      };
    default:
      return state;
  }
}
