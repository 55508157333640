export const backendURL = '/backend';

export const twirpFetchOptions = { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: '{}' };

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
}
