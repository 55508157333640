export const TOOLS = 'TOOLS';
export const BALANCE = 'BALANCE';
export const REPORTS = 'REPORTS';
export const BY_INTERACTION = 'BY_INTERACTION';
export const GROUPING_BY_HOURS = 'GROUPING_BY_HOURS';
export const GROUPING_BY_DAYS = 'GROUPING_BY_DAYS';
export const GROUPING_BY_MONTHS = 'GROUPING_BY_MONTHS';
export const GROUPING_BY_YEARS = 'GROUPING_BY_YEARS';
export const HOUR = 'HOUR';
export const DAY = 'DAY';
export const MONTH = 'MONTH';
export const YEAR = 'YEAR';
export const CUSTOM_DATES = 'CUSTOM_DATES';
export const TODAY = 'TODAY';
export const LAST_7_DAYS = 'LAST_7_DAYS';
export const LAST_14_DAYS = 'LAST_14_DAYS';
export const LAST_30_DAYS = 'LAST_30_DAYS';
export const ID = 'ID';
export const DATE_OF_CREATION = 'DATE_OF_CREATION';
export const SUM = 'SUM';
export const PAYOUT_METHOD = 'PAYOUT_METHOD';
export const CURRENT_MONTH = 'CURRENT_MONTH';
export const LAST_MONTH = 'LAST_MONTH';
export const ADD_FILTER = 'ADD_FILTER';
export const ADD_GROUPING = 'ADD_GROUPING';
export const ADD_COLUMNS = 'ADD_COLUMNS';
export const SELECT_DATES = 'SELECT_DATES';
export const RESET_ALL = 'RESET_ALL';
export const APPLY_FILTERS = 'APPLY_FILTERS';
export const FILTERS = 'FILTERS';
export const CHOOSE_THE_FILTERS_ = 'CHOOSE_THE_FILTERS_';
export const USERS = 'USERS';
export const APPROVED_CONVERSIONS = 'APPROVED_CONVERSIONS';
export const HOLD_CONVERSIONS = 'HOLD_CONVERSIONS';
export const REJECTED_CONVERSIONS = 'REJECTED_CONVERSIONS';
export const ALL_GOALS = 'ALL_GOALS';
export const OFFER = 'OFFER';
export const OFFER_L = 'OFFER_L';
export const CONNECTED_CHANNELS = 'CONNECTED_CHANNELS';
export const CONNECTED_MESSENGERS_TO_OFFERS = 'CONNECTED_MESSENGERS_TO_OFFERS';
export const THE_AUDIENCE = 'THE_AUDIENCE';
export const THE_AUDIENCE_DESC = 'THE_VOLUME_OF_ACTIVE_SUBSCRIBERS_';
export const SUBSCRIBERS = 'SUBSCRIBERS';
export const SUBSCRIBERS_DESC = 'SUBSCRIBERS_DESC';
export const BANNED = 'BANNED';
export const COMPLETED_ALL_ACTIONS = 'COMPLETED_ALL_ACTIONS';
export const CONVERSIONS = 'CONVERSIONS';
export const REVENUE = 'REVENUE';
export const NUMBER_OF_FIRST_EVENT_CONVERSIONS = 'NUMBER_OF_FIRST_EVENT_CONVERSIONS';
export const FIRST_EVENT_CONVERSION_PERCENTAGE = 'FIRST_EVENT_CONVERSION_PERCENTAGE';
export const REPORT_BY_DAY = 'REPORT_BY_DAY';
export const BY_HOURS = 'BY_HOURS';
export const BY_DAY = 'BY_DAY';
export const BY_MONTH = 'BY_MONTH';
export const BY_YEAR = 'BY_YEAR';
export const BY_OFFERS = 'BY_OFFERS';
export const BY_CHANNELS = 'BY_CHANNELS';
export const BY_CHANNEL = 'BY_CHANNEL';
export const CLICKS = 'CLICKS';
export const APPROVED = 'APPROVED';
export const REVENUE_PER_CONVERSION = 'REVENUE_PER_CONVERSION';
export const LTV = 'LTV';
export const LTV_DESC = 'LTV_DESCRIPTION';
export const INCOME_PER_DIALOG = 'INCOME_PER_DIALOG';
export const INCOME_PER_DIALOG_DESCRIPTION = 'INCOME_PER_DIALOG_DESCRIPTION';
export const DELETE_ERROR = 'DELETE_ERROR';
export const OFFER_INFO = 'OFFER_INFO';
export const CHANNEL = 'CHANNEL';
export const CHANNEL_L = 'CHANNEL_L';
export const CHANNEL_L_1 = 'CHANNEL_L_1';
export const CHANNEL_L_2 = 'CHANNEL_L_2';
export const PLATFORM = 'PLATFORM';
export const OFFERS = 'OFFERS';
export const OFFERS_L = 'OFFERS_L';
export const LOGO = 'LOGO';
export const MORE = 'MORE';
export const PLACE_DELETED = 'PLACE_DELETED';
export const ACTIONS = 'ACTIONS';
export const ACTIONS_COUNT = 'ACTIONS_COUNT';
export const CHURN_COUNT = 'CHURN_COUNT';
export const INCOME = 'INCOME';
export const INCOME_DESC = 'INCOME_DESC';
export const SEARCH = 'SEARCH';
export const PLACE = 'PLACE';
export const PLACE_ACTIVE = 'PLACE_ACTIVE';
export const PLACE_STOPPED = 'PLACE_STOPPED';
export const ACTIVATION_ERROR = 'ACTIVATION_ERROR';
export const STOPPED_ERROR = 'STOPPED_ERROR';
export const WANT_DELETE_PACE = 'WANT_DELETE_PACE';
export const CANCEL = 'CANCEL';
export const BACK = 'BACK';
export const PAYOUTS = 'PAYOUTS';
export const CONNECT_OFFER = 'CONNECT_OFFER';
export const REGISTRATION = 'REGISTRATION';
export const MONETIZATION_OPTIONS = 'MONETIZATION_OPTIONS';
export const AVAILABLE_MESSENGERS = 'AVAILABLE_MESSENGERS';
export const EVENTS = 'EVENTS';
export const CLICK_PER_LINK_DESC = 'CLICK_PER_LINK_DESC';
export const OFFER_DESC = 'OFFER_DESC';
export const ERROR = 'ERROR';
export const APPROVE = 'APPROVE';
export const ATTRITION = 'ATTRITION';
export const ON_THIS_PAGE_YOU_CAN_SEE_THE_TERMS_ = 'ON_THIS_PAGE_YOU_CAN_SEE_THE_TERMS_';
export const OFFER_STAT_INCOME_DESC = 'OFFER_STAT_INCOME_DESC';
export const COST_PER_ACTION = 'COST_PER_ACTION';
export const COST_PER_ACTION_DESC = 'COST_PER_ACTION_DESC';
export const REVENUE_SHARE = 'REVENUE_SHARE';
export const REVENUE_SHARE_DESC = 'REVENUE_SHARE_DESC';
export const PENDING = 'PENDING';
export const EXIT = 'EXIT';
export const ALL_OFFERS = 'ALL_OFFERS';
export const MY_OFFERS = 'MY_OFFERS';
export const TOTAL = 'TOTAL';
export const TOTAL_INCOME = 'TOTAL_INCOME';
export const ACTION_TYPE = 'ACTION_TYPE';
export const NEW_USERS = 'NEW_USERS';
export const ACTIVE = 'ACTIVE';
export const ACTIVE_USERS = 'ACTIVE_USERS';
export const CHURN = 'CHURN';
export const DESCRIPTION = 'DESCRIPTION';
export const AVAILABLE_CHANNELS = 'AVAILABLE_CHANNELS';
export const OLDEST_FIRST = 'OLD_FIRST';
export const NEWEST_FIRST = 'NEWEST_FIRST';
export const GOALS_AND_ACTIONS = 'GOALS_AND_ACTIONS';
export const HOLD = 'HOLD';
export const ON_HOLD = 'ON_HOLD';
export const COUNTRY = 'COUNTRY';
export const CZECH_REPUBLIC = 'CZECH_REPUBLIC';
export const UKRAINE = 'UKRAINE';
export const SPAIN = 'SPAIN';
export const POLAND = 'POLAND';
export const USA = 'USA';
export const KAZAKHSTAN = 'KAZAKHSTAN';
export const RUSSIA = 'RUSSIA';
export const ITALY = 'ITALY';
export const GERMANY = 'GERMANY';
export const RESET = 'RESET';
export const DIALOGS_COUNT = 'DIALOGS_COUNT';
export const LEADS = 'LEADS';
export const CR_CLICKED_LINKS = 'CR_CLICKED_LINKS';
export const EARNINGS_PER_CONVERSION = 'EARNINGS_PER_CONVERSION';
export const CR_GIVEN_LINKS = 'CR_GIVEN_LINKS';
export const AUDIENCE_CHURN = 'AUDIENCE_CHURN';
export const EARNINGS_PER_DIALOG = 'EARNINGS_PER_DIALOG';
export const PAYMENTS_AMOUNT = 'PAYMENTS_AMOUNT';
export const RATING_NOT_YET_AVAILABLE = 'RATING_NOT_YET_AVAILABLE';
export const DIALOG_COST = 'DIALOG_COST';
export const CONNECT = 'CONNECT';
export const CHANNELS = 'CHANNELS';
export const NAME = 'NAME';
export const SETTINGS = 'SETTINGS';
export const DIALOGS = 'DIALOGS';
export const LINKS = 'LINKS';
export const STATISTICS = 'STATISTICS';
export const TOUCH_REPORT = 'TOUCH_REPORT';
export const ERROR_DURING_FETCH_BALANCE = 'ERROR_DURING_FETCH_BALANCE';
export const NEW_REPORT = 'NEW_REPORT';
export const DAILY_REPORT = 'DAILY_REPORT';
export const ANALYTICS = 'ANALYTICS';
export const REMOVE = 'REMOVE';
export const FINANCES = 'FINANCES';
export const GEOGRAPHY = 'GEOGRAPHY';
export const UNWANTED_CITIES = 'UNWANTED_CITIES';
export const PREFERRED_CITIES = 'PREFERRED_CITIES';
export const CITIES = 'CITIES';
export const NO_DATA = 'NO_DATA';
export const ALL_CHANNELS = 'ALL_CHANNELS';
export const ADD_CHANNEL = 'ADD_CHANNEL';
export const TRACKING_LINK = 'TRACKING_LINK';
export const SOURCE = 'SOURCE';
export const TRAFFIC_TYPE = 'TRAFFIC_TYPE';
export const CAMPAIGN_NAME = 'CAMPAIGN_NAME';
export const INCOME_PER_DAY = 'INCOME_PER_DAY';
export const EXTRA_FIELD = 'EXTRA_FIELD';
export const MAIN_PARAMETERS = 'MAIN_PARAMETERS';
export const ADDITIONAL_PARAMETERS = 'ADDITIONAL_PARAMETERS';
export const DIRECT_LINK = 'DIRECT_LINK';
export const HTML_CODE = 'HTML_CODE';
export const CLICK_TO_COPY = 'CLICK_TO_COPY';
export const COPIED = 'COPIED';
export const ORDER_A_PAYMENT = 'ORDER_A_PAYMENT';
export const TOTAL_ON_ACCOUNT = 'TOTAL_ON_ACCOUNT';
export const AVAILABLE_FOR_WITHDRAWAL = 'AVAILABLE_FOR_WITHDRAWAL';
export const CHOOSE_THE_RIGHT_MESSENGER = 'CHOOSE_THE_RIGHT_MESSENGER';
export const CHOOSE_A_MONETIZATION_METHOD_FOR_THIS_CHANNEL = 'CHOOSE_A_MONETIZATION_METHOD_FOR_THIS_CHANNEL';
export const CONNECT_CHANNEL = 'CONNECT_CHANNEL';
export const YOUR_CHANNEL_HAS_BEEN_SUCCESSFULLY_CONNECTED = 'YOUR_CHANNEL_HAS_BEEN_SUCCESSFULLY_CONNECTED';
export const ON_THE_CHANNELS_PAGE_ = 'ON_THE_CHANNELS_PAGE_';
export const GO_TO_CHANNELS_PAGE = 'GO_TO_CHANNELS_PAGE';
export const CONNECT_ANOTHER_CHANNEL = 'CONNECT_ANOTHER_CHANNEL';
export const CONNECTING_CHANNELS = 'CONNECTING_CHANNELS';
export const CONVERSION = 'CONVERSION';
export const OFFER_STAT_CONVERSION_DESC = 'CONVERSION_DESC';
export const ALL = 'ALL';
export const FAVORITES = 'FAVORITES';
export const PAYOUT_HISTORY = 'PAYOUT_HISTORY';
export const DOWNLOAD_REPORT = 'DOWNLOAD_REPORT';
export const AMOUNT = 'AMOUNT';
export const STATUS = 'STATUS';
export const ESTIMATE_DATE = 'ESTIMATE_DATE';
export const BILL = 'BILL';
export const BILLING_METHOD = 'BILLING_METHOD';
export const COMMENT = 'COMMENT';
export const CREATED = 'CREATED';
export const WAS_CREATED = 'WAS_CREATED';
export const IN_PROCESSING = 'IN_PROCESSING';
export const COMPLETED = 'COMPLETED';
export const REJECTED = 'REJECTED';
export const PAYMENT_HISTORY = 'PAYMENT_HISTORY';
export const REMOVING_A_HOLD = 'REMOVING_A_HOLD';
export const TERMS_AND_PROCEDURE_OF_PAYMENTS = 'TERMS_AND_PROCEDURE_OF_PAYMENTS';
export const TERMS_AND_PROCEDURE_OF_PAYMENTS_DESC = 'TERMS_AND_PROCEDURE_OF_PAYMENTS_DESC';
export const PAYMENT_TERM = 'PAYMENT_TERM';
export const COMMISSION_AMOUNT = 'COMMISSION_AMOUNT';
export const WORKING_DAY = 'WORKING_DAY';
export const WORKING_DAYS = 'WORKING_DAYS';
export const WORKING_DAYS_2 = 'WORKING_DAYS_2';
export const ZALEYCASH = 'ZALEYCASH';
export const GETUNIQ = 'GETUNIQ';
export const WEBMONEY_WMZ = 'WEBMONEY_WMZ';
export const YANDEX_MONEY = 'YANDEX_MONEY';
export const SOLAR_STAFF = 'SOLAR_STAFF';
export const CAPITALIST = 'CAPITALIST';
export const GOOGLE_ADWORDS = 'GOOGLE_ADWORDS';
export const MYTARGET = 'MYTARGET';
export const YANDEX_DIRECT = 'YANDEX_DIRECT';
export const BANK_CARD = 'BANK_CARD';
export const BANK_TRANSFER = 'BANK_TRANSFER';
export const BANK_TRANSFER_WITH_EDI = 'BANK_TRANSFER_WITH_EDI';
export const DATE = 'DATE';
export const BANK_TRANSFER_SELF_EMPLOYED = 'BANK_TRANSFER_SELF_EMPLOYED';
export const IN_PROGRESS = 'IN_PROGRESS';
export const CLOSED = 'CLOSED';
export const NEW_REQUEST = 'NEW_REQUEST';
export const ENTER_THE_AMOUNT_TO_WITHDRAW = 'ENTER_THE_AMOUNT_TO_WITHDRAW';
export const DOWNLOAD = 'DOWNLOAD';
export const REQUEST = 'REQUEST';
export const SWITCH_PROFILE = 'SWITCH_PROFILE';
export const YOU_NEED_TO_LOG_INTO_YOUR_FACEBOOK_ACCOUNT_TO_PROCEED = 'YOU_NEED_TO_LOG_INTO_YOUR_FACEBOOK_ACCOUNT_TO_PROCEED';
export const YOU_NEED_TO_LOG_INTO_YOUR_VK_ACCOUNT_TO_PROCEED = 'YOU_NEED_TO_LOG_INTO_YOUR_VK_ACCOUNT_TO_PROCEED';
export const GROUPS_VK = 'GROUPS_VK';
export const PAGES_FACEBOOK = 'PAGES_FACEBOOK';
export const CONNECTING_TELEGRAM_BOT = 'CONNECTING_TELEGRAM_BOT';
export const CONNECTING_VIBER_BOT = 'CONNECTING_VIBER_BOT';
export const TOKEN = 'TOKEN';
export const AUTHORIZATION = 'AUTHORIZATION';
export const SIGN_IN = 'SIGN_IN';
export const WHICH_CHANNEL_TO_CONNECT = 'WHICH_CHANNEL_TO_CONNECT';
export const PAUSE = 'PAUSE';
export const START = 'START';
export const MANAGE = 'MANAGE';
export const OFFER_CONNECT_TOOLTIP = 'OFFER_CONNECT_TOOLTIP';
export const CHANNELS_EXPANSION_LIST_TOOLTIP = 'CHANNELS_EXPANSION_LIST_TOOLTIP';
export const CHANNELS_ADD_BUTTON_TOOLTIP = 'CHANNELS_ADD_BUTTON_TOOLTIP';
export const CHANNELS_DIALOGS_TOOLTIP = 'CHANNELS_DIALOGS_TOOLTIP';
export const CHANNELS_LINKS_TOOLTIP = 'CHANNELS_LINKS_TOOLTIP';
export const CHANNELS_ANALYTICS_TOOLTIP = 'CHANNELS_ANALYTICS_TOOLTIP';
export const APPEARANCE = 'APPEARANCE';
export const THE_AMOUNT_OF_MONEY = 'THE_AMOUNT_OF_MONEY';
export const ERROR_LOADING_PAYMENT_METHODS = 'ERROR_LOADING_PAYMENT_METHODS';
export const MONEY_THAT_IS_CURRENTLY_AVAILABLE_FOR_WITHDRAWAL = 'MONEY_THAT_IS_CURRENTLY_AVAILABLE_FOR_WITHDRAWAL';
export const PAYMENTS_YOU_ORDERED = 'PAYMENTS_YOU_ORDERED';
export const WELCOME_TO_FINANCE = 'WELCOME_TO_FINANCE';
export const ON_THIS_PAGE_YOU_CAN_WITHDRAW_YOUR_FUNDS = 'ON_THIS_PAGE_YOU_CAN_WITHDRAW_YOUR_FUNDS';
export const MONEY_THAT_IS_CURRENTLY_NOT_AVAILABLE_FOR_WITHDRAWAL = 'MONEY_THAT_IS_CURRENTLY_NOT_AVAILABLE_FOR_WITHDRAWAL';
export const ORDER_A_PAYOUT = 'ORDER_A_PAYOUT';
export const YIELD_CHART = 'YIELD_CHART';
export const AVAILABLE = 'AVAILABLE';
export const EXIT_DATES_FROM_HOLD = 'EXIT_DATES_FROM_HOLD';
export const NUMBER_OF_CONVERSIONS = 'NUMBER_OF_CONVERSIONS';
export const TOTAL_L = 'TOTAL_L';
export const HOLD_REMOVAL_SCHEDULE = 'HOLD_REMOVAL_SCHEDULE';
export const HOLD_REMOVAL_SCHEDULE_ = 'HOLD_REMOVAL_SCHEDULE_';
export const CAME_OUT_OF_HOLD = 'CAME_OUT_OF_HOLD';
export const ERROR_WHILE_GETTING_LIST_OF_OFFERS = 'ERROR_WHILE_GETTING_LIST_OF_OFFERS';
export const SELECTED = 'SELECTED';
export const SELECTED_1 = 'SELECTED_1';
export const SELECTED_2 = 'SELECTED_2';
export const SELECTED_3 = 'SELECTED_3';
export const BY_OFFER = 'BY_OFFER';
export const OFFERS_1 = 'OFFERS_1';
export const BY_PLATFORM = 'BY_PLATFORM';
export const BY_UTM_MARKS = 'BY_UTM_MARKS';
export const PLATFORM_L = 'PLATFORM_L';
export const PLATFORM_L_1 = 'PLATFORM_L_1';
export const PLATFORMS_L = 'PLATFORMS_L';
export const MARK_L = 'MARK_L';
export const MARK_L_1 = 'MARK_L_1';
export const MARKS_L = 'MARKS_L';
export const COPY_LINK = 'COPY_LINK';
export const LINK_COPIED = 'LINK_COPIED';
export const UTM_MARKS = 'UTM_MARKS';
export const UTM_MARKS_CREATE_DESC = 'UTM_MARKS_CREATE_DESC';
export const DOES_NOT_HAVE_CONNECTED_OFFERS = 'DOES_NOT_HAVE_CONNECTED_OFFERS';
export const DOES_NOT_HAVE_CONNECTED_OFFERS_DESC = 'DOES_NOT_HAVE_CONNECTED_OFFERS_DESC';
export const GO_TO_OFFER_PAGE = 'GO_TO_OFFER_PAGE';
export const EMPTY = 'EMPTY';
export const EMPTY_FAVORITE_OFFERS = 'EMPTY_FAVORITE_OFFERS';
export const DOES_NOT_HAVE_HOLD = 'DOES_NOT_HAVE_HOLD';
export const DOES_NOT_HAVE_HOLD_DESC = 'DOES_NOT_HAVE_HOLD_DESC';
export const YOU_HAVE_NOT_MADE_ANY_PAYMENTS = 'YOU_HAVE_NOT_MADE_ANY_PAYMENTS';
export const YOU_HAVE_NOT_MADE_ANY_PAYMENTS_DESC = 'YOU_HAVE_NOT_MADE_ANY_PAYMENTS_DESC';
export const ORDER_PAYMENT = 'ORDER_PAYMENT';
export const NO_DATA_BY_FILTERS = 'NO_DATA_BY_FILTERS';
export const NO_CONNECTED_CHANNELS = 'NO_CONNECTED_CHANNELS';
export const NO_CONNECTED_CHANNELS_DESC = 'NO_CONNECTED_CHANNELS_DESC';
export const HAVE_USER_WHIS_PHONE = 'HAVE_USER_WHIS_PHONE';
export const HAVE_ACCOUNT_Q = 'HAVE_ACCOUNT_Q';
export const ENTER = 'ENTER';
export const NAME_FIELD = 'NAME_FIELD';
export const NAME_PLACEHOLDER = 'NAME_PLACEHOLDER';
export const PHONE_NUMBER = 'TELEPHONE_NUMBER';
export const INVALID_PASSWORD_TRY_ANOTHER_ONE = 'INVALID_PASSWORD_TRY_ANOTHER_ONE';
export const INVALID_PASSWORD = 'INVALID_PASSWORD';
export const GOOGLE_FIELDS = 'GOOGLE_FIELDS';
export const HELLO_S = 'HELLO';
export const VERIFICATION_CODE = 'VERIFICATION_CODE';
export const GO_BACK = 'GO_BACK';
export const LAST_REGISTRATION_STEP = 'LAST_REGISTRATION_STEP';
export const THINK_OF_A_PASSWORD = 'THINK_OF_A_PASSWORD';
export const PASSWORD = 'PASSWORD';
export const CONFIRM_PASSWORD = 'CONFIRM_PASSWORD';
export const ENTER_THE_CONFIRMATION_CODE = 'ENTER_THE_CONFIRMATION_CODE';
export const YOU_CAN_RESUBMIT_VIA = 'YOU_CAN_RESUBMIT_VIA';
export const RESEND_CODE = 'RESEND_CODE';
export const RESEND_CODE_BUTTON = 'RESEND_CODE_BUTTON';
export const SECONDS_S = 'SECONDS_S';
export const DONE = 'DONE';
export const PLEASE_WAIT_ACCOUNT_VERIFICATION = 'PLEASE_WAIT_ACCOUNT_VERIFICATION';
export const COMPLETE_THE_SURVEY = 'COMPLETE_THE_SURVEY';
export const REGISTRATION_REJECTED = 'REGISTRATION_REJECTED';
export const DATA_NOT_FOUND = 'DATA_NOT_FOUND';
export const INCORRECT_REQUEST = 'INCORRECT_REQUEST';
export const USER_IS_NOT_REGISTERED = 'USER_IS_NOT_REGISTERED';
export const INTERNAL_ERROR = 'INTERNAL_ERROR';
export const SERVER_UNAVAILABLE = 'SERVER_UNAVAILABLE';
export const REGISTRATION_REQUEST = 'REGISTRATION_REQUEST';
export const HOW_DID_YOU_HEAR_ABOUT_LINKSTREAM = 'HOW_DID_YOU_HEAR_ABOUT_LINKSTREAM';
export const WHAT_TYPE_OF_TRAFFIC_DO_YOU_USE = 'WHAT_TYPE_OF_TRAFFIC_DO_YOU_USE';
export const HOW_MANY_SUBSCRIBERS = 'HOW_MANY_SUBSCRIBERS';
export const DO_YOU_HAVE_EXPERIENCE_THROUGH_CPA = 'DO_YOU_HAVE_EXPERIENCE_THROUGH_CPA';
export const ARE_YOU_REGISTERED_A_AN_INDIVIDUAL_ENTREPRENEUR = 'ARE_YOU_REGISTERED_A_AN_INDIVIDUAL_ENTREPRENEUR';
export const SEND_APPLICATION = 'SEND_REQUEST';
export const APPLICATION_SUCCESSFULLY_CREATED = 'APPLICATION_SUCCESSFULLY_CREATED';
export const CONTINUE = 'CONTINUE';
export const REMEMBER_ME = 'REMEMBER_ME';
export const INVALID_INPUT_FORMAT = 'INVALID_INPUT_FORMAT';
export const EMAIL_OR_PHONE = 'EMAIL_OR_PHONE';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const IF_YOU_HAVE_NOT_ACCOUNT = 'IF_YOU_HAVE_NOT_ACCOUNT';
export const REGISTER = 'REGISTER';

