import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button, Grid, Paper, Typography, withStyles,
} from '@material-ui/core';
import classnames from 'classnames';

import logo from './logo.jpg';

const Error = ({ classes }) => (
  <Grid container className={classes.container}>
    <div className={classes.logotype}>
      <img className={classes.logotypeIcon} src={logo} alt="logo" />
      <Typography variant="h3" color="white" className={classes.logotypeText}>Aisales.Admin</Typography>
    </div>

    <Paper classes={{ root: classes.paperRoot }}>
      <Typography variant="h1" color="black" className={classnames(classes.textRow, classes.errorCode)}>404</Typography>
      <Typography variant="h5" color="textSecondary" className={classes.textRow}>Упс! Кажется эта страница еще не существует.</Typography>
      <Typography variant="h6" color="textSecondary" className={classnames(classes.textRow, classes.safetyText)}>А скорее всего никогда не появится.</Typography>
      <Button variant="contained" color="primary" component={Link} to="/" size="large" className={classes.backButton}>Вернуться назад</Button>
    </Paper>
  </Grid>
);

const styles = (theme) => ({
  container: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#2a2a2a',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  logotype: {
    display: 'flex',
    alignItems: 'center',

  },
  logotypeText: {
    fontWeight: 500,
    color: 'white',

  },
  logotypeIcon: {
    width: 70,

  },
  paperRoot: {

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 404,
  },
  textRow: {
    textAlign: 'center',
  },
  errorCode: {
    fontSize: 148,
    fontWeight: 600,
  },
  safetyText: {
    fontWeight: 300,

  },
  backButton: {

    textTransform: 'none',
    fontSize: 22,
  },
});

export default withStyles(styles, { withTheme: true })(Error);
