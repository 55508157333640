'use strict';

/* eslint:disable */
Object.defineProperty(exports, '__esModule', { value: true });
// This file has been generated by https://github.com/reverbnation/protoc-gen-twirp_ts.
// Do not edit.
const twirp_1 = require('./twirp');

const Role_ScopesEntry = /** @class */ (function () {
  function Role_ScopesEntry(m) {
    this._json = {};
    if (m) {
      this._json.key = m.key;
      this._json.value = m.value;
    }
  }
  Object.defineProperty(Role_ScopesEntry.prototype, 'key', {
    // key (key)
    get() {
      return this._json.key;
    },
    set(value) {
      this._json.key = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(Role_ScopesEntry.prototype, 'value', {
    // value (value)
    get() {
      return this._json.value;
    },
    set(value) {
      this._json.value = value;
    },
    enumerable: true,
    configurable: true,
  });
  Role_ScopesEntry.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new Role_ScopesEntry({
      key: m.key,
      value: m.value,
    });
  };
  Role_ScopesEntry.prototype.toJSON = function () {
    return this._json;
  };
  return Role_ScopesEntry;
}());
exports.Role_ScopesEntry = Role_ScopesEntry;
const Role = /** @class */ (function () {
  function Role(m) {
    this._json = {};
    if (m) {
      this._json.id = m.id;
      this._json.name = m.name;
      this._json.project = m.project;
      this._json.lastUpdate = m.lastUpdate;
      this._json.scopes = m.scopes;
    }
  }
  Object.defineProperty(Role.prototype, 'id', {
    // id (id)
    get() {
      return this._json.id;
    },
    set(value) {
      this._json.id = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(Role.prototype, 'name', {
    // name (name)
    get() {
      return this._json.name;
    },
    set(value) {
      this._json.name = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(Role.prototype, 'project', {
    // project (project)
    get() {
      return this._json.project;
    },
    set(value) {
      this._json.project = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(Role.prototype, 'lastUpdate', {
    // lastUpdate (lastUpdate)
    get() {
      return this._json.lastUpdate;
    },
    set(value) {
      this._json.lastUpdate = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(Role.prototype, 'scopes', {
    // scopes (scopes)
    get() {
      return this._json.scopes || [];
    },
    set(value) {
      this._json.scopes = value;
    },
    enumerable: true,
    configurable: true,
  });
  Role.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new Role({
      id: m.id,
      name: m.name,
      project: m.project,
      lastUpdate: m.lastUpdate,
      scopes: m.scopes,
    });
  };
  Role.prototype.toJSON = function () {
    return this._json;
  };
  return Role;
}());
exports.Role = Role;
const GetOneResponse = /** @class */ (function () {
  function GetOneResponse(m) {
    this._json = {};
    if (m) {
      this._json.role = m.role;
    }
  }
  Object.defineProperty(GetOneResponse.prototype, 'role', {
    // role (role)
    get() {
      return this._json.role;
    },
    set(value) {
      this._json.role = value;
    },
    enumerable: true,
    configurable: true,
  });
  GetOneResponse.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new GetOneResponse({
      role: Role.fromJSON(m.role),
    });
  };
  GetOneResponse.prototype.toJSON = function () {
    return this._json;
  };
  return GetOneResponse;
}());
exports.GetOneResponse = GetOneResponse;
const GetManyResponse = /** @class */ (function () {
  function GetManyResponse(m) {
    this._json = {};
    if (m) {
      this._json.roles = m.roles;
    }
  }
  Object.defineProperty(GetManyResponse.prototype, 'roles', {
    // roles (roles)
    get() {
      return this._json.roles || [];
    },
    set(value) {
      this._json.roles = value;
    },
    enumerable: true,
    configurable: true,
  });
  GetManyResponse.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new GetManyResponse({
      roles: (m.roles || []).map((v) => Role.fromJSON(v)),
    });
  };
  GetManyResponse.prototype.toJSON = function () {
    return this._json;
  };
  return GetManyResponse;
}());
exports.GetManyResponse = GetManyResponse;
const CreateResponse = /** @class */ (function () {
  function CreateResponse(m) {
    this._json = {};
    if (m) {
      this._json.id = m.id;
    }
  }
  Object.defineProperty(CreateResponse.prototype, 'id', {
    // id (id)
    get() {
      return this._json.id;
    },
    set(value) {
      this._json.id = value;
    },
    enumerable: true,
    configurable: true,
  });
  CreateResponse.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new CreateResponse({
      id: m.id,
    });
  };
  CreateResponse.prototype.toJSON = function () {
    return this._json;
  };
  return CreateResponse;
}());
exports.CreateResponse = CreateResponse;
const DeleteResponse = /** @class */ (function () {
  function DeleteResponse(m) {
    this._json = {};
    if (m) {
    }
  }
  DeleteResponse.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new DeleteResponse({});
  };
  DeleteResponse.prototype.toJSON = function () {
    return this._json;
  };
  return DeleteResponse;
}());
exports.DeleteResponse = DeleteResponse;
const UpdateResponse = /** @class */ (function () {
  function UpdateResponse(m) {
    this._json = {};
    if (m) {
    }
  }
  UpdateResponse.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new UpdateResponse({});
  };
  UpdateResponse.prototype.toJSON = function () {
    return this._json;
  };
  return UpdateResponse;
}());
exports.UpdateResponse = UpdateResponse;
const GetOneRequest = /** @class */ (function () {
  function GetOneRequest(m) {
    this._json = {};
    if (m) {
      this._json.id = m.id;
    }
  }
  Object.defineProperty(GetOneRequest.prototype, 'id', {
    // id (id)
    get() {
      return this._json.id;
    },
    set(value) {
      this._json.id = value;
    },
    enumerable: true,
    configurable: true,
  });
  GetOneRequest.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new GetOneRequest({
      id: m.id,
    });
  };
  GetOneRequest.prototype.toJSON = function () {
    return this._json;
  };
  return GetOneRequest;
}());
exports.GetOneRequest = GetOneRequest;
const GetManyRequest = /** @class */ (function () {
  function GetManyRequest(m) {
    this._json = {};
    if (m) {
      this._json.project = m.project;
    }
  }
  Object.defineProperty(GetManyRequest.prototype, 'project', {
    // project (project)
    get() {
      return this._json.project;
    },
    set(value) {
      this._json.project = value;
    },
    enumerable: true,
    configurable: true,
  });
  GetManyRequest.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new GetManyRequest({
      project: m.project,
    });
  };
  GetManyRequest.prototype.toJSON = function () {
    return this._json;
  };
  return GetManyRequest;
}());
exports.GetManyRequest = GetManyRequest;
const CreateRequest_ScopesEntry = /** @class */ (function () {
  function CreateRequest_ScopesEntry(m) {
    this._json = {};
    if (m) {
      this._json.key = m.key;
      this._json.value = m.value;
    }
  }
  Object.defineProperty(CreateRequest_ScopesEntry.prototype, 'key', {
    // key (key)
    get() {
      return this._json.key;
    },
    set(value) {
      this._json.key = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(CreateRequest_ScopesEntry.prototype, 'value', {
    // value (value)
    get() {
      return this._json.value;
    },
    set(value) {
      this._json.value = value;
    },
    enumerable: true,
    configurable: true,
  });
  CreateRequest_ScopesEntry.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new CreateRequest_ScopesEntry({
      key: m.key,
      value: m.value,
    });
  };
  CreateRequest_ScopesEntry.prototype.toJSON = function () {
    return this._json;
  };
  return CreateRequest_ScopesEntry;
}());
exports.CreateRequest_ScopesEntry = CreateRequest_ScopesEntry;
const CreateRequest = /** @class */ (function () {
  function CreateRequest(m) {
    this._json = {};
    if (m) {
      this._json.name = m.name;
      this._json.scopes = m.scopes;
    }
  }
  Object.defineProperty(CreateRequest.prototype, 'name', {
    // name (name)
    get() {
      return this._json.name;
    },
    set(value) {
      this._json.name = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(CreateRequest.prototype, 'scopes', {
    // scopes (scopes)
    get() {
      return this._json.scopes || [];
    },
    set(value) {
      this._json.scopes = value;
    },
    enumerable: true,
    configurable: true,
  });
  CreateRequest.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new CreateRequest({
      name: m.name,
      scopes: m.scopes,
    });
  };
  CreateRequest.prototype.toJSON = function () {
    return this._json;
  };
  return CreateRequest;
}());
exports.CreateRequest = CreateRequest;
const DeleteRequest = /** @class */ (function () {
  function DeleteRequest(m) {
    this._json = {};
    if (m) {
      this._json.id = m.id;
    }
  }
  Object.defineProperty(DeleteRequest.prototype, 'id', {
    // id (id)
    get() {
      return this._json.id;
    },
    set(value) {
      this._json.id = value;
    },
    enumerable: true,
    configurable: true,
  });
  DeleteRequest.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new DeleteRequest({
      id: m.id,
    });
  };
  DeleteRequest.prototype.toJSON = function () {
    return this._json;
  };
  return DeleteRequest;
}());
exports.DeleteRequest = DeleteRequest;
const UpdateRequest_ScopesEntry = /** @class */ (function () {
  function UpdateRequest_ScopesEntry(m) {
    this._json = {};
    if (m) {
      this._json.key = m.key;
      this._json.value = m.value;
    }
  }
  Object.defineProperty(UpdateRequest_ScopesEntry.prototype, 'key', {
    // key (key)
    get() {
      return this._json.key;
    },
    set(value) {
      this._json.key = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(UpdateRequest_ScopesEntry.prototype, 'value', {
    // value (value)
    get() {
      return this._json.value;
    },
    set(value) {
      this._json.value = value;
    },
    enumerable: true,
    configurable: true,
  });
  UpdateRequest_ScopesEntry.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new UpdateRequest_ScopesEntry({
      key: m.key,
      value: m.value,
    });
  };
  UpdateRequest_ScopesEntry.prototype.toJSON = function () {
    return this._json;
  };
  return UpdateRequest_ScopesEntry;
}());
exports.UpdateRequest_ScopesEntry = UpdateRequest_ScopesEntry;
const UpdateRequest = /** @class */ (function () {
  function UpdateRequest(m) {
    this._json = {};
    if (m) {
      this._json.id = m.id;
      this._json.name = m.name;
      this._json.scopes = m.scopes;
    }
  }
  Object.defineProperty(UpdateRequest.prototype, 'id', {
    // id (id)
    get() {
      return this._json.id;
    },
    set(value) {
      this._json.id = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(UpdateRequest.prototype, 'name', {
    // name (name)
    get() {
      return this._json.name;
    },
    set(value) {
      this._json.name = value;
    },
    enumerable: true,
    configurable: true,
  });
  Object.defineProperty(UpdateRequest.prototype, 'scopes', {
    // scopes (scopes)
    get() {
      return this._json.scopes || [];
    },
    set(value) {
      this._json.scopes = value;
    },
    enumerable: true,
    configurable: true,
  });
  UpdateRequest.fromJSON = function (m) {
    if (m === void 0) { m = {}; }
    return new UpdateRequest({
      id: m.id,
      name: m.name,
      scopes: m.scopes,
    });
  };
  UpdateRequest.prototype.toJSON = function () {
    return this._json;
  };
  return UpdateRequest;
}());
exports.UpdateRequest = UpdateRequest;
const RoleService = /** @class */ (function () {
  function RoleService(hostname, fetch) {
    this.path = '/twirp/admin.backend.RoleService/';
    this.hostname = hostname;
    this.fetch = fetch;
  }
  RoleService.prototype.url = function (name) {
    return this.hostname + this.path + name;
  };
  RoleService.prototype.getOne = function (params, headers) {
    if (headers === void 0) { headers = {}; }
    return this.fetch(this.url('GetOne'), twirp_1.createTwirpRequest(params, headers)).then((res) => {
      if (!res.ok) {
        return twirp_1.throwTwirpError(res);
      }
      return res.json().then((m) => GetOneResponse.fromJSON(m));
    });
  };
  RoleService.prototype.getMany = function (params, headers) {
    if (headers === void 0) { headers = {}; }
    return this.fetch(this.url('GetMany'), twirp_1.createTwirpRequest(params, headers)).then((res) => {
      if (!res.ok) {
        return twirp_1.throwTwirpError(res);
      }
      return res.json().then((m) => GetManyResponse.fromJSON(m));
    });
  };
  RoleService.prototype.create = function (params, headers) {
    if (headers === void 0) { headers = {}; }
    return this.fetch(this.url('Create'), twirp_1.createTwirpRequest(params, headers)).then((res) => {
      if (!res.ok) {
        return twirp_1.throwTwirpError(res);
      }
      return res.json().then((m) => CreateResponse.fromJSON(m));
    });
  };
  RoleService.prototype.update = function (params, headers) {
    if (headers === void 0) { headers = {}; }
    return this.fetch(this.url('Update'), twirp_1.createTwirpRequest(params, headers)).then((res) => {
      if (!res.ok) {
        return twirp_1.throwTwirpError(res);
      }
      return res.json().then((m) => UpdateResponse.fromJSON(m));
    });
  };
  RoleService.prototype.delete = function (params, headers) {
    if (headers === void 0) { headers = {}; }
    return this.fetch(this.url('Delete'), twirp_1.createTwirpRequest(params, headers)).then((res) => {
      if (!res.ok) {
        return twirp_1.throwTwirpError(res);
      }
      return res.json().then((m) => DeleteResponse.fromJSON(m));
    });
  };
  return RoleService;
}());
exports.RoleService = RoleService;
