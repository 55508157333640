import { Route, Switch } from 'react-router-dom';
import React from 'react';
import Login from '@pages/entrance/components/Login';
import Registration from '@pages/entrance/components/Registration';
import EntranceWrapper from '@pages/entrance/components/EntranceWrapper';
import { useLocation } from 'react-router';

const Entrance = () => {
  const location = useLocation();
  return (
    <div>
      <EntranceWrapper currentTabIdx={location.pathname === '/login' ? 0 : 1}>
        <Switch>
          <Route path="/login" render={() => <Login />} />
          <Route path="/register" render={() => <Registration />} />
        </Switch>
      </EntranceWrapper>
    </div>
  );
};
export default Entrance;
