import React from 'react';
import { greyColor } from '@main/lib';

const style = {
  color: greyColor[25],
};

const dateFormatOptions = [
  {
    value: ['year', 'month', 'day', 'hour'],
    label: <div>
      По часам
      <span style={style}>
        {' '}
        (час, день, месяц, год)
      </span>
    </div>,
  },
  {
    value: ['year', 'month', 'day'],
    label: <div>
      По дням
      <span style={style}>
        {' '}
        (день, месяц, год)
      </span>
    </div>,
  },
  {
    value: ['year', 'month'],
    label: <div>
      По месяцам
      <span style={style}>
        {' '}
        (неделя, месяц, год)
      </span>
    </div>,
  },
  {
    value: ['year'],
    label: <div>
      По годам
      <div style={style}>
        {' '}
        (год)
      </div>

    </div>,
  },
];
export default dateFormatOptions;
