import React from 'react';
import {
  Button, DownloadIcon, RefreshIcon,
  blueColor, Typography,
  FilterIcon,
} from '@main/lib';

import { withStyles } from '@material-ui/core';
import clsx from 'clsx';
import i18n from "@main/i18n";
import {FILTERS} from "@main/i18n/aliases";

const style = () => ({
  counter: {
    width: '19px',
    height: '19px',
    borderRadius: '50%',
    backgroundColor: blueColor[100],
    border: 'white solid 2px',
    boxSizing: 'content-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const Counter = withStyles(style)(({ number, classes, className }) => (
  <div className={clsx(classes.counter, className)}>
    <Typography variant="detailed" style="white" bold>
      {number}
    </Typography>

  </div>
));

const DashboardRightUpperCorner = ({
  classes, onClickDownload, onClickRefresh, onClickFilters, number,
}) => (
  <div className={classes.rightUpperPart}>
    <Button color="secondary" icon={<DownloadIcon />} onClick={onClickDownload} />
    <Button color="secondary" icon={<RefreshIcon />} onClick={onClickRefresh} />
    <div className={classes.rightUpperPart__filterButtonWrapper}>
      {number !== 0 && <Counter number={number} className={classes.rightUpperPart__counter} />}
      {/* <Button size="small" icon={<FilterIcon />} onClick={onClickFilters}>Фильтры</Button> */}
      <Button size="small" icon={<FilterIcon />} onClick={onClickFilters}>{i18n.t(FILTERS)}</Button>
    </div>
  </div>
);

const styles = () => ({
  rightUpperPart__counter: {
    position: 'absolute',
    zIndex: '100',
    right: '-9px',
    top: '-9px',
  },
  rightUpperPart__filterButtonWrapper: {
    position: 'relative',
  },
  rightUpperPart: {
    display: 'flex',
    '& button': {
      marginLeft: '10px',
    },
  },

});

export default withStyles(styles)(DashboardRightUpperCorner);
