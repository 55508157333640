import React from 'react';
import { withStyles } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';

const style = () => ({
  root: {
    width: 'fit-content',
    padding: '0px',
    marginTop: '0px',
    marginLeft: '0px',
    marginRight: '0px',
    marginBottom: '0px',
    '& .Toastify__toast': {
      width: '400px',
      padding: '0px',
      margin: '1px',
      marginBottom: '10px',
    },
    '& .Toastify__toast-body': {
      padding: '0px',
      margin: 'px',
    },
  },
});

const Alert = React.forwardRef((props, ref) => {
  const { classes } = props;
  return (
    <ToastContainer
      draggable={false}
      autoClose={5000}
      closeOnClick={false}
      ref={ref}
      className={classes.root}
      {...props}
    />
  );
});
export default withStyles(style)(Alert);
