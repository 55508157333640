import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import ColorPicker from './ColorPicker';

const Colors = ({ classes, colors, onChange = () => {} }) => (
  <div className={classes.root}>
    <div>
      <div className={classes.inlineColor}>
        <ColorPicker
          current={colors.text}
          onChange={(v) => onChange('text', v)}
        />
        <Typography variant="caption">
          {/* Текст */}
          Text
        </Typography>
      </div>
      <div className={classes.inlineColor}>
        <ColorPicker
          current={colors.links}
          onChange={(v) => onChange('links', v)}
        />
        <Typography variant="caption">
          {/* Ссылки */}
          Links
        </Typography>
      </div>
    </div>
    <div>
      <div className={classes.inlineColor}>
        <ColorPicker
          current={colors.name}
          onChange={(v) => onChange('name', v)}
        />
        <Typography variant="caption">
          {/* Имя */}
          Name
        </Typography>
      </div>
      <div className={classes.inlineColor}>
        <ColorPicker
          current={colors.time}
          onChange={(v) => onChange('time', v)}
        />
        <Typography variant="caption">
          {/* Время отправки */}
          Send time
        </Typography>
      </div>
    </div>
  </div>
);

const styles = {
  root: {
    padding: '0 5px',
    width: 'fit-content',
    marginBottom: '5px',
    display: 'flex',
    '& > div:first-child': {
      paddingRight: '5px',
    },
    '& > div:last-child': {
      paddingLeft: '5px',
    },
  },
  inlineColor: {
    paddingTop: '5px',
    display: 'flex',
    alignItems: 'center',
    width: '200px',
  },
};

export default withStyles(styles)(Colors);
