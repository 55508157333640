import React from 'react';
import { connect } from 'react-redux';
import { InputBase, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import PageTitle from '@components/AnotherPageTitle';
import Background from './Background';
import { setData, update } from '../../actions/landbot';
import Avatar from './Avatar';
import Colors from './Colors';

const Form = ({ classes, ...props }) => {
  const setName = (name) => {
    const data = JSON.parse(JSON.stringify(props.data));
    data.name = name;
    props.onChange(data);
  };

  const setFirstMessage = (first_message) => {
    const data = JSON.parse(JSON.stringify(props.data));
    data.layout.first_message = first_message;
    props.onChange(data);
  };

  const setBackground = (bg) => {
    const data = JSON.parse(JSON.stringify(props.data));
    data.layout.background = bg;
    props.onChange(data);
  };

  const setBotName = (name) => {
    const data = JSON.parse(JSON.stringify(props.data));
    data.layout.bot_message.name = name;
    props.onChange(data);
  };

  const setAvatar = (avatar) => {
    const data = JSON.parse(JSON.stringify(props.data));
    data.layout.bot_message.avatar = avatar;
    props.onChange(data);
  };

  const setBotColors = (key, value) => {
    const data = JSON.parse(JSON.stringify(props.data));
    data.layout.bot_message.colors[key] = value;
    props.onChange(data);
  };

  const setUserColors = (key, value) => {
    const data = JSON.parse(JSON.stringify(props.data));
    data.layout.user_message.colors[key] = value;
    props.onChange(data);
  };

  return (
    <div>
      <PageTitle
        style={{ whiteSpace: 'nowrap', overflow: 'hidden', marginBottom: '0' }}
        no-alpha
        title={(
          <>
            <span style={{ fontSize: '14px' }}>
              <Link to="/app/channels" style={{ color: '#4A4A4A' }}>Каналы</Link>
              {' '}
              /
              {' '}
            </span>
            <span>
              {props.data.name}
              {/* <InputBase className={classes.name} value={props.data.name} */}
              {/*             onChange={e => setName(e.target.value)}/> */}
            </span>
          </>
)}
        button={(
          <Button
            variant="outlined"
            color="primary"
            disabled={!props.changed}
            onClick={() => props.onSave(props.data)}
          >
            Сохранить
          </Button>
)}
      />

      <div className={classes.root}>
        <Typography variant="h4" className={classes.header}>Сообщения оператора</Typography>
        <Typography variant="caption" className={classes.label}>
          Аватар и имя
        </Typography>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ width: '60px' }}>
            <Avatar
              style={{ margin: '5px' }}
              initial={props.data.layout.bot_message.avatar}
              onChange={setAvatar}
            />
          </div>
          <div style={{ flex: 1 }}>
            <div className={classes.inputWrapper} style={{ marginTop: '3px' }}>
              <InputBase
                value={props.data.layout.bot_message.name}
                onChange={(e) => setBotName(e.target.value)}
                placeholder="Имя"
                style={{ width: '100%', marginLeft: '10px', paddingTop: '2px' }}
              />
            </div>
          </div>
        </div>

        <div style={{ marginTop: '10px' }}>
          <Typography variant="caption" className={classes.label}>
            Приветствие
          </Typography>
          <div style={{ padding: '5px', maxWidth: '500px' }}>
            <div className={classes.inputWrapper} style={{ marginTop: '3px', height: 'auto', width: 'auto' }}>
              <InputBase
                multiline
                rows={3}
                rowsMax={5}
                value={props.data.layout.first_message}
                onChange={(e) => setFirstMessage(e.target.value)}
                placeholder="Имя"
                style={{
                  width: '100%', marginLeft: '10px', paddingTop: '2px', fontSize: '13px',
                }}
              />
            </div>
            <Typography variant="colorTextSecondary" style={{ fontSize: '12px' }}>
              Сообщение будет отправлено автоматически, когда пользователь впервые откроет чат.
            </Typography>
          </div>
        </div>

        <div style={{ marginTop: '10px' }}>
          <Typography variant="caption" className={classes.label}>
            Цвета
          </Typography>
          <Colors colors={props.data.layout.bot_message.colors} onChange={setBotColors} />
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: '5px' }}>
            <Background
              initial={props.data.layout.bot_message.colors.background}
              onChange={(bg) => setBotColors('background', bg)}
            />
            <Typography variant="caption">
              Фон
            </Typography>
          </div>
        </div>
        <Typography style={{ marginTop: '10px' }} variant="h4" className={classes.header}>
          Сообщения
          пользователя
        </Typography>

        <div style={{ marginTop: '10px' }}>
          <Typography variant="caption" className={classes.label}>
            Цвета
          </Typography>
          <Colors colors={props.data.layout.user_message.colors} onChange={setUserColors} />
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: '5px' }}>
            <Background
              initial={props.data.layout.user_message.colors.background}
              onChange={(bg) => setUserColors('background', bg)}
            />
            <Typography variant="caption">
              Фон
            </Typography>
          </div>
        </div>
        <Typography variant="h4" className={classes.header}>Общие настройки</Typography>
        <Typography variant="caption" className={classes.label}>Фон</Typography>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '5px' }}>
          <Background initial={props.data.layout.background} onChange={setBackground} />
        </div>
      </div>
    </div>
  );
};

const styles = {
  root: {
    padding: '20px 10px',
    height: 'calc(100vh - 85px)',
    overflow: 'auto',
  },
  name: {
    background: 'rgba(100,100,100, 0)',
    border: '1px solid #e6e6e6',
    width: 'calc(100% - 120px)',
    padding: '5px',
    height: '30px',
    borderRadius: '2px',
    fontSize: '18px',
    transition: 'background .2s',
    '&:hover': {
      background: 'white',
    },
    '&:focus-within': {
      background: 'white',
    },
  },
  header: {
    borderBottom: '1px solid #c5c5c5',
    paddingBottom: '5px',
    marginBottom: '10px',
  },
  inputWrapper: {
    display: 'flex',
    width: '75%',
    background: 'rgb(255, 255, 255)',
    paddingRight: '10px',
    border: '1px solid rgb(230, 230, 230)',
    boxSizing: 'border-box',
    borderRadius: '5px',
    height: '40px',
    transition: 'border .2s',
    '&.invalid': {
      border: '1px solid rgb(234, 132, 132)',
    },
  },
  label: {
    paddingLeft: '5px',
    marginTop: '5px',
    fontWeight: '600 !important',
  },
  '@global': {
    '*::-webkit-scrollbar': {
      width: '4px',
    },
    '*:hover::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
      position: 'absolute',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: '#a1a2a8',
      outline: '1px solid slategrey',
      borderRadius: '5px',
    },
  },
};

export default connect((state) => ({
  data: state.landbot.get('data'),
  changed: state.landbot.get('changed'),
}), (dispatch) => ({
  onChange: (data) => dispatch(setData(data)),
  onSave: (data) => dispatch(update(data)),
}))(withStyles(styles)(Form));
