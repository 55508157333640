import DashboardReportCreationUpdateFormBase from '@pages/dashboard/components/ReportCreationUpdateFormBase';
import React, { useState } from 'react';
import saveStatisticsReport from '@pages/dashboard/services/saveStatisticsReport';
import DashboardReportCreationUpdateFormBaseFinishedState
  from '@pages/dashboard/components/ReportCreationUpdateFormBase/components/FinishedState';

const DashboardReportCreationForm = ({ open, onClose, onFinish }) => {
  const [name, setName] = useState('');
  const [groupedCells, setGroupedCells] = useState([]);
  const [cellsWithData, setCellsWithData] = useState([]);
  const [counters, setCounters] = useState([]);
  const [countersAreEnabled, setCountersAreEnabled] = useState(false);
  return (
    <DashboardReportCreationUpdateFormBase
      open={open}
      name={name}
      onFinish={onFinish}
      counters={counters}
      setCounters={setCounters}
      setName={setName}
      countersAreEnabled={countersAreEnabled}
      setCountersAreEnabled={setCountersAreEnabled}
      modalTitle="Создание отчёта"
      onSave={saveStatisticsReport}
      setGroupedCells={setGroupedCells}
      setCellsWithData={setCellsWithData}
      onClose={onClose}
      groupedCells={groupedCells}
      cellsWithData={cellsWithData}
      finishedStateElement={<DashboardReportCreationUpdateFormBaseFinishedState buttonShown topText="Ваш отчёт успешно создан" />}
    />
  );
};
export default DashboardReportCreationForm;
