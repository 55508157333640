import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import { GradientPickerPopover } from 'react-linear-gradient-picker';
import { withStyles } from '@material-ui/core';

const addOpacityToHex = (color, opacity = 1) => {
  if (opacity === 1 || color.length > 9) {
    return color;
  }

  return color + Math.floor(opacity * 255).toString(16);
};

const WrappedSketchPicker = ({ onSelect, ...rest }) => (
  <SketchPicker
    {...rest}
    color={addOpacityToHex(rest.color, rest.opacity)}
    onChange={(c) => {
      onSelect(c.hex, c.rgb.a);
    }}
  />
);

const initialPallet = [
  { offset: '0.00', color: '#ffffff' },
  { offset: '1.00', color: '#000000' },
];

const GradientPicker = ({ background, classes, onChange }) => {
  const parse = (str) => {
    const first = /linear-gradient\((\d+)deg, /i;
    const second = /(.*)\s(\d+)%/i;
    if (!first.test(str)) {
      return null;
    }
    const angle = Number(first.exec(str)[1]);
    str = str.replace(first, '');
    str = str.slice(0, str.lastIndexOf(')')) + str.slice(str.lastIndexOf(')') + 1);
    return { angle, palette: str.split(', ').map((val) => ({ color: val.match(second)[1], offset: Number(val.match(second)[2]) / 100 })) };
  };
  const [angle, setAngle] = useState(parse(background) ? parse(background).angle : 90);
  const [palette, setPalette] = useState(parse(background) ? parse(background).palette : initialPallet);

  const handleChange = (palette, angle) => {
    const paletteStr = palette.map((pos) => `${pos.color} ${Math.round(pos.offset * 100)}%`);
    onChange(`linear-gradient(${angle}deg, ${paletteStr.join(', ')})`);
  };

  return (
    <div className={classes.root}>
      <GradientPickerPopover {...{
        open: true,
        angle,
        setAngle: (deg) => {
          setAngle(deg);
          handleChange(palette, deg);
        },
        showAnglePicker: true,
        width: 220,
        maxStops: 3,
        paletteHeight: 32,
        palette,
        onPaletteChange: (palette) => {
          setPalette(palette);
          handleChange(palette, angle);
        },
      }}
      >
        <WrappedSketchPicker disableAlpha />
      </GradientPickerPopover>
    </div>
  );
};

const styles = {
  root: {
    '& .popover': {
      position: 'initial',
      width: 'fit-content',
      margin: 'auto',
      background: 'white',
    },
    '& .trigger': {
      display: 'none',
    },
    '& .overlay': {
      display: 'none',
    },
  },
};

export default withStyles(styles)(GradientPicker);
