import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { MultiViewCalendar as MultiViewCalendarKendo } from '@progress/kendo-react-dateinputs';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import '@progress/kendo-theme-default/dist/all.css';
import { Menu, withStyles } from '@material-ui/core';
import { useRef } from 'react';
import { blueColor } from '../colors/index';

const Calendar = (props) => {
  const {
    children, classes, calendarProps, onChange, menuProps,
  } = props;
  const anchorEl = useRef(null);

  return (
    <div {...props}>
      <div ref={anchorEl} id="id">
        {children}
      </div>
      <Menu
        classes={{ paper: classes.menu }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl.current}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        anchorPosition={{ vertical: 'top', horizontal: 'center' }}
        {...menuProps}
      >
        <MultiViewCalendarKendo className={classes.calendar} mode="range" onChange={onChange} {...calendarProps} />
      </Menu>
    </div>
  );
};

const styles = () => ({
  calendar: {
    height: '303px',
    '&&&&&& .k-range-mid, &&&& .k-range-end, &&&& .k-range-start': {
      backgroundImage: `linear-gradient(transparent 1px, ${blueColor[20]} 1px, ${blueColor[20]} calc(100% - 1px), transparent calc(100% - 1px))`,
    },
    '&&&  .k-range-split-start::after': {
      backgroundImage: `linear-gradient(to left, ${blueColor[20]}, transparent 100%)`,
    },
    '&&& .k-range-split-end::after': {
      backgroundImage: `linear-gradient(to right, ${blueColor[20]}, transparent 100%)`,
    },
    '&&& .k-range-start .k-link, &&& .k-range-end .k-link': {
      backgroundColor: blueColor[100],
    },
    '&&& .k-footer .k-nav-today, &&& .k-calendar-header .k-today, &&& .k-content .k-today': {
      color: blueColor[100],
    },
  },
  menu: {
    marginTop: '190px',
  },
});
export default withStyles(styles)(Calendar);
