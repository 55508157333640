const getIsNothingSelected = (filterValues) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const filterValue of Object.values(filterValues)) {
    if (filterValue.length !== 0) {
      return false;
    }
  }
  return true;
};

export default getIsNothingSelected;
