import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { setLoggedFacebook } from '@store/actions';
import {
  CZECH_REPUBLIC,
  GERMANY,
  ITALY,
  KAZAKHSTAN,
  POLAND,
  RUSSIA,
  SPAIN,
  UKRAINE,
  USA,
} from '@main/i18n/aliases';
import { useTranslation } from 'react-i18next';
import { backendURL, twirpFetchOptions } from '@main/toRemove';
import { useHistory } from 'react-router';
import fetch from '@main/utils/handledFetch';
/* eslint-disable no-undef */

const style = () => ({});

const socialNetworkName = 'Facebook';
const FacebookConnector = ({ children, offerId, ...otherProps }) => {
  const isLoggedFacebook = useSelector((state) => state.splitview.get('isLoggedFacebook'));
  const dispatch = useDispatch();
  const [groups, setGroups] = useState(null);
  const [groupToAdd, setGroupToAdd] = useState(null);
  const [userName, setUserName] = useState(null);
  const { t } = useTranslation();
  const history = useHistory();
  const countries = [{ code: 'RU', name: t(RUSSIA) },
    { code: 'CZ', name: t(CZECH_REPUBLIC) },
    { code: 'UA', name: t(UKRAINE) },
    { code: 'ES', name: t(SPAIN) },
    { code: 'PL', name: t(POLAND) },
    { code: 'US', name: t(USA) },
    { code: 'KZ', name: t(KAZAKHSTAN) },
    { code: 'IT', name: t(ITALY) },
    { code: 'DE', name: t(GERMANY) }];

  useEffect(() => {
    window.fbAsyncInit = function () {
      FB.init({
        appId: '690302514718936',
        xfbml: true,
        version: 'v5.0',
        cookie: true,
      });

      FB.getLoginStatus(
        (response) => {
          statusChangeCallback(response);
        },
      );

      // Load the SDK asynchronously
      (function (d, s, id) {
        let js;
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          FB.getLoginStatus(
            (response) => {
              statusChangeCallback(response);
            },
          );
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    };
  });

  const addGroup = async ({
    country, language, monetization, name, connect_instagram, platform_id, token, translate, uri,
  }) => {
    const response = await fetch(
      `${backendURL
      }/twirp/cpa.offers.OffersManagementService/ConnectOffer`,
      {
        ...twirpFetchOptions,
        body: JSON.stringify({
          offer_id: Number(offerId),
          monetization,
          channel: 'facebook',
          group_id: platform_id,
          url: uri,
          platform_input: {
            name,
            channel: 'facebook',
            connect_instagram,
            token,
            id: platform_id,
            language,
            country,
            translate,
          },
        }),
      },
    );
    if (response.ok) {
      return await response.json();
    }
    return {};
  };

  const statusChangeCallback = (response) => {
    // The response object is returned with a status field that lets the
    // app know the current login status of the person.
    // Full docs on the response object can be found in the documentation
    // for FB.getLoginStatus().

    setLoggedFacebook(true);
    getList();
    // if (response.status === "connected") {
    // } else if ("unknown") {
    //     this.forceUpdate();
    // }
  };

  const facebookConnect = () => {
    localStorage.removeItem('fb_access_token');
    const auth = window.open(
      getRedirectURI(),
      'Авторзация',
      'height=600,width=600',
    );

    const poptick = setInterval(() => {
      if (auth.closed) {
        if (localStorage.getItem('fb_access_token') !== null) {
          FB.getLoginStatus((response) => {
            statusChangeCallback(response);
          });
          clearInterval(poptick);
          dispatch(setLoggedFacebook(true));
          getList();
          getName();
        }
      }
    }, 500);
  };
  const getRedirectURI = () => `https://www.facebook.com/dialog/oauth/?scope=manage_pages,pages_manage_metadata,pages_messaging,instagram_manage_comments,instagram_content_publish,instagram_manage_messages,instagram_manage_insights,instagram_basic&client_id=690302514718936&redirect_uri=${
    encodeURIComponent(`https://${window.location.host}/redirect/fb/user`)
  }&response_type=token`;

  const getName = () => {
    FB.api(`/me?access_token=${
      localStorage.getItem('fb_access_token')}`,
    'GET',
    { fields: 'name' },
    (response) => {
      setUserName(response.name);
    });
  };
  const getGroupToken = async ({ monetization }) => addGroup(
    {
      name: groupToAdd.name,
      platform_id: String(groupToAdd.groupId),
      // language: 'ru',
      // country: 'RU',
      connect_instagram: groupToAdd.connect_instagram,
      monetization,
      token: groupToAdd.access_token,
    },
  );

  const getList = () => {
    FB.api(
      `/me/accounts?access_token=${
        localStorage.getItem('fb_access_token')}`,
      'GET',
      { fields: 'tasks,access_token,id,name,picture{url}' },
      (response) => {
        if (!response.data) {
          dispatch(setLoggedFacebook(false));
          localStorage.removeItem('fb_access_token');
        }
        setGroups(
          response.data,
        );
      },
    );
  };

  useEffect(() => {
    if (localStorage.getItem('fb_access_token')) {
      getList();
      getName();
    }
  }, []);

  // if (localStorage.getItem("vk_access_token")) {
  //     getList();
  //     getUserName()
  // }

  const adaptGroups = (groups) => groups?.map((group) => {
    group.image = group.picture.data.url;
    return group;
  });

  return React.cloneElement(children, {
    setLogged: (isLogged) => {
      dispatch(setLoggedFacebook(isLogged));
    },
    socialNetworkName,
    groups: { items: adaptGroups(groups) },
    userName,
    setGroupToAdd,
    groupToAdd,
    connect: facebookConnect,
    onFinish: getGroupToken,
    isLogged: isLoggedFacebook,
    ...children.props,
    ...otherProps,
  });
};

export default withStyles(style)(FacebookConnector);
