import { AccountIcon, Input, MailIcon } from '@main/lib';
import PhoneInput from '@pages/entrance/components/PhoneInput';
import React from 'react';
import { withStyles } from '@material-ui/core';
import {NAME_FIELD, NAME_PLACEHOLDER, PHONE_NUMBER} from "@main/i18n/aliases";
import i18n from "@main/i18n";

const NameEmailPhoneInputs = ({
  classes,
  step,
  name,
  nameError,
  setName,
  setNameError,
  setPhoneNumber,
  phoneNumber,
  email,
  emailError,
  setEmail,
  setEmailError,
  isRegisterViaSocialNetwork,
}) => {
  const nameInput = (
    <div className={classes.element_marginBottom20}>
      <Input
        disabled={step === 2}
        value={name}
        placeholder={i18n.t(NAME_PLACEHOLDER)}
        className={classes.nameEmailPhoneInputs__input}
        errorText={nameError}
        label={i18n.t(NAME_FIELD)}
        onChange={(event) => {
          setName(event.target.value);
          setNameError('');
        }}
        icon={<AccountIcon />}
      />
    </div>
  );

  const phoneInput = (
    <div className={classes.element_marginBottom20}>
      <PhoneInput
        disabled={step === 2}
        value={phoneNumber}
        onChange={setPhoneNumber}
        label={i18n.t(PHONE_NUMBER)}
        className={classes.nameEmailPhoneInputs__input}
      />
    </div>
  );

  const emailInput = (
    <div className={classes.element_marginBottom20}>
      <Input
        disabled={step === 2}
        value={email}
        placeholder="email@mail.com"
        className={classes.nameEmailPhoneInputs__input}
        errorText={emailError}
        label="Email"
        onChange={(event) => {
          setEmail(event.target.value);
          setEmailError('');
        }}
        icon={<MailIcon />}
      />
    </div>
  );
  return (
    <div>
      {nameInput}
      {isRegisterViaSocialNetwork && emailInput}
      {phoneInput}
    </div>
  );
};

const styles = () => ({
  element_marginBottom20: {
    marginBottom: '20px',
    width: '100%',
  },
  nameEmailPhoneInputs__input: {
    width: '100%',
    '&>div:nth-of-type(2)': {
      width: '100%',
    },
  },
});

export default withStyles(styles)(NameEmailPhoneInputs);
