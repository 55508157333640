import React from 'react';
import { connect } from 'react-redux';
import { InputBase, withStyles } from '@material-ui/core';
import HttpsIcon from '@material-ui/icons/Https';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import Arrow from '@aisales/react-uikit';
import {
  frameLoaded, reset, setLanguage, toggleTracker,
} from '../../actions/landbot';

const Browser = ({ classes, ...props }) => {
  const handleFrameRef = (ref) => {
    if (ref == null) {
      return;
    }
    const iframe = ref;
    if (iframe.attachEvent) {
      iframe.attachEvent('onload', () => {
        props.frameLoaded();
      });
    } else {
      iframe.onload = function () {
        props.frameLoaded();
      };
    }
  };

  React.useEffect(() => () => {
    props.onUnmount();
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.addressBar} style={{ height: props.frameLoading ? 81 : 85 }}>
        <div className={classes.addressInput}>
          <HttpsIcon style={{ color: 'green' }} />
          {props.showTracker && (
          <>
            <div style={{
              flex: 1, paddingLeft: '10px', paddingTop: '2px', background: '#fffa001c',
            }}
            >
              <InputBase
                fullWidth
                readOnly
                onFocus={(e) => e.target.select()}
                value={`https://trk.aisales.online/click.php?key=zdzh49ados1i7ov688kr&grid=${props.data.alias}&source=admin.aisales.online&lang=${props.lang}`}
              />
            </div>
            <div style={{ background: '#eaeaef', height: 'inherit', padding: '5px' }}>
              <Arrow style={{ fontSize: '19px', color: '#797979' }} />
            </div>
          </>
          )}
          <div style={{
            flex: 1,
            paddingLeft: '10px',
            paddingTop: '2px',
            background: props.showTracker ? '#00ff001a' : 'none',
          }}
          >
            <InputBase
              fullWidth
              readOnly
              onFocus={(e) => e.target.select()}
              value={`https://${props.data.alias}.aisales.space/?lang=${props.lang}`}
            />
          </div>
        </div>
        <div className={classes.settings}>
          {/* <FormControlLabel */}
          {/*    style={{margin: 0, padding: '0 13px', borderRight: '1px dashed rgb(160, 160, 160)'}} */}
          {/*    control={ */}
          {/*        <Checkbox */}
          {/*            style={{ */}
          {/*                background: "none", */}
          {/*                border: "none", */}
          {/*                boxShadow: "none", */}
          {/*                opacity: 0.7, */}
          {/*                padding: '0' */}
          {/*            }} */}
          {/*            color="primary" */}
          {/*        /> */}
          {/*    } */}
          {/*    onChange={props.toggleTracker} */}
          {/*    checked={props.showTracker} */}
          {/*    labelPlacement={'start'} */}
          {/*    label={<span className={classes.settingLabel}>Показать ссылку на трекер</span>} */}
          {/* /> */}
          <FormControlLabel
            style={{
              margin: 0,
              padding: '0 13px',
              borderRight: '1px dashed rgb(160, 160, 160)',
              marginLeft: '10px',
            }}
            control={(
              <Select
                variant="standard"
                native
                disableUnderline
                value={props.lang}
                onChange={(e) => props.setLang(e.target.value)}
                inputProps={{
                  name: 'age',
                  id: 'age-native-simple',
                }}
                style={{
                  paddingTop: 0, paddingBottom: 0, fontSize: '12px', lineHeight: '1.2rem',
                }}
              >
                <option value="ru">Русский</option>
                <option value="en">Английский</option>
              </Select>
            )}
            labelPlacement="start"
            label={<span className={classes.settingLabel}>Язык</span>}
          />
        </div>
      </div>
      {props.frameLoading && <LinearProgress />}
      <iframe
        ref={handleFrameRef}
        src={`https://${props.data.alias}.aisales.space/?lang=${props.lang}&refresher=${props.data.last_update_time}&uid=new`}
        seamless
        className={classes.frame}
      />
    </div>
  );
};

const styles = {
  root: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
  },
  frame: {
    border: 'none',
    width: '100%',
    height: 'calc(100vh - 85px)',
    background: '#ffffff',
  },
  addressBar: {
    background: '#eaeaef',
  },
  addressInput: {
    height: '30px',
    background: '#cbcbd069',
    borderRadius: '5px',
    marginLeft: '10px',
    marginRight: '10px',
    marginTop: '15px',
    marginBottom: '10px',
    paddingLeft: '5px',
    display: 'flex',
    alignItems: 'center',
  },
  settingLabel: {
    color: '#565656',
    fontSize: '12px',
    marginRight: '10px',
    userSelect: 'none',
  },
  settings: {
    display: 'flex',
    height: '20px',
  },
};

export default connect((state) => ({
  data: state.landbot.get('data'),
  changed: state.landbot.get('changed'),
  frameLoading: state.landbot.get('frameLoading'),
  showTracker: state.landbot.get('trackerLink'),
  lang: state.landbot.get('lang'),
}), (dispatch) => ({
  frameLoaded: () => dispatch(frameLoaded),
  onUnmount: () => dispatch(reset),
  toggleTracker: () => dispatch(toggleTracker),
  setLang: (lang) => dispatch(setLanguage(lang)),
}))(withStyles(styles)(Browser));
