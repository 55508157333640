import React, { useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import { withStyles } from '@material-ui/core';
import { showToast } from '@pages/oldOffers/utils';
import Steps from '@pages/entrance/components/Registration/components/Steps';
import { fetchSecretKey, fetchVerificationId } from '@pages/finance/pages/BalancePage/services/phoneConfirmation';
import parsePhoneNumber from 'libphonenumber-js';
import {
  tryRegisterWithServicesStep1,
  tryRegisterWithServicesStep2,
} from '@pages/entrance/components/Registration/services/tryRegisterWithServicesStep1';
import FirstStep from '@pages/entrance/components/Registration/components/FirstStep';
import SecondStep from '@pages/entrance/components/Registration/components/SecondStep';

import ThirdStep from '@pages/entrance/components/Registration/components/ThirdStep';
import NameEmailPhoneInputs from '@pages/entrance/components/Registration/components/NameEmailPhoneInputs';
import defaultRegister from '@pages/entrance/components/Registration/services/defaultRegister';
import {
  validateEmail,
  validateName, validatePassword,
  validatePasswordConfirmation,
} from '@pages/entrance/components/Registration/utils/validators';
import {INVALID_PASSWORD, INVALID_PASSWORD_TRY_ANOTHER_ONE} from "@main/i18n/aliases";
import i18n from "@main/i18n";

// eslint-disable-next-line no-control-regex

const Registration = ({ classes }) => {
  const [refCode, setRefCode] = useState();
  const [name, setName] = useState(localStorage.getItem('name') || '');
  const [email, setEmail] = useState(localStorage.getItem('email') || '');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [isRegisterViaSocialNetwork, setIsRegisterViaSocialNetwork] = useState(false);
  const [userToken, setUserToken] = useState('');

  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [phoneVerificationToken, setPhoneVerificationToken] = useState('');
  const [passwordConfirmationError, setPasswordConfirmationError] = useState('');
  const [phoneConfirmationCode, setPhoneConfirmationCode] = useState('');
  const userType = 'WEBMASTER';
  // eslint-disable-next-line no-unused-vars
  const [isCodeError, setIsCodeError] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    setRefCode(searchParams.get('ref'));
    const query = new URLSearchParams(window.location.search);
    const code = query.get('code');
    // if there is code there it means that we can enter or registrate via some service
    if (code && step === 0) {
      let state = null;
      if (query?.get?.('state')) {
        state = JSON.parse?.(atob?.(query?.get?.('state')));
      }
      setIsRegisterViaSocialNetwork(true);
      tryRegisterWithServicesStep1(userType, code, state).then((r) => {
        setEmail(r?.email);
        setName(r?.name);
        // eslint-disable-next-line camelcase
        setUserToken(r?.user_token);
      }).catch(() => {
        // user already exists
        // window.location.replace(alternativeEnterInfo[state.from].getHref(refCode));
      });
    }
  }, []);

  const submitPhone = () => {
    const type = 'NUMBER_VERIFICATION';
    fetchVerificationId(phoneNumber, type).then((json) => {
      setPhoneVerificationToken(json.token);
      setStep(1);
    }).finally(() => {
    });
  };

  const moveToSecondStep = () => {
    const nameErrorSync = validateName(name);
    const emailErrorSync = validateEmail(email);
    const phoneNumberErrorSync = !parsePhoneNumber(`+${phoneNumber}`).isValid();
    setNameError(nameErrorSync);
    if (nameErrorSync || phoneNumberErrorSync || (email && emailErrorSync)) {
      return;
    }
    submitPhone();
  };

  const submitCode = () => {
    const type = 'NUMBER_VERIFICATION';
    fetchSecretKey(phoneVerificationToken, phoneConfirmationCode, type).then(() => {
      setStep(2);
    }).catch(() => setIsCodeError(true));
  };

  const handleFinalUserDataSubmit = async () => {
    const error = validatePassword(password);
    const errorPasswordConfirmation = validatePasswordConfirmation(password, passwordConfirmation);
    if (error) {
      setPasswordError(error);
      setPasswordConfirmationError(errorPasswordConfirmation);
      return;
    }
    try {
      setIsLoading(true);
      let response;
      if (isRegisterViaSocialNetwork) {
        response = await tryRegisterWithServicesStep2(userType,
          phoneNumber,
          password,
          phoneVerificationToken,
          userToken,
          refCode);
      } else {
        response = await defaultRegister(userType,
          password,
          phoneVerificationToken,
          refCode,
          name,
          phoneNumber);
      }

      if (response.ok) {
        const json = await response.json();
        console.log(json)
        console.log(json.user.user_id)
        localStorage.clear();
        // localStorage.setItem('user', JSON.stringify(json));
        // localStorage.setItem('tours', JSON.stringify({}));
        window.location = window.location.origin;
      } else {
        throw new Error(i18n.t(INVALID_PASSWORD_TRY_ANOTHER_ONE));
      }
    } catch (e) {
      if (!e.message.includes(i18n.t(INVALID_PASSWORD))) {
        Sentry.captureException(e);
      }
      showToast(e.message);
      setIsLoading(false);
    } finally {
    }
  };

  const nameEmailPhoneInputs = (
    <NameEmailPhoneInputs
      step={step}
      name={name}
      nameError={nameError}
      setName={setName}
      setNameError={setNameError}
      setPhoneNumber={setPhoneNumber}
      phoneNumber={phoneNumber}
      email={email}
      emailError={emailError}
      setEmail={setEmail}
      setEmailError={setEmailError}
      isRegisterViaSocialNetwork={isRegisterViaSocialNetwork}
    />
  );

  const firstStepContents = (
    <FirstStep
        userType={userType}
        phoneNumber={phoneNumber}
      isRegisterViaSocialNetwork={isRegisterViaSocialNetwork}
      email={email}
      name={name}
      nameEmailPhoneInputs={nameEmailPhoneInputs}
      moveToSecondStep={moveToSecondStep}
      refCode={refCode}
    />
  );

  const secondStepContents = (
    <SecondStep
      phoneConfirmationCode={phoneConfirmationCode}
      setPhoneConfirmationCode={setPhoneConfirmationCode}
      phoneNumber={phoneNumber}
      submitPhone={submitPhone}
      submitCode={submitCode}
      setStep={setStep}
    />
  );

  const thirdStepContents = (
    <ThirdStep
      nameEmailPhoneInputs={nameEmailPhoneInputs}
      setPasswordError={setPasswordError}
      passwordError={passwordError}
      setPassword={setPassword}
      passwordConfirmationError={passwordConfirmationError}
      setPasswordConfirmation={setPasswordConfirmation}
      handleUserDataSubmit={handleFinalUserDataSubmit}
    />
  );

  return (
    <div>
      {step === 0 ? (
        firstStepContents
      ) : step === 1 ? (
        secondStepContents
      ) : (thirdStepContents)}
      <Steps activeStep={step} className={classes.registration__steps} />
    </div>

  );
};

const style = () => ({
  registration__steps: {
    marginTop: '60px',
  },
});

export default withStyles(style)(Registration);
