import fetch from '@main/utils/handledFetch';
import { backendURL, twirpFetchOptions } from '@main/toRemove';
import { toast } from 'react-toastify';
import { AlertContents } from '@main/lib';
import React from 'react';

export const saveStatisticsReport = async (body) => {
  if (!body.columns.length || !body.groups.length || !body.name) {
    throw Error('Пустые поля');
  }
  const response = await fetch(
    `${backendURL}/twirp/statistics.cpa.CpaService/SaveStatisticsReport`,
    {
      ...twirpFetchOptions,
      body: JSON.stringify({ report: body }),
    },
  );
  let json = {};
  if (response.ok) {
    json = await response.json();
  }
  return json;
};

export default saveStatisticsReport;
