import {
  InfinityIcon,
  ShieldExclamationMarkIcon,
  ShieldIcon,
  ShieldQuestionMarkIcon,
  ShieldTickIcon,
} from '@main/lib';
import React from 'react';
import { financePaths } from '@pages/finance/constants/financePaths';
import {
  ALL,
  BALANCE,
  COMPLETED,
  IN_PROCESSING, PAYMENT_HISTORY,
  REJECTED, REMOVING_A_HOLD,
  TERMS_AND_PROCEDURE_OF_PAYMENTS,
  WAS_CREATED
} from "@main/i18n/aliases";

const FinanceHistorySubTabs = (t)=>{
  return [{
    text: t(ALL),
    icon: <InfinityIcon />,
    link: `${financePaths.pathPrefixHistory}/all`,
  },
    {
      text: t(WAS_CREATED),
      link: `${financePaths.pathPrefixHistory}/created`,
      icon: <ShieldIcon />,
    },
    {
      text: t(IN_PROCESSING),
      icon: <ShieldQuestionMarkIcon />,
      link: `${financePaths.pathPrefixHistory}/in-process`,
    },
    {
      text: t(COMPLETED),
      icon: <ShieldTickIcon />,
      link: `${financePaths.pathPrefixHistory}/finished`,
    },
    {
      text: t(REJECTED),
      icon: <ShieldExclamationMarkIcon />,
      link: `${financePaths.pathPrefixHistory}/rejected`,
    }];
}

export const financeTabs = (t) => {
  return [{
    text: t(BALANCE), link: financePaths.balancePagePath,
  },
    {
      text: t(PAYMENT_HISTORY),
      link: `${financePaths.pathPrefixHistory}/all`,
      regex: `${financePaths.pathPrefixHistory}`,
      subtabs: FinanceHistorySubTabs(t),
    },
    {
      text: t(REMOVING_A_HOLD),
      link: financePaths.holdWithdrawalPagePath,
    },
    {
      text: t(TERMS_AND_PROCEDURE_OF_PAYMENTS),
      link: financePaths.termsAndProcedurePaymentsPagePath,
    },
  ];
}
