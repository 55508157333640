import {
  DialogueIcon,
  Link,
  LinkIcon,
  ProgressBar,
  ReportIcon,
  TableCell,
  TableRow,
} from '@main/lib';
import { formatCurrency } from '@components/utils';
import React, { useEffect, useState } from 'react';
import RoundImage from '@pages/channels/components/RoundImage';
import { withStyles } from '@material-ui/core';
import ChannelsLine from '@pages/offers/components/ChannelsLine';
import EllipsisDropdown from '@pages/offers/components/MyOffers/components/EllipsisDropdown';
import { useHistory } from 'react-router';
import {useTranslation} from "react-i18next";
import {DIALOGS, LINKS, STATISTICS} from "@main/i18n/aliases";

const ChannelsPageTableRow = ({ channel, onOpenModal, classes }) => {
  const history = useHistory();
  const [openedMenu, setOpenedMenu] = useState(false);
  const {t} = useTranslation()
  useEffect(() => {}, [openedMenu]);

  const description = (
    <div className={classes.channelsLine__description}>
      <b>{'ID '}</b>
      {` ${channel.group_id}`}
    </div>
  );

  return (
    <TableRow className={classes.channelsPageTableRow}>
      <TableCell>
        <div className={classes.channelsPageTableRow__group}>
          {channel.photo && <RoundImage src={channel.photo} />}
          <Link className={classes.channelsPageTableRow__link} onClick={() => { window.location = (channel.url); }}>{channel.name}</Link>
        </div>
      </TableCell>
      <TableCell>
        <Link onClick={() => { history.push(`/app/offers/all-offers/${channel.offer_id}`); }} className={classes.channelsPageTableRow__link}>{channel.offerName}</Link>
      </TableCell>
      <TableCell>
        {/* { channels[channel.channel].name} */}
        <ChannelsLine
          channels={(channel.connect_instagram ? [channel.channel, 'instagram'] : [channel.channel])}
          className={classes.channelsLine__root}
          descriptions={(channel.connect_instagram ? [description, description] : [description])}
        />
      </TableCell>
      <TableCell>
        { formatCurrency(channel.stats.conversations) || 0}
      </TableCell>
      <TableCell className={classes.channelsPageTableRow__actions}>
        <div>
          <ProgressBar
            number={channel.stats.conversions || 0}
            percent={Number((channel.stats.conversions / channel.stats.conversations) * 100 || 0).toPrecision(3)}
          />
        </div>

      </TableCell>
      <TableCell>
        <div className={classes.channelsPageTableRow__income}>
          { channel.stats.income || 0}
          <EllipsisDropdown
            dropdownItems={[{
              name: t(LINKS),
              icon: <LinkIcon />,
              action: () => {
                onOpenModal();
              },
            },
            {
              name: t(DIALOGS),
              icon: <DialogueIcon />,
              action: () => {
                history.push(`/app/offers/${channel.offer_id}/chat?group_id=${channel.group_id}`);
              },
            },
            {
              name: t(STATISTICS),
              icon: <ReportIcon />,
              action: () => {
                history.push(`/app/dashboard?filter={"platform":["${channel.id}"]}`);
              },
            }]}
            className={classes.actionsMenu}
            onClose={() => {
              setOpenedMenu(false);
            }}
            menuIsOpen={openedMenu}
            onClick={(index) => {
              if (index === -1) {
                setOpenedMenu(false);
              } else {
                setOpenedMenu(true);
              }
            }}
          />
        </div>
      </TableCell>
    </TableRow>
  );
};

const styles = () => ({
  channelsPageTableRow: {},
  channelsPageTableRow__link: {
    textDecoration: 'underline',
  },

  channelsLine__root: {
    justifyContent: 'center',
  },
  channelsLine__description: {
    display: 'flex',
    '& > b': {
      marginRight: '0.5em',
    },
  },

  channelsPageTableRow__group: {
    display: 'flex',
    paddingLeft: '12px',
    alignItems: 'center',
    '& > img': {
      marginRight: '10px',
    },
  },
  channelsPageTableRow__income: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '15px',
  },
  channelsPageTableRow__actions: {
    width: '300px',
    boxSizing: 'contentBox',
    padding: '0 60px',
  },
});

export default withStyles(styles)(ChannelsPageTableRow);
