import { withStyles } from '@material-ui/core';
import Block from '@components/Block/Block';
import React, { useEffect, useState } from 'react';
import {
  Input, SearchIcon, Select, SelectItem, TextWithIcon,
} from '@main/lib';
import {useTranslation} from "react-i18next";
import {OFFERS, PLACE, SEARCH} from "@main/i18n/aliases";

const getUniqueItems = (array) => [...new Set(array)];

const ChannelsPageFilterBlock = ({
  classes, offers, channels, onChangePlatformIds, onChangeOfferIds, onChangeSearchInputs, onClickSearch,

}) => {
  const [platformIds, setPlatformIds] = useState([]);
  const [offerIds, setOfferIds] = useState([]);
  const [searchInputs, setSearchInputs] = useState('');
  const {t} = useTranslation()

  useEffect(() => {
    onChangeOfferIds(offerIds); onChangePlatformIds(platformIds); onChangeSearchInputs(searchInputs);
  }, [platformIds, offerIds, searchInputs]);
  return (
    <Block classes={{ root: classes.block__root }}>
      <div className={classes.channelsPageFilterBlock__form}>
        <Input
          onChange={(event) => { setSearchInputs(event.target.value); }}
          className={classes.channelsPageFilterBlock__form__textInput}
          label={t(SEARCH)}
          placeholder={t(SEARCH)}
          endAdornment={<SearchIcon />}
          value={searchInputs}
        />
        <Select
          maxElementsNum={1}
          multiple
          className={classes.channelsPageFilterBlock__form__selectInputWrapper}
          onChange={(event) => { setOfferIds(event.target.value); }}
          classes={{ inputRoot: classes.channelsPageFilterBlock__form__selectInput }}
          label={t(OFFERS)}
          value={offerIds}
        >
          { getUniqueItems((offers).map((offer) => offer.name)).map((offerName) => (
            <SelectItem value={offerName}>
              <TextWithIcon label={offerName} />
            </SelectItem>
          ))}
        </Select>
        <Select
          maxElementsNum={1}
          multiple
          className={classes.channelsPageFilterBlock__form__selectInputWrapper}
          onChange={(event) => { setPlatformIds(event.target.value); }}
          classes={{ inputRoot: classes.channelsPageFilterBlock__form__selectInput }}
          label={t(PLACE)}
          value={platformIds}
        >
          { getUniqueItems((channels).map((channel) => channel.name)).map((channelName) => (
            <SelectItem value={channelName}>
              <TextWithIcon label={channelName} />
            </SelectItem>
          ))}
        </Select>
      </div>
    </Block>
  );
};

const styles = () => ({
  block__root: {
    width: '100%',
    marginBottom: '30px',
  },
  channelsPageFilterBlock__form: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  channelsPageFilterBlock__form__textInput: {
    width: '110%',
    marginRight: '20px',
  },
  channelsPageFilterBlock__form__selectInput: {
    width: '100%',
  },
  channelsPageFilterBlock__form__selectInputWrapper: {
    width: '100%',
    marginRight: '20px',
  },

});
export default withStyles(styles)(ChannelsPageFilterBlock);
