import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { loginSuccess, loginUser } from '@store/actions';
import { validatePassword } from '@pages/entrance/components/Registration/utils/validators';

import {
  blueColor,
  Button,
  Checkbox,
  FormControlLabel,
  Input, Link,
  MailIcon,
} from '@main/lib';

import { withStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import PasswordInput from '@pages/entrance/components/PasswordInput';
import FacebookInit from '@pages/entrance/components/Login/components/FacebookInit';
import AlternativeEnterButton from '@pages/entrance/components/Login/components/AlternativeEnterIcon';
import { tryLoginViaServices } from '@pages/entrance/components/Login/services';
import WordWithLine from '@pages/entrance/components/Login/components/WordWithLine';
import HelpText from '@pages/entrance/components/HelpText';
import alternativeEnterInfo from '@pages/entrance/components/Login/constants/alternativeEnterInfo';
import { financePaths } from '@pages/finance/constants/financePaths';
import NewTicketForm from '@pages/finance/pages/BalancePage/NewTicketForm';
import NewUserForm from "@pages/entrance/components/NewUserForm";
import InformationBlock from '@pages/finance/pages/BalancePage/components/InformationBlock';
import clsx from 'clsx';
import { formatCurrency } from '@pages/finance/utils';
import { ReactComponent as Card } from '@pages/finance/pages/BalancePage/assets/illustrationCard.svg';
import i18n from "@main/i18n";
import {
  EMAIL_OR_PHONE,
  FORGOT_PASSWORD, IF_YOU_HAVE_NOT_ACCOUNT,
  INVALID_INPUT_FORMAT,
  PASSWORD, REGISTER,
  REMEMBER_ME,
  SIGN_IN
} from "@main/i18n/aliases";

const Login = ({ classes }) => {
  const modalOpen = window.location.pathname.includes('form');

  const [refCode, setRefCode] = useState(null);
  const userType = 'WEBMASTER';
  const [error, setError] = useState('');
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem('allOffers')) {
      localStorage.removeItem('allOffers');
    }
  }, []);

  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.login.isAuthenticated);
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    setRefCode(searchParams.get('ref'));

    tryLoginViaServices(userType).then((json) => {
      if (json) {
        localStorage.setItem('user', JSON.stringify(json));
        dispatch(loginSuccess({
          username: json.user.username,
          userEmail: json.user.user_mail,
          userPicture: json.user.picture,
        }));
      }
    });
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  // eslint-disable-next-line no-shadow
  const handleSubmit = async (login, password) => {
    setError(validatePassword(password));
    if (error) {
      setError(i18n.t(INVALID_INPUT_FORMAT));
    }
    await dispatch(loginUser(login, password, rememberMe));
  };

  const ForgotPasswordElement = () => (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    // <a onClick={() => {
    //   history.push('/reset_password');
    // }}
    // >
    //
    // </a>
    <Link onClick={() => {
      history.push('/reset_password');
    }}
    >
      {i18n.t(FORGOT_PASSWORD)}
    </Link>
  );
  if (isAuthenticated) {
    return <Redirect to={{ pathname: '/app/dashboard' }} />;
  }

  return (
    <div className={classes.root}>
      <form onSubmit={(event) => {
        event.preventDefault();
        handleSubmit(login, password);
      }}
      >
        <Input
          className={classes.login__input}
          label={i18n.t(EMAIL_OR_PHONE)}
          onChange={(event) => {
            setLogin(event.target.value);
            setError('');
          }}
          icon={<MailIcon />}
        />
        <PasswordInput
          errorText={error
        && (
        <div className={classes.login__forgetPasswordWrapper}>
          {i18n.t(INVALID_INPUT_FORMAT)}
          <ForgotPasswordElement />
        </div>
        )}
          setError={setError}
          setPassword={setPassword}
          label={i18n.t(PASSWORD)}
          className={classes.login__input}
        />
        {!error && (
        <div className={classes.login__forgetPasswordWrapper}>
          <div className={classes.login__rememberMe}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={rememberMe}
                  onChange={(event) => {
                    setRememberMe(event.target.checked);
                  }}
                />
                )}
              label={i18n.t(REMEMBER_ME)}
            />
          </div>
          <ForgotPasswordElement />
        </div>
        )}
        <div className={classes.center}>
          <Button
            type="submit"
            className={classes.login__button}
          >
            {i18n.t(SIGN_IN)}
          </Button>
          {/*<HelpText*/}
          {/*  className={classes.login__helpText_privacy}*/}
          {/*  detailed*/}
          {/*  // textBeforeLink="Входя в аккаунт или создавая новый, вы соглашаетесь на "*/}
          {/*  textBeforeLink="By logging into an account or creating a new one, you agree to"*/}
          {/*  // linkText="обработку персональных данных"*/}
          {/*  linkText="processing of personal data"*/}
          {/*  link="/policy"*/}
          {/*/>*/}
          {/* <WordWithLine word="Или" className={classes.login__wordWithLine} />
          <div className={classes.login__buttons}>
            {Object.keys(alternativeEnterInfo).map((property) => (
              <AlternativeEnterButton
                className={classes.login__button}
                icon={alternativeEnterInfo[property].icon}
                href={alternativeEnterInfo[property].getHref(refCode)}
                platformName={alternativeEnterInfo[property].name}
                key={property}
              />
            ))}
            <FacebookInit />
          </div> */}
          <HelpText
            className={classes.login__helpText_register}
            textBeforeLink={i18n.t(IF_YOU_HAVE_NOT_ACCOUNT)}
            linkText={i18n.t(REGISTER)}
            link="/register"
          />
        </div>
      </form>
      <NewUserForm
        modalOpen={modalOpen}
        classes={classes}
      />
    </div>
  );
};

const styles = (() => ({
  login__wordWithLine: {
    marginBottom: '0px',
    position: 'relative',
    zIndex:'100',
  },
  login__helpText_privacy: {
    marginTop: '15px',
    marginBottom: '40px',
    position: 'relative',
    zIndex:'100',
  },
  login__helpText_register: {
    marginTop: '40px',
    position: 'relative',
    zIndex:'100',
  },
  login__buttons: {
    width: '100%',
    position: 'relative',
    zIndex:'100',
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    zIndex:'100',
  },

  login__input: {
    marginBottom: '20px',
    width: '100%',
    '&>div:nth-of-type(2)': {
      width: '100%',
    },
    position: 'relative',
    zIndex:'100',

  },
  login__forgetPasswordWrapper: {
    marginTop: '-20px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    zIndex:'100',

  },
  login__rememberMe: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    zIndex:'100',
  },
  login__button: {
    width: '100%',
    marginTop: '20px',
    position: 'relative',
    zIndex:'100',
  },

}));

export default withStyles(styles)(Login);
