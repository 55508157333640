"use strict";
/* eslint:disable */
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
// This file has been generated by https://github.com/reverbnation/protoc-gen-twirp_ts.
// Do not edit.
var admin_tools_landbots = __importStar(require("./admin.tools.landbots.protofiles"));
var twirp_1 = require("./twirp");
var Tool = /** @class */ (function () {
    function Tool(m) {
        this._json = {};
        if (m) {
            this._json['id'] = m['id'];
            this._json['name'] = m['name'];
            this._json['project'] = m['project'];
            this._json['type'] = m['type'];
            this._json['data_id'] = m['dataId'];
            this._json['created_time'] = m['createdTime'];
            this._json['last_update_time'] = m['lastUpdateTime'];
        }
    }
    Object.defineProperty(Tool.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Tool.prototype, "name", {
        // name (name)
        get: function () {
            return this._json.name;
        },
        set: function (value) {
            this._json.name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Tool.prototype, "project", {
        // project (project)
        get: function () {
            return this._json.project;
        },
        set: function (value) {
            this._json.project = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Tool.prototype, "type", {
        // type (type)
        get: function () {
            return this._json.type;
        },
        set: function (value) {
            this._json.type = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Tool.prototype, "dataId", {
        // dataId (data_id)
        get: function () {
            return this._json.data_id;
        },
        set: function (value) {
            this._json.data_id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Tool.prototype, "createdTime", {
        // createdTime (created_time)
        get: function () {
            return this._json.created_time;
        },
        set: function (value) {
            this._json.created_time = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Tool.prototype, "lastUpdateTime", {
        // lastUpdateTime (last_update_time)
        get: function () {
            return this._json.last_update_time;
        },
        set: function (value) {
            this._json.last_update_time = value;
        },
        enumerable: true,
        configurable: true
    });
    Tool.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new Tool({
            id: m['id'],
            name: m['name'],
            project: m['project'],
            type: m['type'],
            dataId: m['data_id'],
            createdTime: m['created_time'],
            lastUpdateTime: m['last_update_time']
        });
    };
    Tool.prototype.toJSON = function () {
        return this._json;
    };
    return Tool;
}());
exports.Tool = Tool;
var ToolWithTrackerInfo = /** @class */ (function () {
    function ToolWithTrackerInfo(m) {
        this._json = {};
        if (m) {
            this._json['id'] = m['id'];
            this._json['name'] = m['name'];
            this._json['project'] = m['project'];
            this._json['type'] = m['type'];
            this._json['data_id'] = m['dataId'];
            this._json['tracker_info'] = m['trackerInfo'];
            this._json['created_time'] = m['createdTime'];
            this._json['last_update_time'] = m['lastUpdateTime'];
        }
    }
    Object.defineProperty(ToolWithTrackerInfo.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToolWithTrackerInfo.prototype, "name", {
        // name (name)
        get: function () {
            return this._json.name;
        },
        set: function (value) {
            this._json.name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToolWithTrackerInfo.prototype, "project", {
        // project (project)
        get: function () {
            return this._json.project;
        },
        set: function (value) {
            this._json.project = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToolWithTrackerInfo.prototype, "type", {
        // type (type)
        get: function () {
            return this._json.type;
        },
        set: function (value) {
            this._json.type = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToolWithTrackerInfo.prototype, "dataId", {
        // dataId (data_id)
        get: function () {
            return this._json.data_id;
        },
        set: function (value) {
            this._json.data_id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToolWithTrackerInfo.prototype, "trackerInfo", {
        // trackerInfo (tracker_info)
        get: function () {
            return this._json.tracker_info;
        },
        set: function (value) {
            this._json.tracker_info = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToolWithTrackerInfo.prototype, "createdTime", {
        // createdTime (created_time)
        get: function () {
            return this._json.created_time;
        },
        set: function (value) {
            this._json.created_time = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToolWithTrackerInfo.prototype, "lastUpdateTime", {
        // lastUpdateTime (last_update_time)
        get: function () {
            return this._json.last_update_time;
        },
        set: function (value) {
            this._json.last_update_time = value;
        },
        enumerable: true,
        configurable: true
    });
    ToolWithTrackerInfo.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new ToolWithTrackerInfo({
            id: m['id'],
            name: m['name'],
            project: m['project'],
            type: m['type'],
            dataId: m['data_id'],
            trackerInfo: TrackerInfo.fromJSON(m['tracker_info']),
            createdTime: m['created_time'],
            lastUpdateTime: m['last_update_time']
        });
    };
    ToolWithTrackerInfo.prototype.toJSON = function () {
        return this._json;
    };
    return ToolWithTrackerInfo;
}());
exports.ToolWithTrackerInfo = ToolWithTrackerInfo;
var TrackerInfo = /** @class */ (function () {
    function TrackerInfo(m) {
        this._json = {};
        if (m) {
            this._json['clicks'] = m['clicks'];
            this._json['lp_clicks'] = m['lpClicks'];
            this._json['cr'] = m['cr'];
        }
    }
    Object.defineProperty(TrackerInfo.prototype, "clicks", {
        // clicks (clicks)
        get: function () {
            return this._json.clicks;
        },
        set: function (value) {
            this._json.clicks = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TrackerInfo.prototype, "lpClicks", {
        // lpClicks (lp_clicks)
        get: function () {
            return this._json.lp_clicks;
        },
        set: function (value) {
            this._json.lp_clicks = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TrackerInfo.prototype, "cr", {
        // cr (cr)
        get: function () {
            return this._json.cr;
        },
        set: function (value) {
            this._json.cr = value;
        },
        enumerable: true,
        configurable: true
    });
    TrackerInfo.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new TrackerInfo({
            clicks: m['clicks'],
            lpClicks: m['lp_clicks'],
            cr: m['cr']
        });
    };
    TrackerInfo.prototype.toJSON = function () {
        return this._json;
    };
    return TrackerInfo;
}());
exports.TrackerInfo = TrackerInfo;
var GetOneResponse = /** @class */ (function () {
    function GetOneResponse(m) {
        this._json = {};
        if (m) {
            this._json['tool'] = m['tool'];
        }
    }
    Object.defineProperty(GetOneResponse.prototype, "tool", {
        // tool (tool)
        get: function () {
            return this._json.tool;
        },
        set: function (value) {
            this._json.tool = value;
        },
        enumerable: true,
        configurable: true
    });
    GetOneResponse.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new GetOneResponse({
            tool: Tool.fromJSON(m['tool'])
        });
    };
    GetOneResponse.prototype.toJSON = function () {
        return this._json;
    };
    return GetOneResponse;
}());
exports.GetOneResponse = GetOneResponse;
var GetManyResponse = /** @class */ (function () {
    function GetManyResponse(m) {
        this._json = {};
        if (m) {
            this._json['tools'] = m['tools'];
        }
    }
    Object.defineProperty(GetManyResponse.prototype, "tools", {
        // tools (tools)
        get: function () {
            return this._json.tools || [];
        },
        set: function (value) {
            this._json.tools = value;
        },
        enumerable: true,
        configurable: true
    });
    GetManyResponse.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new GetManyResponse({
            tools: (m['tools'] || []).map(function (v) { return ToolWithTrackerInfo.fromJSON(v); })
        });
    };
    GetManyResponse.prototype.toJSON = function () {
        return this._json;
    };
    return GetManyResponse;
}());
exports.GetManyResponse = GetManyResponse;
var DeleteResponse = /** @class */ (function () {
    function DeleteResponse(m) {
        this._json = {};
        if (m) {
        }
    }
    DeleteResponse.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new DeleteResponse({});
    };
    DeleteResponse.prototype.toJSON = function () {
        return this._json;
    };
    return DeleteResponse;
}());
exports.DeleteResponse = DeleteResponse;
var UpdateResponse = /** @class */ (function () {
    function UpdateResponse(m) {
        this._json = {};
        if (m) {
            this._json['tool'] = m['tool'];
        }
    }
    Object.defineProperty(UpdateResponse.prototype, "tool", {
        // tool (tool)
        get: function () {
            return this._json.tool;
        },
        set: function (value) {
            this._json.tool = value;
        },
        enumerable: true,
        configurable: true
    });
    UpdateResponse.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new UpdateResponse({
            tool: Tool.fromJSON(m['tool'])
        });
    };
    UpdateResponse.prototype.toJSON = function () {
        return this._json;
    };
    return UpdateResponse;
}());
exports.UpdateResponse = UpdateResponse;
var CreateResponse = /** @class */ (function () {
    function CreateResponse(m) {
        this._json = {};
        if (m) {
            this._json['tool'] = m['tool'];
        }
    }
    Object.defineProperty(CreateResponse.prototype, "tool", {
        // tool (tool)
        get: function () {
            return this._json.tool;
        },
        set: function (value) {
            this._json.tool = value;
        },
        enumerable: true,
        configurable: true
    });
    CreateResponse.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new CreateResponse({
            tool: ToolWithTrackerInfo.fromJSON(m['tool'])
        });
    };
    CreateResponse.prototype.toJSON = function () {
        return this._json;
    };
    return CreateResponse;
}());
exports.CreateResponse = CreateResponse;
var GetOneRequest = /** @class */ (function () {
    function GetOneRequest(m) {
        this._json = {};
        if (m) {
            this._json['id'] = m['id'];
        }
    }
    Object.defineProperty(GetOneRequest.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    GetOneRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new GetOneRequest({
            id: m['id']
        });
    };
    GetOneRequest.prototype.toJSON = function () {
        return this._json;
    };
    return GetOneRequest;
}());
exports.GetOneRequest = GetOneRequest;
var GetManyRequest = /** @class */ (function () {
    function GetManyRequest(m) {
        this._json = {};
        if (m) {
        }
    }
    GetManyRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new GetManyRequest({});
    };
    GetManyRequest.prototype.toJSON = function () {
        return this._json;
    };
    return GetManyRequest;
}());
exports.GetManyRequest = GetManyRequest;
var DeleteRequest = /** @class */ (function () {
    function DeleteRequest(m) {
        this._json = {};
        if (m) {
            this._json['id'] = m['id'];
        }
    }
    Object.defineProperty(DeleteRequest.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    DeleteRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new DeleteRequest({
            id: m['id']
        });
    };
    DeleteRequest.prototype.toJSON = function () {
        return this._json;
    };
    return DeleteRequest;
}());
exports.DeleteRequest = DeleteRequest;
var UpdateRequest = /** @class */ (function () {
    function UpdateRequest(m) {
        this._json = {};
        if (m) {
            this._json['id'] = m['id'];
            this._json['name'] = m['name'];
            this._json['foreign_key'] = m['foreignKey'];
        }
    }
    Object.defineProperty(UpdateRequest.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UpdateRequest.prototype, "name", {
        // name (name)
        get: function () {
            return this._json.name;
        },
        set: function (value) {
            this._json.name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UpdateRequest.prototype, "foreignKey", {
        // foreignKey (foreign_key)
        get: function () {
            return this._json.foreign_key;
        },
        set: function (value) {
            this._json.foreign_key = value;
        },
        enumerable: true,
        configurable: true
    });
    UpdateRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new UpdateRequest({
            id: m['id'],
            name: m['name'],
            foreignKey: m['foreign_key']
        });
    };
    UpdateRequest.prototype.toJSON = function () {
        return this._json;
    };
    return UpdateRequest;
}());
exports.UpdateRequest = UpdateRequest;
var CreateRequest = /** @class */ (function () {
    function CreateRequest(m) {
        this._json = {};
        if (m) {
            this._json['name'] = m['name'];
            this._json['type'] = m['type'];
            this._json['LandBot'] = m['LandBot'];
        }
    }
    Object.defineProperty(CreateRequest.prototype, "name", {
        // name (name)
        get: function () {
            return this._json.name;
        },
        set: function (value) {
            this._json.name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreateRequest.prototype, "type", {
        // type (type)
        get: function () {
            return this._json.type;
        },
        set: function (value) {
            this._json.type = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreateRequest.prototype, "LandBot", {
        // LandBot (LandBot)
        get: function () {
            return this._json.LandBot;
        },
        set: function (value) {
            this._json.LandBot = value;
        },
        enumerable: true,
        configurable: true
    });
    CreateRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new CreateRequest({
            name: m['name'],
            type: m['type'],
            LandBot: admin_tools_landbots.LandBotCreateInput.fromJSON(m['LandBot'])
        });
    };
    CreateRequest.prototype.toJSON = function () {
        return this._json;
    };
    return CreateRequest;
}());
exports.CreateRequest = CreateRequest;
var ToolsService = /** @class */ (function () {
    function ToolsService(hostname, fetch) {
        this.path = '/twirp/admin.tools.ToolsService/';
        this.hostname = hostname;
        this.fetch = fetch;
    }
    ToolsService.prototype.url = function (name) {
        return this.hostname + this.path + name;
    };
    ToolsService.prototype.getOne = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('GetOne'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return GetOneResponse.fromJSON(m); });
        });
    };
    ToolsService.prototype.getMany = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('GetMany'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return GetManyResponse.fromJSON(m); });
        });
    };
    ToolsService.prototype.delete = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('Delete'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return DeleteResponse.fromJSON(m); });
        });
    };
    ToolsService.prototype.update = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('Update'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return UpdateResponse.fromJSON(m); });
        });
    };
    ToolsService.prototype.create = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('Create'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return CreateResponse.fromJSON(m); });
        });
    };
    return ToolsService;
}());
exports.ToolsService = ToolsService;
