"use strict";
/* eslint:disable */
Object.defineProperty(exports, "__esModule", { value: true });
// This file has been generated by https://github.com/reverbnation/protoc-gen-twirp_ts.
// Do not edit.
var twirp_1 = require("./twirp");
var Report = /** @class */ (function () {
    function Report(m) {
        this._json = {};
        if (m) {
            this._json['id'] = m['id'];
            this._json['name'] = m['name'];
            this._json['project'] = m['project'];
            this._json['type'] = m['type'];
            this._json['last_update_time'] = m['lastUpdateTime'];
            this._json['created_time'] = m['createdTime'];
            this._json['google'] = m['google'];
        }
    }
    Object.defineProperty(Report.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Report.prototype, "name", {
        // name (name)
        get: function () {
            return this._json.name;
        },
        set: function (value) {
            this._json.name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Report.prototype, "project", {
        // project (project)
        get: function () {
            return this._json.project;
        },
        set: function (value) {
            this._json.project = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Report.prototype, "type", {
        // type (type)
        get: function () {
            return this._json.type;
        },
        set: function (value) {
            this._json.type = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Report.prototype, "lastUpdateTime", {
        // lastUpdateTime (last_update_time)
        get: function () {
            return this._json.last_update_time;
        },
        set: function (value) {
            this._json.last_update_time = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Report.prototype, "createdTime", {
        // createdTime (created_time)
        get: function () {
            return this._json.created_time;
        },
        set: function (value) {
            this._json.created_time = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Report.prototype, "google", {
        // google (google)
        get: function () {
            return this._json.google;
        },
        set: function (value) {
            this._json.google = value;
        },
        enumerable: true,
        configurable: true
    });
    Report.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new Report({
            id: m['id'],
            name: m['name'],
            project: m['project'],
            type: m['type'],
            lastUpdateTime: m['last_update_time'],
            createdTime: m['created_time'],
            google: GoogleDataPayload.fromJSON(m['google'])
        });
    };
    Report.prototype.toJSON = function () {
        return this._json;
    };
    return Report;
}());
exports.Report = Report;
var GetOneResponse = /** @class */ (function () {
    function GetOneResponse(m) {
        this._json = {};
        if (m) {
            this._json['report'] = m['report'];
        }
    }
    Object.defineProperty(GetOneResponse.prototype, "report", {
        // report (report)
        get: function () {
            return this._json.report;
        },
        set: function (value) {
            this._json.report = value;
        },
        enumerable: true,
        configurable: true
    });
    GetOneResponse.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new GetOneResponse({
            report: Report.fromJSON(m['report'])
        });
    };
    GetOneResponse.prototype.toJSON = function () {
        return this._json;
    };
    return GetOneResponse;
}());
exports.GetOneResponse = GetOneResponse;
var GetManyResponse = /** @class */ (function () {
    function GetManyResponse(m) {
        this._json = {};
        if (m) {
            this._json['reports'] = m['reports'];
        }
    }
    Object.defineProperty(GetManyResponse.prototype, "reports", {
        // reports (reports)
        get: function () {
            return this._json.reports || [];
        },
        set: function (value) {
            this._json.reports = value;
        },
        enumerable: true,
        configurable: true
    });
    GetManyResponse.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new GetManyResponse({
            reports: (m['reports'] || []).map(function (v) { return Report.fromJSON(v); })
        });
    };
    GetManyResponse.prototype.toJSON = function () {
        return this._json;
    };
    return GetManyResponse;
}());
exports.GetManyResponse = GetManyResponse;
var CreateResponse = /** @class */ (function () {
    function CreateResponse(m) {
        this._json = {};
        if (m) {
            this._json['report'] = m['report'];
        }
    }
    Object.defineProperty(CreateResponse.prototype, "report", {
        // report (report)
        get: function () {
            return this._json.report;
        },
        set: function (value) {
            this._json.report = value;
        },
        enumerable: true,
        configurable: true
    });
    CreateResponse.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new CreateResponse({
            report: Report.fromJSON(m['report'])
        });
    };
    CreateResponse.prototype.toJSON = function () {
        return this._json;
    };
    return CreateResponse;
}());
exports.CreateResponse = CreateResponse;
var UpdateResponse = /** @class */ (function () {
    function UpdateResponse(m) {
        this._json = {};
        if (m) {
            this._json['report'] = m['report'];
        }
    }
    Object.defineProperty(UpdateResponse.prototype, "report", {
        // report (report)
        get: function () {
            return this._json.report;
        },
        set: function (value) {
            this._json.report = value;
        },
        enumerable: true,
        configurable: true
    });
    UpdateResponse.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new UpdateResponse({
            report: Report.fromJSON(m['report'])
        });
    };
    UpdateResponse.prototype.toJSON = function () {
        return this._json;
    };
    return UpdateResponse;
}());
exports.UpdateResponse = UpdateResponse;
var DeleteResponse = /** @class */ (function () {
    function DeleteResponse(m) {
        this._json = {};
        if (m) {
        }
    }
    DeleteResponse.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new DeleteResponse({});
    };
    DeleteResponse.prototype.toJSON = function () {
        return this._json;
    };
    return DeleteResponse;
}());
exports.DeleteResponse = DeleteResponse;
var GetOneRequest = /** @class */ (function () {
    function GetOneRequest(m) {
        this._json = {};
        if (m) {
            this._json['id'] = m['id'];
        }
    }
    Object.defineProperty(GetOneRequest.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    GetOneRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new GetOneRequest({
            id: m['id']
        });
    };
    GetOneRequest.prototype.toJSON = function () {
        return this._json;
    };
    return GetOneRequest;
}());
exports.GetOneRequest = GetOneRequest;
var GetManyRequest = /** @class */ (function () {
    function GetManyRequest(m) {
        this._json = {};
        if (m) {
        }
    }
    GetManyRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new GetManyRequest({});
    };
    GetManyRequest.prototype.toJSON = function () {
        return this._json;
    };
    return GetManyRequest;
}());
exports.GetManyRequest = GetManyRequest;
var CreateRequest = /** @class */ (function () {
    function CreateRequest(m) {
        this._json = {};
        if (m) {
            this._json['name'] = m['name'];
            this._json['type'] = m['type'];
            this._json['google'] = m['google'];
        }
    }
    Object.defineProperty(CreateRequest.prototype, "name", {
        // name (name)
        get: function () {
            return this._json.name;
        },
        set: function (value) {
            this._json.name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreateRequest.prototype, "type", {
        // type (type)
        get: function () {
            return this._json.type;
        },
        set: function (value) {
            this._json.type = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CreateRequest.prototype, "google", {
        // google (google)
        get: function () {
            return this._json.google;
        },
        set: function (value) {
            this._json.google = value;
        },
        enumerable: true,
        configurable: true
    });
    CreateRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new CreateRequest({
            name: m['name'],
            type: m['type'],
            google: GoogleDataPayload.fromJSON(m['google'])
        });
    };
    CreateRequest.prototype.toJSON = function () {
        return this._json;
    };
    return CreateRequest;
}());
exports.CreateRequest = CreateRequest;
var UpdateRequest = /** @class */ (function () {
    function UpdateRequest(m) {
        this._json = {};
        if (m) {
            this._json['id'] = m['id'];
            this._json['name'] = m['name'];
            this._json['type'] = m['type'];
            this._json['google'] = m['google'];
        }
    }
    Object.defineProperty(UpdateRequest.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UpdateRequest.prototype, "name", {
        // name (name)
        get: function () {
            return this._json.name;
        },
        set: function (value) {
            this._json.name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UpdateRequest.prototype, "type", {
        // type (type)
        get: function () {
            return this._json.type;
        },
        set: function (value) {
            this._json.type = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UpdateRequest.prototype, "google", {
        // google (google)
        get: function () {
            return this._json.google;
        },
        set: function (value) {
            this._json.google = value;
        },
        enumerable: true,
        configurable: true
    });
    UpdateRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new UpdateRequest({
            id: m['id'],
            name: m['name'],
            type: m['type'],
            google: GoogleDataPayload.fromJSON(m['google'])
        });
    };
    UpdateRequest.prototype.toJSON = function () {
        return this._json;
    };
    return UpdateRequest;
}());
exports.UpdateRequest = UpdateRequest;
var DeleteRequest = /** @class */ (function () {
    function DeleteRequest(m) {
        this._json = {};
        if (m) {
            this._json['id'] = m['id'];
        }
    }
    Object.defineProperty(DeleteRequest.prototype, "id", {
        // id (id)
        get: function () {
            return this._json.id;
        },
        set: function (value) {
            this._json.id = value;
        },
        enumerable: true,
        configurable: true
    });
    DeleteRequest.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new DeleteRequest({
            id: m['id']
        });
    };
    DeleteRequest.prototype.toJSON = function () {
        return this._json;
    };
    return DeleteRequest;
}());
exports.DeleteRequest = DeleteRequest;
var GoogleDataPayload = /** @class */ (function () {
    function GoogleDataPayload(m) {
        this._json = {};
        if (m) {
            this._json['picture'] = m['picture'];
            this._json['url'] = m['url'];
        }
    }
    Object.defineProperty(GoogleDataPayload.prototype, "picture", {
        // picture (picture)
        get: function () {
            return this._json.picture;
        },
        set: function (value) {
            this._json.picture = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GoogleDataPayload.prototype, "url", {
        // url (url)
        get: function () {
            return this._json.url;
        },
        set: function (value) {
            this._json.url = value;
        },
        enumerable: true,
        configurable: true
    });
    GoogleDataPayload.fromJSON = function (m) {
        if (m === void 0) { m = {}; }
        return new GoogleDataPayload({
            picture: m['picture'],
            url: m['url']
        });
    };
    GoogleDataPayload.prototype.toJSON = function () {
        return this._json;
    };
    return GoogleDataPayload;
}());
exports.GoogleDataPayload = GoogleDataPayload;
var ReportsService = /** @class */ (function () {
    function ReportsService(hostname, fetch) {
        this.path = '/twirp/admin.statistics.ReportsService/';
        this.hostname = hostname;
        this.fetch = fetch;
    }
    ReportsService.prototype.url = function (name) {
        return this.hostname + this.path + name;
    };
    ReportsService.prototype.getOne = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('GetOne'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return GetOneResponse.fromJSON(m); });
        });
    };
    ReportsService.prototype.getMany = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('GetMany'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return GetManyResponse.fromJSON(m); });
        });
    };
    ReportsService.prototype.create = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('Create'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return CreateResponse.fromJSON(m); });
        });
    };
    ReportsService.prototype.update = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('Update'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return UpdateResponse.fromJSON(m); });
        });
    };
    ReportsService.prototype.delete = function (params, headers) {
        if (headers === void 0) { headers = {}; }
        return this.fetch(this.url('Delete'), twirp_1.createTwirpRequest(params, headers)).then(function (res) {
            if (!res.ok) {
                return twirp_1.throwTwirpError(res);
            }
            return res.json().then(function (m) { return DeleteResponse.fromJSON(m); });
        });
    };
    return ReportsService;
}());
exports.ReportsService = ReportsService;
