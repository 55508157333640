import React from 'react';
import { Chat } from '@aisales/react-uikit';
import { backendURL, twirpFetchOptions } from '@main/toRemove';
import chatBackground from './tools-icons/landbot.png';

export const toolTypes = [
  {
    name: 'Чат',
    type: 'landbot',
    picture: chatBackground,
  },
];

export const getType = (alias) => {
  switch (alias) {
    case 'landbot':
      return {
        name: 'Чат',
        icon: <Chat style={{ fontSize: 'inherit' }} />,
      };
    default:
      return 'Неизвестный тип';
  }
};

export const showToast = (str, ok) => {
  // toast(
  //     <Notification
  //         type={ok ? "info" : "report"}
  //         message={str}
  //         variant={"contained"}
  //         color={ok ? "success" : "error"}
  //     />,
  //     {
  //         position: toast.POSITION.TOP_RIGHT,
  //         progressClassName: "progress",
  //         className: "notification",
  //     }
  // );
};

export const escapeRegex = (str) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

export const updateSearchParam = (key, value) => {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set(key, value);
  let newUrl = `${window.location.origin + window.location.pathname}?`;
  searchParams.forEach((value, key) => (newUrl += `${key}=${value}&`));
  newUrl = newUrl.substr(0, newUrl.length - 1);
  window.history.pushState({ path: newUrl }, '', newUrl);
};

export const isRecent = (date) => {
  const currentDate = new Date();
  const offerDate = new Date(date * 1000);
  const sevenDaysEarlier = new Date(currentDate.getTime() - (7 * 24 * 60 * 60 * 1000));
  return sevenDaysEarlier < offerDate;
};

export const setFavorite = async ({ isFavorite, offerId }) => {
  if (isFavorite) {
    await setFavoriteTrue(offerId);
  } else {
    await setFavoriteFalse(offerId);
  }
};

export const setFavoriteTrue = async (offerId) => {
  try {
    const res = await fetch(
      `${backendURL
      }/twirp/cpa.offers.OffersManagementService/SetFavorite`,
      {
        ...twirpFetchOptions,
        body: JSON.stringify({
          offer_id: offerId,
        }),
      },
    );
    if (res.ok) {
      const json = await res.json();
    } else {
      throw new Error(res.statusText);
    }
  } catch (e) {

  }
};

export const setFavoriteFalse = async (offerId) => {
  const res = await fetch(
    `${backendURL
    }/twirp/cpa.offers.OffersManagementService/UnsetFavorite`,
    {
      ...twirpFetchOptions,
      body: JSON.stringify({
        offer_id: offerId,
      }),
    },
  );
  if (res.ok) {
    const json = await res.json();
  } else {
    throw new Error(res.statusText);
  }
};
