import { CorrectIcon, blueColor, Button } from '@main/lib';
import React from 'react';
import { withStyles } from '@material-ui/core';

const DashboardCorrectReportButton = ({ classes, onClick }) => (
  <Button className={classes.dashboardCorrectReportButton} onClick={onClick}>
    <CorrectIcon />
  </Button>
);

const styles = () => ({
  dashboardCorrectReportButton: {
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
    width: '24px',
    height: '24px',
    padding: '0px',
  },
});

export default withStyles(styles)(DashboardCorrectReportButton);
